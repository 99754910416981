var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _c(
        "p",
        { staticClass: "text-h7 mt-4 success--text font-weight-medium" },
        [_vm._v("WORKFLOW")]
      ),
      _c(
        "v-row",
        { attrs: { "no-gutters": "", align: "center" } },
        [
          _c("div", [
            _c(
              "p",
              {
                staticClass: "text-h3 my-0",
                staticStyle: { "font-weight": "600" },
              },
              [_vm._v(" Active shipping leads ")]
            ),
          ]),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              class: {
                "mt-6": _vm.$vuetify.breakpoint.mobile,
              },
              attrs: {
                width: _vm.$vuetify.breakpoint.mobile ? "100%" : "250px",
                color: "info",
                large: "",
              },
            },
            [_vm._v(" Request a quote ")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-16" },
        [
          _c(
            "v-row",
            {
              class: {
                "flex-column": _vm.$vuetify.breakpoint.mobile,
              },
              attrs: { "no-gutters": "" },
            },
            [
              _c(
                "v-col",
                {
                  class: {
                    "pr-3": !_vm.$vuetify.breakpoint.mobile,
                  },
                  attrs: { cols: _vm.$vuetify.breakpoint.mobile ? 12 : 4 },
                },
                [
                  _c(
                    "p",
                    {
                      staticClass: "text-h6 d-flex align-center",
                      staticStyle: { "font-weight": "600" },
                    },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("mdi-shape-square-rounded-plus"),
                      ]),
                      _c("span", [_vm._v("Less container load")]),
                    ],
                    1
                  ),
                  _c("ShippingLeadsList"),
                  _c(
                    "p",
                    {
                      staticClass: "mt-2 text--disabled font-weight-medium",
                      staticStyle: { "font-size": "12px" },
                    },
                    [
                      _vm._v(
                        " Submit a form for cargo volumes from one cubic meter up to 20m³. LCL shipment is the most economical way to ship small cargo... "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  class: {
                    "px-3 border-list": !_vm.$vuetify.breakpoint.mobile,
                  },
                  attrs: { cols: _vm.$vuetify.breakpoint.mobile ? 12 : 4 },
                },
                [
                  _c(
                    "p",
                    {
                      staticClass: "text-h6 d-flex align-center",
                      staticStyle: { "font-weight": "600" },
                    },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("mdi-shape-square-rounded-plus"),
                      ]),
                      _c("span", [_vm._v("Full container load")]),
                    ],
                    1
                  ),
                  _c("ShippingLeadsList"),
                  _c(
                    "p",
                    {
                      staticClass: "mt-2 text--disabled font-weight-medium",
                      staticStyle: { "font-size": "12px" },
                    },
                    [
                      _vm._v(
                        " Transportation in ocean containers (FCL). A whole container is utlised for one consignee. Submit a rate request form for a type of container "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  class: {
                    "pl-3": !_vm.$vuetify.breakpoint.mobile,
                  },
                  attrs: { cols: _vm.$vuetify.breakpoint.mobile ? 12 : 4 },
                },
                [
                  _c(
                    "p",
                    {
                      staticClass: "text-h6 d-flex align-center",
                      staticStyle: { "font-weight": "600" },
                    },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("mdi-shape-square-rounded-plus"),
                      ]),
                      _c("span", [_vm._v("In Bulk")]),
                    ],
                    1
                  ),
                  _c("ShippingLeadsList"),
                  _c(
                    "p",
                    {
                      staticClass: "mt-2 text--disabled font-weight-medium",
                      staticStyle: { "font-size": "12px" },
                    },
                    [
                      _vm._v(
                        " Break bulk cargo, bulk cargoes, project cargo and heavy lift, military equipment or almost any other oversized or overweight cargo. "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }