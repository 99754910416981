var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "text-center", staticStyle: { "font-weight": "600" } },
    [
      _c(
        "div",
        {
          staticClass: "text--secondary overline",
          staticStyle: { "font-size": "12px", "line-height": "1rem" },
        },
        [_vm._v(" " + _vm._s(_vm.$filters.formatDate(_vm.date)) + " ")]
      ),
      _c(
        "div",
        {
          staticStyle: { "line-height": "1" },
          style: _vm.$vuetify.breakpoint.mobile
            ? `font-size:18px;`
            : `font-size:30px;`,
        },
        [_vm._v(" " + _vm._s(_vm.code) + " ")]
      ),
      _c(
        "div",
        {
          style: _vm.$vuetify.breakpoint.mobile
            ? `font-size:8px; line-height:1.5;`
            : `font-size:10px; line-height:1.5;`,
        },
        [_vm._v(" " + _vm._s(_vm.terminalName) + " ")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }