var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { staticClass: "background" },
    [
      _c("div", { staticStyle: { position: "relative" } }, [
        _c("div", { staticClass: "bg-globe" }),
      ]),
      _c(
        "v-app-bar",
        { attrs: { flat: "", color: "background" } },
        [
          _c(
            "v-container",
            {
              class: {
                "pa-0": _vm.$vuetify.breakpoint.mobile,
              },
            },
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c("img", {
                    class: {
                      "ml-2": !_vm.$vuetify.breakpoint.mobile,
                    },
                    attrs: {
                      alt: "VSL logo",
                      src: "/assets/logo_vimc_lines.PNG",
                      height: "36px",
                    },
                  }),
                  _c("v-spacer"),
                  _c("ChangeLanguageButton", { staticClass: "mr-8" }),
                  _c(
                    "v-btn",
                    {
                      staticClass: "text--primary",
                      attrs: {
                        rounded: "",
                        color: "secondary",
                        to: "/app",
                        target: "_blank",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("button-login")) + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-main",
        [
          _c(
            "v-container",
            [
              _c("HeaderSearch", { staticStyle: { "min-height": "700px" } }),
              _c("AllServices"),
            ],
            1
          ),
          _c("NewsSlider", {
            staticStyle: { "min-height": "100vh", "margin-top": "170px" },
          }),
          _c("PartnerSlider", { staticStyle: { "margin-top": "170px" } }),
          _c(
            "v-container",
            {
              class: {
                "px-0": _vm.$vuetify.breakpoint.mobile,
              },
              staticStyle: { "margin-top": "170px" },
            },
            [_c("GetStarted")],
            1
          ),
          _c("Footer"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }