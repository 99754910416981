<template>
  <v-card
    rounded="lg"
    class="elevation-0 py-6"
    :class="{
      'elevation-2 px-4': !$vuetify.breakpoint.mobile,
    }"
  >
    <div class="mx-2 mt-4 mb-0">
      <v-img
        src="/assets/img/logo.png"
        height="70px"
        contain
      />
    </div>
    <v-toolbar flat>
      <v-toolbar-title class="overline mx-auto">
        {{ $t('login') }}
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text class="py-0">
      <v-form @submit.prevent="onLogin()">
        <v-text-field
          :label="$t('label-login-username-email-phone')"
          prepend-inner-icon="mdi-account-circle"
          type="text"
          outlined
          autofocus
          :disabled="isLoading"
          v-model.trim="$v.account.$model"
        />
        <v-text-field
          :label="$t('label-login-password')"
          prepend-inner-icon="mdi-lock"
          ref="passwordBox"
          name="password"
          type="password"
          outlined
          :disabled="isLoading"
          v-model.trim="$v.password.$model"
        />
        <v-btn
          width="100%"
          x-large
          color="primary"
          depressed
          type="submit"
          :loading="isLoading"
          :disabled="isLoading || $v.$invalid"
          @click="onLogin()"
          >{{ $t('login') }}
        </v-btn>
      </v-form>
    </v-card-text>
    <v-card-actions class="d-flex flex-column px-4">
      <div class="pt-4">
        {{ $t('dont-have-account-yet') }}
        <span
          ><router-link to="/auth/signup">{{
            $t('signup-now')
          }}</router-link></span
        >
      </div>
      <!-- <router-link class="pt-4" to="/auth/forgot-password"
        >Quên mật khẩu?</router-link
      > -->
    </v-card-actions>
  </v-card>
</template>

<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import { mapState } from 'vuex';

export default {
  data: () => ({
    isLoading: false,
    account: '',
    password: '',
  }),
  validations: {
    account: {
      required,
      minLength: minLength(3),
    },
    password: {
      required,
      minLength: minLength(1),
      maxLength: maxLength(32),
    },
  },
  computed: {
    ...mapState('ui', ['dialogAuth']),
    accountErrors() {
      const errors = [];
      const field = this.$v.account;
      if (!field.$dirty) return errors;
      !field.minLength && errors.push('Tài khoản không hợp lệ');
      !field.required && errors.push('Tài khoản bắt buộc');
      return errors;
    },
    passwordErrors() {
      const errors = [];
      const field = this.$v.password;
      if (!field.$dirty) return errors;
      !field.minLength && errors.push('Mật khẩu phải có tối thiểu 1 kí tự');
      !field.maxLength && errors.push('Mật khẩu chứa tối đa 32 kí tự');
      !field.required && errors.push('Mật khẩu bắt buộc');
      return errors;
    },
    dialog: {
      get() {
        return this.dialogAuth;
      },
      set(value) {
        this.TOGGLE_DIALOG_AUTH(value);
      },
    },
  },
  methods: {
    async onLogin() {
      try {
        this.isLoading = true;

        const form = {
          email: this.account,
          password: this.password,
        };
        await this.$store.dispatch('auth/login', form);

        const redirect = this.$route.query.redirect;

        if (redirect) {
          this.$router.replace(redirect);
        } else {
          this.$router.push('/app');
        }
      } catch (error) {
        const snackbarOption = {
          message: '',
          color: 'error',
        };
        if (error?.response?.status === 401) {
          snackbarOption.message = 'Thông tin đăng nhập không chính xác!';
        } else {
          console.log('[LOG] : error', error);
          snackbarOption.message = 'Có lỗi xảy ra!';
        }
        this.$store.dispatch('ui/showSnackbar', snackbarOption);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style></style>
