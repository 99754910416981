<template>
  <div class="">
    <div class="overline white--text mt-4" style="font-size: 14px !important;">Voyages</div>
    <div class="d-flex flex-column-reverse">
      <div
        v-for="(voyage, index) of containerMovements.voyages"
        :key="index"
      >
        <ContainerTrackingSearchResultItemVoyage
          class="mb-4"
          :voyage="voyage"
          :current-port-code="containerMovements.currentPortCode"
          :force-active="index < containerMovements.voyages.length - 1"
        />
      </div>
    </div>
    <div class="overline white--text"  style="font-size: 14px !important;">Activity Tracking</div>
    <ContainerTrackingSearchResultItemState
      class="mb-4"
      :logs="containerMovements.stepLogs"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ContainerTrackingSearchResultItemState from './ContainerTrackingSearchResultItemState.vue';
import ContainerTrackingSearchResultItemVoyage from './ContainerTrackingSearchResultItemVoyage.vue';
export default {
  components: {
    ContainerTrackingSearchResultItemVoyage,
    ContainerTrackingSearchResultItemState,
  },
  data: () => ({}),
  computed: {
    ...mapState('vsl/containerTracking', ['containerMovements']),
    movementDateRange() {
      const pocs = this.containerMovements.pocs;
      const startDate =
        pocs[0] && this.$filters.formatDate(pocs[0].estimatedTime);
      const endDate =
        pocs[pocs.length - 1] &&
        this.$filters.formatDate(pocs[pocs.length - 1].estimatedTime);
      return `${startDate} - ${endDate}`;
    },
  },

  created() {},
  methods: {},
};
</script>

<style scoped lang="scss">
:deep {
}
</style>
