var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        { attrs: { rounded: "lg", elevation: "0" } },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "", align: "top" } },
            [
              _c("v-col", { staticClass: "pl-4", attrs: { cols: "2" } }, [
                _c(
                  "div",
                  {
                    staticClass: "font-weight-bold primary--text mt-1",
                    staticStyle: { "font-size": "26px" },
                  },
                  [_vm._v(" " + _vm._s(_vm.voyage.code || "UNKNOWN") + " ")]
                ),
                _c(
                  "div",
                  { staticClass: "d-flex flex-column" },
                  [
                    _c(
                      "v-row",
                      {
                        staticClass: "font-weight-semibold",
                        attrs: { "no-gutters": "" },
                      },
                      [
                        _c("v-icon", { attrs: { small: "", left: "" } }, [
                          _vm._v("mdi-ferry"),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "text-truncate",
                            staticStyle: {
                              "font-size": "12px",
                              "max-width": "80%",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.voyage.vesselName || "UNKNOWN") +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      {
                        staticClass: "font-weight-semibold",
                        attrs: { "no-gutters": "" },
                      },
                      [
                        _c("v-icon", { attrs: { small: "", left: "" } }, [
                          _vm._v("mdi-timelapse"),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "text-truncate",
                            staticStyle: {
                              "font-size": "12px",
                              "max-width": "80%",
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.voyageDateRange) + " ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "v-col",
                { staticClass: "py-3", attrs: { cols: "10" } },
                [
                  _c(
                    "v-stepper",
                    {
                      attrs: { "alt-labels": "", elevation: "0" },
                      model: {
                        value: _vm.active,
                        callback: function ($$v) {
                          _vm.active = $$v
                        },
                        expression: "active",
                      },
                    },
                    [
                      _c(
                        "v-stepper-header",
                        [
                          _vm._l(_vm.voyage.pocs, function (poc, index) {
                            return [
                              _c(
                                "v-stepper-step",
                                {
                                  key: `${poc.id}-step`,
                                  attrs: {
                                    complete: _vm.isActive(index),
                                    step: index,
                                    "complete-icon": "",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex flex-column text-center text-uppercase text-no-wrapx",
                                    },
                                    [
                                      poc.estimatedTime
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "text--secondary overline",
                                              staticStyle: {
                                                "font-size": "12px",
                                                "line-height": "1rem",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$filters.formatDate(
                                                      poc.estimatedTime
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      poc.portName
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "text--primary mt-2 mb-1 text-uppercase",
                                              staticStyle: {
                                                "font-weight": "600",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(poc.portName) + " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      poc.terminalName
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "text--secondary text-uppercase",
                                              staticStyle: {
                                                "font-size": "12px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(poc.terminalName) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ]
                              ),
                              index !== _vm.voyage.pocs.length - 1
                                ? _c("v-divider", { key: poc.id })
                                : _vm._e(),
                            ]
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }