<template>
  <v-card
    rounded="lg"
    class="elevation-0 py-6"
    :class="{
      'elevation-2 px-4': !$vuetify.breakpoint.mobile,
    }"
  >
    <div class="mx-2 mt-4 mb-0">
      <v-img
        src="/assets/img/logo.png"
        height="70px"
        contain
      />
    </div>
    <v-toolbar flat>
      <v-toolbar-title class="overline mx-auto">
        {{ $t('title-update-account-information') }}
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text class="py-0">
      <v-form
        @submit="onSignup()"
        onSubmit="return false;"
      >
        <v-text-field
          v-model.trim="signupPhone"
          :label="$t('label-phone')"
          prepend-inner-icon="mdi-cellphone"
          type="text"
          outlined
          :disabled="true"
        />
        <v-text-field
          v-model="$v.name.$model"
          :label="$t('label-name')"
          prepend-inner-icon="mdi-account"
          type="text"
          outlined
          autofocus
          :disabled="isLoading"
          :error-messages="nameErrors"
          :counter="50"
          @input="$v.name.$touch()"
          @blur="$v.name.$touch()"
        />
        <AddressLocationPicker
          v-model="$v.addressLocation.$model"
          :disabled="isLoading"
          outlined
          auto-select-first-district
          :province-label="$t('label-province')"
          :district-label="$t('label-district')"
          :error-messages="addressLocationErrors($v)"
          @input="$v.addressLocation.$touch()"
          @blur="$v.addressLocation.$touch()"
        />
        <v-text-field
          v-model="$v.address.$model"
          :label="$t('label-address')"
          prepend-inner-icon="mdi-map-marker"
          type="text"
          outlined
          :disabled="isLoading"
          :error-messages="addressErrors"
          :counter="200"
          @input="$v.address.$touch()"
          @blur="$v.address.$touch()"
        />
        <v-text-field
          v-model.trim="$v.password.$model"
          :label="$t('label-password')"
          prepend-inner-icon="mdi-lock"
          type="password"
          outlined
          autocomplete="new-password"
          :error-messages="passwordErrors"
          :counter="32"
          @input="$v.password.$touch()"
          @blur="$v.password.$touch()"
        />
        <v-text-field
          v-model.trim="$v.confirmPassword.$model"
          :label="$t('label-repassword')"
          prepend-inner-icon="mdi-lock"
          type="password"
          outlined
          autocomplete="new-password"
          :error-messages="confirmPasswordErrors"
          :counter="32"
          @input="$v.confirmPassword.$touch()"
          @blur="$v.confirmPassword.$touch()"
        />

        <p class="overline">{{ $t('title-booking-email') }}</p>
        <v-text-field
          v-model.trim="$v.email1.$model"
          :label="$t('label-email')"
          prepend-inner-icon="mdi-email"
          type="text"
          outlined
          :disabled="isLoading"
          :error-messages="email1Errors"
          :counter="50"
          @input="$v.email1.$touch()"
          @blur="$v.email1.$touch()"
        />
        <v-text-field
          v-model.trim="$v.email2.$model"
          :label="$t('label-email') + ' 2'"
          prepend-inner-icon="mdi-email"
          type="text"
          outlined
          :disabled="isLoading"
          :error-messages="email2Errors"
          :counter="50"
          @input="$v.email2.$touch()"
          @blur="$v.email2.$touch()"
        />
      </v-form>
      <div
        id="recaptcha-container"
        class="d-flex justify-center align-center"
      ></div>
    </v-card-text>
    <v-card-actions class="d-flex flex-column px-4">
      <v-btn
        width="100%"
        x-large
        color="primary"
        depressed
        :loading="isLoading"
        :disabled="isLoading || $v.$invalid"
        @click="onSignup()"
        >{{ $t('button-create-account') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import AddressLocationPicker from '@/components/shared/local/base/AddressLocationPicker.vue';
import {
  required,
  minLength,
  maxLength,
  sameAs,
  email,
} from 'vuelidate/lib/validators';
import { mapActions, mapState } from 'vuex';

export default {
  components: { AddressLocationPicker },
  data: () => ({
    isLoading: false,
    name: '',
    address: '',
    addressLocation: {
      provinceCode: null,
      districtCode: null,
    },
    password: '',
    confirmPassword: '',
    email1: '',
    email2: '',
  }),
  validations: {
    name: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(100),
    },
    address: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(200),
    },
    addressLocation: {
      provinceCode: {
        required,
      },
      districtCode: {
        required,
      },
    },
    password: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(32),
    },
    confirmPassword: {
      required,
      sameAsPassword: sameAs('password'),
    },
    email1: {
      required,
      email,
    },
    email2: {
      email,
    },
  },
  computed: {
    ...mapState('ui', ['dialogAuth']),
    ...mapState('auth', ['signupPhone']),
    nameErrors() {
      const errors = [];
      const field = this.$v.name;
      if (!field.$dirty) return errors;
      !field.minLength &&
        errors.push(this.$t('validation-error-name-min-length'));
      !field.maxLength &&
        errors.push(this.$t('validation-error-name-max-length'));
      !field.required && errors.push(this.$t('validation-error-name-required'));
      return errors;
    },
    addressErrors() {
      const errors = [];
      const field = this.$v.address;
      if (!field.$dirty) return errors;
      !field.minLength &&
        errors.push(this.$t('validation-error-address-min-length'));
      !field.maxLength &&
        errors.push(this.$t('validation-error-address-max-length'));
      !field.required &&
        errors.push(this.$t('validation-error-address-required'));
      return errors;
    },
    addressLocationErrors() {
      return (v) => {
        const errors = [];
        const field = v.addressLocation;
        if (!field.$dirty) return errors;
        !field.provinceCode.required &&
          errors.push(this.$t('validation-error-address-location-required'));
        return errors;
      };
    },
    passwordErrors() {
      const errors = [];
      const field = this.$v.password;
      if (!field.$dirty) return errors;
      !field.minLength &&
        errors.push(this.$t('validation-error-password-min-length'));
      !field.maxLength &&
        errors.push(this.$t('validation-error-password-max-length'));
      !field.required &&
        errors.push(this.$t('validation-error-password-required'));
      return errors;
    },
    confirmPasswordErrors() {
      const errors = [];
      const field = this.$v.confirmPassword;
      if (!field.$dirty) return errors;
      !field.sameAsPassword &&
        errors.push(this.$t('validation-error-repassword-same-as'));
      !field.required &&
        errors.push(this.$t('validation-error-repassword-required'));
      return errors;
    },
    email1Errors() {
      const errors = [];
      const field = this.$v.email1;
      if (!field.$dirty) return errors;
      !field.email && errors.push(this.$t('validation-error-email-valid'));
      !field.required &&
        errors.push(this.$t('validation-error-email-required'));
      return errors;
    },
    email2Errors() {
      const errors = [];
      const field = this.$v.email2;
      if (!field.$dirty) return errors;
      !field.email && errors.push(this.$t('validation-error-email-valid'));
      return errors;
    },
    dialog: {
      get() {
        return this.dialogAuth;
      },
      set(value) {
        this.TOGGLE_DIALOG_AUTH(value);
      },
    },
  },
  async created() {
    if (!this.signupPhone) {
      this.$router.push('/auth/signup');
    }
  },

  methods: {
    ...mapActions('auth', ['signup']),
    async onSignup() {
      try {
        this.isLoading = true;
        const form = {
          phone: this.signupPhone,
          name: this.name,
          address: this.address,
          password: this.password,
          emails: [this.email1, this.email2],
          provinceCode: this.addressLocation.provinceCode,
          districtCode: this.addressLocation.districtCode,
        };
        await this.signup(form);
        this.$message.success(this.$t('text-create-account-success'));
        this.$router.push('/auth/login');
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style></style>
