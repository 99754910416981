<template>
  <div class="pa-0">
    <div>
      <v-row
          width="100%"
      >
        <v-col
            :cols="$vuetify.breakpoint.mobile ? 12 : 12"
            :class="{
              'pl-3': !$vuetify.breakpoint.mobile,
            }"
        >
          <div v-for="(item, index) in vesselScheduleList"
               :key="item.code"
               class="pt-8">
            <v-card
                flat
                tile
                width="100%"
                class="py-2 pt-3"
                style="border-radius: 5px !important;"
            >
              <v-row class="align-center" >
                <v-col :cols="10" class="justify-center" >
                  <v-row class="align-center justify-center">
<!--                    <v-col :cols="1" class="text-center"></v-col>-->
                    <v-col :cols="7" class="text-center">
                      <v-row class="align-center justify-center">
                        <v-col :cols="8" class="text-center">
                          <div class="caption bubble-item" >
                            <v-icon
                                color="primary"
                                class="mb-2"
                            >
                              mdi-map-marker-radius
                            </v-icon>
                          </div>
                          <div class="caption bubble-item text-uppercase" style="font-weight: bold; font-size: 14px !important;">{{item.pathSchedules[0].polTerminalName}}</div>
                          <div class="caption bubble-item" style="font-size: 14px !important;">{{$filters.formatDateSlash(new Date(item.pathSchedules[0].etd))}}</div>
                        </v-col>
                        <v-col :cols="4" class="text-center">
                          <div class="">
                            <div>
                              <img height="70px" style="padding-left: 10px;" src="https://cdn-icons-png.flaticon.com/256/1077/1077717.png"/>
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col :cols="5" class="text-center">
                      <v-row class="align-center justify-center">
                        <v-col :cols="11" class="text-center">
                          <div class="caption bubble-item">
                            <v-icon
                                color="red"
                                class="mb-2"
                            >
                              mdi-map-marker-radius
                            </v-icon>
                          </div>
                          <div class="caption bubble-item text-uppercase" style="font-weight: bold; font-size: 14px !important;">{{item.pathSchedules[item.pathSchedules.length - 1].podTerminalName}}</div>
                          <div class="caption bubble-item" style="font-size: 14px !important;">{{$filters.formatDateSlash(new Date(item.pathSchedules[item.pathSchedules.length - 1].eta))}}</div>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col :cols="2" class="text-center">
                  <div style="border-left: 1px solid rgb(220, 223, 230);">
                    <button
                        class="px-20 mt-2 mr-3 multiline-button"
                        style="line-height: 0.8;
                        border-radius: 3px;
                        width: 115px;
                        background-color: #e6734f;
                        color: white;
                        margin-left: 4px;"
                        @click="onClick(item)"
                    >
                      <div class="mt-4 mb-4" style="font-size:16px;">
<!--                        <h3 class="mb-2 text&#45;&#45;lighten-1" style="color: white; font-weight: bold;">$100</h3>-->
                        {{ $t('book-now') }}
                      </div>
                    </button>
                    <button
                        class="px-20 mt-2 mr-3 multiline-button gray-hover"
                        style="line-height: 0.8;
                      border-radius: 3px;
                      width: 115px;
                      color: #606266;
                      margin-left: 4px;"
                        @click="toggleDetail(index)"
                    >
                      <div class="mt-4 mb-4" style="font-size:16px;">
                        {{ $t('view-details') }}
                      </div>
                    </button>
                  </div>
                </v-col>
              </v-row>
              <!--              viewDetail-->
<!--              <ViewDetailBooking v-if="showDetail === item.code"/>-->
              <VesselScheduleSearchResultListItemDetail
                v-if="showDetail === index"
                :path-schedules="item.pathSchedules"
                class="mb-4 mt-4"
              />
            </v-card>
          </div>
<!--          <div class="mt-2 mb-2" align="center">-->
<!--            <v-btn-->
<!--                style="text-transform: none;"-->
<!--                width="26%"-->
<!--                class="mb-2"-->
<!--                large-->
<!--                color="info"-->
<!--                depressed-->
<!--                @click="onSend"-->
<!--            >-->
<!--              {{ $t('request-quote') }}-->
<!--              <v-icon right> mdi-arrow-top-right</v-icon>-->
<!--            </v-btn>-->
<!--          </div>-->
        </v-col>

      </v-row>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import {mapActions, mapMutations, mapState} from 'vuex';
import VesselScheduleSearchResultListItemV2 from './VesselScheduleSearchResultListItemV2.vue';
import NoData from '@/components/shared/local/base/NoData.vue';
import ContainerPicker from "@/components/shared/local/vsl/ContainerPicker.vue";
import VesselScheduleSearchResultListItemDetail
  from '@/components/shared/local/vsl/VesselScheduleSearch/VesselScheduleSearchResultListItemDetail.vue';

export default {
  inject: ['getIsSearched'],
  props: {
    vesselScheduleList: {
      type: Array,
    },
  },
  components: { VesselScheduleSearchResultListItemDetail, ContainerPicker, VesselScheduleSearchResultListItemV2, NoData},
  data: () => ({
    showDetail: false,
    currentIndex: 0,
  }),
  computed: {
    ...mapState('vsl/vesselSchedule', [
      'vesselScheduleList',
      'vesselScheduleListQueryParams',
      'vesselScheduleListLoading',
      'vesselScheduleListTotal',
    ]),
  },
  async created() {
  },
  methods: {
   async onClick(item) {
      try {
        this.isLoading = true;
        // await this.$router
        //     .push({
        //       path: 'request-a-quote-V2',
        //       // query: {
        //       //   ...this.$route.query,
        //       //   vessel_code: item?.code,
        //       // },
        //     })
        //     .catch(() => {
        //     });
        await this.$router
          .push({
            path: '/app/shipping/request-a-quote/submit',
            query: {
              schedules_codes: item.pathSchedules.map(({ code }) => code).join(),
                path: item.path.join(),
            },
          })



      } finally {
        this.isLoading = false;
      }
    },
    async onSend() {
      try {
        this.isLoading = true;
        await this.$router
            .push({
              path: 'request-a-quote-send',
              // query: {
              //   ...this.$route.query,
              //   vessel_code: item?.code,
              // },
            })
            .catch(() => {
            });

      } finally {
        this.isLoading = false;
      }
    },
    toggleDetail(index) {
      this.showDetail = this.showDetail === index ? null : index;
    },
  },
};
</script>

<style>

.pa-0{
  width: 1294px;
}
.gray-hover:hover {
  background-color: #f2f2f2;
}

.gray-hover {
  background-color: white;
}
</style>
