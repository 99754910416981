import OrderApi from '@/api/vpl/order.api';
import dayjs from 'dayjs';

const initialState = () => ({
  order: {
    id: null,
    code: null,
    port: {
      id: null,
      name: null,
    },
    customerService: {
      id: null,
      name: null,
    },
    status: null,
    userNote: '',
    attachmentFiles: [],
    createdAt: null,
    updatedAt: null,
  },
  orderList: [],
  orderShipmentList: [],
  orderActionLog: {
    id: null,
    type: null,
    datetime: null,
  },
  orderActionLogList: [],
  orderCommentList: [],
});

const state = initialState();
const getters = {};
const mutations = {
  RESET(state, payload) {
    const initStates = initialState();
    if (!payload) {
      Object.keys(initStates).map((key) => {
        state[key] = initStates[key];
      });
    } else {
      const statesToReset = payload;
      statesToReset.map((key) => {
        state[key] = initStates[key];
      });
    }
  },
  SET_ORDER(state, payload) {
    state.order = payload;
  },
  SET_ORDER_LIST(state, payload) {
    state.orderList = payload;
  },
  SET_ORDER_SHIPMENT_LIST(state, payload) {
    state.orderShipmentList = payload;
  },
  SET_ORDER_ACTION_LOG_LIST(state, payload) {
    state.orderActionLogList = payload;
  },
  SET_ORDER_COMMENT_LIST(state, payload) {
    state.orderCommentList = payload;
  },
};
const actions = {
  async fetchOrderList({ commit }, payload) {
    const res = await OrderApi.fetchOrderList();
    const mapped = res.data.data.map((item) => ({
      id: item.id,
      code: item.code,
      port: item.port,
      customerService: item.customer_service,
      status: item.status,
      createdAt: item.created_at,
      updatedAt: item.updated_at,
    }));
    commit('SET_ORDER_LIST', mapped);
  },
  async fetchOrder({ commit }, payload) {
    const { id } = payload;
    const res = await OrderApi.fetchOrder({ id });
    const { data } = res.data;

    const mappedAttachmentFiles = data.files.map((item) => {
      return {
        fileName: item.file_name,
        displayName: item.display_name,
        mimetype: item.mimetype,
        size: item.size,
        link: `${process.env.VUE_APP_VPL_API}/v1/upload/${item.file_name}`,
      };
    });

    const mapped = {
      id: data.id,
      code: data.code,
      port: data.port,
      customerService: data.customer_service,
      status: data.status,
      userNote: data.user_note,
      attachmentFiles: mappedAttachmentFiles,
      createdAt: data.created_at,
      updatedAt: data.updated_at,
    };

    commit('SET_ORDER', mapped);
  },
  async createOrder({ commit }, payload) {
    const { portCode, customerServiceId } = payload;

    const res = await OrderApi.createOrder({
      port_code: portCode,
      customer_service_id: customerServiceId,
    });
  },
  async updateOrder({ commit }, payload) {
    const { id, userNote, attachmentFiles } = payload;
    const attachmentFileNames = attachmentFiles.map(({ fileName }) => fileName);
    const res = await OrderApi.updateOrder(
      { id },
      {
        user_note: userNote,
        attachment_file_names: attachmentFileNames,
      }
    );
  },
  async updateOrderUserNote({ commit }, payload) {
    const { id, userNote } = payload;
    const res = await OrderApi.updateOrderUserNote(
      { id },
      {
        user_note: userNote,
      }
    );
  },
  async updateOrderAttachments({ commit }, payload) {
    const { id, attachmentFiles } = payload;
    const attachmentFileNames = attachmentFiles.map(({ fileName }) => fileName);
    const res = await OrderApi.updateOrderAttachments(
      { id },
      {
        attachments: attachmentFileNames,
      }
    );
  },
  async deleteOrder(context, payload) {
    const { id } = payload;
    await OrderApi.deleteOrder({ id });
  },
  async createOrderShipment({ commit }, payload) {
    const {
      orderId,
      driverName,
      driverPhone,
      driverVehicle,
      driverVehicleRegistrationFiles,
      driverIdentificationNumber,
      containerName,
      pickUpDate,
      pickUpTime,
    } = payload;

    const [pickupHour, pickupMinute] = pickUpTime
      .split(':')
      .map((item) => Number(item));

    const driverVehicleRegistrationFileNames =
      driverVehicleRegistrationFiles.map(({ fileName }) => fileName);

    const res = await OrderApi.createOrderShipment({
      order_id: orderId,
      driver_name: driverName,
      driver_phone: driverPhone,
      driver_vehicle: driverVehicle,
      files: driverVehicleRegistrationFileNames,
      driver_identification_number: driverIdentificationNumber,
      container_name: containerName,
      pickup_at: dayjs(pickUpDate)
        .hour(pickupHour)
        .minute(pickupMinute)
        .toDate(0),
    });
  },
  async updateOrderShipment({ commit }, payload) {
    const {
      id,
      orderId,
      driverName,
      driverPhone,
      driverVehicle,
      driverVehicleRegistrationFiles,
      driverIdentificationNumber,
      containerName,
      pickUpDate,
      pickUpTime,
    } = payload;

    const [pickupHour, pickupMinute] = pickUpTime
      .split(':')
      .map((item) => Number(item));

    const driverVehicleRegistrationFileNames =
      driverVehicleRegistrationFiles.map(({ fileName }) => fileName);

    const res = await OrderApi.updateOrderShipment(
      { id },
      {
        order_id: orderId,
        driver_name: driverName,
        driver_phone: driverPhone,
        driver_vehicle: driverVehicle,
        files: driverVehicleRegistrationFileNames,
        driver_identification_number: driverIdentificationNumber,
        container_name: containerName,
        pickup_at: dayjs(pickUpDate)
          .hour(pickupHour)
          .minute(pickupMinute)
          .toDate(0),
      }
    );
  },
  async deleteOrderShipment(context, payload) {
    const { orderId, id } = payload;
    await OrderApi.deleteOrderShipment({ orderId, id });
  },
  async fetchOrderShipmentList({ commit }, payload) {
    const { orderId } = payload;
    const res = await OrderApi.fetchOrderShipmentList({ orderId });
    const mapped = res.data.data.map((item) => {
      const mappedFiles = item.files
        .filter((file) => file.type === 'DRIVER_REGISTRATION_FILE')
        .map((file) => {
          return {
            fileName: file.file_name,
            displayName: file.display_name,
            mimetype: file.mimetype,
            size: file.size,
            link: `${process.env.VUE_APP_VPL_API}/v1/upload/${file.file_name}`,
          };
        });

      return {
        id: item.id,
        driverName: item.driver_name,
        driverPhone: item.driver_phone,
        driverVehicle: item.driver_vehicle,
        driverVehicleRegistrationFiles: mappedFiles,
        driverIdentificationNumber: item.driver_identification_number,
        containerName: item.container_name,
        pickUpDate: item.pickUpDate,
        verificationCode: item.code,
        status: item.status,
      };
    });
    commit('SET_ORDER_SHIPMENT_LIST', mapped);
  },
  async submitOrder(context, payload) {
    const { id } = payload;
    await OrderApi.submitOrder({ id });
  },
  async fetchOrderActionLogList({ commit }, payload) {
    const { id } = payload;
    const res = await OrderApi.fetchOrderActionLogList({ id });
    const data = res.data.data;
    const mapped = data.map((item) => {
      const mappedItem = {
        id: item.id,
        type: item.action_type,
        datetime: item.created_at,
      };

      if (item.order_shipment) {
        mappedItem.driverName = item.order_shipment.driver_name;
      }

      return mappedItem;
    });
    commit('SET_ORDER_ACTION_LOG_LIST', mapped);
  },
  async fetchOrderCommentList({ commit }, payload) {
    const { id } = payload;
    const res = await OrderApi.fetchOrderCommentList({ id });
    const data = res.data.data;
    const mapped = data.map((item) => {
      const mappedItem = {
        id: item.id,
        content: item.content,
        files: item.files.map((file) => {
          return {
            fileName: file.file_name,
            displayName: file.display_name,
            mimetype: file.mimetype,
            size: file.size,
            link: `${process.env.VUE_APP_VPL_API}/v1/upload/${file.file_name}`,
          };
        }),
        createdAt: dayjs().isBefore(dayjs(item.created_at))
          ? new Date()
          : item.created_at,
      };

      if (item.created_by_booking_user) {
        mappedItem.user = {
          avatar: item.created_by_booking_user.avatar,
          name: item.created_by_booking_user.name,
          username: item.created_by_booking_user.username,
        };
      }
      if (item.created_by_system_user) {
        mappedItem.user = {
          avatar: item.created_by_system_user.avatar,
          name: item.created_by_system_user.name,
          username: item.created_by_system_user.username,
        };
      }

      return mappedItem;
    });
    commit('SET_ORDER_COMMENT_LIST', mapped);
  },
  async createOrderComment({ commit }, payload) {
    const { id, content, files } = payload;

    const res = await OrderApi.createOrderComment({ id }, { content, files });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
