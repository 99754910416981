var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    [
      _c("NavBarHome", { attrs: { active: "recruitment" } }),
      _c("div", {
        staticStyle: { height: "170px", "background-color": "#ffffff" },
      }),
      _c(
        "div",
        {
          staticClass: "transx_page_content_wrapper",
          staticStyle: { "background-color": "#ffffff" },
        },
        [
          _c("div", { staticClass: "recruitment" }, [
            _c("div", { staticClass: "container" }, [
              _c("div", { staticClass: "label" }, [
                _c("h3", [_vm._v(_vm._s(_vm.$t("itEmployee")))]),
                _c("p", [
                  _c("i", { staticClass: "fa fa-calendar" }),
                  _vm._v(" " + _vm._s(_vm.$t("appDeadline")) + ": 15-8-2024 "),
                  _c(
                    "button",
                    {
                      attrs: { id: "button" },
                      on: { click: _vm.scrollToApplySection },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("sendCV")) + " ")]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "common-info" }, [
                _c("h5", [_vm._v(_vm._s(_vm.$t("overview")))]),
                _c("div", { staticClass: "list-info row" }, [
                  _c("div", { staticClass: "item col-6 d-flex" }, [
                    _vm._m(0),
                    _c(
                      "div",
                      {
                        staticClass: "text",
                        staticStyle: {
                          "flex-direction": "column",
                          display: "flex",
                          "align-items": "flex-start",
                        },
                      },
                      [
                        _c("span", { staticStyle: { "font-weight": "700" } }, [
                          _vm._v(
                            _vm._s(
                              this.$i18n.locale === "en"
                                ? "Salary"
                                : "Mức lương"
                            )
                          ),
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              this.$i18n.locale === "en"
                                ? "Agreement"
                                : "Thỏa thuận"
                            )
                          ),
                        ]),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "item col-6 d-flex" }, [
                    _vm._m(1),
                    _c(
                      "div",
                      {
                        staticClass: "text",
                        staticStyle: {
                          "flex-direction": "column",
                          display: "flex",
                          "align-items": "flex-start",
                        },
                      },
                      [
                        _c("span", { staticStyle: { "font-weight": "700" } }, [
                          _vm._v(_vm._s(_vm.$t("workSchedule"))),
                        ]),
                        _c("span", [_vm._v("8h00 - 17h00")]),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "item col-6 d-flex" }, [
                    _vm._m(2),
                    _c(
                      "div",
                      {
                        staticClass: "text",
                        staticStyle: {
                          "flex-direction": "column",
                          display: "flex",
                          "align-items": "flex-start",
                        },
                      },
                      [
                        _c("span", { staticStyle: { "font-weight": "700" } }, [
                          _vm._v(_vm._s(_vm.$t("gender"))),
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              this.$i18n.locale == "en"
                                ? "Male/Female"
                                : "Nam/Nữ"
                            )
                          ),
                        ]),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "item col-6 d-flex" }, [
                    _vm._m(3),
                    _c(
                      "div",
                      {
                        staticClass: "text",
                        staticStyle: {
                          "flex-direction": "column",
                          display: "flex",
                          "align-items": "flex-start",
                        },
                      },
                      [
                        _c("span", { staticStyle: { "font-weight": "700" } }, [
                          _vm._v(
                            _vm._s(
                              this.$i18n.locale == "en"
                                ? "Recruitment Number"
                                : "Số lượng tuyển dụng"
                            )
                          ),
                        ]),
                        _c("span", [_vm._v("1")]),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "item col-6 d-flex" }, [
                    _vm._m(4),
                    _c(
                      "div",
                      {
                        staticClass: "text",
                        staticStyle: {
                          "flex-direction": "column",
                          display: "flex",
                          "align-items": "flex-start",
                        },
                      },
                      [
                        _c("span", { staticStyle: { "font-weight": "700" } }, [
                          _vm._v(
                            _vm._s(
                              this.$i18n.locale === "en" ? "Level" : "Cấp bậc"
                            ) + " "
                          ),
                        ]),
                        _c("span", [_vm._v(_vm._s(_vm.$t("employyee")))]),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "item col-6 d-flex" }, [
                    _vm._m(5),
                    _c(
                      "div",
                      {
                        staticClass: "text",
                        staticStyle: {
                          "flex-direction": "column",
                          display: "flex",
                          "align-items": "flex-start",
                        },
                      },
                      [
                        _c("span", { staticStyle: { "font-weight": "700" } }, [
                          _vm._v(_vm._s(_vm.$t("experience")) + " "),
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              this.$i18n.locale == "en"
                                ? "Have Experience"
                                : "Có kinh Nghiệm"
                            )
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "address" }, [
                _c("h5", { staticStyle: { "font-weight": "700" } }, [
                  _vm._v(_vm._s(_vm.$t("workLocation"))),
                ]),
                _c("ul", [
                  _c("li", [
                    _vm._v(" " + _vm._s(_vm.$t("addressDetail")) + " "),
                  ]),
                  _c("li", [
                    _vm._v(
                      " " +
                        _vm._s(
                          this.$i18n.locale == "en"
                            ? `No. 1 Dao Duy Anh Street, Phuong Mai Ward, Dong Da District, Hanoi`
                            : "Số 01 Đào Duy Anh, Phương Mai, quận Đống Đa, Hà Nội."
                        ) +
                        " "
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "job-desc" }, [
                _c("h2", { staticStyle: { "text-align": "justify" } }, [
                  _c("span", { staticStyle: { "font-size": "16pt" } }, [
                    _c(
                      "span",
                      { staticStyle: { "font-family": "'Times New Roman'" } },
                      [
                        _c("span", { staticStyle: { color: "#000000" } }, [
                          _vm._v(_vm._s(_vm.$t("jobDesc"))),
                        ]),
                      ]
                    ),
                  ]),
                ]),
                _c("ul", [
                  _c("li", { staticStyle: { "list-style-type": "disc" } }, [
                    _c("span", { staticStyle: { "font-size": "12pt" } }, [
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'Times New Roman'" } },
                        [
                          _c("span", { staticStyle: { color: "#000000" } }, [
                            _vm._v(
                              _vm._s(
                                this.$i18n.locale == "en"
                                  ? `Operate email systems and storage systems.`
                                  : "Vận hành hệ thống Email, hệ thống lưu trữ."
                              )
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("li", { staticStyle: { "list-style-type": "disc" } }, [
                    _c("span", { staticStyle: { "font-size": "12pt" } }, [
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'Times New Roman'" } },
                        [
                          _c("span", { staticStyle: { color: "#000000" } }, [
                            _vm._v(
                              _vm._s(
                                this.$i18n.locale == "en"
                                  ? `Manage the company's website and fanpage.`
                                  : "Quản trị Website, Fanpage Công ty."
                              ) + " "
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("li", { staticStyle: { "list-style-type": "disc" } }, [
                    _c("span", { staticStyle: { "font-size": "12pt" } }, [
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'Times New Roman'" } },
                        [
                          _c("span", { staticStyle: { color: "#000000" } }, [
                            _vm._v(
                              _vm._s(
                                this.$i18n.locale == "en"
                                  ? `Operate accounting software, office management, and business operations software.`
                                  : "Vận hành phần mềm Kế toán, nghiệp vụ, quản trị văn phòng"
                              ) + " "
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("li", { staticStyle: { "list-style-type": "disc" } }, [
                    _c("span", { staticStyle: { "font-size": "12pt" } }, [
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'Times New Roman'" } },
                        [
                          _c("span", { staticStyle: { color: "#000000" } }, [
                            _vm._v(
                              _vm._s(
                                this.$i18n.locale == "en"
                                  ? `Operate SQL Server systems.`
                                  : "Vận hành hệ thống SQL Server."
                              ) + " "
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("li", { staticStyle: { "list-style-type": "disc" } }, [
                    _c("span", { staticStyle: { "font-size": "12pt" } }, [
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'Times New Roman'" } },
                        [
                          _c("span", { staticStyle: { color: "#000000" } }, [
                            _vm._v(
                              _vm._s(
                                this.$i18n.locale == "en"
                                  ? `Coordinate with units to deploy and develop business software projects.`
                                  : "Phối hợp đơn vị triển khai phát triển dự án phần mềm kinh doanh."
                              ) + " "
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("li", { staticStyle: { "list-style-type": "disc" } }, [
                    _c("span", { staticStyle: { "font-size": "12pt" } }, [
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'Times New Roman'" } },
                        [
                          _c("span", { staticStyle: { color: "#000000" } }, [
                            _vm._v(
                              _vm._s(
                                this.$i18n.locale == "en"
                                  ? `Knowledge of marketing and system security.`
                                  : "Có kiến thức về Marketing, bảo mật hệ thống."
                              ) + " "
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("li", { staticStyle: { "list-style-type": "disc" } }, [
                    _c("span", { staticStyle: { "font-size": "12pt" } }, [
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'Times New Roman'" } },
                        [
                          _c("span", { staticStyle: { color: "#000000" } }, [
                            _vm._v(
                              _vm._s(
                                this.$i18n.locale == "en"
                                  ? `System security and backup in case of encryption virus attacks.`
                                  : "Bảo mật hệ thống và backup nếu có tấn công virus mã hóa."
                              ) + " "
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("li", { staticStyle: { "list-style-type": "disc" } }, [
                    _c("span", { staticStyle: { "font-size": "12pt" } }, [
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'Times New Roman'" } },
                        [
                          _c("span", { staticStyle: { color: "#000000" } }, [
                            _vm._v(
                              _vm._s(
                                this.$i18n.locale == "en"
                                  ? `Operate VPN connections.`
                                  : "Vận hành kết nối VPN."
                              ) + " "
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("li", { staticStyle: { "list-style-type": "disc" } }, [
                    _c("span", { staticStyle: { "font-size": "12pt" } }, [
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'Times New Roman'" } },
                        [
                          _c("span", { staticStyle: { color: "#000000" } }, [
                            _vm._v(
                              _vm._s(
                                this.$i18n.locale == "en"
                                  ? `Support other IT-related issues at the office.`
                                  : "Hỗ trợ các vấn đề khác liên quan đến IT tại Văn phòng."
                              ) + " "
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                ]),
                _c("h2", { staticStyle: { "text-align": "justify" } }, [
                  _c("span", { staticStyle: { "font-size": "16pt" } }, [
                    _c(
                      "span",
                      { staticStyle: { "font-family": "'Times New Roman'" } },
                      [
                        _c("span", { staticStyle: { color: "#000000" } }, [
                          _vm._v(_vm._s(_vm.$t("requirement"))),
                        ]),
                      ]
                    ),
                  ]),
                ]),
                _c("ul", [
                  _c("li", { staticStyle: { "list-style-type": "disc" } }, [
                    _c("span", { staticStyle: { "font-size": "12pt" } }, [
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'Times New Roman'" } },
                        [
                          _c("span", { staticStyle: { color: "#000000" } }, [
                            _vm._v(
                              _vm._s(
                                this.$i18n.locale == "en"
                                  ? `Graduated from University with a major in Information Technology (IT).`
                                  : "Tốt nghiệp Đại học chuyên ngành CNTT."
                              )
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("li", { staticStyle: { "list-style-type": "disc" } }, [
                    _c("span", { staticStyle: { "font-size": "12pt" } }, [
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'Times New Roman'" } },
                        [
                          _c("span", { staticStyle: { color: "#000000" } }, [
                            _vm._v(
                              _vm._s(
                                this.$i18n.locale == "en"
                                  ? `Preference for candidates with experience in IT in the Logistics industry.`
                                  : "Ưu tiên cho người có kinh nghiệm làm IT ngành Logistics."
                              )
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("li", { staticStyle: { "list-style-type": "disc" } }, [
                    _c("span", { staticStyle: { "font-size": "12pt" } }, [
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'Times New Roman'" } },
                        [
                          _c("span", { staticStyle: { color: "#000000" } }, [
                            _vm._v(
                              _vm._s(
                                this.$i18n.locale == "en"
                                  ? `At least 3 years of experience in network setup and administration.`
                                  : "Có ít nhất 03 năm kinh nghiệm ở vị trí thiết lập và quản trị mạng máy tính."
                              )
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("li", { staticStyle: { "list-style-type": "disc" } }, [
                    _c("span", { staticStyle: { "font-size": "12pt" } }, [
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'Times New Roman'" } },
                        [
                          _c("span", { staticStyle: { color: "#000000" } }, [
                            _vm._v(
                              _vm._s(
                                this.$i18n.locale == "en"
                                  ? `Experience working with Cisco network devices, routers, and switches.`
                                  : "Có kinh nghiệm làm việc với các thiết bị mạng, các loại router, switch của Cisco."
                              )
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("li", { staticStyle: { "list-style-type": "disc" } }, [
                    _c("span", { staticStyle: { "font-size": "12pt" } }, [
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'Times New Roman'" } },
                        [
                          _c("span", { staticStyle: { color: "#000000" } }, [
                            _vm._v(
                              _vm._s(
                                this.$i18n.locale == "en"
                                  ? `Experience in installing, configuring, and maintaining various types of servers.`
                                  : "Có kinh nghiệm cài đặt, cấu hình và bảo trì các loại server."
                              )
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("li", { staticStyle: { "list-style-type": "disc" } }, [
                    _c("span", { staticStyle: { "font-size": "12pt" } }, [
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'Times New Roman'" } },
                        [
                          _c("span", { staticStyle: { color: "#000000" } }, [
                            _vm._v(
                              _vm._s(
                                this.$i18n.locale == "en"
                                  ? `Experience in installing firewalls and network security.`
                                  : "Có kinh nghiệm về cài đặt các loại firewall, bảo mật mạng."
                              )
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("li", { staticStyle: { "list-style-type": "disc" } }, [
                    _c("span", { staticStyle: { "font-size": "12pt" } }, [
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'Times New Roman'" } },
                        [
                          _c("span", { staticStyle: { color: "#000000" } }, [
                            _vm._v(
                              _vm._s(
                                this.$i18n.locale == "en"
                                  ? `Certifications: CCNA, CCNP, MCSE, VMware.`
                                  : "Có chứng chỉ CCNA, CCNP, MCSE, VMware."
                              )
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("li", { staticStyle: { "list-style-type": "disc" } }, [
                    _c("span", { staticStyle: { "font-size": "12pt" } }, [
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'Times New Roman'" } },
                        [
                          _c("span", { staticStyle: { color: "#000000" } }, [
                            _vm._v(
                              _vm._s(
                                this.$i18n.locale == "en"
                                  ? `Proficient in understanding and operating SQL Server, Cloud Server, Windows Server, NAS.`
                                  : "Hiểu rõ và vận hành SQL Server, Cloud Server, Windows Server, NAS."
                              )
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("li", { staticStyle: { "list-style-type": "disc" } }, [
                    _c("span", { staticStyle: { "font-size": "12pt" } }, [
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'Times New Roman'" } },
                        [
                          _c("span", { staticStyle: { color: "#000000" } }, [
                            _vm._v(
                              _vm._s(
                                this.$i18n.locale == "en"
                                  ? `Familiar with Amazon Cloud server infrastructure.`
                                  : "Am hiểu hạ tầng Cloud server Amazon."
                              )
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("li", { staticStyle: { "list-style-type": "disc" } }, [
                    _c("span", { staticStyle: { "font-size": "12pt" } }, [
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'Times New Roman'" } },
                        [
                          _c("span", { staticStyle: { color: "#000000" } }, [
                            _vm._v(
                              _vm._s(
                                this.$i18n.locale == "en"
                                  ? `Operate Camera systems, VoIP, Access Control Face ID.`
                                  : "Vận hành hệ thống Camera,VoIP , Access Control Face ID."
                              )
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("li", { staticStyle: { "list-style-type": "disc" } }, [
                    _c("span", { staticStyle: { "font-size": "12pt" } }, [
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'Times New Roman'" } },
                        [
                          _c("span", { staticStyle: { color: "#000000" } }, [
                            _vm._v(
                              _vm._s(
                                this.$i18n.locale == "en"
                                  ? `Understanding of Misa Accounting software, Fast Accounting.`
                                  : "Hiểu rõ về phần mềm kế Toán Misa, Fast Accounting."
                              )
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("li", { staticStyle: { "list-style-type": "disc" } }, [
                    _c("span", { staticStyle: { "font-size": "12pt" } }, [
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'Times New Roman'" } },
                        [
                          _c("span", { staticStyle: { color: "#000000" } }, [
                            _vm._v(
                              _vm._s(
                                this.$i18n.locale == "en"
                                  ? `Experience in operating mail systems and understanding the operation of Exchange Online Mail.`
                                  : "Có kinh nghiệm vận hành hệ thống mail và nắm rõ hoạt động của Mail Exchange Online."
                              )
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("li", { staticStyle: { "list-style-type": "disc" } }, [
                    _c("span", { staticStyle: { "font-size": "12pt" } }, [
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'Times New Roman'" } },
                        [
                          _c("span", { staticStyle: { color: "#000000" } }, [
                            _vm._v(
                              _vm._s(
                                this.$i18n.locale == "en"
                                  ? `Manage Fanpage, Website.`
                                  : "Quản trị Fanpage, Website."
                              )
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("li", { staticStyle: { "list-style-type": "disc" } }, [
                    _c("span", { staticStyle: { "font-size": "12pt" } }, [
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'Times New Roman'" } },
                        [
                          _c("span", { staticStyle: { color: "#000000" } }, [
                            _vm._v(
                              _vm._s(
                                this.$i18n.locale == "en"
                                  ? `Knowledge of API, EDI.`
                                  : "Kiến thức API, EDI."
                              )
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                ]),
                _c("h2", { staticStyle: { "text-align": "justify" } }, [
                  _c("span", { staticStyle: { "font-size": "16pt" } }, [
                    _c(
                      "span",
                      { staticStyle: { "font-family": "'Times New Roman'" } },
                      [
                        _c("span", { staticStyle: { color: "#000000" } }, [
                          _vm._v(_vm._s(_vm.$t("benefits"))),
                        ]),
                      ]
                    ),
                  ]),
                ]),
                _c("ul", [
                  _c("li", { staticStyle: { "list-style-type": "disc" } }, [
                    _c("span", { staticStyle: { "font-size": "12pt" } }, [
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'Times New Roman'" } },
                        [
                          _c("span", { staticStyle: { color: "#000000" } }, [
                            _vm._v(_vm._s(_vm.$t("benef1"))),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("li", { staticStyle: { "list-style-type": "disc" } }, [
                    _c("span", { staticStyle: { "font-size": "12pt" } }, [
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'Times New Roman'" } },
                        [
                          _c("span", { staticStyle: { color: "#000000" } }, [
                            _vm._v(_vm._s(_vm.$t("benef2"))),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("li", { staticStyle: { "list-style-type": "disc" } }, [
                    _c("span", { staticStyle: { "font-size": "12pt" } }, [
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'Times New Roman'" } },
                        [
                          _c("span", { staticStyle: { color: "#000000" } }, [
                            _vm._v(_vm._s(_vm.$t("benef3"))),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("li", { staticStyle: { "list-style-type": "disc" } }, [
                    _c("span", { staticStyle: { "font-size": "12pt" } }, [
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'Times New Roman'" } },
                        [
                          _c("span", { staticStyle: { color: "#000000" } }, [
                            _vm._v(_vm._s(_vm.$t("benef4"))),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("li", { staticStyle: { "list-style-type": "disc" } }, [
                    _c("span", { staticStyle: { "font-size": "12pt" } }, [
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'Times New Roman'" } },
                        [
                          _c("span", { staticStyle: { color: "#000000" } }, [
                            _vm._v(_vm._s(_vm.$t("benef5"))),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("li", { staticStyle: { "list-style-type": "disc" } }, [
                    _c("span", { staticStyle: { "font-size": "12pt" } }, [
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'Times New Roman'" } },
                        [
                          _c("span", { staticStyle: { color: "#000000" } }, [
                            _vm._v(_vm._s(_vm.$t("benef6"))),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("li", { staticStyle: { "list-style-type": "disc" } }, [
                    _c("span", { staticStyle: { "font-size": "12pt" } }, [
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'Times New Roman'" } },
                        [
                          _c("span", { staticStyle: { color: "#000000" } }, [
                            _vm._v(_vm._s(_vm.$t("benef7"))),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                ]),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-style": "italic",
                      "text-align": "justify",
                      "font-size": "16px",
                      "font-family": "'Times New Roman'",
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("benefPara")) + " ")]
                ),
                _c("h2", { staticStyle: { "text-align": "justify" } }, [
                  _c("span", { staticStyle: { "font-size": "16pt" } }, [
                    _c(
                      "span",
                      { staticStyle: { "font-family": "'Times New Roman'" } },
                      [
                        _c("span", { staticStyle: { color: "#000000" } }, [
                          _vm._v(_vm._s(_vm.$t("workSchedule"))),
                        ]),
                      ]
                    ),
                  ]),
                ]),
                _c("p", { staticStyle: { "text-align": "justify" } }, [
                  _c("span", { staticStyle: { "font-size": "12pt" } }, [
                    _c(
                      "span",
                      { staticStyle: { "font-family": "'Times New Roman'" } },
                      [
                        _c("span", { staticStyle: { color: "#000000" } }, [
                          _vm._v(_vm._s(_vm.$t("montoFri"))),
                        ]),
                      ]
                    ),
                  ]),
                ]),
                _c("ul", [
                  _c("li", { staticStyle: { "list-style-type": "disc" } }, [
                    _c("span", { staticStyle: { "font-size": "12pt" } }, [
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'Times New Roman'" } },
                        [
                          _c("span", { staticStyle: { color: "#000000" } }, [
                            _vm._v(_vm._s(_vm.$t("morning")) + ": "),
                          ]),
                        ]
                      ),
                    ]),
                    _vm._m(6),
                  ]),
                  _c("li", { staticStyle: { "list-style-type": "disc" } }, [
                    _c("span", { staticStyle: { "font-size": "12pt" } }, [
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'Times New Roman'" } },
                        [
                          _c("span", { staticStyle: { color: "#000000" } }, [
                            _vm._v(
                              _vm._s(_vm.$t("afternoon")) + ": 13h00- 17h00"
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                ]),
                _c("h2", { staticStyle: { "text-align": "justify" } }, [
                  _c("span", { staticStyle: { "font-size": "16pt" } }, [
                    _c(
                      "span",
                      { staticStyle: { "font-family": "'Times New Roman'" } },
                      [
                        _c("span", { staticStyle: { color: "#000000" } }, [
                          _vm._v(_vm._s(_vm.$t("recruitDoc"))),
                        ]),
                      ]
                    ),
                  ]),
                ]),
                _c("ul", [
                  _c("li", { staticStyle: { "list-style-type": "disc" } }, [
                    _c("span", { staticStyle: { "font-size": "12pt" } }, [
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'Times New Roman'" } },
                        [
                          _c("span", { staticStyle: { color: "#000000" } }, [
                            _vm._v(_vm._s(_vm.$t("recruit1"))),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("li", { staticStyle: { "list-style-type": "disc" } }, [
                    _c("span", { staticStyle: { "font-size": "12pt" } }, [
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'Times New Roman'" } },
                        [
                          _c("span", { staticStyle: { color: "#000000" } }, [
                            _vm._v(_vm._s(_vm.$t("recruit2")) + " "),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                ]),
                _c("h2", { staticStyle: { "text-align": "justify" } }, [
                  _c("span", { staticStyle: { "font-size": "16pt" } }, [
                    _c(
                      "span",
                      { staticStyle: { "font-family": "'Times New Roman'" } },
                      [
                        _c("span", { staticStyle: { color: "#000000" } }, [
                          _vm._v(_vm._s(_vm.$t("methodApply"))),
                        ]),
                      ]
                    ),
                  ]),
                ]),
                _c("ul", [
                  _c("li", { staticStyle: { "list-style-type": "disc" } }, [
                    _c("span", { staticStyle: { "font-size": "12pt" } }, [
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'Times New Roman'" } },
                        [
                          _c("span", { staticStyle: { color: "#000000" } }, [
                            _c(
                              "li",
                              { staticStyle: { "list-style-type": "disc" } },
                              [
                                _c(
                                  "span",
                                  { staticStyle: { "font-size": "12pt" } },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "font-family": "'Times New Roman'",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticStyle: { color: "#000000" } },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("method1")) + " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("li", { staticStyle: { "list-style-type": "disc" } }, [
                    _c("span", { staticStyle: { "font-size": "12pt" } }, [
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'Times New Roman'" } },
                        [
                          _c("span", { staticStyle: { color: "#000000" } }, [
                            _c(
                              "li",
                              { staticStyle: { "list-style-type": "disc" } },
                              [
                                _c(
                                  "span",
                                  { staticStyle: { "font-size": "12pt" } },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "font-family": "'Times New Roman'",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticStyle: { color: "#000000" } },
                                          [
                                            _c("div", {
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.$t("method2")
                                                ),
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                ]),
                _c("h2", { staticStyle: { "text-align": "justify" } }, [
                  _c("span", { staticStyle: { "font-size": "16pt" } }, [
                    _c(
                      "span",
                      { staticStyle: { "font-family": "'Times New Roman'" } },
                      [
                        _c("span", { staticStyle: { color: "#000000" } }, [
                          _vm._v(_vm._s(_vm.$t("contactInfo"))),
                        ]),
                      ]
                    ),
                  ]),
                ]),
                _c("ul", [
                  _c("li", { staticStyle: { "list-style-type": "disc" } }, [
                    _c("span", { staticStyle: { "font-size": "12pt" } }, [
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'Times New Roman'" } },
                        [
                          _c("span", { staticStyle: { color: "#000000" } }, [
                            _vm._v(_vm._s(_vm.$t("contactInfo1"))),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("li", { staticStyle: { "list-style-type": "disc" } }, [
                    _c("span", { staticStyle: { "font-size": "12pt" } }, [
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'Times New Roman'" } },
                        [
                          _c("span", { staticStyle: { color: "#000000" } }, [
                            _vm._v(_vm._s(_vm.$t("contactInfo2")) + " "),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._m(7),
                ]),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-style": "italic",
                      "text-align": "justify",
                      "font-size": "16px",
                      "font-family": "'Times New Roman'",
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("contactPara")) + " ")]
                ),
                _c("span", {}),
              ]),
              _c("div", { staticClass: "another-job" }, [
                _c("h3", [_vm._v(_vm._s(_vm.$t("otherJobAvailable")))]),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-12 col-md-6 recruitment-item" },
                    [
                      _c("a", { attrs: { href: "/recruitment/detail/1" } }, [
                        _c("div", { staticClass: "job-item" }, [
                          _c(
                            "p",
                            {
                              staticClass: "name",
                              staticStyle: {
                                color: "#000000",
                                "font-weight": "700",
                                "font-size": "15px",
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("bussEmployee")) + " ")]
                          ),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-5" }, [
                              _vm._m(8),
                              _c("div", {
                                staticStyle: { "margin-bottom": "10px" },
                              }),
                              _c("p", { staticClass: "location-recruitment" }, [
                                _c("img", {
                                  attrs: {
                                    src: require("../../../libhome/img/location.svg"),
                                  },
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      this.$i18n.locale == "en"
                                        ? "Hanoi, Hai Phong, Ho Chi Minh"
                                        : "Hà Nội, Hải Phòng, Hồ Chí Minh"
                                    ) +
                                    " "
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "col-6" }, [
                              _c("p", { staticClass: "salary" }, [
                                _c("img", {
                                  attrs: {
                                    src: require("../../../libhome/img/salary.svg"),
                                  },
                                }),
                                _vm._v(" " + _vm._s(_vm.$t("agreement")) + " "),
                              ]),
                              _c("div", {
                                staticStyle: { "padding-bottom": "5px" },
                              }),
                              _vm._m(9),
                            ]),
                            _vm._m(10),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "col-12 col-md-6 recruitment-item",
                      staticStyle: { display: "block" },
                    },
                    [
                      _c("a", { attrs: { href: "/recruitment/detail/2" } }, [
                        _c("div", { staticClass: "job-item" }, [
                          _c(
                            "p",
                            {
                              staticClass: "name",
                              staticStyle: {
                                color: "#000000",
                                "font-weight": "700",
                                "font-size": "15px",
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("genAccountEmploy")) + " "
                              ),
                            ]
                          ),
                          _c("span", { staticStyle: { "padding-top": "7px" } }),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-5" }, [
                              _vm._m(11),
                              _c("div", {
                                staticStyle: { "padding-bottom": "5px" },
                              }),
                              _c(
                                "p",
                                {
                                  staticClass: "location-recruitment",
                                  staticStyle: { "padding-top": "5px" },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("../../../libhome/img/location.svg"),
                                    },
                                  }),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("location")) + " "
                                  ),
                                ]
                              ),
                            ]),
                            _c("div", { staticClass: "col-6" }, [
                              _c("p", { staticClass: "salary" }, [
                                _c("img", {
                                  attrs: {
                                    src: require("../../../libhome/img/salary.svg"),
                                  },
                                }),
                                _vm._v(" " + _vm._s(_vm.$t("agreement")) + " "),
                              ]),
                              _c("div", {
                                staticStyle: { "padding-bottom": "5px" },
                              }),
                              _vm._m(12),
                            ]),
                            _vm._m(13),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                  _c("div", { staticClass: "col-12 col-md-6" }, [
                    _c("a", { attrs: { href: "/recruitment/detail/4" } }, [
                      _c("div", { staticClass: "job-item" }, [
                        _c(
                          "p",
                          {
                            staticClass: "name",
                            staticStyle: {
                              color: "#000000",
                              "font-weight": "700",
                              "font-size": "15px",
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("techEngineer")) + " ")]
                        ),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-5" }, [
                            _vm._m(14),
                            _c("div", {
                              staticStyle: { "padding-bottom": "5px" },
                            }),
                            _c("p", { staticClass: "location-recruitment" }, [
                              _c("img", {
                                attrs: {
                                  src: require("../../../libhome/img/location.svg"),
                                },
                              }),
                              _vm._v(" " + _vm._s(_vm.$t("location")) + " "),
                            ]),
                            _c("div", {
                              staticStyle: { "padding-bottom": "10px" },
                            }),
                          ]),
                          _c("div", { staticClass: "col-6" }, [
                            _c("p", { staticClass: "salary" }, [
                              _c("img", {
                                attrs: {
                                  src: require("../../../libhome/img/salary.svg"),
                                },
                              }),
                              _vm._v(" " + _vm._s(_vm.$t("agreement")) + " "),
                            ]),
                            _c("div", {
                              staticStyle: { "padding-bottom": "5px" },
                            }),
                            _vm._m(15),
                          ]),
                          _vm._m(16),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "col-12 col-md-6" }, [
                    _c("a", { attrs: { href: "/recruitment/detail/5" } }, [
                      _c("div", { staticClass: "job-item" }, [
                        _c(
                          "p",
                          {
                            staticClass: "name",
                            staticStyle: {
                              color: "#000000",
                              "font-weight": "700",
                              "font-size": "15px",
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("HRC")) + " ")]
                        ),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-5" }, [
                            _vm._m(17),
                            _c("div", {
                              staticStyle: { "padding-bottom": "5px" },
                            }),
                            _c("p", { staticClass: "location-recruitment" }, [
                              _c("img", {
                                attrs: {
                                  src: require("../../../libhome/img/location.svg"),
                                },
                              }),
                              _vm._v(" " + _vm._s(_vm.$t("location")) + " "),
                            ]),
                            _c("div", {
                              staticStyle: { "padding-bottom": "10px" },
                            }),
                          ]),
                          _c("div", { staticClass: "col-6" }, [
                            _c("p", { staticClass: "salary" }, [
                              _c("img", {
                                attrs: {
                                  src: require("../../../libhome/img/salary.svg"),
                                },
                              }),
                              _vm._v(" " + _vm._s(_vm.$t("agreement")) + " "),
                            ]),
                            _c("div", {
                              staticStyle: { "padding-bottom": "5px" },
                            }),
                            _vm._m(18),
                          ]),
                          _vm._m(19),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "col-12 col-md-6" }, [
                    _c("a", { attrs: { href: "/recruitment/detail/6" } }, [
                      _c("div", { staticClass: "job-item" }, [
                        _c(
                          "p",
                          {
                            staticClass: "name",
                            staticStyle: {
                              color: "#000000",
                              "font-weight": "700",
                              "font-size": "15px",
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("AO-VME")) + " ")]
                        ),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-5" }, [
                            _vm._m(20),
                            _c("div", {
                              staticStyle: { "padding-bottom": "5px" },
                            }),
                            _c("p", { staticClass: "location-recruitment" }, [
                              _c("img", {
                                attrs: {
                                  src: require("../../../libhome/img/location.svg"),
                                },
                              }),
                              _vm._v(" " + _vm._s(_vm.$t("location")) + " "),
                            ]),
                            _c("div", {
                              staticStyle: { "padding-bottom": "10px" },
                            }),
                          ]),
                          _c("div", { staticClass: "col-6" }, [
                            _c("p", { staticClass: "salary" }, [
                              _c("img", {
                                attrs: {
                                  src: require("../../../libhome/img/salary.svg"),
                                },
                              }),
                              _vm._v(" " + _vm._s(_vm.$t("agreement")) + " "),
                            ]),
                            _c("div", {
                              staticStyle: { "padding-bottom": "5px" },
                            }),
                            _vm._m(21),
                          ]),
                          _vm._m(22),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
              _c(
                "div",
                {
                  staticClass: "row align-items-center justify-content-center",
                },
                [
                  _c("section", { staticClass: "recruitment-list" }, [
                    _c("div", { staticClass: "container" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-md-20" }, [
                          _c("div", { staticClass: "curriculum-vitae" }, [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-12 col-md-6" }, [
                                _c("div", { staticClass: "text" }, [
                                  _c("p", {
                                    ref: "apply",
                                    staticClass: "label",
                                    domProps: {
                                      innerHTML: _vm._s(_vm.$t("recApply")),
                                    },
                                  }),
                                  _c("p", { staticClass: "desc" }, [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("reacContent1")) + " "
                                    ),
                                  ]),
                                  _c("p", { staticClass: "desc" }, [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("reacContent2")) + " "
                                    ),
                                  ]),
                                  _c("p", {
                                    staticClass: "support-line",
                                    domProps: {
                                      innerHTML: _vm._s(_vm.$t("reacContent3")),
                                    },
                                  }),
                                ]),
                              ]),
                              _c("div", { staticClass: "col-12 col-md-6" }, [
                                _c("div", { staticClass: "form" }, [
                                  _c(
                                    "form",
                                    {
                                      ref: "form",
                                      on: {
                                        submit: function ($event) {
                                          $event.preventDefault()
                                          return _vm.sendEmail.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("input", {
                                        attrs: {
                                          type: "hidden",
                                          name: "_token",
                                          value:
                                            "tUJCQfTUaRbmxLqP62LxN27j10jiTMOFtrcgwl11",
                                        },
                                      }),
                                      _c("input", {
                                        attrs: {
                                          type: "password",
                                          name: "password",
                                          value: "",
                                          hidden: "",
                                        },
                                      }),
                                      _c("div", { staticClass: "form-group" }, [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.form.name,
                                              expression: "form.name",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            type: "text",
                                            name: "name",
                                            placeholder:
                                              this.$i18n.locale === "en"
                                                ? "Full Name"
                                                : "Họ và tên",
                                          },
                                          domProps: { value: _vm.form.name },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                _vm.form,
                                                "name",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]),
                                      _c("div", { staticClass: "form-group" }, [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.form.email,
                                              expression: "form.email",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            type: "email",
                                            name: "email",
                                            placeholder: "Email",
                                          },
                                          domProps: { value: _vm.form.email },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                _vm.form,
                                                "email",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]),
                                      _c("div", { staticClass: "form-group" }, [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.form.phone,
                                              expression: "form.phone",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            type: "text",
                                            name: "phone",
                                            placeholder: _vm.$t("phone"),
                                          },
                                          domProps: { value: _vm.form.phone },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                _vm.form,
                                                "phone",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]),
                                      _c("div", { staticClass: "form-group" }, [
                                        _c(
                                          "select",
                                          {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.form.position,
                                                expression: "form.position",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              name: "recruitment_id",
                                              placeholder:
                                                _vm.$t("recPosition"),
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$selectedVal =
                                                  Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function (o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function (o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                _vm.$set(
                                                  _vm.form,
                                                  "position",
                                                  $event.target.multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "option",
                                              {
                                                attrs: {
                                                  value: "",
                                                  disabled: "",
                                                  selected: "",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      this.$i18n.locale === "en"
                                                        ? "Apply for the position"
                                                        : "Ứng tuyển vào vị trí này"
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "option",
                                              {
                                                domProps: {
                                                  value: _vm.$t("bussDepart"),
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t("bussDepart")
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "option",
                                              {
                                                domProps: {
                                                  value: _vm.$t("acountDepart"),
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t("acountDepart")
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "option",
                                              {
                                                domProps: {
                                                  value: _vm.$t("itDepart"),
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.$t("itDepart")) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "option",
                                              {
                                                domProps: {
                                                  value: _vm.$t("HRD"),
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.$t("HRD")) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex justify-content-between sp-column",
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "add-cv",
                                              attrs: { for: "upload_cv" },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "fa fa-file",
                                              }),
                                              _vm._v(
                                                _vm._s(_vm.$t("uploadCV"))
                                              ),
                                            ]
                                          ),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: !_vm.isHidden,
                                                expression: "!isHidden",
                                              },
                                            ],
                                            attrs: {
                                              type: "file",
                                              name: "cv",
                                              id: "upload_cv",
                                            },
                                            on: {
                                              change: _vm.handleFileUpload,
                                            },
                                          }),
                                          _c(
                                            "button",
                                            {
                                              staticClass: "send",
                                              attrs: {
                                                type: "submit",
                                                disabled: _vm.isLoading,
                                              },
                                            },
                                            [
                                              _vm.isLoading
                                                ? _c("span", [
                                                    _c("i", {
                                                      staticClass:
                                                        "fa fa-spinner fa-spin",
                                                    }),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t("sendCV")
                                                        ) +
                                                        " "
                                                    ),
                                                  ])
                                                : _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t("sendCV")
                                                        ) +
                                                        " "
                                                    ),
                                                  ]),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm.selectedFile
                                        ? _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                margin: "5px 0 0 20px",
                                                "font-size": "16px",
                                                color: "#fff",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.selectedFile.name)
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c("div", { staticClass: "alert" }),
                                    ]
                                  ),
                                ]),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ]),
          ]),
        ]
      ),
      _c("FooterHome"),
      _vm._m(23),
      _c("NavBarLeftHome"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "image" }, [
      _c("img", {
        attrs: {
          src: "https://phuthailuxury.vn/assets_client/images/1.png?v=1719280770",
          alt: "Item Wallet",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "image" }, [
      _c("img", {
        attrs: {
          src: "https://phuthailuxury.vn/assets_client/images/3.png?v=1719280770",
          alt: "Item Timework",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "image" }, [
      _c("img", {
        attrs: {
          src: "https://phuthailuxury.vn/assets_client/images/5.png?v=1719280770",
          alt: "Item Sex",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "image" }, [
      _c("img", {
        attrs: {
          src: "https://phuthailuxury.vn/assets_client/images/2.png?v=1719280770",
          alt: "Item Number",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "image" }, [
      _c("img", {
        attrs: {
          src: "https://phuthailuxury.vn/assets_client/images/4.png?v=1719280770",
          alt: "Item Level",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "image" }, [
      _c("img", {
        attrs: {
          src: "https://phuthailuxury.vn/assets_client/images/6.png?v=1719280770",
          alt: "Item Level",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticStyle: { "font-size": "12pt" } }, [
      _c("span", { staticStyle: { "font-family": "'Times New Roman'" } }, [
        _c("span", { staticStyle: { color: "#000000" } }, [
          _vm._v("8h00 -12h00"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticStyle: { "list-style-type": "disc" } }, [
      _c("span", { staticStyle: { "font-size": "12pt" } }, [
        _c("span", { staticStyle: { "font-family": "'Times New Roman'" } }, [
          _c("span", { staticStyle: { color: "#000000" } }, [
            _vm._v("Email: hr@vimc-lines.com "),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "deadline" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
      _vm._v(" 15-8-2024 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "worktime" }, [
      _c("i", { staticClass: "fa fa-clock" }),
      _vm._v(" 8h00 - 17h00 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-1 pr-0" }, [
      _c("span", { staticClass: "fa fa-angle-right" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "deadline" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
      _vm._v(" 15-8-2024 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "worktime" }, [
      _c("i", { staticClass: "fa fa-clock" }),
      _vm._v(" 8h00 - 17h00 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-1 pr-0" }, [
      _c("span", { staticClass: "fa fa-angle-right" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "deadline" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
      _vm._v(" 15-7-2024 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "worktime" }, [
      _c("i", { staticClass: "fa fa-clock" }),
      _vm._v(" 8h00 - 17h00 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-1 pr-0" }, [
      _c("span", { staticClass: "fa fa-angle-right" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "deadline" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
      _vm._v(" 15-8-2024 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "worktime" }, [
      _c("i", { staticClass: "fa fa-clock" }),
      _vm._v(" 8h00 - 17h00 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-1 pr-0" }, [
      _c("span", { staticClass: "fa fa-angle-right" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "deadline" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
      _vm._v(" 15-8-2024 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "worktime" }, [
      _c("i", { staticClass: "fa fa-clock" }),
      _vm._v(" 8h00 - 17h00 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-1 pr-0" }, [
      _c("span", { staticClass: "fa fa-angle-right" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { staticClass: "back-to-top", attrs: { href: "/home" } }, [
      _c("i", { staticClass: "fa fa-chevron-up" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }