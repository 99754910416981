var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { attrs: { "no-gutters": "" } },
    [
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _vm.provinceCaption
            ? _c("div", { staticClass: "caption" }, [
                _vm._v(" " + _vm._s(_vm.provinceCaption) + " "),
              ])
            : _vm._e(),
          _c(
            "v-autocomplete",
            _vm._b(
              {
                attrs: {
                  value: _vm.value.provinceCode,
                  items: _vm.provinceList,
                  loading: _vm.isLoadingProvince,
                  disabled: _vm.isLoadingProvince || _vm.disabled,
                  label: _vm.provinceLabel,
                  filter: _vm.customFilter,
                  spellcheck: "false",
                  type: "text",
                  "prepend-inner-icon": "mdi-map-marker-radius",
                  "item-text": "nameWithType",
                  "item-value": "code",
                  "return-object": "",
                  "error-messages": _vm.errorMessages,
                },
                on: {
                  change: _vm.onChangeProvince,
                  input: function ($event) {
                    return _vm.$emit("input", _vm.value)
                  },
                  blur: function ($event) {
                    return _vm.$emit("input", _vm.value)
                  },
                },
              },
              "v-autocomplete",
              _vm.$attrs,
              false
            )
          ),
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _vm.districtCaption
            ? _c("div", { staticClass: "caption" }, [
                _vm._v(" " + _vm._s(_vm.districtCaption) + " "),
              ])
            : _vm._e(),
          _c(
            "v-autocomplete",
            _vm._b(
              {
                attrs: {
                  value: _vm.value.districtCode,
                  items: _vm.provinceDistrictList,
                  loading: _vm.isLoadingDistrict,
                  disabled: _vm.isLoadingDistrict || _vm.disabled,
                  label: _vm.districtLabel,
                  filter: _vm.customFilter,
                  spellcheck: "false",
                  type: "text",
                  "prepend-inner-icon": "mdi-map-marker-radius",
                  "item-text": "nameWithType",
                  "item-value": "code",
                  "return-object": "",
                },
                on: { change: _vm.onChangeDistrict },
              },
              "v-autocomplete",
              _vm.$attrs,
              false
            )
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }