<template>
  <v-app class="background">
    <div style="position: relative">
      <div class="bg-globe"></div>
    </div>
    <v-app-bar
      flat
      color="background"
    >
      <v-container
        :class="{
          'pa-0': $vuetify.breakpoint.mobile,
        }"
      >
        <v-row no-gutters>
          <img
            alt="VSL logo"
            src="/assets/logo_vimc_lines.PNG"
            height="36px"
            :class="{
              'ml-2': !$vuetify.breakpoint.mobile,
            }"
          />
          <v-spacer />
          <!-- <v-btn
            v-if="!$vuetify.breakpoint.mobile"
            href="https://vimc.co/"
            target="_blank"
            class="btn-menu"
            plain
            depressed
            :ripple="false"
          >
            Contact Us
          </v-btn> -->

          <ChangeLanguageButton class="mr-8" />

          <v-btn
            rounded
            color="secondary"
            class="text--primary"
            to="/app"
            target="_blank"
          >
            {{ $t('button-login') }}
          </v-btn>
        </v-row>
      </v-container>
    </v-app-bar>
    <v-main>
      <v-container>
        <HeaderSearch style="min-height: 700px" />
        <AllServices />
        <!-- <PopularRoutes style="min-height:700px;" /> -->
      </v-container>
      <NewsSlider style="min-height: 100vh; margin-top: 170px" />
      <!-- <v-container style="margin-top: 170px;">
        <ShippingLeads />
      </v-container> -->
      <PartnerSlider style="margin-top: 170px" />
      <v-container
        :class="{
          'px-0': $vuetify.breakpoint.mobile,
        }"
        style="margin-top: 170px"
      >
        <GetStarted />
      </v-container>
      <Footer />
    </v-main>
  </v-app>
</template>

<script>
import dayjs from 'dayjs';
import HeaderSearch from './HeaderSearch.vue';
import PopularRoutes from './PopularRoutes.vue';
import NewsSlider from './NewsSlider.vue';
import AllServices from './AllServices.vue';
import Footer from './Footer.vue';
import ShippingLeads from './ShippingLeads.vue';
import PartnerSlider from './PartnerSlider.vue';
import GetStarted from './GetStarted.vue';
import ChangeLanguageButton from '@/components/shared/local/base/ChangeLanguageButton.vue';
import { VueperSlides, VueperSlide } from 'vueperslides';

export default {
  components: {
    HeaderSearch,
    PopularRoutes,
    NewsSlider,
    AllServices,
    Footer,
    ShippingLeads,
    PartnerSlider,
    GetStarted,
    ChangeLanguageButton,
    VueperSlides,
    VueperSlide,
  },
  data: () => ({
    links: [
      'Giới thiệu',
      'Tin tức',
      'Công bố thông tin',
      'Quan hệ cổ đông',
      'Thư viện',
    ],
    dates: [dayjs().format('YYYY-MM-DD')],
    modal: false,
    selectedEtdEta: [],
    slides: [
      {
        title: 'Slide #1',
        content: 'Slide 1 content.',
      },
      {
        title: 'Slide #2',
        content: 'Slide 2 content.',
      },
      {
        title: 'Slide #3',
        content: 'Slide 3 content.',
      },
      {
        title: 'Slide #4',
        content: 'Slide 4 content.',
      },
      {
        title: 'Slide #5',
        content: 'Slide 5 content.',
      },
      {
        title: 'Slide #6',
        content: 'Slide 6 content.',
      },
      {
        title: 'Slide #7',
        content: 'Slide 7 content.',
      },
      {
        title: 'Slide #8',
        content: 'Slide 8 content.',
      },
      {
        title: 'Slide #9',
        content: 'Slide 9 content.',
      },
      {
        title: 'Slide #10',
        content: 'Slide 10 content.',
      },
    ],
  }),
  computed: {},
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.bg {
  background: url('/assets/img/globe.png');
  background-repeat: no-repeat;
  background-size: 1207px 1207px;
}

.btn-menu {
  text-transform: capitalize;
  font-weight: 500;
  letter-spacing: inherit;
}

.bg-globe {
  position: absolute;
  width: 100%;
  height: 1207px;
  /* top: -50px; */
  background: url('/assets/img/globe.png');
  background-repeat: no-repeat;
  background-size: 900px 900px;
  background-position: top right;
}

.bg-globe-shadow {
  filter: drop-shadow(4px 10px 90px rgb(61 118 201 / 0.2));
}

:deep {
  .v-main__wrap {
    background-image: linear-gradient(
      180deg,
      rgba(242, 243, 250, 1) 2%,
      rgba(244, 245, 250, 0) 4%,
      rgba(255, 255, 255, 0) 22%
    );
  }
}
</style>
