<template>
  <v-card
    rounded="lg"
    min-height="450"
  >
    <v-card-text>
      <v-container>
        <template v-if="loading">
          <v-timeline
            align-top
            dense
            reverse
          >
            <v-timeline-item
              class="mb-6"
              hide-dot
            >
              <v-skeleton-loader
                type="text"
                :boilerplate="false"
              />
            </v-timeline-item>

            <v-timeline-item
              v-for="item in 4"
              :key="item.id"
              small
              color="none"
            >
              <template v-slot:icon>
                <StatusIndicator />
              </template>

              <v-row class="pt-1">
                <v-col cols="2">
                  <v-skeleton-loader
                    type="text@2"
                    :boilerplate="false"
                  />
                </v-col>
                <v-col cols="2">
                  <v-skeleton-loader
                    type="text@2"
                    :boilerplate="false"
                  />
                </v-col>
                <v-col cols="2">
                  <v-skeleton-loader
                    type="text@2"
                    :boilerplate="false"
                  />
                </v-col>
                <v-col cols="2">
                  <v-skeleton-loader
                    type="text@2"
                    :boilerplate="false"
                  />
                </v-col>

                <v-col cols="2">
                  <v-skeleton-loader
                    type="text@2"
                    :boilerplate="false"
                  />
                </v-col>
                <v-col cols="2">
                  <v-skeleton-loader
                    type="text@2"
                    :boilerplate="false"
                  />
                </v-col>
              </v-row>
            </v-timeline-item>
          </v-timeline>
        </template>
        <v-timeline
          v-else
          align-top
          dense
          reverse
        >
          <v-timeline-item
            class="mb-6"
            hide-dot
          >
            <span>History</span>
            -
            <span class="text-uppercase"
              >CONTAINER NO. {{ containerMovements.containerNo }}</span
            >
          </v-timeline-item>

          <v-timeline-item
            v-for="(item, index) in containerMovements.logs"
            :key="item.id"
            small
            color="none"
          >
            <template v-slot:icon>
              <StatusIndicator
                :status="index === 0 ? 'active' : ''"
                :pulse="index === 0"
              />
            </template>
            <v-row class="pt-1">
              <v-col cols="2">
                <div>
                  <strong>DATETIME</strong>
                </div>
                <div>
                  {{ $filters.formatDatetime(item.movementDate) }}
                </div>
              </v-col>
              <v-col cols="2">
                <strong>STATUS</strong>
                <div>
                  {{ item.status.replace('_', ' ') }}
                </div>
              </v-col>
              <v-col cols="2">
                <div>
                  <strong>VOYAGE</strong>
                </div>
                <div>
                  {{ item.voyageCode }}
                </div>
              </v-col>
              <v-col cols="2">
                <div>
                  <strong>VESSEL</strong>
                </div>
                <div>
                  {{ item.vesselName }}
                </div>
              </v-col>

              <v-col cols="2">
                <strong>POL</strong>
                <div>
                  {{ item.polName }}
                </div>
              </v-col>
              <v-col cols="2">
                <strong>POD</strong>
                <div>
                  {{ item.pofdName }}
                </div>
              </v-col>
            </v-row>
          </v-timeline-item>
        </v-timeline>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
export default {
  props: ['loading'],
  data: () => ({}),
  computed: {
    ...mapState('vsl/containerTracking', ['containerMovements']),
  },

  created() {},
  methods: {},
};
</script>

<style scope lang="scss">
:deep {
}
</style>
