var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        { staticClass: "mb-2", attrs: { "no-gutters": "", align: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: _vm.$vuetify.breakpoint.mobile ? 12 : 8 } },
            [
              _c(
                "div",
                [
                  _c("span", { staticClass: "font-weight-bold" }, [
                    _vm._v(_vm._s(_vm.item.name)),
                  ]),
                  _c("span", { staticClass: "mr-2" }, [
                    _vm._v(" | " + _vm._s(_vm.item.phone)),
                  ]),
                  _vm.item.isDefault
                    ? _c(
                        "v-chip",
                        { attrs: { color: "error", "x-small": "" } },
                        [_vm._v(_vm._s(_vm.$t("default")))]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c("div", [
                _vm._v(" " + _vm._s(_vm.item.emails.join(", ")) + " "),
              ]),
              _c("div", [_vm._v(" " + _vm._s(_vm.item.address) + " ")]),
            ]
          ),
          _c(
            "v-col",
            { attrs: { cols: _vm.$vuetify.breakpoint.mobile ? 12 : 4 } },
            [
              _c(
                "v-row",
                {
                  style: !_vm.$vuetify.breakpoint.mobile
                    ? "justify-content: space-evenly;"
                    : "justify-content: space-between;",
                  attrs: { "no-gutters": "", align: "center" },
                },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        outlined: "",
                        color: "primary",
                        small: "",
                        loading: _vm.isLoading,
                        disabled: _vm.item.isDefault,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.onSetDefaultContact(_vm.item.id)
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("mdi-check"),
                      ]),
                      _vm._v(" " + _vm._s(_vm.$t("set-as-default")) + " "),
                    ],
                    1
                  ),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: { icon: "", color: "orange" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$emit("update", _vm.item)
                                        },
                                      },
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c("v-icon", [
                                    _vm._v("mdi-circle-edit-outline"),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.$t("button-edit")))])]
                  ),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: { icon: "", color: "error" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$emit("delete", _vm.item)
                                        },
                                      },
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [_c("v-icon", [_vm._v("mdi-close")])],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.$t("button-delete")))])]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-divider"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }