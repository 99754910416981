var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "swiper",
    { staticClass: "swiper", attrs: { options: _vm.swiperOption } },
    [
      _c(
        "swiper-slide",
        { staticClass: "slide-1" },
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                {
                  staticStyle: { "padding-top": "140px" },
                  attrs: { "no-gutters": "" },
                },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "white--text",
                      attrs: { cols: _vm.$vuetify.breakpoint.mobile ? 8 : 6 },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "font-weight-medium",
                          style: `font-size: ${
                            _vm.$vuetify.breakpoint.mobile ? "16px" : "36px"
                          };`,
                        },
                        [
                          _vm._v(
                            " VIMC PROVIDES FREE SHIPPING SERVICES SUPPORTING THE CONTROL OF COVID-19 "
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "mt-4", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "white--text",
                      attrs: { cols: _vm.$vuetify.breakpoint.mobile ? 11 : 8 },
                    },
                    [
                      _c(
                        "div",
                        {
                          style: `font-size: ${
                            _vm.$vuetify.breakpoint.mobile ? "12px" : "14px"
                          }; opacity: 0.65;`,
                        },
                        [
                          _vm._v(
                            " Responding to the Government's call, the Prime Minister on the activities of sharing difficulties, supporting localities, organizations, businesses and people in epidemic prevention and control Covid-19. With the strength of a large-scale corporation, having a fleet Sea transportation, seaports and logistics systems stretching over nationwide, Vietnam Maritime Corporation (VIMC) will provide providing free shipping services to support room work, fight against Covid-19. "
                          ),
                          _c("br"),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "swiper-slide",
        [
          _c(
            "swiper-slide",
            { staticClass: "slide-2" },
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    {
                      staticStyle: { "padding-top": "140px" },
                      attrs: { "no-gutters": "" },
                    },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "white--text",
                          attrs: {
                            cols: _vm.$vuetify.breakpoint.mobile ? 8 : 6,
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "font-weight-medium",
                              style: `font-size: ${
                                _vm.$vuetify.breakpoint.mobile ? "16px" : "36px"
                              };`,
                            },
                            [
                              _vm._v(
                                " VIMC PROVIDES FREE SHIPPING SERVICES SUPPORTING THE CONTROL OF COVID-19 "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "mt-4", attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "white--text",
                          attrs: {
                            cols: _vm.$vuetify.breakpoint.mobile ? 11 : 8,
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              style: `font-size: ${
                                _vm.$vuetify.breakpoint.mobile ? "12px" : "14px"
                              }; opacity: 0.65;`,
                            },
                            [
                              _vm._v(
                                " Responding to the Government's call, the Prime Minister on the activities of sharing difficulties, supporting localities, organizations, businesses and people in epidemic prevention and control Covid-19. With the strength of a large-scale corporation, having a fleet Sea transportation, seaports and logistics systems stretching over nationwide, Vietnam Maritime Corporation (VIMC) will provide providing free shipping services to support room work, fight against Covid-19. "
                              ),
                              _c("br"),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", {
        staticClass: "swiper-pagination",
        attrs: { slot: "pagination" },
        slot: "pagination",
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }