var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticStyle: { overflow: "hidden" },
      attrs: { rounded: "lg", ripple: false, to: _vm.to },
    },
    [
      _c(
        "v-card-text",
        { staticClass: "py-0 px-0" },
        [
          _c(
            "v-row",
            { attrs: { justify: "center", "no-gutters": "" } },
            [
              _c(
                "v-expansion-panels",
                {
                  attrs: { accordion: "" },
                  model: {
                    value: _vm.panel,
                    callback: function ($$v) {
                      _vm.panel = $$v
                    },
                    expression: "panel",
                  },
                },
                [
                  _c(
                    "v-expansion-panel",
                    [
                      _c(
                        "v-expansion-panel-header",
                        {
                          staticClass: "pa-0",
                          staticStyle: { display: "block" },
                          scopedSlots: _vm._u([
                            {
                              key: "actions",
                              fn: function () {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "mb-3 mt-2 caption" },
                                    [
                                      _c("v-icon", { attrs: { small: "" } }, [
                                        _vm._v(" mdi-chevron-double-down "),
                                      ]),
                                      _vm._v(" " + _vm._s(_vm.panelText) + " "),
                                      _c("v-icon", { attrs: { small: "" } }, [
                                        _vm._v(" mdi-chevron-double-down "),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        },
                        [
                          _c(
                            "div",
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "pa-1",
                                  attrs: { color: "primary", tile: "" },
                                },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      attrs: {
                                        "no-gutters": "",
                                        align: "center",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-list-item",
                                        [
                                          _c(
                                            "v-list-item-avatar",
                                            { attrs: { size: "24", tile: "" } },
                                            [
                                              _c("v-img", {
                                                attrs: {
                                                  src: "/icons/icon-192x192.png",
                                                  transition: false,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-content",
                                            { staticStyle: { flex: "inital" } },
                                            [
                                              _c(
                                                "v-list-item-title",
                                                { staticClass: "white--text" },
                                                [_vm._v("VIMC")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("v-spacer"),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "text-right white--text",
                                              staticStyle: {
                                                "font-size": "12px",
                                                "line-height": "1.75",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.schedules.voyageCodes.join(
                                                          ", "
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        small: "",
                                                        dark: "",
                                                      },
                                                    },
                                                    [_vm._v("mdi-compass")]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.schedules.path.join(
                                                          ", "
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        small: "",
                                                        dark: "",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "mdi-map-marker-radius"
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  staticClass: "pt-2",
                                  attrs: { "no-gutters": "", align: "center" },
                                },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "align-top",
                                      attrs: { "no-gutters": "" },
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: 5 } },
                                        [
                                          _c(
                                            "VesselScheduleSearchResultListItemPort",
                                            {
                                              attrs: {
                                                date: new Date(
                                                  _vm.schedules.pathSchedules[0].etd
                                                ),
                                                code: _vm.schedules
                                                  .pathSchedules[0].polCode,
                                                "terminal-name":
                                                  _vm.schedules.pathSchedules[0]
                                                    .polTerminalName,
                                              },
                                            }
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "d-flex flex-column align-center justify-center",
                                          attrs: { cols: "2" },
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: "/assets/icons/cargo-ship.png",
                                              height: _vm.$vuetify.breakpoint
                                                .mobile
                                                ? 24
                                                : 50,
                                              width: "auto",
                                            },
                                          }),
                                          _vm._l(
                                            _vm.schedules.pathSchedules,
                                            function (pathSchedule) {
                                              return _c(
                                                "div",
                                                {
                                                  key: pathSchedule.code,
                                                  staticStyle: {
                                                    "font-size": "11px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        pathSchedule.voyageCode
                                                      ) +
                                                      " // "
                                                  ),
                                                  pathSchedule.vesselSlot
                                                    ? _c("span", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "text-slot-available"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  !pathSchedule.vesselSlot
                                                    ? _c("span", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "text-slot-not-available"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ]
                                              )
                                            }
                                          ),
                                        ],
                                        2
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: 5 } },
                                        [
                                          _c(
                                            "VesselScheduleSearchResultListItemPort",
                                            {
                                              attrs: {
                                                date: new Date(
                                                  _vm.schedules.pathSchedules[
                                                    _vm.schedules.pathSchedules
                                                      .length - 1
                                                  ].eta
                                                ),
                                                code: _vm.schedules
                                                  .pathSchedules[
                                                  _vm.schedules.pathSchedules
                                                    .length - 1
                                                ].podCode,
                                                "terminal-name":
                                                  _vm.schedules.pathSchedules[
                                                    _vm.schedules.pathSchedules
                                                      .length - 1
                                                  ].podTerminalName,
                                              },
                                            }
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  !_vm.hideBooking
                                    ? [
                                        _c("v-divider", {
                                          attrs: { vertical: "" },
                                        }),
                                        _c(
                                          "div",
                                          {
                                            class: {
                                              "d-flex w-100":
                                                _vm.$vuetify.breakpoint.mobile,
                                            },
                                          },
                                          [
                                            _c(
                                              "v-row",
                                              {
                                                staticClass: "mx-4 my-2",
                                                class: {
                                                  "mb-0":
                                                    _vm.$vuetify.breakpoint
                                                      .mobile,
                                                  "px-4":
                                                    !_vm.$vuetify.breakpoint
                                                      .mobile,
                                                },
                                                attrs: {
                                                  "no-gutters": "",
                                                  align: "center",
                                                  justify: "center",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    class: {
                                                      "d-flex align-center justify-center":
                                                        _vm.$vuetify.breakpoint
                                                          .mobile,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        staticClass: "mb-2",
                                                        attrs: {
                                                          color: "success",
                                                          target: "_blank",
                                                          to: {
                                                            path: "/app/shipping/request-a-quote/submit",
                                                            query: {
                                                              schedules_codes:
                                                                _vm.scheduleCodes.join(),
                                                              path: _vm.schedules.path.join(),
                                                            },
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" Booking"),
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              right: "",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "mdi-arrow-top-right"
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "text-center caption",
                                                        class: {
                                                          "mr-2":
                                                            !_vm.$vuetify
                                                              .breakpoint
                                                              .mobile,
                                                          "ml-2":
                                                            _vm.$vuetify
                                                              .breakpoint
                                                              .mobile,
                                                        },
                                                      },
                                                      [
                                                        _vm.schedules
                                                          .pathSchedules
                                                          .length > 1
                                                          ? _c(
                                                              "v-chip",
                                                              {
                                                                attrs: {
                                                                  small: "",
                                                                  color:
                                                                    "orange",
                                                                  outlined: "",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      small: "",
                                                                      left: "",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " mdi-swap-vertical-circle "
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(
                                                                  " Transit "
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "v-expansion-panel-content",
                        [
                          _c("VesselScheduleSearchResultListItemDetail", {
                            staticClass: "mb-4",
                            attrs: {
                              "path-schedules": _vm.schedules.pathSchedules,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }