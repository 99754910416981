<template>
  <v-card
    rounded="lg"
    class="elevation-0 py-6"
    :class="{
      'elevation-2 px-4': !$vuetify.breakpoint.mobile,
    }"
  >
    <div class="mx-2 mt-4 mb-0">
      <v-img
        src="/assets/img/logo.png"
        height="70px"
        contain
      />
    </div>
    <v-toolbar flat>
      <v-toolbar-title class="overline mx-auto">
        {{ $t('title-create-new-account') }}
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text class="py-0">
      <v-form
        @submit="onCheckPhoneValid"
        onSubmit="return false;"
      >
        <v-text-field
          :label="$t('label-phone')"
          prepend-inner-icon="mdi-cellphone"
          type="text"
          outlined
          autofocus
          :disabled="isLoading"
          v-model.trim="$v.phone.$model"
        />
      </v-form>
    </v-card-text>
    <v-card-actions class="d-flex flex-column px-4">
      <v-btn
        width="100%"
        x-large
        color="primary"
        depressed
        :loading="isLoading"
        :disabled="isLoading || $v.$invalid"
        @click="onCheckPhoneValid"
        >{{ $t('next') }}
      </v-btn>
      <v-btn
        width="100%"
        x-large
        depressed
        :disabled="isLoading"
        class="mx-0 my-2"
        to="/auth/login"
        >{{ $t('back') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import { mapActions, mapState } from 'vuex';

const PhoneNumber = require('awesome-phonenumber');

export default {
  data: () => ({
    isLoading: false,
    phone: '',
  }),
  validations: {
    phone: {
      required,
      minLength: minLength(1),
    },
  },
  computed: {
    ...mapState('ui', ['dialogAuth']),
    phoneErrors() {
      const errors = [];
      const field = this.$v.phone;
      if (!field.$dirty) return errors;
      !field.required &&
        errors.push(this.$t('validation-error-phone-required'));
      return errors;
    },
    dialog: {
      get() {
        return this.dialogAuth;
      },
      set(value) {
        this.TOGGLE_DIALOG_AUTH(value);
      },
    },
  },

  methods: {
    ...mapActions('auth', ['checkPhoneValid']),
    async onCheckPhoneValid() {
      try {
        this.isLoading = true;
        await this.checkPhoneValid({ phone: this.phone });
        this.$router.push(`/auth/signup/update-require-info`);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style></style>
