<template>
  <v-card
    class="px-4"
    rounded="lg"
    elevation="0"
  >
    <v-row
      no-gutters
      class="pt-2"
      align="center"
    >
      <v-col
        cols="5"
        class="font-weight-bold primary--text"
        style="font-size: 20px; line-height: 1.375rem"
      >
        {{ voyage.code || 'UNKNOWN' }}
      </v-col>
      <v-col cols="7">
        <div class="d-flex flex-column">
          <v-row
            no-gutters
            class="font-weight-semibold justify-end"
          >
            <div
              class="text-truncate"
              style="font-size: 12px; max-width: 80%"
            >
              {{ voyage.vesselName || 'UNKNOWN' }}
            </div>
            <v-icon
              small
              right
              >mdi-ferry</v-icon
            >
          </v-row>
          <v-row
            no-gutters
            class="font-weight-semibold justify-end"
          >
            <div
              class="text-truncate"
              style="font-size: 12px; max-width: 80%"
            >
              {{ voyageDateRange }}
            </div>
            <v-icon
              small
              right
              >mdi-timelapse</v-icon
            >
          </v-row>
        </div>
      </v-col>
    </v-row>
    <v-stepper
      v-model="active"
      vertical
      elevation="0"
    >
      <template v-for="(poc, index) in portOfCalls">
        <v-stepper-step
          :key="`${poc.id}-step`"
          :complete="isActive(index)"
          :step="index"
          complete-icon=""
        >
          <div class="text-left text-uppercase w-100">
            <div
              v-if="poc.estimatedTime"
              class="text--secondary overline"
              style="font-size: 12px; line-height: 1rem"
            >
              {{ $filters.formatDate(poc.estimatedTime) }}
            </div>
            <div
              v-if="poc.portName"
              class="text--primary my-1 text-uppercase text-truncate"
              style="font-weight: 600"
            >
              {{ poc.portName }}
            </div>
            <div
              v-if="poc.terminalName"
              class="text--secondary text-uppercase"
              style="font-size: 12px"
            >
              {{ poc.terminalName }}
            </div>
          </div>
        </v-stepper-step>
        <v-stepper-content
          :step="index"
          :key="`${poc.id}-content`"
        >
        </v-stepper-content>
      </template>
    </v-stepper>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
export default {
  props: ['loading', 'voyage', 'currentPortCode'],
  data: () => ({
    active: 0,
    portOfCalls: [],
  }),
  computed: {
    ...mapState('vsl/containerTracking', ['containerMovements']),
    voyageDateRange() {
      let startDate, endDate;
      if (this.voyage.pocs[0] && this.voyage.pocs[0].estimatedTime) {
        startDate = this.$filters.formatDate(this.voyage.pocs[0].estimatedTime);
      }
      if (
        this.voyage.pocs[this.voyage.pocs.length - 1] &&
        this.voyage.pocs[this.voyage.pocs.length - 1].estimatedTime
      ) {
        endDate = this.$filters.formatDate(
          this.voyage.pocs[this.voyage.pocs.length - 1].estimatedTime
        );
      }

      if (!startDate && !endDate) {
        return 'UNKNOWN';
      }

      return `${startDate} - ${endDate}`;
    },
  },

  created() {
    this.portOfCalls = [...this.voyage.pocs].reverse();
    this.active = this.portOfCalls.findIndex(
      (poc) => poc.portCode === this.currentPortCode
    );
  },
  methods: {
    isActive(index) {
      const currentPortCodeIndex = this.portOfCalls.findIndex(
        (poc) => poc.portCode === this.currentPortCode
      );

      if (currentPortCodeIndex !== -1 && index >= currentPortCodeIndex) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style scoped lang="scss">
:deep {
  .v-stepper__step {
    align-items: initial !important;
    max-width: 90%;
    padding: 24px 0;
  }
  .v-stepper__step__step {
    position: relative;
    box-shadow: 0 1px 3px 0 rgb(94 86 105 / 20%),
      0 3px 1px -2px rgb(94 86 105 / 12%), 0 2px 2px 0 rgb(94 86 105 / 14%);
  }
  .v-stepper--vertical .v-stepper__content {
    margin: -40px -36px -16px 12px !important;
  }

  .v-stepper .v-stepper__step__step:after {
    content: '';
    display: block;
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transform: scale(0.5);
  }

  .v-stepper__step:not(.v-stepper__step--complete):not(.v-stepper__step--error)
    .v-stepper__step__step {
    background: var(--v-primary-base) !important;
    opacity: 0.12;
  }
}
</style>
