import ContainerTrackingApi from '@/api/vsl/container-tracking.api';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Asia/Ho_Chi_Minh');

const initialState = () => ({
  containerMovements: {
    currentStep: null,
    currentDepotPortCode: null,
    currentTerminalPortCode: null,
    currentPortCode: null,
    containerNo: null,
    bookingNo: null,
    sealNo: null,
    termCode: null,
    voyages: [],
    stepLogs: [],
    logs: [],
  },
});

const state = initialState();
const getters = {};
const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
  SET_CONTAINER_MOVEMENTS(state, payload) {
    state.containerMovements = payload;
  },
};
const actions = {
  async fetchContainerTrackingInfo({ commit }, payload) {
    const { containerNo, bookingNo } = payload;

    // const res = await ContainerTrackingApi.getContainerTrackingInfo({
    //   containerNo,
    // });
    const queryParams = new URLSearchParams({
      container_no: containerNo,
      booking_no: bookingNo,
    }).toString();
    const url = `http://localhost:5041/api/v1/container-tracking?${queryParams}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const res = await response.json();
    console.log('res', res);

    const {
      voyages,
      container_no,
      booking_no,
      seal_no,
      term_code,
      step_state_logs,
      current_step_state,
      logs,
    } = res.data;

    const mappedContainerMovements = {
      currentStep: step_state_logs?.step,
      currentDepotPortCode: current_step_state?.depot_code,
      currentTerminalPortCode: current_step_state?.terminal_code,
      currentPortCode:
        current_step_state?.state?.depot?.port?.code ||
        current_step_state?.state?.terminal?.port?.code,
      containerNo: container_no,
      bookingNo: booking_no,
      sealNo: seal_no,
      termCode: term_code,
      voyages: [],
      stepLogs: Object.entries(step_state_logs)
        .reverse()
        .map(
          ([key, value]) =>
            !!value && {
              step: key,
              status: value?.status,
              movementDate: value?.movement_date,
              portCode: value?.port?.code,
              portName: value?.port?.name,
              portCountryCode: value?.port?.country_code,
              depotCode: value?.depot?.code,
              depotName: value?.depot?.name,
              terminalCode: value?.terminal?.code,
              terminalName: value?.terminal?.name,
              depotPortCode: value?.depot?.port?.code,
              depotPortName: value?.depot?.port?.name,
              terminalPortCode: value?.terminal?.port?.code,
              terminalPortName: value?.terminal?.port?.name,
            }
        )
        .filter(Boolean),
    };

    const mappedVoyages = voyages.map((item) => {
      const mappedItem = {
        id: item.id,
        code: item.code,
        vesselCode: item.vessel?.code,
        vesselName: item.vessel?.name,
        pocs: item.voyage_pocs.map((poc) => {
          return {
            id: poc.id,
            portCode: poc.port?.code,
            portName: poc.port?.name,
            terminalCode: poc.terminal?.code,
            terminalName: poc.terminal?.name,
            estimatedTime: dayjs.tz(poc.estimated_time).toDate(),
            estimatedTimeType: poc.estimated_time_type,
          };
        }),
      };
      return mappedItem;
    });

    mappedContainerMovements.voyages = mappedVoyages;

    // const { pocs } = mappedContainerMovements;

    // let pocIndex;
    // let samePocIndex;
    // for (pocIndex = 0; pocIndex < pocs.length; pocIndex++) {
    //   const found = pocs.find((item, index) => {
    //     if (index > pocIndex && item.portCode === pocs[pocIndex].portCode) {
    //       samePocIndex = index;
    //       return true;
    //     }
    //     return false;
    //   });
    //   if (found) break;
    // }
    // if (samePocIndex){
    //   mappedContainerMovements.voyagesPocs = [
    //     pocs.slice(0, pocIndex + 1),
    //     pocs.slice(samePocIndex, pocs.length + 2),
    //   ];
    // } else {
    //   mappedContainerMovements.voyagesPocs = [mappedContainerMovements.pocs];
    // }

    const mappedLogs = logs.map((item) => {
      const mappedItem = {
        id: item.id,
        containerNo: item.container_no,
        containerCode: item.container_size_code,
        vesselCode: item.vessel_code,
        vesselName: item.vessel?.name,
        voyageCode: item.voyage_code,
        status: item.status,
        movementDate: item.movement_date,
        polCode: item?.pol?.code,
        polName: item?.pol?.name,
        polCountryCode: item?.pol?.country_code,
        podCode: item?.pod?.code,
        podName: item?.pod?.name,
        podCountryCode: item?.pod?.country_code,
        pofdCode: item?.pofd?.code,
        pofdName: item?.pofd?.name,
        pofdCountryCode: item?.pofd?.country_code,
        systemDate: item.system_date,
      };
      return mappedItem;
    });
    console.log(
      'mappedContainerMovements,mappedContainerMovements',
      mappedContainerMovements
    );
    commit('SET_CONTAINER_MOVEMENTS', {
      ...mappedContainerMovements,
      logs: mappedLogs,
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
