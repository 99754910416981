<template>
  <div>
    <v-card
      rounded="lg"
      elevation="0"
    >
      <v-row
        no-gutters
        align="top"
      >
        <v-col
          cols="2"
          class="pl-4"
        >
          <div
            class="font-weight-bold primary--text mt-1"
            style="font-size: 26px"
          >
            {{ voyage.code || 'UNKNOWN' }}
          </div>
          <div class="d-flex flex-column">
            <v-row
              no-gutters
              class="font-weight-semibold"
            >
              <v-icon
                small
                left
                >mdi-ferry</v-icon
              >
              <div
                class="text-truncate"
                style="font-size: 12px; max-width: 80%"
              >
                {{ voyage.vesselName || 'UNKNOWN' }}
              </div>
            </v-row>
            <v-row
              no-gutters
              class="font-weight-semibold"
            >
              <v-icon
                small
                left
                >mdi-timelapse</v-icon
              >
              <div
                class="text-truncate"
                style="font-size: 12px; max-width: 80%"
              >
                {{ voyageDateRange }}
              </div>
            </v-row>
          </div>
        </v-col>
        <v-col
          cols="10"
          class="py-3"
        >
          <v-stepper
            v-model="active"
            alt-labels
            elevation="0"
          >
            <v-stepper-header>
              <template v-for="(poc, index) in voyage.pocs">
                <v-stepper-step
                  :key="`${poc.id}-step`"
                  :complete="isActive(index)"
                  :step="index"
                  complete-icon=""
                >
                  <div
                    class="d-flex flex-column text-center text-uppercase text-no-wrapx"
                  >
                    <div
                      v-if="poc.estimatedTime"
                      class="text--secondary overline"
                      style="font-size: 12px; line-height: 1rem"
                    >
                      {{ $filters.formatDate(poc.estimatedTime) }}
                    </div>
                    <div
                      v-if="poc.portName"
                      class="text--primary mt-2 mb-1 text-uppercase"
                      style="font-weight: 600"
                    >
                      {{ poc.portName }}
                    </div>
                    <div
                      v-if="poc.terminalName"
                      class="text--secondary text-uppercase"
                      style="font-size: 12px"
                    >
                      {{ poc.terminalName }}
                    </div>
                  </div>
                </v-stepper-step>
                <v-divider
                  v-if="index !== voyage.pocs.length - 1"
                  :key="poc.id"
                ></v-divider>
              </template>
            </v-stepper-header>
          </v-stepper>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  props: ['loading', 'voyage', 'currentPortCode', 'forceActive'],
  data: () => ({
    active: 0,
  }),
  computed: {
    ...mapState('vsl/containerTracking', ['containerMovements']),
    voyageDateRange() {
      let startDate, endDate;
      if (this.voyage.pocs[0] && this.voyage.pocs[0].estimatedTime) {
        startDate = this.$filters.formatDate(this.voyage.pocs[0].estimatedTime);
      }
      if (
        this.voyage.pocs[this.voyage.pocs.length - 1] &&
        this.voyage.pocs[this.voyage.pocs.length - 1].estimatedTime
      ) {
        endDate = this.$filters.formatDate(
          this.voyage.pocs[this.voyage.pocs.length - 1].estimatedTime
        );
      }

      if (!startDate && !endDate) {
        return 'UNKNOWN';
      }

      return `${startDate} - ${endDate}`;
    },
  },

  created() {
    console.log('[LOG] : this.voyage', this.voyage.pocs);
    console.log('[LOG] : this.currentPortCode', this.currentPortCode);

    this.active = this.voyage.pocs.findIndex(
      (poc) => poc.portCode === this.currentPortCode
    );
    if (this.forceActive && this.active === -1) {
      this.active = this.voyage.pocs.length - 1;
    }
  },
  methods: {
    isActive(index) {
      if (this.active === this.voyage.pocs.length - 1) {
        return true;
      }

      const currentPortCodeIndex = this.voyage.pocs.findIndex(
        (poc) => poc.portCode === this.currentPortCode
      );

      if (currentPortCodeIndex !== -1 && index <= currentPortCodeIndex) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style scoped lang="scss">
:deep {
  .v-stepper--alt-labels .v-stepper__step {
    padding: 0 12px;
    flex-basis: 200px;
  }
  .v-stepper--alt-labels .v-stepper__header .v-divider {
    margin: 18px -36px 0 !important;
  }

  .v-stepper__label {
    width: auto !important;
  }
  .v-stepper__step__step {
    position: relative;
    box-shadow: 0 1px 3px 0 rgb(94 86 105 / 20%),
      0 3px 1px -2px rgb(94 86 105 / 12%), 0 2px 2px 0 rgb(94 86 105 / 14%);
  }

  .v-stepper
    .v-stepper__step.v-stepper__step--active
    .v-stepper__step__step:after {
    content: '';
    display: block;
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transform: scale(0.5);
  }

  .v-stepper
    .v-stepper__step.v-stepper__step--complete
    .v-stepper__step__step:after {
    content: '';
    display: block;
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transform: scale(0.5);
  }

  .v-stepper
    .v-stepper__step.v-stepper__step--inactive
    .v-stepper__step__step:after {
    content: '';
    display: block;
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transform: scale(0.5);
  }

  .v-stepper__step:not(.v-stepper__step--complete):not(.v-stepper__step--error)
    .v-stepper__step__step {
    background: var(--v-primary-base) !important;
    opacity: 0.12;
  }

  .v-stepper--alt-labels .v-stepper__header .v-divider {
    margin: 35px -54px 0;
    align-self: flex-start;
    border-width: 3px;
    position: relative;
    border-radius: 6px;
  }

  .v-stepper .v-divider:after {
    content: '';
    display: block;
    height: 6px;
    width: 100%;
    width: 0;
    background: var(--v-primary-base);
    transition: all 0.1s ease-in-out;
    position: absolute;
    top: -3px;
    left: -2%;
    border-radius: 6px;
  }

  .v-stepper
    .v-stepper__step:not(.v-stepper__step--active.v-stepper__step--complete):not(.v-stepper__step--inactive)
    + .v-divider:after {
    width: 104%;
  }
}
</style>
