var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "px-4", attrs: { rounded: "lg", elevation: "0" } },
    [
      _c(
        "v-row",
        { staticClass: "pt-2", attrs: { "no-gutters": "", align: "center" } },
        [
          _c(
            "v-col",
            {
              staticClass: "font-weight-bold primary--text",
              staticStyle: { "font-size": "20px", "line-height": "1.375rem" },
              attrs: { cols: "5" },
            },
            [_vm._v(" " + _vm._s(_vm.voyage.code || "UNKNOWN") + " ")]
          ),
          _c("v-col", { attrs: { cols: "7" } }, [
            _c(
              "div",
              { staticClass: "d-flex flex-column" },
              [
                _c(
                  "v-row",
                  {
                    staticClass: "font-weight-semibold justify-end",
                    attrs: { "no-gutters": "" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "text-truncate",
                        staticStyle: {
                          "font-size": "12px",
                          "max-width": "80%",
                        },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.voyage.vesselName || "UNKNOWN") + " "
                        ),
                      ]
                    ),
                    _c("v-icon", { attrs: { small: "", right: "" } }, [
                      _vm._v("mdi-ferry"),
                    ]),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  {
                    staticClass: "font-weight-semibold justify-end",
                    attrs: { "no-gutters": "" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "text-truncate",
                        staticStyle: {
                          "font-size": "12px",
                          "max-width": "80%",
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.voyageDateRange) + " ")]
                    ),
                    _c("v-icon", { attrs: { small: "", right: "" } }, [
                      _vm._v("mdi-timelapse"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "v-stepper",
        {
          attrs: { vertical: "", elevation: "0" },
          model: {
            value: _vm.active,
            callback: function ($$v) {
              _vm.active = $$v
            },
            expression: "active",
          },
        },
        [
          _vm._l(_vm.portOfCalls, function (poc, index) {
            return [
              _c(
                "v-stepper-step",
                {
                  key: `${poc.id}-step`,
                  attrs: {
                    complete: _vm.isActive(index),
                    step: index,
                    "complete-icon": "",
                  },
                },
                [
                  _c("div", { staticClass: "text-left text-uppercase w-100" }, [
                    poc.estimatedTime
                      ? _c(
                          "div",
                          {
                            staticClass: "text--secondary overline",
                            staticStyle: {
                              "font-size": "12px",
                              "line-height": "1rem",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$filters.formatDate(poc.estimatedTime)
                                ) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                    poc.portName
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "text--primary my-1 text-uppercase text-truncate",
                            staticStyle: { "font-weight": "600" },
                          },
                          [_vm._v(" " + _vm._s(poc.portName) + " ")]
                        )
                      : _vm._e(),
                    poc.terminalName
                      ? _c(
                          "div",
                          {
                            staticClass: "text--secondary text-uppercase",
                            staticStyle: { "font-size": "12px" },
                          },
                          [_vm._v(" " + _vm._s(poc.terminalName) + " ")]
                        )
                      : _vm._e(),
                  ]),
                ]
              ),
              _c("v-stepper-content", {
                key: `${poc.id}-content`,
                attrs: { step: index },
              }),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }