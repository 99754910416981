var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container-wrapper" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c(
          "nav",
          {
            staticClass: "navbar nav-sticky navbar-expand-md navbar-light",
            staticStyle: { "margin-top": "10px" },
            attrs: { id: "navbarCr" },
          },
          [
            _c("div", { staticClass: "container-nav" }, [
              _vm._m(0),
              _vm._m(1),
              _vm._m(2),
              _c(
                "div",
                {
                  staticClass: "collapse navbar-collapse",
                  attrs: { id: "navbarSupportedContent" },
                },
                [
                  _c("ul", { staticClass: "navbar-nav ml-auto py-4 py-md-0" }, [
                    _c(
                      "li",
                      {
                        staticClass: "nav-item pl-4 pl-md-0 ml-0 ml-md-4",
                        class: { active: _vm.active === "home" },
                      },
                      [
                        _c(
                          "a",
                          { staticClass: "nav-link", attrs: { href: "/" } },
                          [_vm._v(_vm._s(_vm.$t("home")))]
                        ),
                      ]
                    ),
                    _c(
                      "li",
                      {
                        staticClass: "nav-item pl-4 pl-md-0 ml-0 ml-md-4",
                        class: { active: _vm.active === "blog" },
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "nav-link",
                            attrs: { href: "/blogs" },
                          },
                          [_vm._v(_vm._s(_vm.$t("blogs")))]
                        ),
                      ]
                    ),
                    _c(
                      "li",
                      {
                        staticClass: "nav-item pl-4 pl-md-0 ml-0 ml-md-4",
                        class: { active: _vm.active === "services" },
                      },
                      [
                        _c("a", { staticClass: "nav-link" }, [
                          _vm._v(_vm._s(_vm.$t("services"))),
                        ]),
                        _c("ul", { staticClass: "submenu" }, [
                          _c("li", [
                            _c(
                              "a",
                              {
                                staticClass: "nav-link-sub",
                                attrs: { href: "/vesselSchedule" },
                              },
                              [_vm._v(_vm._s(_vm.$t("vesselSchedule")))]
                            ),
                          ]),
                          _c("li", [
                            _c(
                              "a",
                              {
                                staticClass: "nav-link-sub",
                                attrs: { href: "/Quote" },
                              },
                              [_vm._v(_vm._s(_vm.$t("Quote")))]
                            ),
                          ]),
                          _c("li", [
                            _c(
                              "a",
                              {
                                staticClass: "nav-link-sub",
                                attrs: { href: "/Booking" },
                              },
                              [_vm._v(_vm._s(_vm.$t("Booking")))]
                            ),
                          ]),
                          _c("li", [
                            _c(
                              "a",
                              {
                                staticClass: "nav-link-sub",
                                attrs: { href: "/Tracking" },
                              },
                              [_vm._v(_vm._s(_vm.$t("Tracking")))]
                            ),
                          ]),
                        ]),
                      ]
                    ),
                    _c(
                      "li",
                      {
                        staticClass: "nav-item pl-4 pl-md-0 ml-0 ml-md-4",
                        class: { active: _vm.active === "contact" },
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "nav-link",
                            attrs: { href: "/contact" },
                          },
                          [_vm._v(_vm._s(_vm.$t("contact")))]
                        ),
                      ]
                    ),
                    _c(
                      "li",
                      {
                        staticClass: "nav-item pl-4 pl-md-0 ml-0 ml-md-4",
                        class: { active: _vm.active === "recruitment" },
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "nav-link",
                            attrs: { href: "/recruitment" },
                          },
                          [_vm._v(_vm._s(_vm.$t("recruitment")))]
                        ),
                      ]
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "ml-auto d-flex align-items-center" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "language-switcher mr-3",
                          on: {
                            mouseover: function ($event) {
                              _vm.showLanguageDropdown = true
                            },
                            mouseleave: function ($event) {
                              _vm.showLanguageDropdown = false
                            },
                          },
                        },
                        [
                          _c("button", [
                            _c("span", {
                              class:
                                _vm.currentLanguage === "vi"
                                  ? "flag-icon flag-icon-vn"
                                  : "flag-icon flag-icon-gb",
                            }),
                          ]),
                          _vm.showLanguageDropdown
                            ? _c("div", { staticClass: "dropdown-menu" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "dropdown-item",
                                    class: {
                                      active: _vm.currentLanguage === "en",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.changeLanguage("en")
                                      },
                                    },
                                  },
                                  [
                                    _c("span", {
                                      staticClass: "flag-icon flag-icon-gb",
                                    }),
                                    _vm._v(" " + _vm._s(_vm.$t("eng")) + " "),
                                  ]
                                ),
                                _c(
                                  "button",
                                  {
                                    staticClass: "dropdown-item",
                                    class: {
                                      active: _vm.currentLanguage === "vi",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.changeLanguage("vi")
                                      },
                                    },
                                  },
                                  [
                                    _c("span", {
                                      staticClass: "flag-icon flag-icon-vn",
                                    }),
                                    _vm._v("  " + _vm._s(_vm.$t("vie")) + " "),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "btn btn-custom",
                          attrs: { href: "/contact" },
                        },
                        [_vm._v(_vm._s(_vm.$t("getAQuote")))]
                      ),
                    ]
                  ),
                ]
              ),
            ]),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "navbar-brand",
        staticStyle: {
          "padding-left": "30px",
          "margin-top": "auto",
          "margin-bottom": "auto",
        },
        attrs: { target: "_blank" },
      },
      [
        _c("img", {
          staticClass: "nav-column-open-btn",
          attrs: {
            src: require("../../../assets/icons/icon-menu-nav.png"),
            "aria-label": "Open menu",
            "data-nav-column-toggler": "",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "navbar-brand-logo",
        staticStyle: {
          "padding-left": "40px",
          "margin-top": "auto",
          "margin-bottom": "auto",
        },
        attrs: { href: "/" },
      },
      [
        _c("img", {
          attrs: { src: require("../../../libhome/img/footer1.svg"), alt: "" },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "navbar-toggler",
        attrs: {
          type: "button",
          "data-toggle": "collapse",
          "data-target": "#navbarSupportedContent",
          "aria-controls": "navbarSupportedContent",
          "aria-expanded": "false",
          "aria-label": "Toggle navigation",
        },
      },
      [_c("span", { staticClass: "navbar-toggler-icon" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }