import VSLRoutes from './app-vsl.route';
import VPLRoutes from './app-vpl.route';

const routes = [
  {
    path: '/app',
    redirect: '/app/shipping',
    name: 'App',
    component: () => import('@/views/App'),
    children: [
      {
        path: 'shipping',
        redirect: '/app/shipping/request-a-quote',
        component: () => import('@/views/App/VSL'),
        children: [...VSLRoutes],
      },
      {
        path: 'port',
        redirect: '/app/port/my-order',
        component: () => import('@/views/App/VPL'),
        children: [...VPLRoutes],
      },
    ],
  },
];

export default routes;
