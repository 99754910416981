var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", {
      staticClass: "mt-6",
      staticStyle: {
        width: "100%",
        height: "75px",
        "background-size": "250px",
        "background-repeat": "repeat",
      },
    }),
    _c("div", { staticClass: "wrapper" }, [
      _c(
        "div",
        { staticClass: "container" },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                {
                  staticClass: "mb-4",
                  attrs: { cols: "12", sm: "6", md: "4" },
                },
                [
                  _c("img", {
                    attrs: {
                      width: "712",
                      height: "63",
                      src: "/assets/logo-vimc-footer.png",
                      alt: "logo vimc footer",
                    },
                  }),
                ]
              ),
              _c(
                "v-col",
                { attrs: { cols: _vm.$vuetify.breakpoint.mobile ? 12 : 8 } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "address mb-6",
                      class: {
                        "pl-4": _vm.$vuetify.breakpoint.mobile,
                      },
                    },
                    [
                      _vm._v(
                        " Address: Tòa nhà Trung tâm Thương mại Hàng hải Quốc tế (OCEAN PARK BUILDING) "
                      ),
                      _c("br"),
                      _vm._v("Số 1 Đào Duy Anh, Phương Mai, Đống Đa, Hà Nội "),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "contact__info",
                      class: {
                        "flex-column align-end": _vm.$vuetify.breakpoint.mobile,
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          class: {
                            "d-flex": !_vm.$vuetify.breakpoint.mobile,
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "contact__info_item" },
                            [
                              _c(
                                "v-icon",
                                { attrs: { small: "", color: "primary" } },
                                [_vm._v("mdi-phone")]
                              ),
                              _c("span", { staticClass: "item_text ml-1" }, [
                                _vm._v("(024) 35770825~29"),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "contact__info_item" },
                            [
                              _c(
                                "v-icon",
                                { attrs: { small: "", color: "primary" } },
                                [_vm._v("mdi-card-account-phone")]
                              ),
                              _c("span", { staticClass: "item_text ml-1" }, [
                                _vm._v("(024) 35770850/60"),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "contact__info_item" },
                            [
                              _c(
                                "v-icon",
                                { attrs: { small: "", color: "primary" } },
                                [_vm._v("mdi-email")]
                              ),
                              _c("span", { staticClass: "item_text ml-1" }, [
                                _vm._v("info@vimc.co"),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "contact__info_item" },
                            [
                              _c(
                                "v-icon",
                                { attrs: { small: "", color: "primary" } },
                                [_vm._v("mdi-microsoft-internet-explorer")]
                              ),
                              _c("span", { staticClass: "item_text ml-1" }, [
                                _vm._v("vimc.co"),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "contact__info_item" },
                            [
                              _c(
                                "v-icon",
                                { attrs: { small: "", color: "primary" } },
                                [_vm._v("mdi-source-branch-sync")]
                              ),
                              _c("span", { staticClass: "item_text ml-1" }, [
                                _vm._v(_vm._s(_vm.version)),
                              ]),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }