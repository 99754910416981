import { render, staticRenderFns } from "./DetailRecruitTechEmployee.vue?vue&type=template&id=5095ff39&"
import script from "./DetailRecruitTechEmployee.vue?vue&type=script&lang=js&"
export * from "./DetailRecruitTechEmployee.vue?vue&type=script&lang=js&"
import style0 from "./DetailRecruitTechEmployee.vue?vue&type=style&index=0&id=5095ff39&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/data/jenkins/workspace/booking-lines-prod/lines-fe/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5095ff39')) {
      api.createRecord('5095ff39', component.options)
    } else {
      api.reload('5095ff39', component.options)
    }
    module.hot.accept("./DetailRecruitTechEmployee.vue?vue&type=template&id=5095ff39&", function () {
      api.rerender('5095ff39', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Home/PageChild/DetailRecruitTechEmployee.vue"
export default component.exports