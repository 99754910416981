import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import vi from 'vuetify/es5/locale/vi';
import en from 'vuetify/es5/locale/en';
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { vi, en },
    current: localStorage.getItem('locale') || 'vi',
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#3973d4',
        secondary: '#ccd1dc',
        background: '#f2f3f8',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        blue: '#3973d4',
      },
    },
  },
});
