var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { rounded: "lg", "min-height": "450" } },
    [
      _c(
        "v-card-text",
        [
          _c(
            "v-container",
            [
              _vm.loading
                ? [
                    _c(
                      "v-timeline",
                      { attrs: { "align-top": "", dense: "", reverse: "" } },
                      [
                        _c(
                          "v-timeline-item",
                          { staticClass: "mb-6", attrs: { "hide-dot": "" } },
                          [
                            _c("v-skeleton-loader", {
                              attrs: { type: "text", boilerplate: false },
                            }),
                          ],
                          1
                        ),
                        _vm._l(4, function (item) {
                          return _c(
                            "v-timeline-item",
                            {
                              key: item.id,
                              attrs: { small: "", color: "none" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "icon",
                                    fn: function () {
                                      return [_c("StatusIndicator")]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _c(
                                "v-row",
                                { staticClass: "pt-1" },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "2" } },
                                    [
                                      _c("v-skeleton-loader", {
                                        attrs: {
                                          type: "text@2",
                                          boilerplate: false,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "2" } },
                                    [
                                      _c("v-skeleton-loader", {
                                        attrs: {
                                          type: "text@2",
                                          boilerplate: false,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "2" } },
                                    [
                                      _c("v-skeleton-loader", {
                                        attrs: {
                                          type: "text@2",
                                          boilerplate: false,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "2" } },
                                    [
                                      _c("v-skeleton-loader", {
                                        attrs: {
                                          type: "text@2",
                                          boilerplate: false,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "2" } },
                                    [
                                      _c("v-skeleton-loader", {
                                        attrs: {
                                          type: "text@2",
                                          boilerplate: false,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "2" } },
                                    [
                                      _c("v-skeleton-loader", {
                                        attrs: {
                                          type: "text@2",
                                          boilerplate: false,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                      ],
                      2
                    ),
                  ]
                : _c(
                    "v-timeline",
                    { attrs: { "align-top": "", dense: "", reverse: "" } },
                    [
                      _c(
                        "v-timeline-item",
                        { staticClass: "mb-6", attrs: { "hide-dot": "" } },
                        [
                          _c("span", [_vm._v("History")]),
                          _vm._v(" - "),
                          _c("span", { staticClass: "text-uppercase" }, [
                            _vm._v(
                              "CONTAINER NO. " +
                                _vm._s(_vm.containerMovements.containerNo)
                            ),
                          ]),
                        ]
                      ),
                      _vm._l(
                        _vm.containerMovements.logs,
                        function (item, index) {
                          return _c(
                            "v-timeline-item",
                            {
                              key: item.id,
                              attrs: { small: "", color: "none" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "icon",
                                    fn: function () {
                                      return [
                                        _c("StatusIndicator", {
                                          attrs: {
                                            status: index === 0 ? "active" : "",
                                            pulse: index === 0,
                                          },
                                        }),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _c(
                                "v-row",
                                { staticClass: "pt-1" },
                                [
                                  _c("v-col", { attrs: { cols: "2" } }, [
                                    _c("div", [
                                      _c("strong", [_vm._v("DATETIME")]),
                                    ]),
                                    _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$filters.formatDatetime(
                                              item.movementDate
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]),
                                  _c("v-col", { attrs: { cols: "2" } }, [
                                    _c("strong", [_vm._v("STATUS")]),
                                    _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            item.status.replace("_", " ")
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]),
                                  _c("v-col", { attrs: { cols: "2" } }, [
                                    _c("div", [
                                      _c("strong", [_vm._v("VOYAGE")]),
                                    ]),
                                    _c("div", [
                                      _vm._v(
                                        " " + _vm._s(item.voyageCode) + " "
                                      ),
                                    ]),
                                  ]),
                                  _c("v-col", { attrs: { cols: "2" } }, [
                                    _c("div", [
                                      _c("strong", [_vm._v("VESSEL")]),
                                    ]),
                                    _c("div", [
                                      _vm._v(
                                        " " + _vm._s(item.vesselName) + " "
                                      ),
                                    ]),
                                  ]),
                                  _c("v-col", { attrs: { cols: "2" } }, [
                                    _c("strong", [_vm._v("POL")]),
                                    _c("div", [
                                      _vm._v(" " + _vm._s(item.polName) + " "),
                                    ]),
                                  ]),
                                  _c("v-col", { attrs: { cols: "2" } }, [
                                    _c("strong", [_vm._v("POD")]),
                                    _c("div", [
                                      _vm._v(" " + _vm._s(item.pofdName) + " "),
                                    ]),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }
                      ),
                    ],
                    2
                  ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }