import { api } from '@/api';

export default {
  login(data) {
    return api.post('/v1/auth/login', data);
  },
  signupWithSms(data) {
    return api.post('/v1/auth/signup-with-sms', data);
  },
  checkPhoneValid(data) {
    return api.post('/v1/auth/check-phone-number-valid', data);
  },
  signup(data) {
    return api.post('/v1/auth/signup-with-phone-number', data);
  },
};
