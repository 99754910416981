<template>
  <v-card>
    <v-toolbar flat>
      <v-btn
        icon
        @click="$emit('close-dialog')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title class="text-uppercase">
        TRACKING RESULTS
      </v-toolbar-title>
    </v-toolbar>

    <v-card-text
      class="pa-0"
      style="line-height: 1.5; font-size: inital"
    >
      <v-card
        class="mt-6 px-3 pb-8 pt-4"
        rounded="lg"
        elevation="2"
        color="primary"
      >
        <v-row
          no-gutters
          align="center"
          class="white--text"
        >
          <v-row no-gutters>
            <v-icon
              small
              left
              dark
              >mdi-manjaro</v-icon
            >
            <div style="width: 110px">Container No.</div>
            <span
              class="text-uppercase"
              style="font-weight: 600"
            >
              {{ containerMovements.containerNo }}</span
            >
          </v-row>
          <v-row no-gutters>
            <v-icon
              small
              left
              dark
              >mdi-script-text</v-icon
            >
            <div style="width: 110px">Booking No.</div>

            <span
              class="text-uppercase"
              style="font-weight: 600"
            >
              {{ containerMovements.bookingNo }}</span
            >
          </v-row>
        </v-row>
        <MobileContainerTrackingSearchResultItem />
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
import MobileContainerTrackingSearchResultItem from './MobileContainerTrackingSearchResultItem.vue';

export default {
  components: {
    MobileContainerTrackingSearchResultItem,
  },
  data: () => ({
    dialog: {
      log: false,
    },
  }),
  computed: {
    ...mapState('vsl/containerTracking', ['containerMovements']),
  },

  created() {},
  methods: {},
};
</script>

<style scoped lang="scss">
:deep {
  .v-stepper__label {
    width: 100%;
  }
}
</style>
