// VSL
import billLadingStore from './bill-lading.store';
import commodityStore from './commodity.store';
import containerDeliveryOrderStore from './container-delivery-order.store';
import containerReleaseOrderV2Store from './container-release-order-v2.store';
import containerReleaseOrderStore from './container-release-order.store';
import containerTrackingStore from './container-tracking.store';
import containerStore from './container.store';
import debitV2Store from './debit-v2.store';
import debitStore from './debit.store';
import portStore from './port.store';
import quotationCroStore from './quotation-cro.store';
import quotationStore from './quotation.store';
import termStore from './term.store';
import uploadStore from './upload.store';
import vesselScheduleStore from './vessel-schedule.store';
import vesselStore from './vessel.store';
import voyageStore from './voyage.store';
export default {
  namespaced: true,
  modules: {
    vesselSchedule: vesselScheduleStore,
    quotation: quotationStore,
    quotationCro: quotationCroStore,
    term: termStore,
    commodity: commodityStore,
    container: containerStore,
    port: portStore,
    vessel: vesselStore,
    upload: uploadStore,
    containerTracking: containerTrackingStore,
    debit: debitStore,
    debitV2: debitV2Store,
    voyage: voyageStore,
    containerReleaseOrder: containerReleaseOrderStore,
    containerReleaseOrderV2: containerReleaseOrderV2Store,
    billLading: billLadingStore,
    containerDeliveryOrder: containerDeliveryOrderStore
  },
};
