var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "transx_blog_listing_item" }, [
    _c(
      "div",
      {
        staticClass: "transx_blog_listing_image_container",
        attrs: { id: _vm.id },
      },
      [
        _c("img", {
          staticClass: "transx_img--bg",
          attrs: { src: `${_vm.imageSrc}`, alt: "Featured Image" },
        }),
        _c("div", { staticClass: "transx_post_cat_cont" }, [
          _c(
            "div",
            {
              staticClass: "transx_post_cat_wrapper",
              staticStyle: { position: "relative" },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    position: "absolute",
                    bottom: "0",
                    padding: "5px 20px",
                    "background-color": "#e8734e",
                    "font-weight": "700",
                    "font-size": "14px",
                    color: "aliceblue",
                    "border-radius": "2px",
                    "margin-left": "16px",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t(_vm.category)) + " ")]
              ),
              _c("a", { staticClass: "link link_text", attrs: { href: "" } }),
            ]
          ),
        ]),
      ]
    ),
    _c("div", { staticClass: "transx_blog_listing_content_wrapper" }, [
      _c("div", { staticClass: "transx_blog_listing_meta" }, [
        _c("span", { staticClass: "transx_blog_listing_date" }, [
          _vm._v(_vm._s(_vm.$t(_vm.date))),
        ]),
        _c("span", { staticClass: "transx_separator" }, [_vm._v("/")]),
        _c("span", { staticClass: "transx_blog_listing_author" }, [
          _vm._v(
            " " +
              _vm._s(this.$i18n.locale === "en" ? "by" : "bởi") +
              " Vimc Lines "
          ),
        ]),
      ]),
      _c("h3", { staticClass: "transx_blog_listing_title" }, [
        _c("a", { attrs: { href: _vm.detailLink } }, [
          _vm._v(_vm._s(_vm.$t(_vm.title))),
        ]),
      ]),
      _c(
        "p",
        {
          staticClass: "transx_blog_listing_excerpt",
          staticStyle: { "text-align": "justify" },
        },
        [_vm._v(" " + _vm._s(_vm.$t(_vm.content)) + " ")]
      ),
      _c(
        "a",
        {
          staticClass: "transx_button_explore",
          staticStyle: { border: "2px solid #e8734e" },
          attrs: { href: _vm.detailLink },
        },
        [
          _c("span", { staticClass: "explore-more" }, [
            _vm._v(" " + _vm._s(_vm.$t("exploremore")) + "   "),
            _c("a", { attrs: { href: "/", target: "_blank" } }, [
              _c("svg", { staticClass: "icon" }, [
                _c(
                  "svg",
                  {
                    staticClass: "icon arrow-icon",
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      viewBox: "0 0 24 24",
                      id: "next-arrow",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        d: "M22.707,12.707a1,1,0,0,0,0-1.414l-5-5a1,1,0,0,0-1.414,1.414L19.586,11H2a1,1,0,0,0,0,2H19.586l-3.293,3.293a1,1,0,0,0,1.414,1.414Z",
                        fill: "#e8734e",
                      },
                    }),
                  ]
                ),
              ]),
            ]),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }