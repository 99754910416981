var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.isLoading
        ? _vm._l(_vm.contactList, function (item) {
            return _c(
              "v-card",
              { key: item.id, staticClass: "mb-4", attrs: { flat: "" } },
              [
                _c("UserSettingsContactListItem", {
                  attrs: { item: item },
                  on: {
                    delete: _vm.onDeleteContactItem,
                    update: _vm.onUpdateContactItem,
                  },
                }),
              ],
              1
            )
          })
        : _vm._e(),
      _vm.isLoading
        ? _vm._l(3, function (item) {
            return _c(
              "v-card",
              { key: item.id, staticClass: "mb-4", attrs: { flat: "" } },
              [
                _c("v-skeleton-loader", {
                  staticClass: "mb-2",
                  attrs: { type: "text@3", boilerplate: false },
                }),
                _c("v-divider"),
              ],
              1
            )
          })
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600px", transition: "dialog-transition" },
          model: {
            value: _vm.dialog.delete,
            callback: function ($$v) {
              _vm.$set(_vm.dialog, "delete", $$v)
            },
            expression: "dialog.delete",
          },
        },
        [
          _vm.dialog.delete
            ? _c("UserSettingsContactDelete", {
                attrs: { item: _vm.selectedItem },
                on: {
                  "close-dialog": function ($event) {
                    _vm.dialog.delete = false
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            "max-width": "600px",
            fullscreen: _vm.$vuetify.breakpoint.mobile,
            transition: "dialog-transition",
          },
          model: {
            value: _vm.dialog.update,
            callback: function ($$v) {
              _vm.$set(_vm.dialog, "update", $$v)
            },
            expression: "dialog.update",
          },
        },
        [
          _vm.dialog.update
            ? _c("UserSettingsContactUpdate", {
                attrs: { item: _vm.selectedItem },
                on: {
                  "close-dialog": function ($event) {
                    _vm.dialog.update = false
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }