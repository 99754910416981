var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        fullscreen: _vm.$vuetify.breakpoint.mobile,
        scrollable: "",
        persistent: "",
        "max-width": "1000px",
        transition: "dialog-transition",
      },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _vm.dialog
        ? _c("UserSettings", {
            on: {
              "close-dialog": function ($event) {
                _vm.dialog = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }