const routes = [
  {
    path: 'my-order',
    name: 'my order',
    component: () => import('@/views/App/VPL/MyOrder'),
    children: [
      {
        path: '',
        name: 'order list',
        meta: {
          title: 'Danh sách order',
        },
        component: () => import('@/views/App/VPL/MyOrder/OrderList'),
      },
      {
        path: ':id',
        name: 'order detail',
        meta: {
          title: 'Thông tin order',
        },
        component: () => import('@/views/App/VPL/MyOrder/OrderDetail'),
      },
    ],
  },
];

export default routes;
