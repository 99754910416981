<template>
  <div>
    <div
      class="mt-6"
      style="
        width: 100%;
        height: 75px;
        background-size: 250px;
        background-repeat: repeat;
      "
    ></div>
    <div class="wrapper">
      <div class="container">
        <v-row no-gutters>
          <v-col
            cols="12"
            sm="6"
            md="4"
            class="mb-4"
          >
            <img
              width="712"
              height="63"
              src="/assets/logo-vimc-footer.png"
              alt="logo vimc footer"
            />
          </v-col>
          <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 8">
            <div
              class="address mb-6"
              :class="{
                'pl-4': $vuetify.breakpoint.mobile,
              }"
            >
              Address: Tòa nhà Trung tâm Thương mại Hàng hải Quốc tế (OCEAN PARK
              BUILDING)
              <br />Số 1 Đào Duy Anh, Phương Mai, Đống Đa, Hà Nội
            </div>
            <div
              class="contact__info"
              :class="{
                'flex-column align-end': $vuetify.breakpoint.mobile,
              }"
            >
              <div
                :class="{
                  'd-flex': !$vuetify.breakpoint.mobile,
                }"
              >
                <div class="contact__info_item">
                  <v-icon
                    small
                    color="primary"
                    >mdi-phone</v-icon
                  >
                  <span class="item_text ml-1">(024) 35770825~29</span>
                </div>
                <div class="contact__info_item">
                  <v-icon
                    small
                    color="primary"
                    >mdi-card-account-phone</v-icon
                  >
                  <span class="item_text ml-1">(024) 35770850/60</span>
                </div>
                <div class="contact__info_item">
                  <v-icon
                    small
                    color="primary"
                    >mdi-email</v-icon
                  >
                  <span class="item_text ml-1">info@vimc.co</span>
                </div>
                <div class="contact__info_item">
                  <v-icon
                    small
                    color="primary"
                    >mdi-microsoft-internet-explorer</v-icon
                  >
                  <span class="item_text ml-1">vimc.co</span>
                </div>
                <div class="contact__info_item">
                  <v-icon
                    small
                    color="primary"
                    >mdi-source-branch-sync</v-icon
                  >
                  <span class="item_text ml-1">{{ version }}</span>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import pjson from '../../../package.json';

export default {
  components: {},
  computed: {
    version() {
      return `v${pjson.version}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  justify-content: center;
  background: #004e9f url('../../libhome/img/Footer.svg') left bottom no-repeat;
  color: #dfdfdf;
  padding: 30px 0 20px;
  font-size: 15px;

  img {
    max-width: 100%;
    height: auto;
    padding: 0 15px;
  }
}

.address {
  line-height: 1.6;
}

.contact__info {
  display: flex;
  font-size: 15px;
  line-height: 1.6;

  &_item {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }
}
</style>
