import { api } from '@/api';

export default {
  getMyInfo() {
    return api.get('/v1/users/me');
  },
  updateRequireInfo(data) {
    return api.post('/v1/users/update-require-info', data);
  },
  updateMyInfo(data) {
    return api.patch('/v1/users/me', data);
  },
  changeMyPassword(data) {
    return api.post('/v1/users/me/change-password', data);
  },
};
