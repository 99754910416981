var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "elevation-0 py-6",
      class: {
        "elevation-2 px-4": !_vm.$vuetify.breakpoint.mobile,
      },
      attrs: { rounded: "lg" },
    },
    [
      _c(
        "div",
        { staticClass: "mx-2 mt-4 mb-0" },
        [
          _c("v-img", {
            attrs: { src: "/assets/img/logo.png", height: "70px", contain: "" },
          }),
        ],
        1
      ),
      _c(
        "v-toolbar",
        { attrs: { flat: "" } },
        [
          _c("v-toolbar-title", { staticClass: "overline mx-auto" }, [
            _vm._v(" Xác thực "),
          ]),
        ],
        1
      ),
      _c(
        "v-card-text",
        { staticClass: "py-0" },
        [
          _c(
            "v-form",
            {
              attrs: { onSubmit: "return false;" },
              on: {
                submit: function ($event) {
                  return _vm.verifyOTP()
                },
              },
            },
            [
              _c("v-text-field", {
                attrs: {
                  label: "Nhập mã xác thực",
                  "prepend-inner-icon": "mdi-cellphone-key",
                  type: "text",
                  inputmode: "numeric",
                  autocomplete: "one-time-code",
                  outlined: "",
                  autofocus: "",
                  disabled: _vm.isLoading,
                  "hide-details": "",
                },
                model: {
                  value: _vm.$v.otp.$model,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.$v.otp,
                      "$model",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "$v.otp.$model",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mx-4 my-2", staticStyle: { "font-size": "14px" } },
        [
          _vm._v(" Không nhận được mã xác thực? "),
          _c("span", [
            _c(
              "a",
              {
                on: {
                  click: function ($event) {
                    return _vm.resendOTP()
                  },
                },
              },
              [_vm._v("Gửi lại")]
            ),
          ]),
        ]
      ),
      _c("div", {
        staticClass: "d-flex justify-center align-center my-4",
        attrs: { id: "recaptcha-container" },
      }),
      _c(
        "v-card-actions",
        { staticClass: "d-flex flex-column px-4" },
        [
          _c(
            "v-btn",
            {
              attrs: {
                width: "100%",
                "x-large": "",
                color: "primary",
                depressed: "",
                loading: _vm.isLoading,
                disabled: _vm.isLoading || _vm.$v.$invalid,
              },
              on: {
                click: function ($event) {
                  return _vm.verifyOTP()
                },
              },
            },
            [_vm._v("Tiếp tục ")]
          ),
          _c(
            "v-btn",
            {
              staticClass: "mx-0 my-2",
              attrs: {
                width: "100%",
                "x-large": "",
                depressed: "",
                disabled: _vm.isLoading,
                to: "/auth/signup",
              },
            },
            [_vm._v("Quay lại ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }