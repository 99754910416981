var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        {
          class: {
            "px-1": _vm.$vuetify.breakpoint.mobile,
          },
          attrs: { cols: "12" },
        },
        [
          _c(
            "div",
            [
              _c(
                "div",
                {
                  staticClass: "px-2",
                  class: {
                    "mt-14": !_vm.$vuetify.breakpoint.mobile,
                    "mt-16": _vm.$vuetify.breakpoint.mobile,
                  },
                },
                [
                  _c(
                    "p",
                    {
                      staticClass: "mt-4 text-uppercase primary--text",
                      class: {
                        "text-h3": !_vm.$vuetify.breakpoint.mobile,
                        "text-h4": _vm.$vuetify.breakpoint.mobile,
                      },
                      staticStyle: { "font-weight": "600" },
                    },
                    [
                      _vm._v(" Link people "),
                      _c("br"),
                      _vm._v(" Link the world "),
                    ]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "px-0",
                      staticStyle: {
                        "font-size": "0.7rem",
                        "font-weight": "600",
                        color: "rgba(0, 0, 0, 0.7) !important",
                      },
                      attrs: {
                        disabled: "",
                        text: "",
                        ripple: false,
                        rounded: "",
                        small: "",
                      },
                    },
                    [_vm._v("SHIP TO AND FROM ANYWHERE IN THE WORLD")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  class: {
                    "mt-12": _vm.$vuetify.breakpoint.mobile,
                  },
                  staticStyle: { "margin-top": "96px" },
                },
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c("v-spacer"),
                      _c("div", {}, [
                        _c("span", {}, [_vm._v("by")]),
                        _c("span", { staticClass: "ml-1 font-weight-bold" }, [
                          _vm._v("VIMC"),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "5" } },
                        [
                          _c(
                            "v-row",
                            {
                              staticClass: "my-2",
                              attrs: {
                                "no-gutters": "",
                                align: "center",
                                justify: "center",
                              },
                            },
                            [
                              _c(
                                "v-row",
                                {
                                  attrs: { "no-gutters": "", align: "center" },
                                },
                                [
                                  _c(
                                    "v-card",
                                    {
                                      staticStyle: { overflow: "hidden" },
                                      attrs: { rounded: "lg", width: "100%" },
                                    },
                                    [
                                      _c(
                                        "v-tabs",
                                        {
                                          attrs: {
                                            "active-class":
                                              _vm.searchMenuTabs[_vm.tab].color,
                                            height: "40px",
                                            grow: "",
                                          },
                                          model: {
                                            value: _vm.tab,
                                            callback: function ($$v) {
                                              _vm.tab = $$v
                                            },
                                            expression: "tab",
                                          },
                                        },
                                        [
                                          _c("v-tabs-slider", {
                                            attrs: {
                                              color: `${
                                                _vm.searchMenuTabs[_vm.tab]
                                                  .color
                                              }`,
                                            },
                                          }),
                                          _vm._l(
                                            _vm.searchMenuTabs,
                                            function (item) {
                                              return _c(
                                                "v-tab",
                                                {
                                                  key: item.key,
                                                  attrs: {
                                                    "active-class": `${item.color} ${item.colorModifier} ${item.color}--text`,
                                                    ripple: false,
                                                  },
                                                },
                                                [
                                                  _c("v-icon", {
                                                    attrs: {
                                                      left: "",
                                                      color: item.color,
                                                    },
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        item.icon
                                                      ),
                                                    },
                                                  }),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.title) +
                                                      " "
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-col", { attrs: { cols: "7" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.tab,
                    callback: function ($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab",
                  },
                },
                [
                  _c(
                    "v-tab-item",
                    { key: "0" },
                    [_c("ContainerTrackingSearch")],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    { key: "1" },
                    [
                      _c("VesselScheduleSearch", {
                        attrs: { "request-open-in-new-tab": "" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }