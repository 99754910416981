import quotationCroApi from '@/api/vsl/quotation-cro.api';
import QuotationCroApi from '@/api/vsl/quotation-cro.api';

const initialState = () => ({
  myQuotationCro: {
    id: null,
    code: null,
    depot: null,
    shipper: null,
    issueDate: null,
    expiryDate: null,
    status: null,
    createdAt: null,
    updatedAt: null,
    containers: [],
  },
  myQuotationCroList: [],
});

const state = initialState();
const getters = {};
const mutations = {
  RESET(state, payload) {
    const initStates = initialState();
    if (!payload) {
      Object.keys(initStates).map((key) => {
        state[key] = initStates[key];
      });
    } else {
      const statesToReset = payload;
      statesToReset.map((key) => {
        state[key] = initStates[key];
      });
    }
  },
  SET_MY_QUOTATION_CRO_LIST(state, payload) {
    state.myQuotationCroList = payload;
  },
  SET_MY_QUOTATION_CRO(state, payload) {
    state.myQuotationCro = payload;
  },
};
const actions = {
  async fetchMyQuotationCroList({ commit }, payload) {
    const { quotationId } = payload;
    const res = await quotationCroApi.fetchMyQuotationCroList({ quotationId });
    const mapped = res.data.data.map((item) => {
      const mappedItem = {
        id: item.id,
        no: item.no,
        depot: item.depot,
        shipper: item.shipper,
        issueDate: item.issue_date,
        expiryDate: item.expiry_date,
        status: item.status,
        createdAt: item.created_at,
        updatedAt: item.updated_at,
      };
      return mappedItem;
    });
    commit('SET_MY_QUOTATION_CRO_LIST', mapped);
  },
  async fetchMyQuotationCro({ commit }, payload) {
    const { id } = payload;
    const res = await quotationCroApi.fetchMyQuotationCro({ id });
    const { data } = res.data;
    const mappedItem = {
      id: data.id,
      code: data.code,
      depot: data.depot,
      shipper: data.shipper,
      issueDate: data.issue_date,
      expiryDate: data.expiry_date,
      status: data.status,
      createdAt: data.created_at,
      updatedAt: data.updated_at,
      containers: data.quotation_cro_containers.map((cont) => ({
        id: cont.id,
        containerNo: cont.container_no,
        containerCode: cont.container_size_code,
        remark: cont.remark,
      })),
    };
    commit('SET_MY_QUOTATION_CRO', mappedItem);
  },
  async confirmMyQuotationCro({ commit }, payload) {
    const { id } = payload;
    await quotationCroApi.confirmMyQuotationCro({ id });
  },
  async createQuotationCro({ commit }, payload) {
    const { quotationId, containers } = payload;
    const mapped = containers.map((item) => {
      const mappedItem = {
        container_size_code: item.containerCode,
      };
      return mappedItem;
    });
    await quotationCroApi.createQuotationCro({
      quotation_id: quotationId,
      cro_containers: mapped,
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
