<template>
  <div>
    <v-card
      class="px-6 py-4"
      elevation="2"
      style="background-color: #e6734f !important;"
      rounded="lg"
    >
      <v-row
        no-gutters
        align="center"
        class="white--text"
      >
        <div style="font-size: 16px;">
          <v-icon
            small
            dark
            >mdi-manjaro</v-icon
          >
          Container No.
          <span
            class="text-uppercase"
            style="font-weight: 600"
          >
            {{ containerMovements.containerNo }}</span
          >
        </div>
        <div class="ml-10" style="font-size: 16px;">
          <v-icon
            small
            dark
            >mdi-script-text</v-icon
          >
          Booking No.
          <span
            class="text-uppercase"
            style="font-weight: 600"
          >
            {{ containerMovements.bookingNo }}</span
          >
        </div>
        <v-spacer />
        <v-btn
          class=""
          text
          color="white"
          style="font-size: 16px;"
          :ripple="false"
          @click="dialog.log = true"
        >
          <v-icon left>mdi-history</v-icon>
          History
        </v-btn>
      </v-row>
      <ContainerTrackingSearchResultItem />
    </v-card>

    <v-dialog
      v-model="dialog.log"
      transition="dialog-transition"
    >
      <ContainerTrackingSearchResultLog
        v-if="dialog.log"
        @close-dialog="dialog.orderShipmentDelete = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ContainerTrackingSearchResultItem from './ContainerTrackingSearchResultItem.vue';
import ContainerTrackingSearchResultItemVoyage from './ContainerTrackingSearchResultItemVoyage.vue';
import ContainerTrackingSearchResultLog from './ContainerTrackingSearchResultLog.vue';

export default {
  components: {
    ContainerTrackingSearchResultLog,
    ContainerTrackingSearchResultItem,
    ContainerTrackingSearchResultItemVoyage,
  },
  data: () => ({
    dialog: {
      log: false,
    },
  }),
  computed: {
    ...mapState('vsl/containerTracking', ['containerMovements']),
  },

  created() {},
  methods: {},
};
</script>

<style scoped lang="scss">
:deep {
  .v-stepper__label {
    width: 100%;
  }
}
</style>
