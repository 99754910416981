<template>
  <div class="">
    <v-btn
      color="primary"
      class="text-none"
      :loading="isSelecting"
      @click="onButtonClick"
      v-bind="$attrs"
    >
      <v-icon
        left
        v-if="icon"
      >
        {{ icon }}
      </v-icon>
      {{ buttonText }}
    </v-btn>
    <input
      ref="uploader"
      class="d-none"
      type="file"
      @change="onFileChanged"
      v-bind="{
        accept: accept ? accept : undefined,
        multiple: multiple ? multiple : undefined,
      }"
    />
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';

export default {
  props: {
    icon: {
      type: String,
      default: 'mdi-upload',
    },
    buttonText: {
      type: String,
      default: 'Tải file',
    },
    accept: {
      type: String,
      required: false,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    isSelecting: false,
  }),
  methods: {
    onButtonClick() {
      this.isSelecting = true;
      window.addEventListener(
        'focus',
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      this.$refs.uploader.click();
    },
    onFileChanged(event) {
      if (event.target.files.length > 0) {
        const selectedFiles = Array.from(event.target.files);
        const list = [];
        for (let i = 0; i < selectedFiles.length; i++) {
          const fileObject = {
            id: uuidv4(),
            displayName: selectedFiles[i].name,
            isTemp: true,
          };
          const formData = new FormData();
          formData.append('file', selectedFiles[i]);
          list.push({
            ...fileObject,
            file: selectedFiles[i],
            formData,
          });
        }
        this.$emit('change', list);
      }
    },
  },
};
</script>

<style></style>
