var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "bg-get-started", attrs: { rounded: "xl" } },
    [
      _c(
        "v-card-text",
        {
          staticClass: "text-center white--text",
          class: {
            "pa-16": !_vm.$vuetify.breakpoint.mobile,
            "py-16": _vm.$vuetify.breakpoint.mobile,
          },
        },
        [
          _c(
            "p",
            {
              staticClass: "text-h4",
              staticStyle: { "font-weight": "600", opacity: "0.87" },
            },
            [_vm._v(" Combine everything in one place ")]
          ),
          _c(
            "p",
            {
              staticClass: "font-weight-medium",
              class: {
                "px-8": !_vm.$vuetify.breakpoint.mobile,
              },
              staticStyle: { "font-size": "16px" },
            },
            [
              _vm._v(
                " Search from more than 500,000 ocean freight quotes and get the latest international shipping rates from any carrier in seconds. With us you get a launching pad for your supply chain, and with it the effectiveness, control and transparency of your business "
              ),
            ]
          ),
          _c(
            "v-btn",
            {
              staticClass: "info--text font-weight-bold",
              attrs: { width: "250px" },
            },
            [_vm._v("Get started")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }