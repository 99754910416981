var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    [
      _c("NavBarHome", { attrs: { active: "services" } }),
      _c(
        "div",
        {
          staticClass: "carousel slide carousel-fade",
          attrs: {
            id: "carousel-2",
            "data-ride": "carousel",
            "data-interval": "6000",
          },
        },
        [
          _c(
            "div",
            { staticClass: "carousel-inner", attrs: { role: "listbox" } },
            [
              _c("div", { staticClass: "carousel-item active" }, [
                _c("img", {
                  staticClass: "d-block img-fluid",
                  attrs: {
                    src: require("../../../libhome/img/cang hai phong-chi nhanh tan vu.jpg"),
                    alt: "responsive image",
                  },
                }),
                _c("div", { staticClass: "carousel-caption" }, [
                  _c("div", [
                    _c("div", { staticClass: "transx_content_slide_wrapper" }, [
                      _c("div", { staticClass: "transx_content_container" }, [
                        _c("div", { staticClass: "transx_content_wrapper_1" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "transx_up_heading_overlay transx_overlay_align_center",
                              attrs: { id: "carousel-item-caption" },
                            },
                            [_vm._v(" VIMC LINES ")]
                          ),
                          _c(
                            "h2",
                            { staticClass: "transx_content_slider_title" },
                            [_vm._v(" " + _vm._s(_vm.$t("Booking")) + " ")]
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "booking-page", attrs: { id: "booking-page" } },
        [
          _c(
            "div",
            { staticClass: "container container-booking" },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      class: {
                        "px-1": _vm.$vuetify.breakpoint.mobile,
                      },
                      attrs: { cols: "12" },
                    },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "div",
                            {
                              class: {
                                "mt-12": _vm.$vuetify.breakpoint.mobile,
                              },
                            },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "my-2",
                                  attrs: {
                                    "no-gutters": "",
                                    align: "center",
                                    justify: "center",
                                  },
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      class: {
                                        "px-1": _vm.$vuetify.breakpoint.mobile,
                                      },
                                      attrs: { cols: "5" },
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          attrs: {
                                            "no-gutters": "",
                                            align: "center",
                                          },
                                        },
                                        [
                                          _c("v-card", {
                                            staticStyle: { overflow: "hidden" },
                                            attrs: {
                                              rounded: "lg",
                                              width: "100%",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("v-col", {
                                    class: {
                                      "px-1": _vm.$vuetify.breakpoint.mobile,
                                    },
                                    attrs: { cols: "7" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-tabs-items",
                            {
                              model: {
                                value: _vm.tab,
                                callback: function ($$v) {
                                  _vm.tab = $$v
                                },
                                expression: "tab",
                              },
                            },
                            [
                              _c(
                                "v-tab-item",
                                { key: "0" },
                                [_c("ContainerTrackingSearch")],
                                1
                              ),
                              _c(
                                "v-tab-item",
                                { key: "1" },
                                [
                                  _c("VesselScheduleSearch", {
                                    attrs: { "request-open-in-new-tab": "" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c("v-col", {
                    class: {
                      "px-1": _vm.$vuetify.breakpoint.mobile,
                    },
                    attrs: { cols: "7" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "booking-page-table",
          attrs: { id: "booking-page-table" },
        },
        [
          _c("div", { staticClass: "container container-booking-table" }, [
            _c(
              "div",
              { staticClass: "table-container" },
              [
                _vm.isLoading
                  ? _c(
                      "div",
                      { staticClass: "loading-container" },
                      [
                        _c("v-progress-circular", {
                          staticClass: "loading-spinner",
                          attrs: {
                            indeterminate: "",
                            color: "primary",
                            size: "50",
                          },
                        }),
                        _vm._m(0),
                      ],
                      1
                    )
                  : _c("a-table", {
                      attrs: {
                        sticky: "",
                        columns: _vm.columns,
                        "data-source": _vm.tableData,
                        pagination: { pageSize: 10 },
                        scroll: _vm.scrollSettings,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "bodyCell",
                          fn: function ({ column }) {
                            return [
                              column.key === "operation"
                                ? [_c("a", [_vm._v("action")])]
                                : _vm._e(),
                            ]
                          },
                        },
                        {
                          key: "summary",
                          fn: function () {
                            return [
                              _c(
                                "a-table-summary",
                                {
                                  attrs: {
                                    fixed: _vm.fixedTop ? "top" : "bottom",
                                  },
                                },
                                [
                                  _c(
                                    "a-table-summary-row",
                                    [
                                      _c(
                                        "a-table-summary-cell",
                                        { attrs: { index: 0, "col-span": 2 } },
                                        [
                                          _c("a-switch", {
                                            attrs: {
                                              "checked-children": "Fixed Top",
                                              "un-checked-children":
                                                "Fixed Top",
                                            },
                                            model: {
                                              value: _vm.fixedTop,
                                              callback: function ($$v) {
                                                _vm.fixedTop = $$v
                                              },
                                              expression: "fixedTop",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-table-summary-cell",
                                        { attrs: { index: 2, "col-span": 8 } },
                                        [_vm._v(" Scroll Context ")]
                                      ),
                                      _c(
                                        "a-table-summary-cell",
                                        { attrs: { index: 10 } },
                                        [_vm._v("Fix Right")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
              ],
              1
            ),
          ]),
        ]
      ),
      _c("FooterHome"),
      _vm._m(1),
      _c("NavBarLeftHome"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "loading-text" }, [
      _c("span", [_vm._v("Loading")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { staticClass: "back-to-top", attrs: { href: "/home" } }, [
      _c("i", { staticClass: "fa fa-chevron-up" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }