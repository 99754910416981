<template>
  <div class="pa-0">
    <div>
      <!--      <template v-if="!isLoading">-->
      <!--        <VesselScheduleSearchResultListItemV2-->
      <!--          v-for="item in vesselScheduleList"-->
      <!--          :key="item.code"-->
      <!--          :schedules="item"-->
      <!--          class="mb-2"-->
      <!--        />-->
      <!--      </template>-->
      <!-- <template v-if="!isLoading">
        <VesselScheduleSearchResultBook
          :vesselScheduleList="vesselScheduleList"
        />
      </template> -->

      <!-- <template v-if="isLoading"> -->
      <!-- <v-card
          v-for="(item, i) in 5"
          :key="i"
          class="mb-2"
          rounded="lg"
        >
          <v-skeleton-loader
            :boilerplate="false"
            type="list-item-three-line@2"
            height="228px"
          />
        </v-card> -->
      <!-- </template>
      <NoData
        v-if="getIsSearched() && !isLoading && list.length > 0"
        background="white"
        rounded="lg"
        height="348px"
        :text="$t('text-no-data')"
      /> -->
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { mapActions, mapMutations, mapState } from 'vuex';
import VesselScheduleSearchResultListItemV2 from './VesselScheduleSearchResultListItemV2.vue';
import NoData from '@/components/shared/local/base/NoData.vue';
import VesselScheduleSearchResultBook from '@/components/shared/local/vsl/VesselScheduleSearch/VesselScheduleSearchResultBook.vue';
export default {
  inject: ['getIsSearched'],
  components: {
    VesselScheduleSearchResultBook,
    VesselScheduleSearchResultListItemV2,
    NoData,
  },
  data: () => ({
    currentIndex: 0,
  }),
  computed: {
    ...mapState('vsl/vesselSchedule', [
      'vesselScheduleList',
      'vesselScheduleListQueryParams',
      'vesselScheduleListLoading',
      'vesselScheduleListTotal',
    ]),
    isLoading: {
      get() {
        return this.vesselScheduleListLoading;
      },
      set(value) {
        this.SET_VESSEL_SCHEDULE_LIST_LOADING(value);
      },
    },
    queryParams: {
      get() {
        return this.vesselScheduleListQueryParams;
      },
      set(value) {
        this.SET_VESSEL_SCHEDULE_LIST_QUERY_PARAMS(value);
      },
    },
    list() {
      const transformedList = this.vesselScheduleList.map(
        ({ type, path, pathSchedules }) => {
          if (type === 'transit') {
            const mergedPathSchedules = path.map((value, index) => {
              let port = {
                code: value,
              };

              if (index == 0) {
                const schedules = pathSchedules[index];
                port = {
                  ...port,
                  name: schedules.polName,
                  departureDate: schedules.etd,
                  terminalName: schedules.polTerminalName,
                  terminalCode: schedules.polTerminalCode,
                };
                return port;
              }
              if (index == path.length - 1) {
                const schedules = pathSchedules[index - 1];

                port = {
                  ...port,
                  name: schedules.podName,
                  arrivalDate: schedules.eta,
                  terminalName: schedules.podTerminalName,
                  terminalCode: schedules.podTerminalCode,
                };
                return port;
              }

              port = {
                ...port,
                name: pathSchedules[index].polName,
                arrivalDate: pathSchedules[index - 1].eta,
                departureDate: pathSchedules[index].etd,
                terminalName: pathSchedules[index].polTerminalName,
                terminalCode: pathSchedules[index].polTerminalCode,
              };
              return port;
            });
            return mergedPathSchedules;
          }

          if (type === 'direct') {
            const schedules = [
              {
                code: pathSchedules[0].polCode,
                name: pathSchedules[0].polName,
                departureDate: pathSchedules[0].etd,
                terminalName: pathSchedules[0].polTerminalName,
                terminalCode: pathSchedules[0].polTerminalCode,
              },
              {
                code: pathSchedules[0].podCode,
                name: pathSchedules[0].podName,
                arrivalDate: pathSchedules[0].eta,
                terminalName: pathSchedules[0].podTerminalName,
                terminalCode: pathSchedules[0].podTerminalCode,
              },
            ];
            return schedules;
          }
        }
      );
      console.log('[LOG] : list', transformedList);
      return transformedList;
    },
  },
  async created() {
    try {
      this.isLoading = true;
      let {
        // page = this.queryParams.page,
        // size = this.queryParams.size,
        pol_code,
        pod_code,
        from_etd,
        to_eta,
      } = this.$route.query;

      if (!from_etd) {
        from_etd = dayjs().format('YYYY-MM-DD');
      }
      // await this.$router
      //   .push({
      //     query: {
      //       pol_code,
      //       pod_code,
      //       from_etd,
      //       to_eta,
      //     },
      //   })
      //   .catch(() => {});
      const queryParams = {
        etdEta: [from_etd, to_eta],
        polCode: pol_code,
        podCode: pod_code,
      };
      this.queryParams = queryParams;

      // await this.fetchVesselScheduleList();
    } finally {
      this.isLoading = false;
    }
  },
  destroyed() {
    this.RESET();
  },
  methods: {
    ...mapMutations('vsl/vesselSchedule', [
      'SET_VESSEL_SCHEDULE_LIST_QUERY_PARAMS',
      'SET_VESSEL_SCHEDULE_LIST_LOADING',
      'RESET',
    ]),
    ...mapActions('vsl/vesselSchedule', ['fetchVesselScheduleList']),
  },
};
</script>

<style></style>
