import VesselScheduleApi from '@/api/vsl/vessel-schedule.api';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Asia/Ho_Chi_Minh');

const initialState = () => ({
  vesselSchedule: {
    code: null,
    voyageCode: null,
    vesselName: '',
    vesselSlot: false,
    etd: null,
    eta: null,
    polCode: null,
    polName: '',
    polCountryCode: null,
    polTerminalName: '',
    polTerminalCode: null,
    podCode: null,
    podName: '',
    podCountryCode: null,
    podTerminalName: '',
    podTerminalCode: null,
  },
  vesselScheduleList: [],
  vesselScheduleListTotal: 0,
  vesselScheduleListQueryParams: {
    page: 0,
    size: 5,
    voyageCode: null,
    vesselCode: null,
    polCode: null,
    podCode: null,
    etdEta: [],
  },
  vesselScheduleListLoading: false,
});

const scheduleDetailMapper = (item) => ({
  code: item.code,
  voyageCode: item.voyage?.code,
  vesselName: item.vessel?.name,
  vesselSlot: item.vessel?.is_slot_available,
  etd: dayjs.tz(item.etd).toDate(),
  eta: dayjs.tz(item.eta).toDate(),
  polCode: item.pol_terminal?.port?.code,
  polName: item.pol_terminal?.port?.name,
  polCountryCode: item.pol_terminal?.port?.country_code,
  polTerminalName: item.pol_terminal?.name,
  polTerminalCode: item.pol_terminal?.code,
  podCode: item.pod_terminal.port?.code,
  podName: item.pod_terminal.port?.name,
  podCountryCode: item.pod_terminal?.port?.country_code,
  podTerminalName: item.pod_terminal?.name,
  podTerminalCode: item.pod_terminal?.code,
});

const state = initialState();
const getters = {};
const mutations = {
  RESET(state, payload) {
    const initStates = initialState();
    if (!payload) {
      Object.keys(initStates).map((key) => {
        state[key] = initStates[key];
      });
    } else {
      const statesToReset = payload;
      statesToReset.map((key) => {
        state[key] = initStates[key];
      });
    }
  },
  SET_VESSEL_SCHEDULE(state, payload) {
    state.vesselSchedule = payload;
  },
  SET_VESSEL_SCHEDULE_LIST(state, payload) {
    state.vesselScheduleList = payload;
  },
  ADD_TO_VESSEL_SCHEDULE_LIST(state, payload) {
    state.vesselScheduleList.push(...payload);
  },
  SET_VESSEL_SCHEDULE_LIST_TOTAL(state, payload) {
    state.vesselScheduleListTotal = payload;
  },
  SET_VESSEL_SCHEDULE_LIST_QUERY_PARAMS(state, payload) {
    Object.keys(payload).map((key) => {
      state.vesselScheduleListQueryParams[key] = payload[key];
    });
  },
  SET_VESSEL_SCHEDULE_LIST_LOADING(state, payload) {
    state.vesselScheduleListLoading = payload;
  },
};
const actions = {
  async fetchVesselScheduleList({ commit, state }, payload) {
    const { vesselScheduleListQueryParams: queryParams } = state;

    const res = await VesselScheduleApi.fetchVesselScheduleList({
      // page: queryParams.page,
      // size: queryParams.size,
      search: queryParams.search,
      vessel_code: queryParams.vesselCode,
      pol_code: queryParams.polCode,
      pod_code: queryParams.podCode,
      from_etd: queryParams.etdEta[0],
      to_eta: queryParams.etdEta[1],
    });
    const mapped = res.data.data.map((item) => {
      const mappedItem = {
        code: item.code,
        voyageCode: item.voyage.code,
        vesselName: item.vessel.name,
        vesselSlot: item.vessel.is_slot_available,
        etd: dayjs.tz(item.etd).toDate(),
        eta: dayjs.tz(item.eta).toDate(),
        polCode: item.pol_terminal.port.code,
        polName: item.pol_terminal.port.name,
        polCountryCode: item.pol_terminal.port.country_code,
        polTerminalName: item.pol_terminal.name,
        polTerminalCode: item.pol_terminal.code,
        podCode: item.pod_terminal.port.code,
        podName: item.pod_terminal.port.name,
        podCountryCode: item.pod_terminal.port.country_code,
        podTerminalName: item.pod_terminal.name,
        podTerminalCode: item.pod_terminal.code,
      };
      return mappedItem;
    });
    commit('SET_VESSEL_SCHEDULE_LIST_TOTAL', res.data.total);
    commit('SET_VESSEL_SCHEDULE_LIST', mapped);
  },
  async fetchVesselSchedule({ commit }, payload) {
    const { code } = payload;
    const res = await VesselScheduleApi.getVesselSchedule({ code });
    const item = res.data.data;
    const mappedItem = {
      code: item.code,
      voyageCode: item.voyage.code,
      vesselName: item.vessel.name,
      vesselSlot: item.vessel.is_slot_available,
      etd: dayjs.tz(item.etd).toDate(),
      eta: dayjs.tz(item.eta).toDate(),
      polCode: item.pol_terminal.port.code,
      polName: item.pol_terminal.port.name,
      polCountryCode: item.pol_terminal.port.country_code,
      polTerminalName: item.pol_terminal.name,
      polTerminalCode: item.pol_terminal.code,
      podCode: item.pod_terminal.port.code,
      podName: item.pod_terminal.port.name,
      podCountryCode: item.pod_terminal.port.country_code,
      podTerminalName: item.pod_terminal.name,
      podTerminalCode: item.pod_terminal.code,
    };
    commit('SET_VESSEL_SCHEDULE', mappedItem);
  },

  async searchVesselSchedule({ commit }, payload) {
    const { vesselScheduleListQueryParams: queryParams } = state;
    const { searchType } = payload;

    let body;

    if (searchType === 'portSearching') {
      body = {
        pol_code: queryParams.polCode,
        pod_code: queryParams.podCode,
        vessel_code: queryParams.vesselCode,
        from_etd: queryParams.etdEta[0],
        to_eta: queryParams.etdEta[1],
      };
    }
    if (searchType === 'voyageCodeSearching') {
      body = {
        voyage_code: queryParams.voyageCode,
      };
    }

    console.log('[LOG] : body', body);
    const res = await VesselScheduleApi.searchVesselSchedule(body);

    const { direct_paths, transit_paths } = res.data.data;

    const directPaths = direct_paths.map(
      ({ path, voyage_codes, schedules }) => ({
        type: 'direct',
        path,
        voyageCodes: voyage_codes,
        pathSchedules: schedules.map((item) => scheduleDetailMapper(item)),
      })
    );
    const transitPaths = transit_paths.map(
      ({ path, voyage_codes, schedules }) => ({
        type: 'transit',
        path,
        voyageCodes: voyage_codes,
        pathSchedules: schedules.map((item) => scheduleDetailMapper(item)),
      })
    );

    const paths = [...directPaths, ...transitPaths];
    // const paths = [...transitPaths, ...directPaths];

    commit('SET_VESSEL_SCHEDULE_LIST', paths);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
