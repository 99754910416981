var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _c(
        "div",
        {
          staticClass: "overline white--text mt-4",
          staticStyle: { "font-size": "14px !important" },
        },
        [_vm._v("Voyages")]
      ),
      _c(
        "div",
        { staticClass: "d-flex flex-column-reverse" },
        _vm._l(_vm.containerMovements.voyages, function (voyage, index) {
          return _c(
            "div",
            { key: index },
            [
              _c("ContainerTrackingSearchResultItemVoyage", {
                staticClass: "mb-4",
                attrs: {
                  voyage: voyage,
                  "current-port-code": _vm.containerMovements.currentPortCode,
                  "force-active":
                    index < _vm.containerMovements.voyages.length - 1,
                },
              }),
            ],
            1
          )
        }),
        0
      ),
      _c(
        "div",
        {
          staticClass: "overline white--text",
          staticStyle: { "font-size": "14px !important" },
        },
        [_vm._v("Activity Tracking")]
      ),
      _c("ContainerTrackingSearchResultItemState", {
        staticClass: "mb-4",
        attrs: { logs: _vm.containerMovements.stepLogs },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }