var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "pa-4" },
    [
      _c(
        "v-card-title",
        { staticClass: "mb-4", attrs: { "primary-title": "" } },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "", align: "center" } },
            [
              _c("div", [
                _c("div", [_vm._v("Request voyage")]),
                _c("div", { staticClass: "caption" }, [
                  _vm._v("Submit your voyage information"),
                ]),
              ]),
              _c("v-spacer"),
              _c(
                "div",
                {
                  style: _vm.$vuetify.breakpoint.mobile
                    ? "width: 100%; display:flex; justify-content: flex-end; margin-top: 12px"
                    : "",
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-2",
                      attrs: { color: "error", disabled: _vm.isLoading },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("close-dialog")
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("mdi-close"),
                      ]),
                      _vm._v(" Cancel"),
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "success", loading: _vm.isLoading },
                      on: { click: _vm.onSubmitRequest },
                    },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("mdi-send"),
                      ]),
                      _vm._v(" Submit"),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card-text",
        [
          _c(
            "v-form",
            { attrs: { onSubmit: "return false;" } },
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            {
                              attrs: {
                                cols: _vm.$vuetify.breakpoint.mobile ? 12 : 8,
                              },
                            },
                            [
                              _c(
                                "v-row",
                                { attrs: { "no-gutters": "", align: "end" } },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      attrs: {
                                        cols: _vm.$vuetify.breakpoint.mobile
                                          ? 12
                                          : 5,
                                      },
                                    },
                                    [
                                      _c("div", { staticClass: "caption" }, [
                                        _vm._v("From"),
                                      ]),
                                      _c("PortPicker", {
                                        attrs: {
                                          value: _vm.selectedPolCode,
                                          solo: "",
                                          label: "Port of Departure",
                                          clearable: "",
                                          "hide-details": "",
                                          outlined: "",
                                          dense: "",
                                          "no-self-load-data": "",
                                        },
                                        on: { change: _vm.onSelectPOL },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "d-flex align-center justify-center",
                                      attrs: {
                                        cols: _vm.$vuetify.breakpoint.mobile
                                          ? 12
                                          : 1,
                                      },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "mb-2",
                                          attrs: { color: "primary" },
                                        },
                                        [_vm._v(" mdi-swap-horizontal-circle ")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      attrs: {
                                        cols: _vm.$vuetify.breakpoint.mobile
                                          ? 12
                                          : 5,
                                      },
                                    },
                                    [
                                      _c("div", { staticClass: "caption" }, [
                                        _vm._v("To"),
                                      ]),
                                      _c("PortPicker", {
                                        attrs: {
                                          value: _vm.selectedPodCode,
                                          solo: "",
                                          label: "Port of Arrival",
                                          clearable: "",
                                          "hide-details": "",
                                          outlined: "",
                                          dense: "",
                                          "no-self-load-data": "",
                                        },
                                        on: { change: _vm.onSelectPOD },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              attrs: {
                                cols: _vm.$vuetify.breakpoint.mobile ? 12 : 4,
                              },
                            },
                            [
                              _c(
                                "v-dialog",
                                {
                                  ref: "dialog",
                                  attrs: {
                                    persistent: "",
                                    "max-width": "670px",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            "div",
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "caption" },
                                                [
                                                  _vm._v(
                                                    "Departure Date - Arrival Date"
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-text-field",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        value:
                                                          _vm.dateRangeText,
                                                        label: "ETD - ETA",
                                                        "hide-details": "",
                                                        "prepend-inner-icon":
                                                          "mdi-calendar-month",
                                                        readonly: "",
                                                        solo: "",
                                                        outlined: "",
                                                        dense: "",
                                                      },
                                                    },
                                                    "v-text-field",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                )
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                  model: {
                                    value: _vm.modal,
                                    callback: function ($$v) {
                                      _vm.modal = $$v
                                    },
                                    expression: "modal",
                                  },
                                },
                                [
                                  _c(
                                    "v-date-picker",
                                    {
                                      attrs: {
                                        range: "",
                                        landscape:
                                          !_vm.$vuetify.breakpoint.mobile,
                                        "full-width": "",
                                        allowedDates: _vm.allowedDates,
                                      },
                                      on: { change: _vm.onChangeDates },
                                      model: {
                                        value: _vm.dates,
                                        callback: function ($$v) {
                                          _vm.dates = $$v
                                        },
                                        expression: "dates",
                                      },
                                    },
                                    [
                                      _c("v-spacer"),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { text: "", color: "primary" },
                                          on: {
                                            click: function ($event) {
                                              _vm.modal = false
                                            },
                                          },
                                        },
                                        [_vm._v(" Hủy ")]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { text: "", color: "primary" },
                                          on: { click: _vm.onSelectEtdEta },
                                        },
                                        [_vm._v(" Chọn ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { align: "end", "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            {
                              attrs: {
                                cols: _vm.$vuetify.breakpoint.mobile ? 12 : 8,
                              },
                            },
                            [
                              _c(
                                "v-row",
                                { attrs: { "no-gutters": "" } },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      attrs: {
                                        cols: _vm.$vuetify.breakpoint.mobile
                                          ? 12
                                          : 11,
                                      },
                                    },
                                    [
                                      _c("div", { staticClass: "caption" }, [
                                        _vm._v("Voyage"),
                                      ]),
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "Enter voyage code",
                                          "prepend-inner-icon": "mdi-compass",
                                          type: "text",
                                          solo: "",
                                          flat: "",
                                          outlined: "",
                                          dense: "",
                                          "hide-details": "",
                                          clearable: "",
                                        },
                                        model: {
                                          value: _vm.typedSearch,
                                          callback: function ($$v) {
                                            _vm.typedSearch = $$v
                                          },
                                          expression: "typedSearch",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              attrs: {
                                cols: _vm.$vuetify.breakpoint.mobile ? 12 : 4,
                              },
                            },
                            [
                              _c("div", { staticClass: "caption" }, [
                                _vm._v("Vessel"),
                              ]),
                              _c("VesselPicker", {
                                attrs: {
                                  value: _vm.selectedVesselCode,
                                  label: "Choose vessel",
                                  clearable: "",
                                  "hide-details": "",
                                  solo: "",
                                  outlined: "",
                                  dense: "",
                                  "no-self-load-data": "",
                                },
                                on: { change: _vm.onSelectVessel },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "overline mt-8" }, [
                _vm._v("Container information"),
              ]),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    {
                      attrs: { cols: _vm.$vuetify.breakpoint.mobile ? 12 : 4 },
                    },
                    [
                      _c("div", { staticClass: "caption" }, [
                        _vm._v("Container type size"),
                      ]),
                      _c("ContainerPicker", {
                        staticClass: "pr-3",
                        attrs: {
                          label: "Select container type size",
                          "prepend-inner-icon": "mdi-checkbox-intermediate",
                          solo: "",
                          outlined: "",
                          dense: "",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      attrs: { cols: _vm.$vuetify.breakpoint.mobile ? 12 : 4 },
                    },
                    [
                      _c("div", { staticClass: "caption" }, [
                        _vm._v("Commodity"),
                      ]),
                      _c("CommodityPicker", {
                        staticClass: "pr-3",
                        attrs: {
                          label: "Select commodity",
                          "prepend-inner-icon": "mdi-package",
                          solo: "",
                          outlined: "",
                          dense: "",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      attrs: { cols: _vm.$vuetify.breakpoint.mobile ? 12 : 4 },
                    },
                    [
                      _c("div", { staticClass: "caption" }, [
                        _vm._v("Quantity"),
                      ]),
                      _c("v-text-field", {
                        attrs: {
                          type: "number",
                          "prepend-inner-icon": "mdi-dots-triangle",
                          label: "Pick quantity",
                          solo: "",
                          outlined: "",
                          dense: "",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }