var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "elevation-0 py-6",
      class: {
        "elevation-2 px-4": !_vm.$vuetify.breakpoint.mobile,
      },
    },
    [
      _c(
        "v-toolbar",
        { attrs: { flat: "" } },
        [
          _c("v-toolbar-title", { staticClass: "overline mx-auto" }, [
            _vm._v(" Tạo tài khoản "),
          ]),
        ],
        1
      ),
      _c(
        "v-card-text",
        { staticClass: "py-0" },
        [
          _c(
            "v-form",
            [
              _c("v-text-field", {
                attrs: {
                  label: "Họ tên",
                  "prepend-inner-icon": "mdi-account",
                  type: "text",
                  outlined: "",
                  rounded: "",
                  autofocus: "",
                  "error-messages": _vm.nameErrors,
                  counter: 32,
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.name.$touch()
                  },
                  blur: function ($event) {
                    return _vm.$v.name.$touch()
                  },
                },
                model: {
                  value: _vm.$v.name.$model,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.$v.name,
                      "$model",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "$v.name.$model",
                },
              }),
              _c("v-text-field", {
                attrs: {
                  label: "Số điện thoại",
                  "prepend-inner-icon": "mdi-cellphone",
                  type: "tel",
                  outlined: "",
                  rounded: "",
                  "error-messages": _vm.phoneErrors,
                  counter: 12,
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.name.$touch()
                  },
                  blur: function ($event) {
                    return _vm.$v.name.$touch()
                  },
                },
                model: {
                  value: _vm.$v.phone.$model,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.$v.phone,
                      "$model",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "$v.phone.$model",
                },
              }),
              _c("v-text-field", {
                attrs: {
                  label: "Email",
                  "prepend-inner-icon": "mdi-email",
                  type: "text",
                  outlined: "",
                  rounded: "",
                  "error-messages": _vm.emailErrors,
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.name.$touch()
                  },
                  blur: function ($event) {
                    return _vm.$v.name.$touch()
                  },
                },
                model: {
                  value: _vm.$v.email.$model,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.$v.email,
                      "$model",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "$v.email.$model",
                },
              }),
              _c("v-text-field", {
                attrs: {
                  label: "Địa chỉ",
                  "prepend-inner-icon": "mdi-email",
                  type: "text",
                  outlined: "",
                  rounded: "",
                  counter: 256,
                  "error-messages": _vm.addressErrors,
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.address.$touch()
                  },
                  blur: function ($event) {
                    return _vm.$v.address.$touch()
                  },
                },
                model: {
                  value: _vm.$v.address.$model,
                  callback: function ($$v) {
                    _vm.$set(_vm.$v.address, "$model", $$v)
                  },
                  expression: "$v.address.$model",
                },
              }),
              _c("v-text-field", {
                attrs: {
                  label: "Mật khẩu",
                  "prepend-inner-icon": "mdi-lock",
                  type: "password",
                  rounded: "",
                  outlined: "",
                  autocomplete: "new-password",
                  "error-messages": _vm.passwordErrors,
                  counter: 32,
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.password.$touch()
                  },
                  blur: function ($event) {
                    return _vm.$v.password.$touch()
                  },
                },
                model: {
                  value: _vm.$v.password.$model,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.$v.password,
                      "$model",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "$v.password.$model",
                },
              }),
              _c("v-text-field", {
                attrs: {
                  label: "Nhập lại mật khẩu",
                  "prepend-inner-icon": "mdi-lock",
                  type: "password",
                  rounded: "",
                  outlined: "",
                  autocomplete: "new-password",
                  "error-messages": _vm.confirmPasswordErrors,
                  counter: 32,
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.confirmPassword.$touch()
                  },
                  blur: function ($event) {
                    return _vm.$v.confirmPassword.$touch()
                  },
                },
                model: {
                  value: _vm.$v.confirmPassword.$model,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.$v.confirmPassword,
                      "$model",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "$v.confirmPassword.$model",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card-actions",
        { staticClass: "d-flex flex-column px-4" },
        [
          _c(
            "v-btn",
            {
              attrs: {
                width: "100%",
                "x-large": "",
                color: "primary",
                depressed: "",
                rounded: "",
                loading: _vm.isLoading,
                disabled: _vm.isLoading || _vm.$v.$invalid,
              },
              on: { click: _vm.onSignup },
            },
            [_vm._v("Tạo tài khoản")]
          ),
          _c(
            "v-btn",
            {
              staticClass: "mt-4",
              attrs: {
                width: "100%",
                "x-large": "",
                depressed: "",
                rounded: "",
                disabled: _vm.isLoading,
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("change-tab", "Login")
                },
              },
            },
            [_vm._v(" Quay lại ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }