var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mb-16" },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("VesselScheduleSearchFilterV3", {
                staticClass: "mb-2",
                class: {
                  "mb-2": _vm.$vuetify.breakpoint.mobile,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              attrs: {
                cols: _vm.$vuetify.breakpoint.mobile ? 12 : 12,
                order: _vm.$vuetify.breakpoint.mobile ? 2 : 1,
              },
            },
            [_c("VesselScheduleSearchResultList")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }