var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "px-4", attrs: { rounded: "lg", flat: "" } },
    _vm._l(_vm.pathSchedules, function (schedule, index) {
      return _c(
        "div",
        { key: index },
        [
          _vm.pathSchedules.length > 1
            ? [
                index !== 0
                  ? _c(
                      "v-card",
                      {
                        staticClass: "py-1 px-2 my-2",
                        attrs: { color: "orange darken-1", rounded: "lg" },
                      },
                      [
                        _c(
                          "v-row",
                          {
                            staticClass: "white--text",
                            attrs: { "no-gutters": "", align: "center" },
                          },
                          [
                            _c(
                              "div",
                              [
                                _c(
                                  "v-icon",
                                  { attrs: { small: "", dark: "" } },
                                  [_vm._v("mdi-swap-vertical-circle")]
                                ),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("text-transiting-to-voyage")
                                    ) +
                                    " "
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "text-left",
                                staticStyle: {
                                  "font-size": "12px",
                                  "line-height": "1.75",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "px-1" },
                                  [
                                    _c(
                                      "v-icon",
                                      { attrs: { small: "", dark: "" } },
                                      [_vm._v("mdi-compass")]
                                    ),
                                    _vm._v(
                                      " " + _vm._s(schedule.voyageCode) + " "
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _c(
                      "v-card",
                      {
                        staticClass: "py-1 px-2 my-2",
                        attrs: { color: "cyan darken-1", rounded: "lg" },
                      },
                      [
                        _c(
                          "v-row",
                          {
                            staticClass: "white--text",
                            attrs: { "no-gutters": "", align: "center" },
                          },
                          [
                            _c(
                              "div",
                              [
                                _c(
                                  "v-icon",
                                  { attrs: { small: "", dark: "" } },
                                  [_vm._v("mdi-chevron-up-circle")]
                                ),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("text-starting-from-voyage")
                                    ) +
                                    " "
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "text-left",
                                staticStyle: {
                                  "font-size": "12px",
                                  "line-height": "1.75",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "px-1" },
                                  [
                                    _c(
                                      "v-icon",
                                      { attrs: { small: "", dark: "" } },
                                      [_vm._v("mdi-compass")]
                                    ),
                                    _vm._v(
                                      " " + _vm._s(schedule.voyageCode) + " "
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
              ]
            : _vm._e(),
          _c(
            "v-card",
            {
              staticStyle: { overflow: "hidden" },
              attrs: { color: "background", rounded: "lg", flat: "" },
            },
            [
              _c(
                "v-card",
                {
                  staticClass: "white--text py-1",
                  attrs: { color: "grey darken-3", tile: "" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "text-left",
                      staticStyle: {
                        "font-size": "12px",
                        "line-height": "1.75",
                      },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "px-2" },
                        [
                          _c("v-icon", { attrs: { small: "", dark: "" } }, [
                            _vm._v("mdi-compass"),
                          ]),
                          _vm._v(" " + _vm._s(schedule.voyageCode) + " "),
                        ],
                        1
                      ),
                      _c(
                        "span",
                        [
                          _c("v-icon", { attrs: { small: "", dark: "" } }, [
                            _vm._v("mdi-ferry"),
                          ]),
                          _vm._v(" " + _vm._s(schedule.vesselName) + " "),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              ),
              _c(
                "v-stepper",
                { attrs: { vertical: "", elevation: "0" } },
                [
                  _c(
                    "v-stepper-step",
                    {
                      attrs: {
                        complete: "",
                        step: 1,
                        "complete-icon":
                          index === 0
                            ? "mdi-map-marker"
                            : "mdi-radiobox-marked",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "text-left text-uppercase w-100" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "text--secondary overline",
                              staticStyle: {
                                "font-size": "12px",
                                "line-height": "1rem",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    schedule.etd &&
                                      _vm.$filters.formatDatetime(schedule.etd)
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "text--primary my-1 text-uppercase text-truncate",
                              staticStyle: { "font-weight": "600" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    schedule.polCode + " - " + schedule.polName
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "text--secondary text-uppercase",
                              staticStyle: { "font-size": "12px" },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(schedule.polTerminalName) + " "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c("v-stepper-content", { attrs: { step: 1 } }),
                  _c(
                    "v-stepper-step",
                    {
                      attrs: {
                        complete: "",
                        step: 2,
                        "complete-icon":
                          index === _vm.pathSchedules.length - 1
                            ? "mdi-map-marker-radius"
                            : "mdi-radiobox-marked",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "text-left text-uppercase w-100" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "text--secondary overline",
                              staticStyle: {
                                "font-size": "12px",
                                "line-height": "1rem",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    schedule.eta &&
                                      _vm.$filters.formatDatetime(schedule.eta)
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "text--primary my-1 text-uppercase text-truncate",
                              staticStyle: { "font-weight": "600" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    schedule.podCode + " - " + schedule.podName
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "text--secondary text-uppercase",
                              staticStyle: { "font-size": "12px" },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(schedule.podTerminalName) + " "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }