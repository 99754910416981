<template>
  <div class="">
    <p class="text-h7 mt-4 success--text font-weight-medium">
      ALWAYS IMPROVING
    </p>
    <p
      class="text-h3 mt-0"
      style="font-weight: 600"
    >
      Services
    </p>
    <div class="mt-16">
      <v-row
        no-gutters
        :class="{
          'flex-column': $vuetify.breakpoint.mobile,
        }"
      >
        <v-col
          :cols="$vuetify.breakpoint.mobile ? 12 : 4"
          class="py-3"
        >
          <v-card
            rounded="lg"
            href="https://vimc.co/van-tai-bien/"
            target="_blank"
          >
            <v-img
              src="/assets/img/shipping-vimc.png"
              class="white--text align-end"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.2)"
              height="235px"
            >
              <v-card-title>Shipping</v-card-title>
            </v-img>
          </v-card>
        </v-col>
        <v-col
          :cols="$vuetify.breakpoint.mobile ? 12 : 4"
          class="py-3"
          :class="{
            'px-3': !$vuetify.breakpoint.mobile,
          }"
        >
          <v-card
            rounded="lg"
            href="https://vimc.co/khai-thac-cang-bien/"
            target="_blank"
          >
            <v-img
              src="/assets/img/port-vimc.png"
              class="white--text align-end"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.2)"
              height="235px"
            >
              <v-card-title>Port</v-card-title>
            </v-img>
          </v-card>
        </v-col>

        <v-col
          :cols="$vuetify.breakpoint.mobile ? 12 : 4"
          class="py-3"
        >
          <v-card
            rounded="lg"
            href="https://vimc.co/dich-vu-hang-hai/"
            target="_blank"
          >
            <v-img
              src="/assets/img/logistics-vimc-1.png"
              class="white--text align-end"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.2)"
              height="235px"
            >
              <v-card-title>Logistics</v-card-title>
            </v-img>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
