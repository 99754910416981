<template>
  <v-autocomplete
    :value="value"
    :items="list"
    :loading="isLoading"
    :disabled="isLoading"
    type="text"
    prepend-inner-icon="mdi-ferry"
    item-text="name"
    item-value="code"
    return-object
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-slot:label>
      <span
        v-if="required"
        class="red--text"
      >
        <strong>* </strong>
      </span>
      {{ label }}
    </template>
  </v-autocomplete>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

export default {
  components: {},
  props: {
    value: {
      type: String,
    },
    autoSelectFirst: {
      type: Boolean,
      default: false,
      required: false,
    },
    noSelfLoadData: {
      type: Boolean,
      default: false,
      required: false,
    },
    label: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isLoading: false,
  }),
  computed: {
    ...mapState('vsl/vessel', { list: 'vesselList' }),
  },
  async created() {
    try {
      if (!this.noSelfLoadData) {
        this.isLoading = true;
        await this.fetchList();
      }
      if (!this.value && this.autoSelectFirst) {
        this.$emit('change', this.list[0]);
      }
    } finally {
      this.isLoading = false;
    }
  },
  watch: {},
  methods: {
    ...mapActions('vsl/vessel', { fetchList: 'fetchVesselList' }),
  },
};
</script>

<style></style>
