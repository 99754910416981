const routes = [
  {
    path: 'request-a-quote',
    name: 'request-a-quote',
    component: () => import('@/views/App/VSL/RequestAQuote'),
    meta: { title: 'Yêu cầu báo giá' },
    children: [
      {
        path: '',
        name: 'vessel schedules',
        meta: {
          title: 'Lịch tàu',
        },
        component: () => import('@/views/App/VSL/RequestAQuote/VesselSchedule'),
      },
      {
        path: 'submit',
        name: 'quotation-form',
        meta: {
          title: 'Gửi thông tin báo giá',
        },
        component: () => import('@/views/App/VSL/RequestAQuote/QuotationForm'),
      },
    ],
  },
  {
    path: 'my-quotation',
    name: 'My quotation',
    component: () => import('@/views/App/VSL/MyQuotation'),
    meta: { title: 'Danh sách báo giá' },
    children: [
      {
        path: '',
        name: 'my quotation list',
        meta: {
          title: 'Danh sách báo giá',
        },
        component: () => import('@/views/App/VSL/MyQuotation/MyQuotationList'),
      },
      {
        path: ':id',
        name: 'my quotation detail',
        meta: {
          title: 'Thông tin báo giá',
        },
        component: () =>
          import('@/views/App/VSL/MyQuotation/MyQuotationDetail'),
      },
    ],
  },
  {
    path: 'container-tracking',
    name: 'Container tracking',
    component: () => import('@/views/App/VSL/ContainerTracking'),
    meta: { title: 'Tracking' },
  },
  {
    path: 'debit-search',
    name: 'Debit search',
    component: () => import('@/views/App/VSL/DebitSearch'),
    meta: { title: 'Debit' },
  },
  {
    path: 'my-debit',
    name: 'My debit',
    component: () => import('@/views/App/VSL/MyDebit'),
    meta: { title: 'Debit' },
    children: [
      {
        path: '',
        name: 'my debit list',
        meta: {
          title: 'Danh sách debit',
        },
        component: () => import('@/views/App/VSL/MyDebit/MyDebitList'),
      },
    ],
  },
  {
    path: 'container-release-order-search',
    name: 'Container Release Order Search',
    component: () => import('@/views/App/VSL/ContainerReleaseOrderSearch'),
    meta: { title: 'Lệnh cấp container' },
  },
  {
    path: 'my-container-release-order',
    name: 'My container release order',
    component: () => import('@/views/App/VSL/MyContainerReleaseOrder'),
    meta: { title: 'Container Release Order' },
    children: [
      {
        path: '',
        name: 'my container release order list',
        meta: {
          title: 'Danh sách lệnh cấp container',
        },
        component: () =>
          import(
            '@/views/App/VSL/MyContainerReleaseOrder/MyContainerReleaseOrderList'
          ),
      },
    ],
  },
  {
    path: 'my-bill-lading',
    name: 'My bill lading',
    component: () => import('@/views/App/VSL/MyBillLading'),
    meta: { title: 'Bill of Ladings' },
    children: [
      {
        path: '',
        name: 'my bill lading list',
        meta: {
          title: 'Danh sách vận đơn',
        },
        component: () =>
          import('@/views/App/VSL/MyBillLading/MyBillLadingList'),
      },
    ],
  },
  {
    path: 'my-container-delivery-order',
    name: 'My delivery order',
    component: () => import('@/views/App/VSL/MyContainerDeliveryOrder'),
    meta: { title: 'Delivery Order' },
    children: [
      {
        path: '',
        name: 'my delivery order list',
        meta: {
          title: 'Danh sách lệnh giao hàng',
        },
        component: () =>
          import('@/views/App/VSL/MyContainerDeliveryOrder/MyContainerDeliveryOrderList'),
      },
    ],
  },
];

export default routes;
