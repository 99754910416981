var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    [
      _c("NavBarHome", { attrs: { active: "contact" } }),
      _c(
        "div",
        {
          staticClass: "carousel slide carousel-fade",
          attrs: {
            id: "carousel-2",
            "data-ride": "carousel",
            "data-interval": "6000",
          },
        },
        [
          _c(
            "div",
            { staticClass: "carousel-inner", attrs: { role: "listbox" } },
            [
              _c("div", { staticClass: "carousel-item active" }, [
                _c("img", {
                  staticClass: "d-block img-fluid",
                  attrs: {
                    src: require("../../../libhome/img/vimc diamond2.png"),
                    alt: "responsive image",
                  },
                }),
                _c("div", { staticClass: "carousel-caption" }, [
                  _c("div", [
                    _c("div", { staticClass: "transx_content_slide_wrapper" }, [
                      _c("div", { staticClass: "transx_content_container" }, [
                        _c("div", { staticClass: "transx_content_wrapper_1" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "transx_up_heading_overlay transx_overlay_align_center",
                            },
                            [_vm._v(" VIMC LINES ")]
                          ),
                          _c(
                            "h2",
                            { staticClass: "transx_content_slider_title" },
                            [_vm._v(" " + _vm._s(_vm.$t("contactUs")) + " ")]
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
        ]
      ),
      _c("div", { staticClass: "location location-contact" }, [
        _c("div", { staticClass: "container container-call" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-lg-7" }, [
              _c(
                "div",
                {
                  staticClass: "section-header section-header-call text-left",
                  attrs: { id: "contact-1" },
                },
                [
                  _c("p", [_vm._v(_vm._s(_vm.$t("callUsNow")))]),
                  _c("h2", [_vm._v("02435770894")]),
                ]
              ),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-6" }, [
                  _c("div", { staticClass: "location-item" }, [
                    _c("i", { staticClass: "fa fa-map-marker-alt" }),
                    _c("div", { staticClass: "location-text" }, [
                      _c("h3", [_vm._v(_vm._s(_vm.$t("haiphong")))]),
                      _c("p", [_vm._v(_vm._s(_vm.$t("locaHP")))]),
                      _c("p", [
                        _c("strong", [_vm._v(_vm._s(_vm.$t("call")) + ":")]),
                        _vm._v("090 4116369 "),
                      ]),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "col-md-6" }, [
                  _c("div", { staticClass: "location-item" }, [
                    _c("i", { staticClass: "fa fa-map-marker-alt" }),
                    _c("div", { staticClass: "location-text" }, [
                      _c("h3", [_vm._v(_vm._s(_vm.$t("hochiminh")))]),
                      _c("p", [_vm._v(_vm._s(_vm.$t("locaHCM")))]),
                      _c("p", [
                        _c("strong", [_vm._v(_vm._s(_vm.$t("call")) + ":")]),
                        _vm._v("+84.974.040.006 "),
                      ]),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "col-md-6" }, [
                  _c("div", { staticClass: "location-item" }, [
                    _c("i", { staticClass: "fa fa-map-marker-alt" }),
                    _c("div", { staticClass: "location-text" }, [
                      _c("h3", [_vm._v(_vm._s(_vm.$t("danang")))]),
                      _c("p", [_vm._v(_vm._s(_vm.$t("locaDN")))]),
                      _c("p", [
                        _c("strong", [_vm._v(_vm._s(_vm.$t("call")) + ":")]),
                        _vm._v("+84-236-3823538 "),
                      ]),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "col-md-6" }, [
                  _c("div", { staticClass: "location-item" }, [
                    _c("i", { staticClass: "fa fa-map-marker-alt" }),
                    _c("div", { staticClass: "location-text" }, [
                      _c("h3", [_vm._v(_vm._s(_vm.$t("quynhon")))]),
                      _c("p", [_vm._v(" " + _vm._s(_vm.$t("locaQN")) + " ")]),
                      _c("p", [
                        _c("strong", [_vm._v(_vm._s(_vm.$t("call")) + ":")]),
                        _vm._v("+84.256.382.2687 "),
                      ]),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "col-md-6" }, [
                  _c("div", { staticClass: "location-item" }, [
                    _c("i", { staticClass: "fa fa-map-marker-alt" }),
                    _c("div", { staticClass: "location-text" }, [
                      _c("h3", [_vm._v("CHENNAI - AGENT")]),
                      _c("p", [_vm._v(" " + _vm._s(_vm.$t("chennai")) + " ")]),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "col-md-6" }, [
                  _c("div", { staticClass: "location-item" }, [
                    _c("i", { staticClass: "fa fa-map-marker-alt" }),
                    _c("div", { staticClass: "location-text" }, [
                      _c("h3", [_vm._v("PORT KLANG - AGENT")]),
                      _c("p", [_vm._v(" " + _vm._s(_vm.$t("klang")) + " ")]),
                    ]),
                  ]),
                ]),
                _vm._m(0),
              ]),
            ]),
            _c("div", { staticClass: "col-lg-5" }, [
              _c("div", { staticClass: "location-form" }, [
                _c("h3", [_vm._v(_vm._s(_vm.$t("getInTouch")))]),
                _c(
                  "form",
                  {
                    ref: "form",
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.sendEmail.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "control-group" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.name,
                            expression: "name",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          placeholder:
                            _vm.$i18n.locale === "en" ? "Name" : "Tên",
                          required: "",
                        },
                        domProps: { value: _vm.name },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.name = $event.target.value
                          },
                        },
                      }),
                    ]),
                    _c("div", { staticClass: "control-group" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.email,
                            expression: "email",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "email",
                          placeholder: "Email",
                          required: "",
                        },
                        domProps: { value: _vm.email },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.email = $event.target.value
                          },
                        },
                      }),
                    ]),
                    _c("div", { staticClass: "control-group" }, [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.message,
                            expression: "message",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          placeholder:
                            _vm.$i18n.locale === "en" ? "Description" : "Mô tả",
                          required: "",
                        },
                        domProps: { value: _vm.message },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.message = $event.target.value
                          },
                        },
                      }),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "call-footer",
                        staticStyle: {
                          display: "flex",
                          "justify-content": "center",
                        },
                      },
                      [
                        _c("div", { staticClass: "btn-basic" }, [
                          _c("input", {
                            attrs: {
                              type: "submit",
                              disabled: _vm.isLoading || _vm.isButtonDisabled,
                            },
                            domProps: {
                              value:
                                _vm.$i18n.locale === "en"
                                  ? "Send Request"
                                  : "Gửi yêu cầu",
                            },
                          }),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "container container-contact" }, [
        _c(
          "div",
          { staticClass: "row transx_sidebar_none transx_bg_color_default" },
          [
            _c("div", { staticClass: "col-lg-12 col-xl-12 col-p" }, [
              _c("div", { staticClass: "transx_content_wrapper" }, [
                _c(
                  "div",
                  {
                    staticClass: "elementor elementor-42",
                    attrs: {
                      "data-elementor-type": "wp-page",
                      "data-elementor-id": "42",
                      "data-elementor-settings": "[]",
                    },
                  },
                  [
                    _c("div", { staticClass: "elementor-section-wrap" }, [
                      _c(
                        "section",
                        {
                          staticClass:
                            "elementor-section elementor-top-section elementor-element elementor-element-223bd736 elementor-hidden-tablet elementor-hidden-phone elementor-section-boxed elementor-section-height-default elementor-section-height-default",
                          staticStyle: { padding: "137px 0px 140px 0px" },
                          attrs: {
                            "data-id": "223bd736",
                            "data-element_type": "section",
                          },
                        },
                        [
                          _c("div", {
                            staticClass: "elementor-background-overlay",
                          }),
                          _c(
                            "v-row",
                            {},
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "col-5 elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-7b9387f9 elementor-left",
                                  attrs: {
                                    "data-id": "7b9387f9",
                                    "data-element_type": "column",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "elementor-widget-wrap elementor-element-populated",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "elementor-element elementor-element-346ab512 elementor-widget elementor-widget-transx_heading",
                                          attrs: {
                                            "data-id": "346ab512",
                                            "data-element_type": "widget",
                                            "data-widget_type":
                                              "transx_heading.default",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "elementor-widget-container",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "transx_heading_widget",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "transx_up_heading",
                                                      staticStyle: {
                                                        fontsize: "16px",
                                                      },
                                                      attrs: {
                                                        id: "contact-2",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t("contactUs")
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "h2",
                                                    {
                                                      staticClass:
                                                        "transx_heading",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "contactInfo"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "elementor-element elementor-element-ce65c13 elementor-widget elementor-widget-transx_icon_box",
                                          attrs: {
                                            "data-id": "ce65c13",
                                            "data-element_type": "widget",
                                            "data-widget_type":
                                              "transx_icon_box.default",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "elementor-widget-container",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "transx_icon_box_widget",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "transx_icon_box_item transx_view_type_3",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "transx_icon_container",
                                                          staticStyle: {
                                                            border:
                                                              "1px solid #e8734a",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "svg",
                                                            {
                                                              staticClass:
                                                                "icon",
                                                            },
                                                            [
                                                              _c(
                                                                "svg",
                                                                {
                                                                  attrs: {
                                                                    viewBox:
                                                                      "0 0 384 384",
                                                                    xmlns:
                                                                      "http://www.w3.org/2000/svg",
                                                                  },
                                                                },
                                                                [
                                                                  _c("path", {
                                                                    attrs: {
                                                                      d: "M353.188 252.052c-23.51 0-46.594-3.677-68.469-10.906-10.906-3.719-23.323-.833-30.438 6.417l-43.177 32.594c-50.073-26.729-80.917-57.563-107.281-107.26l31.635-42.052c8.219-8.208 11.167-20.198 7.635-31.448-7.26-21.99-10.948-45.063-10.948-68.583C132.146 13.823 118.323 0 101.333 0H30.812C13.823 0 0 13.823 0 30.812 0 225.563 158.438 384 353.188 384c16.99 0 30.813-13.823 30.813-30.813v-70.323c-.001-16.989-13.824-30.812-30.813-30.812zm9.479 101.136c0 5.229-4.25 9.479-9.479 9.479-182.99 0-331.854-148.865-331.854-331.854 0-5.229 4.25-9.479 9.479-9.479h70.521c5.229 0 9.479 4.25 9.479 9.479 0 25.802 4.052 51.125 11.979 75.115 1.104 3.542.208 7.208-3.375 10.938L82.75 165.427a10.674 10.674 0 00-1 11.26c29.927 58.823 66.292 95.188 125.531 125.542 3.604 1.885 8.021 1.49 11.292-.979l49.677-37.635a9.414 9.414 0 019.667-2.25c24.156 7.979 49.479 12.021 75.271 12.021 5.229 0 9.479 4.25 9.479 9.479v70.323z",
                                                                    },
                                                                  }),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "transx_icon_box_content_cont",
                                                        },
                                                        [
                                                          _c(
                                                            "h5",
                                                            {
                                                              staticClass:
                                                                "transx_icon_box_title",
                                                              staticStyle: {
                                                                color: "#000",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "phone"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "transx_info_container",
                                                            },
                                                            [
                                                              _c("p", [
                                                                _c(
                                                                  "a",
                                                                  {
                                                                    staticClass:
                                                                      "orange-color",
                                                                    staticStyle:
                                                                      {
                                                                        color:
                                                                          "#e8734a",
                                                                      },
                                                                    attrs: {
                                                                      href: "tel:+842435770894",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "+842435770894"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "elementor-element elementor-element-3a7cafcb elementor-widget elementor-widget-transx_icon_box",
                                          attrs: {
                                            "data-id": "3a7cafcb",
                                            "data-element_type": "widget",
                                            "data-widget_type":
                                              "transx_icon_box.default",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "elementor-widget-container",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "transx_icon_box_widget",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "transx_icon_box_item transx_view_type_3",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "transx_icon_container",
                                                          staticStyle: {
                                                            border:
                                                              "1px solid #e8734a",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "svg",
                                                            {
                                                              staticClass:
                                                                "icon",
                                                            },
                                                            [
                                                              _c(
                                                                "svg",
                                                                {
                                                                  attrs: {
                                                                    viewBox:
                                                                      "0 0 479.058 479.058",
                                                                    xmlns:
                                                                      "http://www.w3.org/2000/svg",
                                                                  },
                                                                },
                                                                [
                                                                  _c("path", {
                                                                    attrs: {
                                                                      d: "M434.146 59.882H44.912C20.146 59.882 0 80.028 0 104.794v269.47c0 24.766 20.146 44.912 44.912 44.912h389.234c24.766 0 44.912-20.146 44.912-44.912v-269.47c0-24.766-20.146-44.912-44.912-44.912zm0 29.941c2.034 0 3.969.422 5.738 1.159L239.529 264.631 39.173 90.982a14.902 14.902 0 015.738-1.159zm0 299.411H44.912c-8.26 0-14.971-6.71-14.971-14.971V122.615l199.778 173.141c2.822 2.441 6.316 3.655 9.81 3.655s6.988-1.213 9.81-3.655l199.778-173.141v251.649c-.001 8.26-6.711 14.97-14.971 14.97z",
                                                                    },
                                                                  }),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "transx_icon_box_content_cont",
                                                        },
                                                        [
                                                          _c(
                                                            "h5",
                                                            {
                                                              staticClass:
                                                                "transx_icon_box_title",
                                                              staticStyle: {
                                                                color: "#000",
                                                              },
                                                            },
                                                            [_vm._v(" Email ")]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "transx_info_container",
                                                            },
                                                            [
                                                              _c("p", [
                                                                _c(
                                                                  "a",
                                                                  {
                                                                    staticClass:
                                                                      "orange-color",
                                                                    staticStyle:
                                                                      {
                                                                        color:
                                                                          "#e8734a",
                                                                      },
                                                                    attrs: {
                                                                      href: "mailto:transx_info@gmail.com",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "vimclines@vimc-lines.com"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "elementor-element elementor-element-6e96e15b elementor-widget elementor-widget-transx_icon_box",
                                          attrs: {
                                            "data-id": "6e96e15b",
                                            "data-element_type": "widget",
                                            "data-widget_type":
                                              "transx_icon_box.default",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "elementor-widget-container",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "transx_icon_box_widget",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "transx_icon_box_item transx_view_type_3",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "transx_icon_container",
                                                          staticStyle: {
                                                            border:
                                                              "1px solid #e8734a",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "svg",
                                                            {
                                                              staticClass:
                                                                "icon",
                                                            },
                                                            [
                                                              _c(
                                                                "svg",
                                                                {
                                                                  attrs: {
                                                                    viewBox:
                                                                      "0 0 512 512",
                                                                    xmlns:
                                                                      "http://www.w3.org/2000/svg",
                                                                  },
                                                                },
                                                                [
                                                                  _c("path", {
                                                                    attrs: {
                                                                      d: "M256 0C153.755 0 70.573 83.182 70.573 185.426c0 126.888 165.939 313.167 173.004 321.035 6.636 7.391 18.222 7.378 24.846 0 7.065-7.868 173.004-194.147 173.004-321.035C441.425 83.182 358.244 0 256 0zm0 469.729c-55.847-66.338-152.035-197.217-152.035-284.301 0-83.834 68.202-152.036 152.035-152.036s152.035 68.202 152.035 152.035C408.034 272.515 311.861 403.37 256 469.729z",
                                                                    },
                                                                  }),
                                                                  _c("path", {
                                                                    attrs: {
                                                                      d: "M256 92.134c-51.442 0-93.292 41.851-93.292 93.293S204.559 278.72 256 278.72s93.291-41.851 93.291-93.293S307.441 92.134 256 92.134zm0 153.194c-33.03 0-59.9-26.871-59.9-59.901s26.871-59.901 59.9-59.901 59.9 26.871 59.9 59.901-26.871 59.901-59.9 59.901z",
                                                                    },
                                                                  }),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "transx_icon_box_content_cont",
                                                        },
                                                        [
                                                          _c(
                                                            "h5",
                                                            {
                                                              staticClass:
                                                                "transx_icon_box_title",
                                                              staticStyle: {
                                                                color: "#000",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "address"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "transx_info_container",
                                                            },
                                                            [
                                                              _c(
                                                                "p",
                                                                {
                                                                  staticStyle: {
                                                                    color:
                                                                      "#e8734a",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "location"
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "elementor-element elementor-element-370835ee elementor-widget elementor-widget-transx_icon_box",
                                          attrs: {
                                            "data-id": "370835ee",
                                            "data-element_type": "widget",
                                            "data-widget_type":
                                              "transx_icon_box.default",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "elementor-widget-container",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "transx_icon_box_widget",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "transx_icon_box_item transx_view_type_3",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "transx_icon_container",
                                                          staticStyle: {
                                                            border:
                                                              "1px solid #e8734a",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "svg",
                                                            {
                                                              staticClass:
                                                                "icon",
                                                            },
                                                            [
                                                              _c(
                                                                "svg",
                                                                {
                                                                  attrs: {
                                                                    viewBox:
                                                                      "-21 0 512 512",
                                                                    xmlns:
                                                                      "http://www.w3.org/2000/svg",
                                                                  },
                                                                },
                                                                [
                                                                  _c("path", {
                                                                    attrs: {
                                                                      d: "M389.332 160c-44.094 0-80-35.883-80-80s35.906-80 80-80c44.098 0 80 35.883 80 80s-35.902 80-80 80zm0-128c-26.453 0-48 21.523-48 48s21.547 48 48 48 48-21.523 48-48-21.547-48-48-48zm0 480c-44.094 0-80-35.883-80-80s35.906-80 80-80c44.098 0 80 35.883 80 80s-35.902 80-80 80zm0-128c-26.453 0-48 21.523-48 48s21.547 48 48 48 48-21.523 48-48-21.547-48-48-48zM80 336c-44.098 0-80-35.883-80-80s35.902-80 80-80 80 35.883 80 80-35.902 80-80 80zm0-128c-26.453 0-48 21.523-48 48s21.547 48 48 48 48-21.523 48-48-21.547-48-48-48zm0 0",
                                                                    },
                                                                  }),
                                                                  _c("path", {
                                                                    attrs: {
                                                                      d: "M135.703 240.426c-5.57 0-10.988-2.903-13.91-8.063-4.375-7.68-1.707-17.453 5.973-21.824L325.719 97.684c7.656-4.414 17.449-1.727 21.8 5.976 4.376 7.68 1.708 17.45-5.972 21.824L143.594 238.336a16.03 16.03 0 01-7.89 2.09zm197.93 176a15.86 15.86 0 01-7.895-2.11L127.785 301.461c-7.68-4.371-10.344-14.145-5.972-21.824 4.351-7.7 14.125-10.367 21.804-5.973l197.95 112.852c7.68 4.375 10.347 14.144 5.976 21.824-2.945 5.183-8.363 8.086-13.91 8.086zm0 0",
                                                                    },
                                                                  }),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "transx_icon_box_content_cont",
                                                        },
                                                        [
                                                          _c(
                                                            "h5",
                                                            {
                                                              staticClass:
                                                                "transx_icon_box_title",
                                                              staticStyle: {
                                                                color: "#000",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "followUs"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "transx_info_container",
                                                            },
                                                            [
                                                              _c(
                                                                "ul",
                                                                {
                                                                  staticClass:
                                                                    "transx_icon_box_socials",
                                                                },
                                                                [
                                                                  _c("li", [
                                                                    _c(
                                                                      "a",
                                                                      {
                                                                        staticClass:
                                                                          "btn",
                                                                        attrs: {
                                                                          href: "mailto:vimclines@vimc-lines.com",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "img",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                src: require("../../../libhome/img/gmail1.svg"),
                                                                                alt: "",
                                                                                width:
                                                                                  "20px",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "a",
                                                                      {
                                                                        staticClass:
                                                                          "btn",
                                                                        attrs: {
                                                                          href: "https://wa.me/84335299516",
                                                                          target:
                                                                            "_blank",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "img",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                src: require("../../../libhome/img/whatsapp1.svg"),
                                                                                alt: "",
                                                                                width:
                                                                                  "20px",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "a",
                                                                      {
                                                                        staticClass:
                                                                          "btn",
                                                                        attrs: {
                                                                          href: "skype:suoinho_mk?chat",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "img",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                src: require("../../../libhome/img/skype1.svg"),
                                                                                alt: "",
                                                                                width:
                                                                                  "20px",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "a",
                                                                      {
                                                                        staticClass:
                                                                          "btn",
                                                                        attrs: {
                                                                          href: "https://zalo.me/0974040006",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "img",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                src: require("../../../libhome/img/zalo1.svg"),
                                                                                alt: "",
                                                                                width:
                                                                                  "20px",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "col-7 elementor-column elementor-col-66 elementor-top-column elementor-element elementor-element-5075a951 elementor-right",
                                  attrs: {
                                    "data-id": "5075a951",
                                    "data-element_type": "column",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "elementor-widget-wrap elementor-element-populated",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "elementor-element elementor-element-72dc12f8 elementor-widget elementor-widget-transx_heading",
                                          attrs: {
                                            "data-id": "72dc12f8",
                                            "data-element_type": "widget",
                                            "data-widget_type":
                                              "transx_heading.default",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "elementor-widget-container",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "transx_heading_widget",
                                                },
                                                [
                                                  _c(
                                                    "h4",
                                                    {
                                                      staticClass:
                                                        "transx_heading",
                                                      attrs: {
                                                        id: "message-form",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t("sendMess")
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "elementor-element elementor-element-7d88533b elementor-widget elementor-widget-shortcode",
                                          attrs: {
                                            "data-id": "7d88533b",
                                            "data-element_type": "widget",
                                            "data-widget_type":
                                              "shortcode.default",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "elementor-widget-container",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "elementor-shortcode",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "wpcf7",
                                                      attrs: {
                                                        role: "form",
                                                        id: "wpcf7-f4-p42-o1",
                                                        lang: "en-US",
                                                        dir: "ltr",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "screen-reader-response",
                                                        },
                                                        [
                                                          _c("p", {
                                                            attrs: {
                                                              role: "status",
                                                              "aria-live":
                                                                "polite",
                                                              "aria-atomic":
                                                                "true",
                                                            },
                                                          }),
                                                          _c("ul"),
                                                        ]
                                                      ),
                                                      _c(
                                                        "form",
                                                        {
                                                          ref: "form",
                                                          staticClass:
                                                            "wpcf7-form init",
                                                          attrs: {
                                                            action:
                                                              "/themes/transx-new/contact-us/#wpcf7-f4-p42-o1",
                                                            method: "post",
                                                            novalidate:
                                                              "novalidate",
                                                            "data-status":
                                                              "init",
                                                          },
                                                          on: {
                                                            submit: function (
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                              return _vm.sendEmail.apply(
                                                                null,
                                                                arguments
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "transx_contact_form",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "transx_contact_form_field",
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "wpcf7-form-control-wrap your-name",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "input",
                                                                        {
                                                                          directives:
                                                                            [
                                                                              {
                                                                                name: "model",
                                                                                rawName:
                                                                                  "v-model",
                                                                                value:
                                                                                  _vm.name,
                                                                                expression:
                                                                                  "name",
                                                                              },
                                                                            ],
                                                                          staticClass:
                                                                            "wpcf7-form-control wpcf7-text wpcf7-validates-as-required",
                                                                          attrs:
                                                                            {
                                                                              type: "text",
                                                                              name: "your-name",
                                                                              size: "40",
                                                                              "aria-required":
                                                                                "true",
                                                                              "aria-invalid":
                                                                                "false",
                                                                              placeholder:
                                                                                _vm
                                                                                  .$i18n
                                                                                  .locale ===
                                                                                "en"
                                                                                  ? "Full Name"
                                                                                  : "Họ và tên",
                                                                            },
                                                                          domProps:
                                                                            {
                                                                              value:
                                                                                _vm.name,
                                                                            },
                                                                          on: {
                                                                            input:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                if (
                                                                                  $event
                                                                                    .target
                                                                                    .composing
                                                                                )
                                                                                  return
                                                                                _vm.name =
                                                                                  $event.target.value
                                                                              },
                                                                          },
                                                                        }
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "transx_contact_form_field",
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "wpcf7-form-control-wrap your-email",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "input",
                                                                        {
                                                                          directives:
                                                                            [
                                                                              {
                                                                                name: "model",
                                                                                rawName:
                                                                                  "v-model",
                                                                                value:
                                                                                  _vm.email,
                                                                                expression:
                                                                                  "email",
                                                                              },
                                                                            ],
                                                                          staticClass:
                                                                            "wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email",
                                                                          attrs:
                                                                            {
                                                                              type: "email",
                                                                              name: "your-email",
                                                                              size: "40",
                                                                              "aria-required":
                                                                                "true",
                                                                              "aria-invalid":
                                                                                "false",
                                                                              placeholder:
                                                                                "Email",
                                                                            },
                                                                          domProps:
                                                                            {
                                                                              value:
                                                                                _vm.email,
                                                                            },
                                                                          on: {
                                                                            input:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                if (
                                                                                  $event
                                                                                    .target
                                                                                    .composing
                                                                                )
                                                                                  return
                                                                                _vm.email =
                                                                                  $event.target.value
                                                                              },
                                                                          },
                                                                        }
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "transx_contact_form_aria",
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "wpcf7-form-control-wrap your-message",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "textarea",
                                                                        {
                                                                          directives:
                                                                            [
                                                                              {
                                                                                name: "model",
                                                                                rawName:
                                                                                  "v-model",
                                                                                value:
                                                                                  _vm.message,
                                                                                expression:
                                                                                  "message",
                                                                              },
                                                                            ],
                                                                          staticClass:
                                                                            "wpcf7-form-control-aria wpcf7-textarea",
                                                                          attrs:
                                                                            {
                                                                              name: "your-message",
                                                                              cols: "40",
                                                                              rows: "10",
                                                                              "aria-invalid":
                                                                                "false",
                                                                              placeholder:
                                                                                _vm
                                                                                  .$i18n
                                                                                  .locale ===
                                                                                "en"
                                                                                  ? "Message"
                                                                                  : "Tin nhắn",
                                                                            },
                                                                          domProps:
                                                                            {
                                                                              value:
                                                                                _vm.message,
                                                                            },
                                                                          on: {
                                                                            input:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                if (
                                                                                  $event
                                                                                    .target
                                                                                    .composing
                                                                                )
                                                                                  return
                                                                                _vm.message =
                                                                                  $event.target.value
                                                                              },
                                                                          },
                                                                        }
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "transx_contact_form_button",
                                                                },
                                                                [
                                                                  _c("input", {
                                                                    staticClass:
                                                                      "has-spinner wpcf7-submit",
                                                                    staticStyle:
                                                                      {
                                                                        border:
                                                                          "2px solid #e8734a",
                                                                      },
                                                                    attrs: {
                                                                      type: "submit",
                                                                      disabled:
                                                                        _vm.isLoading ||
                                                                        _vm.isButtonDisabled,
                                                                    },
                                                                    domProps: {
                                                                      value:
                                                                        _vm
                                                                          .$i18n
                                                                          .locale ===
                                                                        "en"
                                                                          ? "Send Message"
                                                                          : "Gửi tin nhắn",
                                                                    },
                                                                  }),
                                                                  _c("span", {
                                                                    staticClass:
                                                                      "wpcf7-spinner",
                                                                  }),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c("div", {
                                                            staticClass:
                                                              "wpcf7-response-output",
                                                            attrs: {
                                                              "aria-hidden":
                                                                "true",
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("section", {
                        staticClass:
                          "elementor-top-section elementor-element elementor-element-3903e669 elementor-hidden-desktop elementor-section-boxed elementor-section-height-default elementor-section-height-default",
                        attrs: {
                          "data-id": "3903e669",
                          "data-element_type": "section",
                        },
                      }),
                    ]),
                  ]
                ),
              ]),
              _c("div", { staticClass: "transx_content_paging_wrapper" }),
            ]),
          ]
        ),
      ]),
      _vm._m(1),
      _c("FooterHome"),
      _vm._m(2),
      _c("NavBarLeftHome"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-6" }, [
      _c("div", { staticClass: "location-item" }, [
        _c("i", { staticClass: "fa fa-map-marker-alt" }),
        _c("div", { staticClass: "location-text" }, [
          _c("h3", [_vm._v("Kolkata, India - AGENT")]),
          _c("p", { staticStyle: { "text-align": "justify" } }, [
            _vm._v(
              " Seahorse Ship Agencies Pvt. Ltd. Hastings Chambers, 4th Floor, 7C, Kiron Shankar Roy Road, Kolkata-700001.India. "
            ),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "section",
      {
        staticClass:
          "elementor-section elementor-top-section elementor-element elementor-element-18ef6fc elementor-section-full_width elementor-section-stretched elementor-section-height-default elementor-section-height-default",
        staticStyle: { height: "400px" },
        attrs: {
          "data-id": "18ef6fc",
          "data-element_type": "section",
          "data-settings": '{"stretch_section":"section-stretched"}',
        },
      },
      [
        _c(
          "div",
          {
            staticClass: "elementor-container elementor-column-gap-no",
            staticStyle: {
              "z-index": "3",
              position: "absolute",
              height: "100%",
              width: "100%",
              padding: "0px",
              "border-width": "0px",
              margin: "0px",
              left: "0px",
              top: "0px",
              "touch-action": "pan-x pan-y",
            },
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-213b7a92",
                attrs: { "data-id": "213b7a92", "data-element_type": "column" },
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "elementor-widget-wrap elementor-element-populated",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "elementor-element elementor-element-02a5f75 elementor-widget elementor-widget-google_maps",
                        attrs: {
                          "data-id": "02a5f75",
                          "data-element_type": "widget",
                          "data-widget_type": "google_maps.default",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "elementor-widget-container" },
                          [
                            _c(
                              "div",
                              { staticClass: "elementor-custom-embed" },
                              [
                                _c("iframe", {
                                  staticStyle: { border: "0" },
                                  attrs: {
                                    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3724.6347957964826!2d105.8378493751975!3d21.00727158851638!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3135ac7802b8752b%3A0xca91cd914f429947!2zVOG7lW5nIGPDtG5nIHR5IEjDoG5nIEjhuqNpIFZp4buHdCBOYW0gLSBWSU1D!5e0!3m2!1svi!2s!4v1711503233131!5m2!1svi!2s",
                                    width: "100%",
                                    height: "300px",
                                    allowfullscreen: "",
                                    loading: "lazy",
                                    referrerpolicy:
                                      "no-referrer-when-downgrade",
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          ]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { staticClass: "back-to-top", attrs: { href: "/home" } }, [
      _c("i", { staticClass: "fa fa-chevron-up" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }