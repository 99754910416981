<template>
  <v-card>
    <v-toolbar
      flat
      color="primary"
      dark
    >
      <v-toolbar-title>{{ $t('title-account-settings') }}</v-toolbar-title>
      <v-spacer />
      <v-btn
        icon
        @click="$emit('close-dialog')"
      >
        <v-icon dark>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-tabs
      v-model="activeTab"
      show-arrows
    >
      <v-tabs-slider color="primary"></v-tabs-slider>
      <v-tab>
        <v-icon left> mdi-account </v-icon>
        {{ $t('account') }}
      </v-tab>
      <v-tab>
        <v-icon left> mdi-lock </v-icon>
        {{ $t('password') }}
      </v-tab>
      <v-tab>
        <v-icon left> mdi-contacts </v-icon>
        {{ $t('title-contact-infor') }}
      </v-tab>

      <v-tab-item>
        <UserSettingsAccount
          :class="{
            'px-8 py-4': !$vuetify.breakpoint.mobile,
          }"
          style="min-height: 80vh"
        />
      </v-tab-item>
      <v-tab-item>
        <UserSettingsChangePassword
          :class="{
            'px-8 py-4': !$vuetify.breakpoint.mobile,
          }"
          style="min-height: 80vh"
        />
      </v-tab-item>

      <v-tab-item>
        <UserSettingsContact style="min-height: 80vh" />
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import UserSettingsAccount from './UserSettingsAccount.vue';
import UserSettingsChangePassword from './UserSettingsChangePassword.vue';
import UserSettingsContact from './UserSettingsContact/index.vue';
export default {
  components: {
    UserSettingsAccount,
    UserSettingsChangePassword,
    UserSettingsContact,
  },
  data: () => ({
    tab: null,
    tabs: [
      {
        index: 0,
        key: 'account',
      },
      {
        index: 1,
        key: 'password',
      },
      {
        index: 2,
        key: 'contacts',
      },
    ],
  }),
  computed: {
    ...mapState('ui', ['dialogUserSettings']),
    activeTab: {
      get() {
        const { index } = this.tabs.find(
          ({ key }) => key === this.dialogUserSettings.tabKey
        );
        return index;
      },
      set(value) {
        const { key } = this.tabs.find(({ index }) => index === value);
        this.TOGGLE_DIALOG_USER_SETTINGS({
          status: this.dialogUserSettings.status,
          tabKey: key,
        });
      },
    },
  },

  created() {},
  methods: {
    ...mapMutations('ui', ['TOGGLE_DIALOG_USER_SETTINGS']),
  },
};
</script>

<style></style>
