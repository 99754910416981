var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "headerN",
      class: { "sidebar-open": _vm.sidebarVisible },
      attrs: { "data-header": "" },
    },
    [
      _c("div", { staticClass: "container" }, [
        _c(
          "nav",
          { staticClass: "navbar-column", attrs: { "data-navbar-column": "" } },
          [
            _c("div", { staticClass: "navbar-column-top" }, [
              _vm._m(0),
              _c(
                "button",
                {
                  staticClass: "nav-column-close-btn",
                  staticStyle: { "text-align": "right", "margin-left": "95px" },
                  attrs: {
                    "aria-label": "Close menu",
                    "data-nav-column-toggler": "",
                  },
                  on: { click: _vm.toggleSidebar },
                },
                [_c("v-icon", { attrs: { right: "" } }, [_vm._v("mdi-close")])],
                1
              ),
            ]),
            _c(
              "ul",
              {
                staticClass: "navbar-column-list",
                staticStyle: { "list-style": "none", "margin-top": "10px" },
              },
              [
                _c(
                  "li",
                  {
                    staticClass: "navbar-column-item nav-item",
                    on: {
                      mouseenter: function ($event) {
                        return _vm.showSubmenu("home")
                      },
                      mouseleave: function ($event) {
                        return _vm.hideSubmenu("home")
                      },
                    },
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "navbar-column-link nav-link",
                        attrs: { href: "/", "data-nav-column-link": "" },
                      },
                      [_c("span", [_vm._v(_vm._s(_vm.$t("home")))])]
                    ),
                    _vm.submenuVisible.home
                      ? _c("ul", { staticClass: "submenu" }, [
                          _c(
                            "li",
                            {
                              staticClass: "submenu-item",
                              on: {
                                click: function ($event) {
                                  return _vm.handleSubmenuItemClick("services")
                                },
                              },
                            },
                            [
                              _c(
                                "a",
                                {
                                  attrs: { href: "#services" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(_vm.$t("services")) + " ")]
                              ),
                            ]
                          ),
                          _c(
                            "li",
                            {
                              staticClass: "submenu-item",
                              on: {
                                click: function ($event) {
                                  return _vm.handleSubmenuItemClick("about-us")
                                },
                              },
                            },
                            [
                              _c(
                                "a",
                                {
                                  attrs: { href: "#about-us" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("aboutUsPage")) + " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "li",
                            {
                              staticClass: "submenu-item",
                              on: {
                                click: function ($event) {
                                  return _vm.handleSubmenuItemClick("quote")
                                },
                              },
                            },
                            [
                              _c(
                                "a",
                                {
                                  attrs: { href: "#quote" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(_vm.$t("quote")) + " ")]
                              ),
                            ]
                          ),
                          _c(
                            "li",
                            {
                              staticClass: "submenu-item",
                              on: {
                                click: function ($event) {
                                  return _vm.handleSubmenuItemClick(
                                    "all-services"
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "a",
                                {
                                  attrs: { href: "#all-services" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("allServices")) + " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "li",
                            {
                              staticClass: "submenu-item",
                              on: {
                                click: function ($event) {
                                  return _vm.handleSubmenuItemClick(
                                    "achivement"
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "a",
                                {
                                  attrs: { href: "#achivement" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("achivement")) + " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "li",
                            {
                              staticClass: "submenu-item",
                              on: {
                                click: function ($event) {
                                  return _vm.handleSubmenuItemClick("history")
                                },
                              },
                            },
                            [
                              _c(
                                "a",
                                {
                                  attrs: { href: "#history" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(_vm.$t("history")) + " ")]
                              ),
                            ]
                          ),
                          _c(
                            "li",
                            {
                              staticClass: "submenu-item",
                              on: {
                                click: function ($event) {
                                  return _vm.handleSubmenuItemClick("our-team")
                                },
                              },
                            },
                            [
                              _c(
                                "a",
                                {
                                  attrs: { href: "#our-team" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("meetOurTeamLow")) + " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ]
                ),
                _c(
                  "li",
                  {
                    staticClass: "navbar-column-item nav-item",
                    on: {
                      mouseenter: function ($event) {
                        return _vm.showSubmenu("blogs")
                      },
                      mouseleave: function ($event) {
                        return _vm.hideSubmenu("blogs")
                      },
                    },
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "navbar-column-link nav-link",
                        attrs: { href: "/blogs", "data-nav-column-link": "" },
                      },
                      [
                        _c("span", [_vm._v(_vm._s(_vm.$t("blogs")))]),
                        _vm.submenuVisible.blogs
                          ? _c("ul", { staticClass: "submenu" }, [
                              _c(
                                "li",
                                {
                                  staticClass: "submenu-item",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleSubmenuItemClick(
                                        "blogs-1"
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "#blogs-1" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("establishment")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "li",
                                {
                                  staticClass: "submenu-item",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleSubmenuItemClick(
                                        "blogs-4"
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "#blogs-4" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("annoucement")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "li",
                                {
                                  staticClass: "submenu-item",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleSubmenuItemClick(
                                        "blogs-2"
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "#blogs-2" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("seafarer")) + " "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "li",
                                {
                                  staticClass: "submenu-item",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleSubmenuItemClick(
                                        "blogs-3"
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "#blogs-3" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("containerTerminal")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ]
                ),
                _c(
                  "li",
                  {
                    staticClass: "navbar-column-item nav-item",
                    on: {
                      mouseenter: function ($event) {
                        return _vm.showSubmenu("services")
                      },
                      mouseleave: function ($event) {
                        return _vm.hideSubmenu("services")
                      },
                    },
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "navbar-column-link nav-link",
                        attrs: { "data-nav-column-link": "" },
                      },
                      [_c("span", [_vm._v(_vm._s(_vm.$t("services")))])]
                    ),
                    _vm.submenuVisible.services
                      ? _c("ul", { staticClass: "submenu" }, [
                          _c(
                            "li",
                            {
                              staticClass: "submenu-item",
                              on: {
                                click: function ($event) {
                                  return _vm.handleSubmenuItemClick(
                                    "vesselSchedule"
                                  )
                                },
                              },
                            },
                            [
                              _c("a", { attrs: { href: "/vesselSchedule" } }, [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("vesselSchedule")) + " "
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "li",
                            {
                              staticClass: "submenu-item",
                              on: {
                                click: function ($event) {
                                  return _vm.handleSubmenuItemClick("Quote")
                                },
                              },
                            },
                            [
                              _c("a", { attrs: { href: "/Quote" } }, [
                                _vm._v(" " + _vm._s(_vm.$t("Quote")) + " "),
                              ]),
                            ]
                          ),
                          _c(
                            "li",
                            {
                              staticClass: "submenu-item",
                              on: {
                                click: function ($event) {
                                  return _vm.handleSubmenuItemClick("Booking")
                                },
                              },
                            },
                            [
                              _c("a", { attrs: { href: "/Booking" } }, [
                                _vm._v(" " + _vm._s(_vm.$t("Booking")) + " "),
                              ]),
                            ]
                          ),
                          _c(
                            "li",
                            {
                              staticClass: "submenu-item",
                              on: {
                                click: function ($event) {
                                  return _vm.handleSubmenuItemClick("Tracking")
                                },
                              },
                            },
                            [
                              _c("a", { attrs: { href: "/Tracking" } }, [
                                _vm._v(" " + _vm._s(_vm.$t("Tracking")) + " "),
                              ]),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ]
                ),
                _c(
                  "li",
                  {
                    staticClass: "navbar-column-item nav-item",
                    on: {
                      mouseenter: function ($event) {
                        return _vm.showSubmenu("contact")
                      },
                      mouseleave: function ($event) {
                        return _vm.hideSubmenu("contact")
                      },
                    },
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "navbar-column-link nav-link",
                        attrs: { href: "/contact", "data-nav-column-link": "" },
                      },
                      [_c("span", [_vm._v(_vm._s(_vm.$t("contact")))])]
                    ),
                    _vm.submenuVisible.contact
                      ? _c("ul", { staticClass: "submenu" }, [
                          _c(
                            "li",
                            {
                              staticClass: "submenu-item",
                              on: {
                                click: function ($event) {
                                  return _vm.handleSubmenuItemClick("contact-1")
                                },
                              },
                            },
                            [
                              _c(
                                "a",
                                {
                                  attrs: { href: "#contact-1" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("contact-1")) + " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "li",
                            {
                              staticClass: "submenu-item",
                              on: {
                                click: function ($event) {
                                  return _vm.handleSubmenuItemClick("contact-2")
                                },
                              },
                            },
                            [
                              _c(
                                "a",
                                {
                                  attrs: { href: "#contact-2" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("contact-2")) + " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ]
                ),
                _c(
                  "li",
                  {
                    staticClass: "navbar-column-item nav-item",
                    on: {
                      mouseenter: function ($event) {
                        return _vm.showSubmenu("recruitment")
                      },
                      mouseleave: function ($event) {
                        return _vm.hideSubmenu("recruitment")
                      },
                    },
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "navbar-column-link nav-link",
                        attrs: {
                          href: "/recruitment",
                          "data-nav-column-link": "",
                        },
                      },
                      [_c("span", [_vm._v(_vm._s(_vm.$t("recruitment")))])]
                    ),
                    _vm.submenuVisible.recruitment
                      ? _c("ul", { staticClass: "submenu" }, [
                          _c(
                            "li",
                            {
                              staticClass: "submenu-item",
                              on: {
                                click: function ($event) {
                                  return _vm.handleSubmenuItemClick(
                                    "recruitment-1"
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "a",
                                {
                                  attrs: { href: "#recruitment-1" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("recruitment1")) + " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "li",
                            {
                              staticClass: "submenu-item",
                              on: {
                                click: function ($event) {
                                  return _vm.handleSubmenuItemClick(
                                    "recruitment-2"
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "a",
                                {
                                  attrs: { href: "#recruitment-2" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("recruitment2")) + " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "li",
                            {
                              staticClass: "submenu-item",
                              on: {
                                click: function ($event) {
                                  return _vm.handleSubmenuItemClick(
                                    "recruitment-3"
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "a",
                                {
                                  attrs: { href: "#recruitment-3" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("recruitment3")) + " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ]
                ),
              ]
            ),
          ]
        ),
        _c("div", {
          staticClass: "overlay",
          attrs: { "data-nav-column-toggler": "", "data-overlay": "" },
          on: { click: _vm.toggleSidebar },
        }),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { attrs: { href: "/" } }, [
      _c("img", {
        staticStyle: { "font-size": "small", "max-height": "40px" },
        attrs: { src: "/assets/logo_vimc_lines.PNG", alt: "" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }