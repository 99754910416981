var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {}, [
    _c(
      "p",
      { staticClass: "text-h3 mt-4", staticStyle: { "font-weight": "600" } },
      [_vm._v(" Popular routes ")]
    ),
    _c(
      "div",
      { staticClass: "mt-4" },
      [
        _c(
          "p",
          [
            _c("span", { staticClass: "info--text" }, [_vm._v("Import")]),
            _vm._v(" to "),
            _c("b", [_vm._v("Vietnam")]),
            _c("flag", { staticClass: "mx-2", attrs: { iso: "vn" } }),
          ],
          1
        ),
        _c(
          "v-row",
          { attrs: { "no-gutters": "" } },
          [
            _c(
              "v-col",
              { staticClass: "pr-2", attrs: { cols: "6" } },
              [
                _c("PopularRoutesItem", {
                  staticClass: "mb-2",
                  attrs: {
                    container: "FCL",
                    location: "Bueno Aires",
                    country: "ar",
                    price: "1375",
                    currency: "USD",
                  },
                }),
                _c("PopularRoutesItem", {
                  staticClass: "mb-2",
                  attrs: {
                    container: "FCL",
                    location: "Kaohsiung",
                    country: "tw",
                    price: "608",
                    currency: "USD",
                  },
                }),
              ],
              1
            ),
            _c(
              "v-col",
              { staticClass: "pl-2", attrs: { cols: "6" } },
              [
                _c("PopularRoutesItem", {
                  staticClass: "mb-2",
                  attrs: {
                    container: "FCL",
                    location: "Jebel Ali",
                    country: "ae",
                    price: "668",
                    currency: "USD",
                  },
                }),
                _c("PopularRoutesItem", {
                  staticClass: "mb-2",
                  attrs: {
                    container: "FCL",
                    location: "Los Angeles International Airport",
                    country: "us",
                    price: "1100",
                    currency: "USD",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "mt-4" },
      [
        _c(
          "p",
          [
            _c("span", { staticClass: "success--text" }, [_vm._v("Export")]),
            _vm._v(" from "),
            _c("b", [_vm._v("Vietnam")]),
            _c("flag", { staticClass: "mx-2", attrs: { iso: "vn" } }),
          ],
          1
        ),
        _c(
          "v-row",
          { attrs: { "no-gutters": "" } },
          [
            _c(
              "v-col",
              { staticClass: "pr-2", attrs: { cols: "6" } },
              [
                _c("PopularRoutesItem", {
                  staticClass: "mb-2",
                  attrs: {
                    container: "FCL",
                    location: "Los Angeles",
                    country: "us",
                    price: "14315",
                    currency: "USD",
                  },
                }),
                _c("PopularRoutesItem", {
                  staticClass: "mb-2",
                  attrs: {
                    container: "FCL",
                    location: "Gemlik",
                    country: "tr",
                    price: "8184",
                    currency: "USD",
                  },
                }),
              ],
              1
            ),
            _c(
              "v-col",
              { staticClass: "pl-2", attrs: { cols: "6" } },
              [
                _c("PopularRoutesItem", {
                  staticClass: "mb-2",
                  attrs: {
                    container: "FCL",
                    location: "Antwerpen",
                    country: "be",
                    price: "7306",
                    currency: "USD",
                  },
                }),
                _c("PopularRoutesItem", {
                  staticClass: "mb-2",
                  attrs: {
                    container: "FCL",
                    location: "Kuantan",
                    country: "my",
                    price: "561",
                    currency: "USD",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }