var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            {
              staticClass: "mb-2",
              attrs: { "no-gutters": "", align: "center" },
            },
            [
              _c("p", { staticClass: "overline" }, [
                _vm._v(_vm._s(_vm.$t("title-contact-list"))),
              ]),
              _c("v-spacer"),
              _c(
                "div",
                {
                  style: _vm.$vuetify.breakpoint.mobile
                    ? "width: 100%; text-align: right"
                    : "",
                },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.dialog.create = true
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("mdi-plus"),
                      ]),
                      _vm._v(" " + _vm._s(_vm.$t("button-contact-add")) + " "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("UserSettingsContactList"),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            scrollable: "",
            persistent: "",
            fullscreen: _vm.$vuetify.breakpoint.mobile,
            "max-width": "600px",
            transition: "dialog-transition",
          },
          model: {
            value: _vm.dialog.create,
            callback: function ($$v) {
              _vm.$set(_vm.dialog, "create", $$v)
            },
            expression: "dialog.create",
          },
        },
        [
          _vm.dialog.create
            ? _c("UserSettingsContactCreate", {
                on: {
                  "close-dialog": function ($event) {
                    _vm.dialog.create = false
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }