var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c(
        "v-toolbar",
        { attrs: { flat: "" } },
        [
          _c(
            "v-btn",
            {
              attrs: { icon: "" },
              on: {
                click: function ($event) {
                  return _vm.$emit("close-dialog")
                },
              },
            },
            [_c("v-icon", [_vm._v("mdi-close")])],
            1
          ),
          _c("v-toolbar-title", { staticClass: "text-uppercase" }, [
            _vm._v(" TRACKING RESULTS "),
          ]),
        ],
        1
      ),
      _c(
        "v-card-text",
        {
          staticClass: "pa-0",
          staticStyle: { "line-height": "1.5", "font-size": "inital" },
        },
        [
          _c(
            "v-card",
            {
              staticClass: "mt-6 px-3 pb-8 pt-4",
              attrs: { rounded: "lg", elevation: "2", color: "primary" },
            },
            [
              _c(
                "v-row",
                {
                  staticClass: "white--text",
                  attrs: { "no-gutters": "", align: "center" },
                },
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-icon",
                        { attrs: { small: "", left: "", dark: "" } },
                        [_vm._v("mdi-manjaro")]
                      ),
                      _c("div", { staticStyle: { width: "110px" } }, [
                        _vm._v("Container No."),
                      ]),
                      _c(
                        "span",
                        {
                          staticClass: "text-uppercase",
                          staticStyle: { "font-weight": "600" },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.containerMovements.containerNo)
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-icon",
                        { attrs: { small: "", left: "", dark: "" } },
                        [_vm._v("mdi-script-text")]
                      ),
                      _c("div", { staticStyle: { width: "110px" } }, [
                        _vm._v("Booking No."),
                      ]),
                      _c(
                        "span",
                        {
                          staticClass: "text-uppercase",
                          staticStyle: { "font-weight": "600" },
                        },
                        [_vm._v(" " + _vm._s(_vm.containerMovements.bookingNo))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("MobileContainerTrackingSearchResultItem"),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }