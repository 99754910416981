import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import containerDeliveryOrderApi from '@/api/vsl/container-delivery-order.api';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Asia/Ho_Chi_Minh');

const initialState = () => ({
  myContainerDeliveryOrderList: [],
  myContainerDeliveryOrderListQueryParams: {
    search: null,
    page: null,
    size: null,
  },
  myContainerDeliveryOrderListTotal: 0,
  myContainerDeliveryOrderListTotalPage: 0,
  myContainerDeliveryOrderDetail: {
    id: null,
    no: null,
    searchingType: null,
    availableFiles: [],
  },
});

const state = initialState();
const getters = {};
const mutations = {
  RESET(state, payload) {
    const initStates = initialState();
    if (!payload) {
      Object.keys(initStates).map((key) => {
        state[key] = initStates[key];
      });
    } else {
      const statesToReset = payload;
      statesToReset.map((key) => {
        state[key] = initStates[key];
      });
    }
  },
  SET_MY_CONTAINER_DELIVERY_ORDER_LIST(state, payload) {
    state.myContainerDeliveryOrderList = payload;
  },
  SET_MY_CONTAINER_DELIVERY_ORDER_LIST_QUERY_PARAMS(state, payload) {
    state.myContainerDeliveryOrderListQueryParams = {
      ...state.myContainerDeliveryOrderListQueryParams,
      ...payload,
    };
  },
  SET_MY_CONTAINER_DELIVERY_ORDER_LIST_TOTAL(state, payload) {
    state.myContainerDeliveryOrderListTotal = payload;
  },
  SET_MY_CONTAINER_DELIVERY_ORDER_LIST_TOTAL_PAGE(state, payload) {
    state.myContainerDeliveryOrderListTotalPage = payload;
  },
};
const actions = {
  async fetchMyContainerDeliveryOrderList({ state, commit }, payload) {
    const res =
      await containerDeliveryOrderApi.fetchMyContainerDeliveryOrderList({
        search: state.myContainerDeliveryOrderListQueryParams.search,
        page: state.myContainerDeliveryOrderListQueryParams.page,
        size: state.myContainerDeliveryOrderListQueryParams.size,
      });
    const mapped = res.data.data.map((item) => {
      const mappedItem = {
        id: item.id,
        no: item.delivery_no,
        billLadingNo: item.bill_lading_no,
        issueDate: item.date && dayjs.tz(item.date).toDate(),
        consigneeCode: item.consignee_code,
      };
      return mappedItem;
    });
    const size = state.myContainerDeliveryOrderListQueryParams.size || 10;
    const page = res.data.total === 0 ? 1 : Math.ceil(res.data.total / size);
    commit('SET_MY_CONTAINER_DELIVERY_ORDER_LIST', mapped);
    commit('SET_MY_CONTAINER_DELIVERY_ORDER_LIST_TOTAL', res.data.total);
    commit('SET_MY_CONTAINER_DELIVERY_ORDER_LIST_TOTAL_PAGE', page);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
