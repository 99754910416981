var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "elevation-0 py-6",
      class: {
        "elevation-2 px-4": !_vm.$vuetify.breakpoint.mobile,
      },
      attrs: { rounded: "lg" },
    },
    [
      _c(
        "div",
        { staticClass: "mx-2 mt-4 mb-0" },
        [
          _c("v-img", {
            attrs: { src: "/assets/img/logo.png", height: "70px", contain: "" },
          }),
        ],
        1
      ),
      _c(
        "v-toolbar",
        { attrs: { flat: "" } },
        [
          _c("v-toolbar-title", { staticClass: "overline mx-auto" }, [
            _vm._v(
              " " + _vm._s(_vm.$t("title-update-account-information")) + " "
            ),
          ]),
        ],
        1
      ),
      _c(
        "v-card-text",
        { staticClass: "py-0" },
        [
          _c(
            "v-form",
            {
              attrs: { onSubmit: "return false;" },
              on: {
                submit: function ($event) {
                  return _vm.onSignup()
                },
              },
            },
            [
              _c("v-text-field", {
                attrs: {
                  label: _vm.$t("label-phone"),
                  "prepend-inner-icon": "mdi-cellphone",
                  type: "text",
                  outlined: "",
                  disabled: true,
                },
                model: {
                  value: _vm.signupPhone,
                  callback: function ($$v) {
                    _vm.signupPhone = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "signupPhone",
                },
              }),
              _c("v-text-field", {
                attrs: {
                  label: _vm.$t("label-name"),
                  "prepend-inner-icon": "mdi-account",
                  type: "text",
                  outlined: "",
                  autofocus: "",
                  disabled: _vm.isLoading,
                  "error-messages": _vm.nameErrors,
                  counter: 50,
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.name.$touch()
                  },
                  blur: function ($event) {
                    return _vm.$v.name.$touch()
                  },
                },
                model: {
                  value: _vm.$v.name.$model,
                  callback: function ($$v) {
                    _vm.$set(_vm.$v.name, "$model", $$v)
                  },
                  expression: "$v.name.$model",
                },
              }),
              _c("AddressLocationPicker", {
                attrs: {
                  disabled: _vm.isLoading,
                  outlined: "",
                  "auto-select-first-district": "",
                  "province-label": _vm.$t("label-province"),
                  "district-label": _vm.$t("label-district"),
                  "error-messages": _vm.addressLocationErrors(_vm.$v),
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.addressLocation.$touch()
                  },
                  blur: function ($event) {
                    return _vm.$v.addressLocation.$touch()
                  },
                },
                model: {
                  value: _vm.$v.addressLocation.$model,
                  callback: function ($$v) {
                    _vm.$set(_vm.$v.addressLocation, "$model", $$v)
                  },
                  expression: "$v.addressLocation.$model",
                },
              }),
              _c("v-text-field", {
                attrs: {
                  label: _vm.$t("label-address"),
                  "prepend-inner-icon": "mdi-map-marker",
                  type: "text",
                  outlined: "",
                  disabled: _vm.isLoading,
                  "error-messages": _vm.addressErrors,
                  counter: 200,
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.address.$touch()
                  },
                  blur: function ($event) {
                    return _vm.$v.address.$touch()
                  },
                },
                model: {
                  value: _vm.$v.address.$model,
                  callback: function ($$v) {
                    _vm.$set(_vm.$v.address, "$model", $$v)
                  },
                  expression: "$v.address.$model",
                },
              }),
              _c("v-text-field", {
                attrs: {
                  label: _vm.$t("label-password"),
                  "prepend-inner-icon": "mdi-lock",
                  type: "password",
                  outlined: "",
                  autocomplete: "new-password",
                  "error-messages": _vm.passwordErrors,
                  counter: 32,
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.password.$touch()
                  },
                  blur: function ($event) {
                    return _vm.$v.password.$touch()
                  },
                },
                model: {
                  value: _vm.$v.password.$model,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.$v.password,
                      "$model",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "$v.password.$model",
                },
              }),
              _c("v-text-field", {
                attrs: {
                  label: _vm.$t("label-repassword"),
                  "prepend-inner-icon": "mdi-lock",
                  type: "password",
                  outlined: "",
                  autocomplete: "new-password",
                  "error-messages": _vm.confirmPasswordErrors,
                  counter: 32,
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.confirmPassword.$touch()
                  },
                  blur: function ($event) {
                    return _vm.$v.confirmPassword.$touch()
                  },
                },
                model: {
                  value: _vm.$v.confirmPassword.$model,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.$v.confirmPassword,
                      "$model",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "$v.confirmPassword.$model",
                },
              }),
              _c("p", { staticClass: "overline" }, [
                _vm._v(_vm._s(_vm.$t("title-booking-email"))),
              ]),
              _c("v-text-field", {
                attrs: {
                  label: _vm.$t("label-email"),
                  "prepend-inner-icon": "mdi-email",
                  type: "text",
                  outlined: "",
                  disabled: _vm.isLoading,
                  "error-messages": _vm.email1Errors,
                  counter: 50,
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.email1.$touch()
                  },
                  blur: function ($event) {
                    return _vm.$v.email1.$touch()
                  },
                },
                model: {
                  value: _vm.$v.email1.$model,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.$v.email1,
                      "$model",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "$v.email1.$model",
                },
              }),
              _c("v-text-field", {
                attrs: {
                  label: _vm.$t("label-email") + " 2",
                  "prepend-inner-icon": "mdi-email",
                  type: "text",
                  outlined: "",
                  disabled: _vm.isLoading,
                  "error-messages": _vm.email2Errors,
                  counter: 50,
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.email2.$touch()
                  },
                  blur: function ($event) {
                    return _vm.$v.email2.$touch()
                  },
                },
                model: {
                  value: _vm.$v.email2.$model,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.$v.email2,
                      "$model",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "$v.email2.$model",
                },
              }),
            ],
            1
          ),
          _c("div", {
            staticClass: "d-flex justify-center align-center",
            attrs: { id: "recaptcha-container" },
          }),
        ],
        1
      ),
      _c(
        "v-card-actions",
        { staticClass: "d-flex flex-column px-4" },
        [
          _c(
            "v-btn",
            {
              attrs: {
                width: "100%",
                "x-large": "",
                color: "primary",
                depressed: "",
                loading: _vm.isLoading,
                disabled: _vm.isLoading || _vm.$v.$invalid,
              },
              on: {
                click: function ($event) {
                  return _vm.onSignup()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("button-create-account")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }