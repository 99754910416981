var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    [
      _c("NavBarHome", { attrs: { active: "services" } }),
      _c(
        "div",
        {
          staticClass: "carousel slide carousel-fade carousel-slide",
          attrs: {
            id: "carousel-2",
            "data-ride": "carousel",
            "data-interval": "6000",
          },
        },
        [
          _c(
            "div",
            { staticClass: "carousel-inner", attrs: { role: "listbox" } },
            [
              _c("div", { staticClass: "carousel-item active" }, [
                _c("img", {
                  staticClass: "d-block img-fluid",
                  attrs: {
                    src: require("../../../libhome/img/vimc accelerating-digital-transformation.png"),
                    alt: "responsive image",
                  },
                }),
                _c("div", { staticClass: "carousel-caption" }, [
                  _c("div", [
                    _c("div", { staticClass: "transx_content_slide_wrapper" }, [
                      _c("div", { staticClass: "transx_content_container" }, [
                        _c("div", { staticClass: "transx_content_wrapper_1" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "transx_up_heading_overlay transx_overlay_align_center",
                              attrs: { id: "carousel-item-caption" },
                            },
                            [_vm._v(" VIMC LINES ")]
                          ),
                          _c(
                            "h2",
                            { staticClass: "transx_content_slider_title" },
                            [_vm._v(" " + _vm._s(_vm.$t("Tracking")) + " ")]
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "tracking-page", attrs: { id: "tracking-page" } },
        [
          _c(
            "div",
            { staticClass: "container container-tracking" },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      class: {
                        "px-1": _vm.$vuetify.breakpoint.mobile,
                      },
                      attrs: { cols: "12" },
                    },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "div",
                            {
                              class: {
                                "mt-12": _vm.$vuetify.breakpoint.mobile,
                              },
                            },
                            [
                              _c("v-row", { attrs: { "no-gutters": "" } }),
                              _c(
                                "v-row",
                                {
                                  staticClass: "my-2",
                                  attrs: {
                                    "no-gutters": "",
                                    align: "center",
                                    justify: "center",
                                  },
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      class: {
                                        "px-1": _vm.$vuetify.breakpoint.mobile,
                                      },
                                      attrs: { cols: "5" },
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          attrs: {
                                            "no-gutters": "",
                                            align: "center",
                                          },
                                        },
                                        [
                                          _c("v-card", {
                                            staticStyle: { overflow: "hidden" },
                                            attrs: {
                                              rounded: "lg",
                                              width: "100%",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("v-col", {
                                    class: {
                                      "px-1": _vm.$vuetify.breakpoint.mobile,
                                    },
                                    attrs: { cols: "7" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-tabs-items",
                            {
                              model: {
                                value: _vm.tab,
                                callback: function ($$v) {
                                  _vm.tab = $$v
                                },
                                expression: "tab",
                              },
                            },
                            [
                              _c(
                                "v-tab-item",
                                { key: "0" },
                                [_c("ContainerTrackingSearch")],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c("v-col", {
                    class: {
                      "px-1": _vm.$vuetify.breakpoint.mobile,
                    },
                    attrs: { cols: "7" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c("FooterHome"),
      _vm._m(0),
      _c("NavBarLeftHome"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { staticClass: "back-to-top", attrs: { href: "/home" } }, [
      _c("i", { staticClass: "fa fa-chevron-up" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }