var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c(
        "v-toolbar",
        { attrs: { flat: "", color: "primary", dark: "" } },
        [
          _c("v-toolbar-title", [
            _vm._v(_vm._s(_vm.$t("title-account-settings"))),
          ]),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { icon: "" },
              on: {
                click: function ($event) {
                  return _vm.$emit("close-dialog")
                },
              },
            },
            [_c("v-icon", { attrs: { dark: "" } }, [_vm._v("mdi-close")])],
            1
          ),
        ],
        1
      ),
      _c(
        "v-tabs",
        {
          attrs: { "show-arrows": "" },
          model: {
            value: _vm.activeTab,
            callback: function ($$v) {
              _vm.activeTab = $$v
            },
            expression: "activeTab",
          },
        },
        [
          _c("v-tabs-slider", { attrs: { color: "primary" } }),
          _c(
            "v-tab",
            [
              _c("v-icon", { attrs: { left: "" } }, [_vm._v(" mdi-account ")]),
              _vm._v(" " + _vm._s(_vm.$t("account")) + " "),
            ],
            1
          ),
          _c(
            "v-tab",
            [
              _c("v-icon", { attrs: { left: "" } }, [_vm._v(" mdi-lock ")]),
              _vm._v(" " + _vm._s(_vm.$t("password")) + " "),
            ],
            1
          ),
          _c(
            "v-tab",
            [
              _c("v-icon", { attrs: { left: "" } }, [_vm._v(" mdi-contacts ")]),
              _vm._v(" " + _vm._s(_vm.$t("title-contact-infor")) + " "),
            ],
            1
          ),
          _c(
            "v-tab-item",
            [
              _c("UserSettingsAccount", {
                class: {
                  "px-8 py-4": !_vm.$vuetify.breakpoint.mobile,
                },
                staticStyle: { "min-height": "80vh" },
              }),
            ],
            1
          ),
          _c(
            "v-tab-item",
            [
              _c("UserSettingsChangePassword", {
                class: {
                  "px-8 py-4": !_vm.$vuetify.breakpoint.mobile,
                },
                staticStyle: { "min-height": "80vh" },
              }),
            ],
            1
          ),
          _c(
            "v-tab-item",
            [
              _c("UserSettingsContact", {
                staticStyle: { "min-height": "80vh" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }