import { vslApi } from '@/api';

export default {
  createRequestQuotation(data) {
    return vslApi.post('/v1/quotations', data);
  },
  calculateCommodityPrices(data) {
    return vslApi.post('/v1/quotations/get-commodity-prices', data);
  },
  fetchMyQuotationList(params) {
    return vslApi.get('/v1/quotations', { params: params });
  },
  fetchMyQuotation({ id }) {
    return vslApi.get(`/v1/quotations/${id}`);
  },
  fetchMyQuotationActionLogList({ id }) {
    return vslApi.get(`/v1/quotations/${id}/action-logs`);
  },
  fetchMyQuotationCommodityList({ id }) {
    return vslApi.get(`/v1/quotations/${id}/commodities`);
  },
  fetchMyQuotationSurchargeList({ id }) {
    return vslApi.get(`/v1/quotations/${id}/surcharges`);
  },
  resendMyQuotation({ id }, data) {
    return vslApi.post(`/v1/quotations/${id}/user-resend-quotation`, data);
  },
  confirmMyQuotation({ id }, data) {
    return vslApi.post(`/v1/quotations/${id}/user-confirm-quotation`, data);
  },
  fetchQuotationCommentList({ id }) {
    return vslApi.get(`/v1/quotations/${id}/comments?sort=asc`);
  },
  createQuotationComment({ id }, data) {
    return vslApi.post(`/v1/quotations/${id}/comments`, data);
  },
  downloadQuotationAttachment(quotationId, type, format) {
    return vslApi.get(`/v1/quotations/${quotationId}/attachments`, {
      params: {
        type,
        format,
      },
      responseType: 'blob',
    });
  },
};
