var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c(
        "v-card-text",
        { staticStyle: { "font-size": "18px" } },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "", align: "center" } },
            [
              _c(
                "v-col",
                { staticClass: "text-truncate", attrs: { cols: "7" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "primary--text",
                      attrs: {
                        rounded: "",
                        color: "secondary lighten-1",
                        elevation: "0",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.container) + " ")]
                  ),
                  _c("flag", {
                    staticClass: "mx-4",
                    attrs: { iso: _vm.country },
                  }),
                  _c(
                    "span",
                    { staticClass: "font-weight-medium text--primary" },
                    [
                      _vm._v(
                        _vm._s(_vm.location) +
                          ", " +
                          _vm._s(_vm.country.toUpperCase())
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c("v-col", { attrs: { cols: "4" } }, [
                _c("span", { staticClass: "mx-4" }, [_vm._v("from")]),
                _c("span", { staticClass: "font-weight-bold text--primary" }, [
                  _vm._v(_vm._s(_vm.currency) + " " + _vm._s(_vm.price)),
                ]),
              ]),
              _c(
                "v-col",
                { attrs: { cols: "1" } },
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "", justify: "center" } },
                    [_c("v-icon", [_vm._v(" mdi-chevron-right ")])],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }