<template>
  <v-card
    class="py-1 px-2 no-legend"
    rounded="lg"
  >
    <v-card-text
      class="pt-1"
      :class="{
        'pa-2': $vuetify.breakpoint.mobile,
      }"
    >
      <!-- <v-tabs
        v-model="tab"
      >
        <v-tab :ripple="false"></v-tab>
      </v-tabs> -->

      <v-tabs-items v-model="tab">
        <v-tab-item
          key="1"
          eager
        >
          <v-form
            @submit="searchSchedule"
            onSubmit="return false;"
          >
            <!-- Hàng đầu tiên -->
            <v-row
              no-gutters
              class="mb-4"
            >
              <!-- Thêm lớp mb-4 để tạo khoảng cách -->
              <v-col
                :cols="$vuetify.breakpoint.mobile ? 12 : 4"
                class="pr-2"
              >
                <div class="caption caption-import">{{ $t('label-pol') }}</div>
                <PortPicker
                  :value="selectedPolCode"
                  @change="onSelectPOL"
                  solo
                  :label="$t('label-pol')"
                  clearable
                  hide-details
                  outlined
                  dense
                  class="custom-port custom-opacity"
                  height="60px"
                />
              </v-col>

              <v-col
                :cols="$vuetify.breakpoint.mobile ? 12 : 4"
                class="px-2"
              >
                <div class="caption caption-import">{{ $t('label-pod') }}</div>
                <PortPicker
                  :value="selectedPodCode"
                  @change="onSelectPOD"
                  solo
                  :label="$t('label-pod')"
                  clearable
                  hide-details
                  outlined
                  dense
                  class="custom-port custom-opacity"
                  height="60px"
                />
              </v-col>

              <!-- Phần ETD-ETA (Date Picker) -->
              <v-col
                :cols="$vuetify.breakpoint.mobile ? 12 : 4"
                class="pl-3"
              >
                <v-dialog
                  ref="dialog"
                  v-model="modal"
                  persistent
                  max-width="650px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div>
                      <div class="caption caption-import">
                        {{ $t('etd-eta') }}
                      </div>
                      <v-text-field
                        :value="dateRangeText"
                        label="ETD - ETA"
                        hide-details
                        prepend-inner-icon="mdi-calendar-month"
                        readonly
                        solo
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                        height="60px"
                        class="custom-text-field custom-opacity"
                      />
                    </div>
                  </template>
                  <v-container class="custom-container">
                    <v-row>
                      <v-col cols="6">
                        <v-date-picker
                          v-model="dates[0]"
                          :allowedDates="allowedDates"
                          @change="onChangeStartDate"
                          full-width
                        />
                      </v-col>
                      <v-col cols="6">
                        <v-date-picker
                          v-model="dates[1]"
                          :allowedDates="allowedDates"
                          @change="onChangeEndDate"
                          full-width
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-btn
                          block
                          color="primary"
                          @click="onSelectEtdEta"
                        >
                          {{ $t('button-select') }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-dialog>
              </v-col>
            </v-row>

            <!-- Hàng thứ hai -->
            <v-row no-gutters>
              <!-- Vessel Picker -->
              <v-col
                :cols="$vuetify.breakpoint.mobile ? 12 : 4"
                class="pr-2"
              >
                <div class="caption">{{ $t('vessel') }}</div>
                <VesselPicker
                  :value="selectedVesselCode"
                  @change="onSelectVessel"
                  :label="$t('label-vessel-picker')"
                  clearable
                  hide-details
                  solo
                  outlined
                  dense
                  height="60px"
                  class="ml-0 custom-opacity"
                />
              </v-col>

              <!-- Voyage -->
              <v-col
                :cols="$vuetify.breakpoint.mobile ? 12 : 4"
                class="px-2"
              >
                <div class="caption">{{ $t('voyage') }}</div>
                <v-text-field
                  v-model="typedSearch"
                  :label="$t('enter-voyage-code')"
                  prepend-inner-icon="mdi-compass"
                  type="text"
                  solo
                  flat
                  outlined
                  dense
                  hide-details
                  clearable
                  height="60px"
                  class="custom-opacity"
                />
              </v-col>

              <!-- Search Button -->
              <v-col
                :cols="$vuetify.breakpoint.mobile ? 12 : 4"
                class="pl-3"
              >
                <v-btn
                  style="
                    text-transform: none;
                    background-color: #e6734f !important;
                  "
                  class="px-10 mr-3 btn-margin"
                  color="info"
                  width="100%"
                  height="53px"
                  large
                  :loading="isLoading"
                  @click="searchSchedule"
                >
                  <v-icon left> mdi-magnify </v-icon>
                  {{ $t('search') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
    <v-dialog
      v-model="dialog.request"
      scrollable
      persistent
      max-width="900px"
      :fullscreen="$vuetify.breakpoint.mobile"
      transition="dialog-transition"
    >
      <VesselScheduleSearchRequest
        v-if="dialog.request"
        @close-dialog="dialog.request = false"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import dayjs from 'dayjs';
import { mapActions, mapMutations, mapState } from 'vuex';
import PortPicker from '@/components/shared/local/vsl/PortPicker.vue';
import VesselPicker from '@/components/shared/local/vsl/VesselPicker.vue';
import VesselScheduleSearchRequest from './VesselScheduleSearchRequest.vue';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css'; // Import CSS

export default {
  inject: ['setIsSearched'],
  components: { PortPicker, VesselPicker, VesselScheduleSearchRequest },
  data() {
    return {
      tab: 0,
      dialog: {
        request: false,
      },
      dates: [],
      modal: false,
      isExpandFilter: false,
      typedSearch: '',
      selectedVesselCode: null,
      selectedEtdEta: [],
      selectedPolCode: null,
      selectedPodCode: null,
    };
  },
  computed: {
    ...mapState('vsl/vesselSchedule', {
      queryParams: 'vesselScheduleListQueryParams',
      vesselScheduleListLoading: 'vesselScheduleListLoading',
    }),
    isLoading: {
      get() {
        return this.vesselScheduleListLoading;
      },
      set(value) {
        this.SET_VESSEL_SCHEDULE_LIST_LOADING(value);
      },
    },
    dateRangeText() {
      const formattedStartDate = dayjs(this.dates[0]).format('DD.MM.YYYY');
      const formattedEndDate = dayjs(this.dates[1]).format('DD.MM.YYYY');
      return `${formattedStartDate} - ${formattedEndDate}`;
    },
    advancedFilterCount() {
      const filters = {
        selectedVesselCode: this.selectedVesselCode,
        selectedPolCode: this.selectedPolCode,
        selectedPodCode: this.selectedPodCode,
      };
      console.log('[LOG] : filters', filters);
      const count = Object.keys(filters).filter(
        (x) => filters[x] != undefined
      ).length;
      console.log('[LOG] : count', count);
      return count;
    },
  },
  created() {
    const { search, vessel_code, pol_code, pod_code, from_etd, to_etd } =
      this.$route.query;

    // Kiểm tra và gán giá trị từ query params
    this.typedSearch = search;
    this.selectedVesselCode = vessel_code || null;
    this.selectedPolCode = pol_code || null;
    this.selectedPodCode = pod_code || null;

    // Nếu có giá trị từ query params cho ngày, gán nó vào
    if (from_etd && to_etd) {
      this.dates = [from_etd, to_etd];
    } else {
      // Nếu không có giá trị từ query params, đặt giá trị mặc định
      this.dates = [
        dayjs().format('YYYY-MM-DD'), // Ngày bắt đầu là hôm nay
        dayjs().add(1, 'month').format('YYYY-MM-DD'), // Ngày kết thúc là 1 tháng sau
      ];
    }
  },
  watch: {
    typedSearch(value) {
      this.SET_VESSEL_SCHEDULE_LIST_QUERY_PARAMS({
        voyageCode: value,
      });
    },
  },
  methods: {
    ...mapMutations('vsl/vesselSchedule', [
      'SET_VESSEL_SCHEDULE_LIST_QUERY_PARAMS',
      'SET_VESSEL_SCHEDULE_LIST_LOADING',
    ]),
    ...mapActions('vsl/vesselSchedule', ['searchVesselSchedule']),

    async searchSchedule() {
      try {
        this.isLoading = true;

        // Cập nhật queryParams với các giá trị hiện tại
        this.SET_VESSEL_SCHEDULE_LIST_QUERY_PARAMS({
          voyageCode: this.typedSearch,
          vesselCode: this.selectedVesselCode,
          polCode: this.selectedPolCode,
          podCode: this.selectedPodCode,
          etdEta: this.dates, // Cập nhật mốc thời gian
        });

        let result = [];

        // Kiểm tra các điều kiện và gọi API
        if (this.tab === 0) {
          if (!this.selectedPolCode || !this.selectedPodCode) {
            this.$message.error(this.$t('validation-message-pol-pod-required'));
            return;
          }
          if (this.selectedPolCode === this.selectedPodCode) {
            this.$message.error(this.$t('validation-message-pol-pod-not-same'));
            return;
          }
          result = await this.searchVesselSchedule({
            searchType: 'portSearching',
          });

          if (!result || result.length === 0) {
            this.$message.error('No data found'); // Hiển thị thông báo không có dữ liệu
            return;
          }
        }
        if (this.tab === 1) {
          if (!this.typedSearch) {
            this.$message.error(
              this.$t('validation-message-voyage-code-required')
            );
            return;
          }
          result = await this.searchVesselSchedule({
            searchType: 'voyageCodeSearching',
          });

          if (!result || result.length === 0) {
            this.$message.error('No data found'); // Hiển thị thông báo không có dữ liệu
            return;
          }
        }

        // Đảm bảo queryParams được cập nhật đúng cách
        await this.$router
          .push({
            query: {
              voyage_code: this.typedSearch,
              vessel_code: this.selectedVesselCode,
              pol_code: this.selectedPolCode,
              pod_code: this.selectedPodCode,
              from_etd: this.dates[0] || undefined,
              to_etd: this.dates[1] || undefined,
            },
          })
          .catch(() => {});

        this.setIsSearched(true);
      } finally {
        this.isLoading = false;
      }
    },
    async onSelectVessel(item) {
      this.selectedVesselCode = item?.code;
      this.SET_VESSEL_SCHEDULE_LIST_QUERY_PARAMS({ vesselCode: item?.code });
    },
    async onSelectPOL(item) {
      this.selectedPolCode = item?.code;
      this.SET_VESSEL_SCHEDULE_LIST_QUERY_PARAMS({ polCode: item?.code });
    },
    async onSelectPOD(item) {
      this.selectedPodCode = item?.code;
      this.SET_VESSEL_SCHEDULE_LIST_QUERY_PARAMS({ podCode: item?.code });
    },
    onChangeStartDate(value) {
      const startDate = dayjs(value);
      const endDate = dayjs(this.dates[1]);

      if (endDate.isBefore(startDate)) {
        this.dates[1] = startDate.format('YYYY-MM-DD'); // Đặt EndDate bằng StartDate nếu EndDate trước StartDate
      } else {
        this.dates[0] = value; // Cập nhật StartDate
      }

      this.selectedEtdEta = this.dates;
      this.SET_VESSEL_SCHEDULE_LIST_QUERY_PARAMS({
        etdEta: this.selectedEtdEta,
      });
    },

    onChangeEndDate(value) {
      const startDate = dayjs(this.dates[0]);
      const endDate = dayjs(value);

      if (endDate.isBefore(startDate)) {
        this.dates[1] = startDate.format('YYYY-MM-DD'); // Đặt EndDate bằng StartDate nếu EndDate trước StartDate
      } else {
        this.dates[1] = value; // Cập nhật EndDate
      }

      this.selectedEtdEta = this.dates;
      this.SET_VESSEL_SCHEDULE_LIST_QUERY_PARAMS({
        etdEta: this.selectedEtdEta,
      });
    },
    async onSelectEtdEta() {
      this.modal = false;
      if (dayjs(this.dates[0]).isAfter(dayjs(this.dates[1]))) {
        [this.dates[0], this.dates[1]] = [this.dates[1], this.dates[0]];
      }
      this.selectedEtdEta = this.dates;
      this.SET_VESSEL_SCHEDULE_LIST_QUERY_PARAMS({
        etdEta: this.selectedEtdEta,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
:deep {
  .v-tab {
    text-transform: initial;
  }

  .v-slide-group__prev {
    display: none !important;
  }
}

.v-select .v-input__append-inner {
  display: none;
}

.v-select .v-select__slot .v-select__selections {
  padding-right: 0;
}

.v-select .v-select__control {
  padding-right: 0;
}

.caption {
  font-size: 16px !important;
  margin-top: 10px !important;
}

.caption-import::before {
  content: '*'; /* Nội dung dấu * */
  color: red; /* Màu đỏ */
  margin-right: 4px; /* Khoảng cách giữa * và caption */
  font-weight: bold; /* Đậm */
}

.custom-port {
  width: 400px !important;
}

.custom-text-field {
  width: 400px; /* Thay đổi chiều rộng theo yêu cầu */
  /* Nếu muốn thay đổi chiều cao, có thể thêm: */
}

.btn-margin {
  margin-top: 35px;
  font-size: 16px;
  background: #e6734f !important;
}

.custom-container {
  padding: 12px 0px 12px 24px !important;
}

.custom-opacity {
  opacity: 0.8 !important;
}
</style>
