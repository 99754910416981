var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        {
          staticClass: "px-6 py-4",
          staticStyle: { "background-color": "#e6734f !important" },
          attrs: { elevation: "2", rounded: "lg" },
        },
        [
          _c(
            "v-row",
            {
              staticClass: "white--text",
              attrs: { "no-gutters": "", align: "center" },
            },
            [
              _c(
                "div",
                { staticStyle: { "font-size": "16px" } },
                [
                  _c("v-icon", { attrs: { small: "", dark: "" } }, [
                    _vm._v("mdi-manjaro"),
                  ]),
                  _vm._v(" Container No. "),
                  _c(
                    "span",
                    {
                      staticClass: "text-uppercase",
                      staticStyle: { "font-weight": "600" },
                    },
                    [_vm._v(" " + _vm._s(_vm.containerMovements.containerNo))]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "ml-10", staticStyle: { "font-size": "16px" } },
                [
                  _c("v-icon", { attrs: { small: "", dark: "" } }, [
                    _vm._v("mdi-script-text"),
                  ]),
                  _vm._v(" Booking No. "),
                  _c(
                    "span",
                    {
                      staticClass: "text-uppercase",
                      staticStyle: { "font-weight": "600" },
                    },
                    [_vm._v(" " + _vm._s(_vm.containerMovements.bookingNo))]
                  ),
                ],
                1
              ),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticStyle: { "font-size": "16px" },
                  attrs: { text: "", color: "white", ripple: false },
                  on: {
                    click: function ($event) {
                      _vm.dialog.log = true
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v("mdi-history"),
                  ]),
                  _vm._v(" History "),
                ],
                1
              ),
            ],
            1
          ),
          _c("ContainerTrackingSearchResultItem"),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { transition: "dialog-transition" },
          model: {
            value: _vm.dialog.log,
            callback: function ($$v) {
              _vm.$set(_vm.dialog, "log", $$v)
            },
            expression: "dialog.log",
          },
        },
        [
          _vm.dialog.log
            ? _c("ContainerTrackingSearchResultLog", {
                on: {
                  "close-dialog": function ($event) {
                    _vm.dialog.orderShipmentDelete = false
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }