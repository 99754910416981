import { vslApi } from '@/api';

export default {
  fetchMyQuotationCroList({ quotationId }) {
    return vslApi.get(`/v1/quotation-cros?quotation_id=${quotationId}`);
  },
  fetchMyQuotationCro({ id }) {
    return vslApi.get(`/v1/quotation-cros/${id}`);
  },
  confirmMyQuotationCro({ id }) {
    return vslApi.post(`/v1/quotation-cros/${id}/confirm-cro`);
  },
  createQuotationCro(data) {
    return vslApi.post(`/v1/quotation-cros/`, data);
  },
};
