var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "elevation-0 py-6",
      class: {
        "elevation-2 px-4": !_vm.$vuetify.breakpoint.mobile,
      },
      attrs: { rounded: "lg" },
    },
    [
      _c(
        "div",
        { staticClass: "mx-2 mt-4 mb-0" },
        [
          _c("v-img", {
            attrs: { src: "/assets/img/logo.png", height: "70px", contain: "" },
          }),
        ],
        1
      ),
      _c(
        "v-toolbar",
        { attrs: { flat: "" } },
        [
          _c("v-toolbar-title", { staticClass: "overline mx-auto" }, [
            _vm._v(" " + _vm._s(_vm.$t("login")) + " "),
          ]),
        ],
        1
      ),
      _c(
        "v-card-text",
        { staticClass: "py-0" },
        [
          _c(
            "v-form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.onLogin()
                },
              },
            },
            [
              _c("v-text-field", {
                attrs: {
                  label: _vm.$t("label-login-username-email-phone"),
                  "prepend-inner-icon": "mdi-account-circle",
                  type: "text",
                  outlined: "",
                  autofocus: "",
                  disabled: _vm.isLoading,
                },
                model: {
                  value: _vm.$v.account.$model,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.$v.account,
                      "$model",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "$v.account.$model",
                },
              }),
              _c("v-text-field", {
                ref: "passwordBox",
                attrs: {
                  label: _vm.$t("label-login-password"),
                  "prepend-inner-icon": "mdi-lock",
                  name: "password",
                  type: "password",
                  outlined: "",
                  disabled: _vm.isLoading,
                },
                model: {
                  value: _vm.$v.password.$model,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.$v.password,
                      "$model",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "$v.password.$model",
                },
              }),
              _c(
                "v-btn",
                {
                  attrs: {
                    width: "100%",
                    "x-large": "",
                    color: "primary",
                    depressed: "",
                    type: "submit",
                    loading: _vm.isLoading,
                    disabled: _vm.isLoading || _vm.$v.$invalid,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.onLogin()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("login")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-card-actions", { staticClass: "d-flex flex-column px-4" }, [
        _c("div", { staticClass: "pt-4" }, [
          _vm._v(" " + _vm._s(_vm.$t("dont-have-account-yet")) + " "),
          _c(
            "span",
            [
              _c("router-link", { attrs: { to: "/auth/signup" } }, [
                _vm._v(_vm._s(_vm.$t("signup-now"))),
              ]),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }