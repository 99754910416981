<template>
  <v-card
    class=""
    rounded="lg"
    :ripple="false"
    :to="to"
    style="overflow: hidden"
  >
    <v-card-text class="py-0 px-0">
      <v-row
        justify="center"
        no-gutters
      >
        <v-expansion-panels
          accordion
          v-model="panel"
        >
          <v-expansion-panel>
            <v-expansion-panel-header
              class="pa-0"
              style="display: block"
            >
              <div>
                <v-card
                  color="primary"
                  class="pa-1"
                  tile
                >
                  <v-row
                    no-gutters
                    align="center"
                  >
                    <v-list-item>
                      <v-list-item-avatar
                        size="24"
                        tile
                      >
                        <v-img
                          src="/icons/icon-192x192.png"
                          :transition="false"
                        ></v-img>
                      </v-list-item-avatar>
                      <v-list-item-content style="flex: inital">
                        <v-list-item-title class="white--text"
                          >VIMC</v-list-item-title
                        >
                      </v-list-item-content>
                      <v-spacer />
                      <div
                        class="text-right white--text"
                        style="font-size: 12px; line-height: 1.75"
                      >
                        <div>
                          {{ schedules.voyageCodes.join(', ') }}
                          <v-icon
                            small
                            dark
                            >mdi-compass</v-icon
                          >
                        </div>
                        <div>
                          {{ schedules.path.join(', ') }}
                          <v-icon
                            small
                            dark
                            >mdi-map-marker-radius</v-icon
                          >
                        </div>
                      </div>
                    </v-list-item>
                  </v-row>
                </v-card>

                <v-row
                  no-gutters
                  align="center"
                  class="pt-2"
                >
                  <v-row
                    no-gutters
                    class="align-top"
                  >
                    <v-col :cols="5">
                      <VesselScheduleSearchResultListItemPort
                        :date="new Date(schedules.pathSchedules[0].etd)"
                        :code="schedules.pathSchedules[0].polCode"
                        :terminal-name="
                          schedules.pathSchedules[0].polTerminalName
                        "
                      />
                    </v-col>
                    <v-col
                      cols="2"
                      class="d-flex flex-column align-center justify-center"
                    >
                      <img
                        src="/assets/icons/cargo-ship.png"
                        :height="$vuetify.breakpoint.mobile ? 24 : 50"
                        width="auto"
                      />
                      <div
                        v-for="pathSchedule of schedules.pathSchedules"
                        :key="pathSchedule.code"
                        style="font-size: 11px"
                      >
                        {{ pathSchedule.voyageCode }} //
                        <span v-if="pathSchedule.vesselSlot">
                          {{ $t('text-slot-available') }}
                        </span>
                        <span v-if="!pathSchedule.vesselSlot">
                          {{ $t('text-slot-not-available') }}
                        </span>
                      </div>
                    </v-col>
                    <v-col :cols="5">
                      <VesselScheduleSearchResultListItemPort
                        :date="
                          new Date(
                            schedules.pathSchedules[
                              schedules.pathSchedules.length - 1
                            ].eta
                          )
                        "
                        :code="
                          schedules.pathSchedules[
                            schedules.pathSchedules.length - 1
                          ].podCode
                        "
                        :terminal-name="
                          schedules.pathSchedules[
                            schedules.pathSchedules.length - 1
                          ].podTerminalName
                        "
                      />
                    </v-col>
                  </v-row>

                  <template v-if="!hideBooking">
                    <v-divider vertical></v-divider>
                    <div
                      :class="{
                        'd-flex w-100': $vuetify.breakpoint.mobile,
                      }"
                    >
                      <v-row
                        no-gutters
                        class="mx-4 my-2"
                        :class="{
                          'mb-0': $vuetify.breakpoint.mobile,
                          'px-4': !$vuetify.breakpoint.mobile,
                        }"
                        align="center"
                        justify="center"
                      >
                        <div
                          :class="{
                            'd-flex align-center justify-center':
                              $vuetify.breakpoint.mobile,
                          }"
                        >
                          <v-btn
                            class="mb-2"
                            color="success"
                            target="_blank"
                            :to="{
                              path: '/app/shipping/request-a-quote/submit',
                              query: {
                                schedules_codes: scheduleCodes.join(),
                                path: schedules.path.join(),
                              },
                            }"
                          >
                            Booking<v-icon right>mdi-arrow-top-right</v-icon>
                          </v-btn>
                          <div
                            class="text-center caption"
                            :class="{
                              'mr-2': !$vuetify.breakpoint.mobile,
                              'ml-2': $vuetify.breakpoint.mobile,
                            }"
                          >
                            <v-chip
                              v-if="schedules.pathSchedules.length > 1"
                              small
                              color="orange"
                              outlined
                            >
                              <v-icon
                                small
                                left
                              >
                                mdi-swap-vertical-circle
                              </v-icon>
                              Transit
                            </v-chip>
                          </div>
                        </div>
                      </v-row>
                    </div>
                  </template>
                </v-row>
              </div>

              <template v-slot:actions>
                <div class="mb-3 mt-2 caption">
                  <v-icon small> mdi-chevron-double-down </v-icon>
                  {{ panelText }}
                  <v-icon small> mdi-chevron-double-down </v-icon>
                </div>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <VesselScheduleSearchResultListItemDetail
                :path-schedules="schedules.pathSchedules"
                class="mb-4"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import dayjs from 'dayjs';
import VesselScheduleSearchResultListItemPort from './VesselScheduleSearchResultListItemPort.vue';
import VesselScheduleSearchResultListItemDetail from './VesselScheduleSearchResultListItemDetail.vue';

export default {
  props: {
    schedules: {
      type: Object,
    },
    to: {
      type: Object,
    },
    hideBooking: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    VesselScheduleSearchResultListItemPort,
    VesselScheduleSearchResultListItemDetail,
  },
  data: () => ({
    panel: undefined,
    panelText: '',
  }),
  computed: {
    scheduleCodes() {
      return this.schedules.pathSchedules.map(({ code }) => code);
    },
  },
  watch: {
    panel(value) {
      if (value === undefined) {
        this.panelText = this.$t('button-view-detail');
      } else {
        this.panelText = this.$t('button-hide-detail');
      }
    },
  },
  async created() {
    this.panelText = this.$t('button-view-detail');
  },
  methods: {
    formatVesselSlot(value) {
      if (value === true) {
        return this.$t('text-slot-available');
      }
      if (value === false) {
        return this.$t('text-slot-not-available');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
:deep {
  .v-expansion-panel-header__icon {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media (pointer: none), (pointer: coarse) {
    .v-expansion-panel-content__wrap {
      padding: 0;
    }
  }
}
</style>
