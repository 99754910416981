var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _c(
        "v-btn",
        _vm._b(
          {
            staticClass: "text-none",
            attrs: { color: "primary", loading: _vm.isSelecting },
            on: { click: _vm.onButtonClick },
          },
          "v-btn",
          _vm.$attrs,
          false
        ),
        [
          _vm.icon
            ? _c("v-icon", { attrs: { left: "" } }, [
                _vm._v(" " + _vm._s(_vm.icon) + " "),
              ])
            : _vm._e(),
          _vm._v(" " + _vm._s(_vm.buttonText) + " "),
        ],
        1
      ),
      _c(
        "input",
        _vm._b(
          {
            ref: "uploader",
            staticClass: "d-none",
            attrs: { type: "file" },
            on: { change: _vm.onFileChanged },
          },
          "input",
          {
            accept: _vm.accept ? _vm.accept : undefined,
            multiple: _vm.multiple ? _vm.multiple : undefined,
          },
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }