var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c(
        "v-card-text",
        {
          class: {
            "ma-0 pa-0": !_vm.$vuetify.breakpoint.mobile,
          },
        },
        [
          _c(
            "v-row",
            {
              class: {
                "flex-column": _vm.$vuetify.breakpoint.mobile,
              },
              attrs: { "no-gutters": "" },
            },
            [
              _c(
                "v-col",
                {
                  staticClass: "d-flex flex-column align-center mt-4",
                  attrs: { cols: _vm.$vuetify.breakpoint.mobile ? 12 : 3 },
                },
                [
                  _c("v-avatar", { attrs: { size: "196" } }, [
                    _c("img", { attrs: { src: _vm.avatarLink } }),
                  ]),
                  _vm.username
                    ? _c("div", { staticClass: "mb-2 mt-1" }, [
                        _vm._v(" " + _vm._s(`@${_vm.username}`) + " "),
                      ])
                    : _vm._e(),
                  _c("FilePicker", {
                    attrs: {
                      accept: "image/*",
                      text: "",
                      "button-text": this.$t("button-change-avatar"),
                      icon: null,
                      width: "100%",
                      multiple: false,
                    },
                    on: { change: _vm.onChangeAvatar },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  class: {
                    "pl-8": !_vm.$vuetify.breakpoint.mobile,
                  },
                  attrs: { cols: _vm.$vuetify.breakpoint.mobile ? 12 : 9 },
                },
                [
                  _c(
                    "v-form",
                    {
                      attrs: { onSubmit: "return false;" },
                      on: {
                        submit: function ($event) {
                          return _vm.onSignup()
                        },
                      },
                    },
                    [
                      _c("p", { staticClass: "overline" }, [
                        _vm._v(_vm._s(_vm.$t("title-account-info"))),
                      ]),
                      _c(
                        "div",
                        [
                          _c("div", { staticClass: "caption" }, [
                            _vm._v(_vm._s(_vm.$t("name"))),
                          ]),
                          _c("v-text-field", {
                            attrs: {
                              label: _vm.$t("label-name"),
                              "prepend-inner-icon": "mdi-account",
                              type: "text",
                              solo: "",
                              outlined: "",
                              dense: "",
                              disabled: _vm.isLoading,
                              "error-messages": _vm.nameErrors,
                              counter: 100,
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.name.$touch()
                              },
                              blur: function ($event) {
                                return _vm.$v.name.$touch()
                              },
                            },
                            model: {
                              value: _vm.$v.name.$model,
                              callback: function ($$v) {
                                _vm.$set(_vm.$v.name, "$model", $$v)
                              },
                              expression: "$v.name.$model",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c("div", { staticClass: "caption" }, [
                            _vm._v(_vm._s(_vm.$t("tax-no"))),
                          ]),
                          _c("v-text-field", {
                            attrs: {
                              label: _vm.$t("label-tax-no"),
                              "prepend-inner-icon": "mdi-briefcase",
                              type: "text",
                              solo: "",
                              outlined: "",
                              dense: "",
                              counter: 25,
                            },
                            model: {
                              value: _vm.$v.taxNo.$model,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.$v.taxNo,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "$v.taxNo.$model",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c("div", { staticClass: "caption" }, [
                            _vm._v(_vm._s(_vm.$t("company-registration-no"))),
                          ]),
                          _c("v-text-field", {
                            attrs: {
                              label: _vm.$t("label-company-registration-no"),
                              "prepend-inner-icon": "mdi-file-sign",
                              type: "text",
                              solo: "",
                              outlined: "",
                              dense: "",
                              counter: 25,
                            },
                            model: {
                              value: _vm.$v.companyRegistrationNo.$model,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.$v.companyRegistrationNo,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "$v.companyRegistrationNo.$model",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c("div", { staticClass: "caption" }, [
                            _vm._v("Website"),
                          ]),
                          _c("v-text-field", {
                            attrs: {
                              label: _vm.$t("label-website"),
                              "prepend-inner-icon": "mdi-web",
                              type: "text",
                              solo: "",
                              outlined: "",
                              dense: "",
                              counter: 100,
                              "error-messages": _vm.websiteErrors,
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.website.$touch()
                              },
                              blur: function ($event) {
                                return _vm.$v.website.$touch()
                              },
                            },
                            model: {
                              value: _vm.$v.website.$model,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.$v.website,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "$v.website.$model",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "mt-4", attrs: { "no-gutters": "" } },
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              staticClass: "px-8",
                              class: {
                                "w-100": _vm.$vuetify.breakpoint.mobile,
                              },
                              attrs: {
                                color: "primary",
                                loading: _vm.isLoading,
                              },
                              on: { click: _vm.onSaveAccountInfo },
                            },
                            [_vm._v(_vm._s(_vm.$t("button-save-change")))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }