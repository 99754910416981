// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../libhome/img/Footer.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".wrapper[data-v-375ccf21] {\n  display: flex;\n  justify-content: center;\n  background: #004e9f url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") left bottom no-repeat;\n  color: #dfdfdf;\n  padding: 30px 0 20px;\n  font-size: 15px;\n}\n.wrapper img[data-v-375ccf21] {\n  max-width: 100%;\n  height: auto;\n  padding: 0 15px;\n}\n.address[data-v-375ccf21] {\n  line-height: 1.6;\n}\n.contact__info[data-v-375ccf21] {\n  display: flex;\n  font-size: 15px;\n  line-height: 1.6;\n}\n.contact__info_item[data-v-375ccf21] {\n  display: flex;\n  align-items: center;\n  margin-right: 10px;\n}", ""]);
// Exports
module.exports = exports;
