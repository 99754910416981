import Vue from 'vue';
import FlagIcon from 'vue-flag-icon';
import VueRx from 'vue-rx';
import Vuelidate from 'vuelidate';
import App from './App.vue';
import i18n from './i18n';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';
import 'swiper/swiper.scss';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import Toast, { POSITION } from 'vue-toastification';
import 'vue-toastification/dist/index.css';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import('dayjs/locale/vi');

import prettyBytes from 'pretty-bytes';

import StatusIndicator from 'vue-status-indicator';

import { Integrations } from '@sentry/tracing';
import * as Sentry from '@sentry/vue';

// Service Worker
import './registerServiceWorker';
import '../src/libhome/css/main.css';

import Antd, {
  Carousel,
  DatePicker,
  Icon,
  Steps,
  Card,
  Row,
  Col,
  Button,
  Pagination,
  Tabs,
} from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import { VueperSlide, VueperSlides } from 'vueperslides';
import 'vueperslides/dist/vueperslides.css';
import * as VueGoogleMaps from 'vue2-google-maps';
Vue.use(Card);
Vue.use(Row);
Vue.use(Col);
Vue.use(Button);
Vue.use(VueperSlides);
Vue.use(VueperSlide);
Vue.use(DatePicker);
Vue.use(Carousel);
Vue.use(Icon);
Vue.use(Steps);
Vue.use(Pagination);
Vue.use(Tabs);
Vue.use(Antd);
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDp3Ud3SixagW8bJOwtosy5bBMB5JRNS_k',
    region: 'VN',
    language: 'vi',
  },
});
Vue.use(Toast, {
  position: POSITION.TOP_CENTER,
  timeout: 1000,
  pauseOnHover: false,
  hideProgressBar: true,
});

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_ENV !== 'local' && process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
  ],
  tracesSampleRate: 1.0,
});

Vue.use(StatusIndicator);

Vue.use(VueAwesomeSwiper);
Vue.use(FlagIcon);
Vue.use(Vuelidate);
Vue.use(VueRx);

Vue.config.productionTip = false;

Vue.prototype.$gapi = window.gapi;

const locale = localStorage.getItem('locale');
if (!locale) {
  localStorage.setItem('locale', 'vi');
  i18n.locale = 'vi';
} else {
  i18n.locale = locale;
}

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');

Vue.config.errorHandler = function (error, vm, info) {
  Sentry.captureException(error);
  if (error?.response?.data?.message) {
    store.dispatch('ui/showSnackbar', {
      message: error.response.data.message,
      color: 'error',
    });
  } else {
    console.log('[LOG] ERROR', error);
    store.dispatch('ui/showSnackbar', {
      message:
        'An error occurred. Please try again!' + `  <br> ${error.toString()}`,
      color: 'error',
    });
  }
};

Vue.prototype.$message = {
  success(text, timeout = 4000) {
    store.dispatch('ui/showSnackbar', {
      message: text,
      color: 'success',
      timeout,
    });
  },
  error(text, timeout = 4000) {
    store.dispatch('ui/showSnackbar', {
      message: text,
      color: 'error',
      timeout,
    });
  },
};

Vue.prototype.$filters = {
  formatDate(value) {
    return dayjs(value).format('DD.MM.YYYY');
  },
  formatTime(value) {
    return dayjs(value).format('HH:mm');
  },
  formatDatetime(value) {
    return dayjs(value).format('HH:mm - DD.MM.YYYY');
  },
  formatRelativeTime(value) {
    dayjs.extend(relativeTime);
    dayjs.locale('vi');
    return dayjs(value).fromNow();
  },
  formatFileSize(value) {
    return prettyBytes(value);
  },
  formatDateSlash(value) {
    return dayjs(value).format('DD/MM/YYYY');
  },
};
