var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "p",
        {
          staticClass:
            "text-h6 font-weight-medium text--secondary text-center mb-8",
        },
        [_vm._v(" Partner ")]
      ),
      _c(
        "swiper",
        { staticClass: "swiper", attrs: { options: _vm.swiperOption } },
        [
          _c("swiper-slide", [
            _c("img", {
              attrs: {
                src: "/assets/img/partner/evergreen.png",
                width: "150px",
                height: "100%",
              },
            }),
          ]),
          _c("swiper-slide", [
            _c("img", {
              attrs: {
                src: "/assets/img/partner/evn.png",
                width: "250px",
                height: "100%",
              },
            }),
          ]),
          _c("swiper-slide", [
            _c("img", {
              attrs: {
                src: "/assets/img/partner/hoaphat.png",
                width: "250px",
                height: "100%",
              },
            }),
          ]),
          _c("swiper-slide", [
            _c("img", {
              attrs: {
                src: "/assets/img/partner/honda.png",
                width: "250px",
                height: "100%",
              },
            }),
          ]),
          _c("swiper-slide", [
            _c("img", {
              attrs: {
                src: "/assets/img/partner/idemitsu.png",
                width: "250px",
                height: "100%",
              },
            }),
          ]),
          _c("swiper-slide", [
            _c("img", {
              attrs: {
                src: "/assets/img/partner/petrovietnam.png",
                width: "150px",
                height: "100%",
              },
            }),
          ]),
          _c("swiper-slide", [
            _c("img", {
              attrs: {
                src: "/assets/img/partner/scg.png",
                width: "150px",
                height: "100%",
              },
            }),
          ]),
          _c("swiper-slide", [
            _c("img", {
              attrs: {
                src: "/assets/img/partner/toyota.png",
                width: "150px",
                height: "100%",
              },
            }),
          ]),
          _c("div", {
            staticClass: "swiper-pagination",
            attrs: { slot: "pagination" },
            slot: "pagination",
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }