<template>
  <v-card>
    <v-card-title>
      <v-row no-gutters>
        <div>{{ $t('title-contact-edit') }}</div>
        <v-spacer />
        <div
          :style="
            $vuetify.breakpoint.mobile ? 'width: 100%; text-align: right' : ''
          "
        >
          <v-btn
            :disabled="isLoading"
            text
            class="mr-2"
            @click="$emit('close-dialog')"
            >{{ $t('button-close') }}</v-btn
          >
          <v-btn
            :disabled="isLoading || $v.$invalid"
            :loading="isLoading"
            color="primary"
            @click="onUpdateContact"
            >{{ $t('button-save-change') }}</v-btn
          >
        </div>
      </v-row>
    </v-card-title>

    <v-card-text>
      <v-form
        @submit="onUpdateContact()"
        onSubmit="return false;"
      >
        <v-row
          no-gutters
          align="center"
        >
          <v-col
            :cols="$vuetify.breakpoint.mobile ? 12 : 6"
            :class="{ 'pr-4': !$vuetify.breakpoint.mobile }"
          >
            <div class="caption">{{ $t('name') }}</div>
            <v-text-field
              v-model="$v.name.$model"
              :label="$t('label-name')"
              prepend-inner-icon="mdi-account"
              type="text"
              outlined
              solo
              dense
              :disabled="isLoading"
              :error-messages="nameErrors"
              :counter="100"
              @input="$v.name.$touch()"
              @blur="$v.name.$touch()"
            />
          </v-col>
          <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 6">
            <div class="caption">{{ $t('phone') }}</div>
            <v-text-field
              v-model.trim="$v.phone.$model"
              :label="$t('label-phone')"
              prepend-inner-icon="mdi-cellphone"
              type="text"
              outlined
              solo
              dense
              :disabled="isLoading"
              :counter="12"
              @input="$v.phone.$touch()"
              @blur="$v.phone.$touch()"
            />
          </v-col>
        </v-row>

        <AddressLocationPicker
          v-model="$v.addressLocation.$model"
          :disabled="isLoading"
          solo
          outlined
          dense
          auto-select-first-district
          :province-caption="$t('province')"
          :district-caption="$t('district')"
          :province-label="$t('label-province')"
          :district-label="$t('label-district')"
          :error-messages="addressLocationErrors($v)"
          @input="$v.addressLocation.$touch()"
          @blur="$v.addressLocation.$touch()"
        />

        <div>
          <div class="caption">{{ $t('address') }}</div>
          <v-text-field
            v-model="$v.address.$model"
            :label="$t('label-address')"
            prepend-inner-icon="mdi-map-marker"
            type="text"
            outlined
            solo
            dense
            :disabled="isLoading"
            :error-messages="addressErrors"
            :counter="200"
            @input="$v.address.$touch()"
            @blur="$v.address.$touch()"
          />
        </div>

        <div class="overline mt-2">{{ $t('title-booking-email') }}</div>
        <v-scroll-y-transition
          group
          leave-absolute
          tag="div"
        >
          <div
            v-for="v in $v.emails.$each.$iter"
            :key="v.$model.id"
            :ref="v.$model.id"
          >
            <div class="caption">Email</div>
            <v-text-field
              v-model.trim="v.email.$model"
              :label="$t('label-email')"
              prepend-inner-icon="mdi-email"
              append-outer-icon="mdi-close"
              type="text"
              outlined
              solo
              dense
              :disabled="isLoading"
              :error-messages="emailsErrors(v)"
              :counter="50"
              @input="v.email.$touch()"
              @blur="v.email.$touch()"
              @click:append-outer="onRemoveEmail(v.$model)"
            />
          </div>
        </v-scroll-y-transition>
        <v-btn
          class="mb-4"
          text
          :ripple="false"
          rounded
          color="primary"
          @click="onAddEmail"
        >
          <v-icon left> mdi-plus </v-icon>
          {{ $t('button-add-email') }}
        </v-btn>
        <v-btn
          :disabled="isLoading || $v.$invalid"
          :loading="isLoading"
          color="primary"
          width="100%"
          @click="onUpdateContact"
          >{{ $t('button-save-change') }}</v-btn
        >
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  required,
  minLength,
  maxLength,
  email,
  sameAs,
} from 'vuelidate/lib/validators';
import { mapActions, mapState } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import AddressLocationPicker from '../../AddressLocationPicker.vue';

export default {
  components: { AddressLocationPicker },
  props: {
    item: {
      type: Object,
    },
  },
  data: () => ({
    isLoading: false,
    name: '',
    phone: '',
    address: '',
    addressLocation: {
      provinceCode: null,
      districtCode: null,
    },
    emails: [
      {
        id: uuidv4(),
        email: '',
      },
    ],
    isDefault: true,
  }),
  validations: {
    phone: {},
    name: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(100),
    },
    address: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(200),
    },
    addressLocation: {
      provinceCode: {
        required,
      },
      districtCode: {
        required,
      },
    },
    emails: {
      required,
      minLength: minLength(1),
      $each: {
        email: {
          email,
          required,
        },
      },
    },
  },
  computed: {
    ...mapState('userContact', ['contactDetail']),
    nameErrors() {
      const errors = [];
      const field = this.$v.name;
      if (!field.$dirty) return errors;
      !field.minLength &&
        errors.push(this.$t('validation-error-name-min-length'));
      !field.maxLength &&
        errors.push(this.$t('validation-error-name-max-length'));
      !field.required && errors.push(this.$t('validation-error-name-required'));
      return errors;
    },
    addressErrors() {
      const errors = [];
      const field = this.$v.address;
      if (!field.$dirty) return errors;
      !field.minLength &&
        errors.push(this.$t('validation-error-address-min-length'));
      !field.maxLength &&
        errors.push(this.$t('validation-error-address-max-length'));
      !field.required &&
        errors.push(this.$t('validation-error-address-required'));
      return errors;
    },
    addressLocationErrors() {
      return (v) => {
        const errors = [];
        const field = v.addressLocation;
        if (!field.$dirty) return errors;
        !field.provinceCode.required &&
          errors.push(this.$t('validation-error-address-location-required'));
        return errors;
      };
    },
    emailsErrors() {
      return (v) => {
        const errors = [];
        const field = v.email;
        if (!field.$dirty) return errors;
        !field.email && errors.push(this.$t('validation-error-email-valid'));
        !field.required &&
          errors.push(this.$t('validation-error-email-required'));
        return errors;
      };
    },
  },
  async created() {
    try {
      this.isLoading = true;
      await this.fetchContactDetail({ id: this.item.id });
      this.name = this.contactDetail.name;
      this.phone = this.contactDetail.phone;
      this.address = this.contactDetail.address;
      this.emails = this.contactDetail.emails;
      this.isDefault = this.contactDetail.isDefault;
      this.addressLocation = {
        provinceCode: this.contactDetail.provinceCode,
        districtCode: this.contactDetail.districtCode,
      };
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    ...mapActions('userContact', [
      'updateContact',
      'fetchContactList',
      'fetchContactDetail',
    ]),
    async onUpdateContact() {
      try {
        this.isLoading = true;
        const form = {
          id: this.contactDetail.id,
          name: this.name,
          phone: this.phone,
          address: this.address,
          emails: this.emails,
          provinceCode: this.addressLocation.provinceCode,
          districtCode: this.addressLocation.districtCode,
        };
        console.log('[LOG] : form', form);
        await this.updateContact(form);
        await this.fetchContactList();
        this.$emit('close-dialog');
      } finally {
        this.isLoading = false;
      }
    },
    onChangeAddressLocation(value) {
      this.addressLocation = value;
    },
    onAddEmail() {
      const newItem = {
        id: uuidv4(),
        email: '',
      };
      this.emails.push(newItem);
      this.scrollToElement(newItem);
    },
    onRemoveEmail(item) {
      if (this.emails.length === 1) {
        this.$message.error(this.$t('validation-error-email-required'));
        return;
      }
      this.emails = this.emails.filter((email) => email.id !== item.id);
    },
    scrollToElement(item) {
      setTimeout(() => {
        const el = this.$refs[item.id];
        if (el) {
          el[0].scrollIntoView({ behavior: 'smooth' });
        }
      }, 150);
    },
  },
};
</script>

<style lang="scss" scoped></style>
