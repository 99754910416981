var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    {
      staticClass: "font-weight-medium",
      staticStyle: { "font-size": "12px" },
      attrs: { "no-gutters": "", align: "center" },
    },
    [
      _c(
        "v-col",
        { staticClass: "d-flex justify-start", attrs: { cols: "2" } },
        [_c("span", { staticClass: "info--text" }, [_vm._v("8 Hours")])]
      ),
      _c(
        "v-col",
        {
          staticClass: "d-flex align-center justify-center",
          attrs: { cols: "10" },
        },
        [
          _c("v-hover", {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ hover }) {
                  return [
                    _c(
                      "v-card",
                      {
                        staticClass: "px-3 py-2",
                        staticStyle: { cursor: "pointer" },
                        attrs: {
                          width: "100%",
                          color: hover ? "secondary" : "background",
                          elevation: hover ? 1 : 0,
                          ripple: true,
                        },
                      },
                      [
                        _c(
                          "v-row",
                          { attrs: { "no-gutters": "" } },
                          [
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "d-flex align-center justify-start",
                                attrs: { cols: "5" },
                              },
                              [
                                _c("flag", { attrs: { iso: "us" } }),
                                _c("span", { staticClass: "pl-3" }, [
                                  _vm._v(" Long Beach "),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "d-flex align-center justify-center",
                                attrs: { cols: "2" },
                              },
                              [_c("v-icon", [_vm._v(" mdi-chevron-right ")])],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "d-flex align-center justify-end",
                                attrs: { cols: "5" },
                              },
                              [
                                _c(
                                  "div",
                                  [
                                    _c("span", { staticClass: "pr-3" }, [
                                      _vm._v("Istanbul"),
                                    ]),
                                    _c("flag", { attrs: { iso: "ca" } }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }