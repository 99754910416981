<template>
  <div>
    <v-row
      no-gutters
      align="center"
      class="mb-2"
    >
      <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 8">
        <div>
          <span class="font-weight-bold">{{ item.name }}</span>
          <span class="mr-2"> | {{ item.phone }}</span>
          <v-chip
            color="error"
            x-small
            v-if="item.isDefault"
            >{{ $t('default') }}</v-chip
          >
        </div>
        <div>
          {{ item.emails.join(', ') }}
        </div>
        <div>
          {{ item.address }}
        </div>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 4">
        <v-row
          no-gutters
          align="center"
          :style="
            !$vuetify.breakpoint.mobile
              ? 'justify-content: space-evenly;'
              : 'justify-content: space-between;'
          "
        >
          <v-btn
            outlined
            color="primary"
            small
            :loading="isLoading"
            :disabled="item.isDefault"
            @click="onSetDefaultContact(item.id)"
          >
            <v-icon left>mdi-check</v-icon>
            {{ $t('set-as-default') }}
          </v-btn>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="orange"
                v-bind="attrs"
                v-on="on"
                @click="$emit('update', item)"
              >
                <v-icon>mdi-circle-edit-outline</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('button-edit') }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="error"
                v-bind="attrs"
                v-on="on"
                @click="$emit('delete', item)"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('button-delete') }}</span>
          </v-tooltip>
        </v-row>
      </v-col>
    </v-row>
    <v-divider />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  props: {
    item: {
      type: Object,
    },
  },
  data: () => ({
    isLoading: false,
  }),
  methods: {
    ...mapActions('userContact', ['fetchContactList', 'setDefaultContact']),
    async onSetDefaultContact(id) {
      try {
        this.isLoading = true;
        await this.setDefaultContact({ id });
        await this.fetchContactList();
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style></style>
