<template>
  <div class="">
    <Snackbar />
    <DialogAuth />
    <DialogUserSettings />
  </div>
</template>

<script>
import DialogAuth from './DialogAuth';
import Snackbar from './Snackbar';
import DialogUserSettings from './DialogUserSettings';

export default {
  components: {
    Snackbar,
    DialogAuth,
    DialogUserSettings,
  },
};
</script>

<style></style>
