var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    [
      _c("NavBarHome", { attrs: { active: "recruitment" } }),
      _c(
        "div",
        {
          staticClass: "carousel slide carousel-fade",
          attrs: {
            id: "carousel-2",
            "data-ride": "carousel",
            "data-interval": "6000",
          },
        },
        [
          _c(
            "div",
            { staticClass: "carousel-inner", attrs: { role: "listbox" } },
            [
              _c("div", { staticClass: "carousel-item active" }, [
                _c("img", {
                  staticClass: "d-block img-fluid",
                  attrs: {
                    src: require("../../../libhome/img/Cảng Hải Phòng - .jpg"),
                    alt: "responsive image",
                  },
                }),
                _c("div", { staticClass: "carousel-caption" }, [
                  _c("div", [
                    _c("div", { staticClass: "transx_content_slide_wrapper" }, [
                      _c("div", { staticClass: "transx_content_container" }, [
                        _c("div", { staticClass: "transx_content_wrapper_1" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "transx_up_heading_overlay transx_overlay_align_center",
                            },
                            [_vm._v(" VIMC LINES ")]
                          ),
                          _c(
                            "h2",
                            { staticClass: "transx_content_slider_title" },
                            [_vm._v(" " + _vm._s(_vm.$t("recruitment")) + " ")]
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "transx_page_content_wrapper",
          staticStyle: { "background-color": "#ffffff" },
        },
        [
          _c("div", { staticClass: "recruitment" }, [
            _c("div", { staticClass: "container" }, [
              _c("div", { staticClass: "row align-items-center" }, [
                _c("section", { staticClass: "recruitment-list" }, [
                  _c("div", { staticClass: "container" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-12" }, [
                        _c(
                          "h2",
                          {
                            staticClass: "label",
                            attrs: { id: "recruitment-1" },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("recPosition")) + " ")]
                        ),
                        _vm._m(0),
                        _c("div", { staticClass: "list-jobs" }, [
                          _c(
                            "ul",
                            {
                              staticClass: "nav nav-tabs",
                              attrs: { id: "myTab", role: "tablist" },
                            },
                            [
                              _c("li", { staticClass: "nav-item" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "nav-link-recruitment active",
                                    attrs: {
                                      id: "all-tab",
                                      "data-toggle": "tab",
                                      href: "#all",
                                      role: "tab",
                                      "aria-controls": "all",
                                      "aria-selected": "true",
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("all")) + " ")]
                                ),
                              ]),
                              _c("li", { staticClass: "nav-item" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "nav-link-recruitment",
                                    attrs: {
                                      id: "khoi-cong-nghe-tab",
                                      "data-toggle": "tab",
                                      href: "#khoi-cong-nghe",
                                      role: "tab",
                                      "aria-controls": "khoi-cong-nghe",
                                      "aria-selected": "true",
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("bussDepart")) + " ")]
                                ),
                              ]),
                              _c("li", { staticClass: "nav-item" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "nav-link-recruitment",
                                    attrs: {
                                      id: "khoi-kinh-doanh-tab",
                                      "data-toggle": "tab",
                                      href: "#khoi-kinh-doanh",
                                      role: "tab",
                                      "aria-controls": "khoi-kinh-doanh",
                                      "aria-selected": "true",
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("acountDepart")) + " ")]
                                ),
                              ]),
                              _c("li", { staticClass: "nav-item" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "nav-link-recruitment",
                                    attrs: {
                                      id: "khoi-van-phong-tab",
                                      "data-toggle": "tab",
                                      href: "#khoi-van-phong",
                                      role: "tab",
                                      "aria-controls": "khoi-van-phong",
                                      "aria-selected": "true",
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("itDepart")) + " ")]
                                ),
                              ]),
                              _c("li", { staticClass: "nav-item" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "nav-link-recruitment",
                                    attrs: {
                                      id: "khoi-nhan-su-tab",
                                      "data-toggle": "tab",
                                      href: "#khoi-nhan-su",
                                      role: "tab",
                                      "aria-controls": "khoi-nhan-su",
                                      "aria-selected": "true",
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("HRD")) + " ")]
                                ),
                              ]),
                            ]
                          ),
                          _c("div", { staticClass: "tab-content" }, [
                            _c(
                              "div",
                              {
                                staticClass: "tab-pane fade show active",
                                attrs: { id: "all" },
                              },
                              [
                                _c("div", { staticClass: "scroll" }, [
                                  _c("div", { staticClass: "row" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-12 col-md-6 recruitment-item",
                                        staticStyle: { display: "block" },
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: "/recruitment/detail/1",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "job-item" },
                                              [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass: "name",
                                                    staticStyle: {
                                                      color: "#000000",
                                                      "font-weight": "700",
                                                      "font-size": "15px",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t("bussEmployee")
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "row" },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-5" },
                                                      [
                                                        _vm._m(1),
                                                        _c("div", {
                                                          staticStyle: {
                                                            "padding-bottom":
                                                              "5px",
                                                          },
                                                        }),
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "location-recruitment",
                                                          },
                                                          [
                                                            _c("img", {
                                                              attrs: {
                                                                src: require("../../../libhome/img/location.svg"),
                                                              },
                                                            }),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  this.$i18n
                                                                    .locale ==
                                                                    "en"
                                                                    ? "Hanoi, Hai Phong, Ho Chi Minh"
                                                                    : `Hà Nội, Hải Phòng, Hồ Chí Minh`
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        _c("div", {
                                                          staticStyle: {
                                                            "padding-bottom":
                                                              "10px",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-6" },
                                                      [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "salary",
                                                          },
                                                          [
                                                            _c("img", {
                                                              attrs: {
                                                                src: require("../../../libhome/img/salary.svg"),
                                                              },
                                                            }),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "agreement"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        _c("div", {
                                                          staticStyle: {
                                                            "padding-bottom":
                                                              "5px",
                                                          },
                                                        }),
                                                        _vm._m(2),
                                                      ]
                                                    ),
                                                    _vm._m(3),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-12 col-md-6 recruitment-item",
                                        staticStyle: { display: "block" },
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: "/recruitment/detail/2",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "job-item" },
                                              [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass: "name",
                                                    staticStyle: {
                                                      color: "#000000",
                                                      "font-weight": "700",
                                                      "font-size": "15px",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "genAccountEmploy"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "row" },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-5" },
                                                      [
                                                        _vm._m(4),
                                                        _c("div", {
                                                          staticStyle: {
                                                            "padding-bottom":
                                                              "5px",
                                                          },
                                                        }),
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "location-recruitment",
                                                          },
                                                          [
                                                            _c("img", {
                                                              attrs: {
                                                                src: require("../../../libhome/img/location.svg"),
                                                              },
                                                            }),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "location"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        _c("div", {
                                                          staticStyle: {
                                                            "padding-bottom":
                                                              "10px",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-6" },
                                                      [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "salary",
                                                          },
                                                          [
                                                            _c("img", {
                                                              attrs: {
                                                                src: require("../../../libhome/img/salary.svg"),
                                                              },
                                                            }),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "agreement"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        _c("div", {
                                                          staticStyle: {
                                                            "padding-bottom":
                                                              "5px",
                                                          },
                                                        }),
                                                        _vm._m(5),
                                                      ]
                                                    ),
                                                    _vm._m(6),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-12 col-md-6 recruitment-item",
                                        staticStyle: { display: "block" },
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: "/recruitment/detail/3",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "job-item" },
                                              [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass: "name",
                                                    staticStyle: {
                                                      color: "#000000",
                                                      "font-weight": "700",
                                                      "font-size": "15px",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t("itEmployee")
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "row" },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-5" },
                                                      [
                                                        _vm._m(7),
                                                        _c("div", {
                                                          staticStyle: {
                                                            "padding-bottom":
                                                              "5px",
                                                          },
                                                        }),
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "location-recruitment",
                                                          },
                                                          [
                                                            _c("img", {
                                                              attrs: {
                                                                src: require("../../../libhome/img/location.svg"),
                                                              },
                                                            }),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "location"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        _c("div", {
                                                          staticStyle: {
                                                            "padding-bottom":
                                                              "10px",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-6" },
                                                      [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "salary",
                                                          },
                                                          [
                                                            _c("img", {
                                                              attrs: {
                                                                src: require("../../../libhome/img/salary.svg"),
                                                              },
                                                            }),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "agreement"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        _c("div", {
                                                          staticStyle: {
                                                            "padding-bottom":
                                                              "5px",
                                                          },
                                                        }),
                                                        _vm._m(8),
                                                      ]
                                                    ),
                                                    _vm._m(9),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-12 col-md-6 recruitment-item",
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: "/recruitment/detail/4",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "job-item" },
                                              [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass: "name",
                                                    staticStyle: {
                                                      color: "#000000",
                                                      "font-weight": "700",
                                                      "font-size": "15px",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t("techEngineer")
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "row" },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-5" },
                                                      [
                                                        _vm._m(10),
                                                        _c("div", {
                                                          staticStyle: {
                                                            "padding-bottom":
                                                              "5px",
                                                          },
                                                        }),
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "location-recruitment",
                                                          },
                                                          [
                                                            _c("img", {
                                                              attrs: {
                                                                src: require("../../../libhome/img/location.svg"),
                                                              },
                                                            }),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "location"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        _c("div", {
                                                          staticStyle: {
                                                            "padding-bottom":
                                                              "10px",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-6" },
                                                      [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "salary",
                                                          },
                                                          [
                                                            _c("img", {
                                                              attrs: {
                                                                src: require("../../../libhome/img/salary.svg"),
                                                              },
                                                            }),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "agreement"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        _c("div", {
                                                          staticStyle: {
                                                            "padding-bottom":
                                                              "5px",
                                                          },
                                                        }),
                                                        _vm._m(11),
                                                      ]
                                                    ),
                                                    _vm._m(12),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-12 col-md-6 recruitment-item",
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: "/recruitment/detail/5",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "job-item" },
                                              [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass: "name",
                                                    staticStyle: {
                                                      color: "#000000",
                                                      "font-weight": "700",
                                                      "font-size": "15px",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(_vm.$t("HRC")) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "row" },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-5" },
                                                      [
                                                        _vm._m(13),
                                                        _c("div", {
                                                          staticStyle: {
                                                            "padding-bottom":
                                                              "5px",
                                                          },
                                                        }),
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "location-recruitment",
                                                          },
                                                          [
                                                            _c("img", {
                                                              attrs: {
                                                                src: require("../../../libhome/img/location.svg"),
                                                              },
                                                            }),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "location"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        _c("div", {
                                                          staticStyle: {
                                                            "padding-bottom":
                                                              "10px",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-6" },
                                                      [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "salary",
                                                          },
                                                          [
                                                            _c("img", {
                                                              attrs: {
                                                                src: require("../../../libhome/img/salary.svg"),
                                                              },
                                                            }),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "agreement"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        _c("div", {
                                                          staticStyle: {
                                                            "padding-bottom":
                                                              "5px",
                                                          },
                                                        }),
                                                        _vm._m(14),
                                                      ]
                                                    ),
                                                    _vm._m(15),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-12 col-md-6 recruitment-item",
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: "/recruitment/detail/6",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "job-item" },
                                              [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass: "name",
                                                    staticStyle: {
                                                      color: "#000000",
                                                      "font-weight": "700",
                                                      "font-size": "15px",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t("AO-VME")
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "row" },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-5" },
                                                      [
                                                        _vm._m(16),
                                                        _c("div", {
                                                          staticStyle: {
                                                            "padding-bottom":
                                                              "5px",
                                                          },
                                                        }),
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "location-recruitment",
                                                          },
                                                          [
                                                            _c("img", {
                                                              attrs: {
                                                                src: require("../../../libhome/img/location.svg"),
                                                              },
                                                            }),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "location"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        _c("div", {
                                                          staticStyle: {
                                                            "padding-bottom":
                                                              "10px",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-6" },
                                                      [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "salary",
                                                          },
                                                          [
                                                            _c("img", {
                                                              attrs: {
                                                                src: require("../../../libhome/img/salary.svg"),
                                                              },
                                                            }),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "agreement"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        _c("div", {
                                                          staticStyle: {
                                                            "padding-bottom":
                                                              "5px",
                                                          },
                                                        }),
                                                        _vm._m(17),
                                                      ]
                                                    ),
                                                    _vm._m(18),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                                _vm._m(19),
                                _vm._m(20),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "tab-pane fade",
                                attrs: { id: "khoi-cong-nghe" },
                              },
                              [
                                _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-md-6" },
                                    [
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href: "/recruitment/detail/1",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "job-item" },
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticClass: "name",
                                                  staticStyle: {
                                                    color: "#000000",
                                                    "font-weight": "700",
                                                    "font-size": "15px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t("bussEmployee")
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "col-5" },
                                                    [
                                                      _vm._m(21),
                                                      _c("div", {
                                                        staticStyle: {
                                                          "padding-bottom":
                                                            "5px",
                                                        },
                                                      }),
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "location-recruitment",
                                                        },
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              src: require("../../../libhome/img/location.svg"),
                                                            },
                                                          }),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                this.$i18n
                                                                  .locale ==
                                                                  "en"
                                                                  ? "Hanoi, Hai Phong, Ho Chi Minh"
                                                                  : `Hà Nội, Hải Phòng, Hồ Chí Minh`
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                      _c("div", {
                                                        staticStyle: {
                                                          "padding-bottom":
                                                            "10px",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "col-6" },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass: "salary",
                                                        },
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              src: require("../../../libhome/img/salary.svg"),
                                                            },
                                                          }),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "agreement"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                      _c("div", {
                                                        staticStyle: {
                                                          "padding-bottom":
                                                            "5px",
                                                        },
                                                      }),
                                                      _vm._m(22),
                                                    ]
                                                  ),
                                                  _vm._m(23),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "tab-pane fade",
                                attrs: { id: "khoi-kinh-doanh" },
                              },
                              [
                                _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-md-6" },
                                    [
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href: "/recruitment/detail/2",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "job-item" },
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticClass: "name",
                                                  staticStyle: {
                                                    color: "#000000",
                                                    "font-weight": "700",
                                                    "font-size": "15px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "genAccountEmploy"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "col-5" },
                                                    [
                                                      _vm._m(24),
                                                      _c("div", {
                                                        staticStyle: {
                                                          "padding-bottom":
                                                            "5px",
                                                        },
                                                      }),
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "location-recruitment",
                                                        },
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              src: require("../../../libhome/img/location.svg"),
                                                            },
                                                          }),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "location"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                      _c("div", {
                                                        staticStyle: {
                                                          "padding-bottom":
                                                            "10px",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "col-6" },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass: "salary",
                                                        },
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              src: require("../../../libhome/img/salary.svg"),
                                                            },
                                                          }),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "agreement"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                      _c("div", {
                                                        staticStyle: {
                                                          "padding-bottom":
                                                            "5px",
                                                        },
                                                      }),
                                                      _vm._m(25),
                                                    ]
                                                  ),
                                                  _vm._m(26),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-md-6" },
                                    [
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href: "/recruitment/detail/6",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "job-item" },
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticClass: "name",
                                                  staticStyle: {
                                                    color: "#000000",
                                                    "font-weight": "700",
                                                    "font-size": "15px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.$t("AO-VME")) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "col-5" },
                                                    [
                                                      _vm._m(27),
                                                      _c("div", {
                                                        staticStyle: {
                                                          "padding-bottom":
                                                            "5px",
                                                        },
                                                      }),
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "location-recruitment",
                                                        },
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              src: require("../../../libhome/img/location.svg"),
                                                            },
                                                          }),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "location"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                      _c("div", {
                                                        staticStyle: {
                                                          "padding-bottom":
                                                            "10px",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "col-6" },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass: "salary",
                                                        },
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              src: require("../../../libhome/img/salary.svg"),
                                                            },
                                                          }),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "agreement"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                      _c("div", {
                                                        staticStyle: {
                                                          "padding-bottom":
                                                            "5px",
                                                        },
                                                      }),
                                                      _vm._m(28),
                                                    ]
                                                  ),
                                                  _vm._m(29),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "tab-pane fade",
                                attrs: { id: "khoi-van-phong" },
                              },
                              [
                                _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-md-6" },
                                    [
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href: "/recruitment/detail/3",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "job-item" },
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticClass: "name",
                                                  staticStyle: {
                                                    color: "#000000",
                                                    "font-weight": "700",
                                                    "font-size": "15px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t("itEmployee")
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "col-5" },
                                                    [
                                                      _vm._m(30),
                                                      _c("div", {
                                                        staticStyle: {
                                                          "padding-bottom":
                                                            "5px",
                                                        },
                                                      }),
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "location-recruitment",
                                                        },
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              src: require("../../../libhome/img/location.svg"),
                                                            },
                                                          }),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "location"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                      _c("div", {
                                                        staticStyle: {
                                                          "padding-bottom":
                                                            "10px",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "col-6" },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass: "salary",
                                                        },
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              src: require("../../../libhome/img/salary.svg"),
                                                            },
                                                          }),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "agreement"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                      _c("div", {
                                                        staticStyle: {
                                                          "padding-bottom":
                                                            "5px",
                                                        },
                                                      }),
                                                      _vm._m(31),
                                                    ]
                                                  ),
                                                  _vm._m(32),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-md-6" },
                                    [
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href: "/recruitment/detail/4",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "job-item" },
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticClass: "name",
                                                  staticStyle: {
                                                    color: "#000000",
                                                    "font-weight": "700",
                                                    "font-size": "15px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t("techEngineer")
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "col-5" },
                                                    [
                                                      _vm._m(33),
                                                      _c("div", {
                                                        staticStyle: {
                                                          "padding-bottom":
                                                            "5px",
                                                        },
                                                      }),
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "location-recruitment",
                                                        },
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              src: require("../../../libhome/img/location.svg"),
                                                            },
                                                          }),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "location"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                      _c("div", {
                                                        staticStyle: {
                                                          "padding-bottom":
                                                            "10px",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "col-6" },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass: "salary",
                                                        },
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              src: require("../../../libhome/img/salary.svg"),
                                                            },
                                                          }),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "agreement"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                      _c("div", {
                                                        staticStyle: {
                                                          "padding-bottom":
                                                            "5px",
                                                        },
                                                      }),
                                                      _vm._m(34),
                                                    ]
                                                  ),
                                                  _vm._m(35),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "tab-pane fade",
                                attrs: { id: "khoi-nhan-su" },
                              },
                              [
                                _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-md-6" },
                                    [
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href: "/recruitment/detail/5",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "job-item" },
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticClass: "name",
                                                  staticStyle: {
                                                    color: "#000000",
                                                    "font-weight": "700",
                                                    "font-size": "15px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.$t("HRC")) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "col-5" },
                                                    [
                                                      _vm._m(36),
                                                      _c("div", {
                                                        staticStyle: {
                                                          "padding-bottom":
                                                            "5px",
                                                        },
                                                      }),
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "location-recruitment",
                                                        },
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              src: require("../../../libhome/img/location.svg"),
                                                            },
                                                          }),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "location"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                      _c("div", {
                                                        staticStyle: {
                                                          "padding-bottom":
                                                            "10px",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "col-6" },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass: "salary",
                                                        },
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              src: require("../../../libhome/img/salary.svg"),
                                                            },
                                                          }),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "agreement"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                      _c("div", {
                                                        staticStyle: {
                                                          "padding-bottom":
                                                            "5px",
                                                        },
                                                      }),
                                                      _vm._m(37),
                                                    ]
                                                  ),
                                                  _vm._m(38),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "image-library" }, [
                          _c(
                            "div",
                            {
                              staticClass: "label",
                              attrs: { id: "recruitment-2" },
                            },
                            [
                              _c("h2", [_vm._v(_vm._s(_vm.$t("libPhoto")))]),
                              _vm._m(39),
                            ]
                          ),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "curriculum-vitae",
                            attrs: { id: "recruitment-3" },
                          },
                          [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-12 col-md-6" }, [
                                _c("div", { staticClass: "text" }, [
                                  _c("p", {
                                    ref: "apply",
                                    staticClass: "label",
                                    domProps: {
                                      innerHTML: _vm._s(_vm.$t("recApply")),
                                    },
                                  }),
                                  _c("p", { staticClass: "desc" }, [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("reacContent1")) + " "
                                    ),
                                  ]),
                                  _c("p", { staticClass: "desc" }, [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("reacContent2")) + " "
                                    ),
                                  ]),
                                  _c("p", {
                                    staticClass: "support-line",
                                    domProps: {
                                      innerHTML: _vm._s(_vm.$t("reacContent3")),
                                    },
                                  }),
                                ]),
                              ]),
                              _c("div", { staticClass: "col-12 col-md-6" }, [
                                _c("div", { staticClass: "form" }, [
                                  _c(
                                    "form",
                                    {
                                      ref: "form",
                                      on: {
                                        submit: function ($event) {
                                          $event.preventDefault()
                                          return _vm.sendEmail.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("input", {
                                        attrs: {
                                          type: "hidden",
                                          name: "_token",
                                          value:
                                            "tUJCQfTUaRbmxLqP62LxN27j10jiTMOFtrcgwl11",
                                        },
                                      }),
                                      _c("input", {
                                        attrs: {
                                          type: "password",
                                          name: "password",
                                          value: "",
                                          hidden: "",
                                        },
                                      }),
                                      _c("div", { staticClass: "form-group" }, [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.form.name,
                                              expression: "form.name",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            type: "text",
                                            name: "name",
                                            placeholder:
                                              this.$i18n.locale === "en"
                                                ? "Full Name"
                                                : "Họ và tên",
                                          },
                                          domProps: { value: _vm.form.name },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                _vm.form,
                                                "name",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]),
                                      _c("div", { staticClass: "form-group" }, [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.form.email,
                                              expression: "form.email",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            type: "email",
                                            name: "email",
                                            placeholder: "Email",
                                          },
                                          domProps: { value: _vm.form.email },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                _vm.form,
                                                "email",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]),
                                      _c("div", { staticClass: "form-group" }, [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.form.phone,
                                              expression: "form.phone",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            type: "text",
                                            name: "phone",
                                            placeholder: _vm.$t("phone"),
                                          },
                                          domProps: { value: _vm.form.phone },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                _vm.form,
                                                "phone",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]),
                                      _c("div", { staticClass: "form-group" }, [
                                        _c(
                                          "select",
                                          {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.form.position,
                                                expression: "form.position",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              name: "recruitment_id",
                                              placeholder:
                                                _vm.$t("recPosition"),
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$selectedVal =
                                                  Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function (o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function (o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                _vm.$set(
                                                  _vm.form,
                                                  "position",
                                                  $event.target.multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "option",
                                              {
                                                attrs: {
                                                  value: "",
                                                  disabled: "",
                                                  selected: "",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      this.$i18n.locale === "en"
                                                        ? "Apply for the position"
                                                        : "Ứng tuyển vào vị trí này"
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "option",
                                              {
                                                domProps: {
                                                  value: _vm.$t("bussDepart"),
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t("bussDepart")
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "option",
                                              {
                                                domProps: {
                                                  value: _vm.$t("acountDepart"),
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t("acountDepart")
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "option",
                                              {
                                                domProps: {
                                                  value: _vm.$t("itDepart"),
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.$t("itDepart")) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "option",
                                              {
                                                domProps: {
                                                  value: _vm.$t("HRD"),
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.$t("HRD")) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex justify-content-between sp-column",
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "add-cv",
                                              attrs: { for: "upload_cv" },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "fa fa-file",
                                              }),
                                              _vm._v(
                                                _vm._s(_vm.$t("uploadCV"))
                                              ),
                                            ]
                                          ),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: !_vm.isHidden,
                                                expression: "!isHidden",
                                              },
                                            ],
                                            attrs: {
                                              type: "file",
                                              name: "cv",
                                              id: "upload_cv",
                                            },
                                            on: {
                                              change: _vm.handleFileUpload,
                                            },
                                          }),
                                          _c(
                                            "button",
                                            {
                                              staticClass: "send",
                                              attrs: {
                                                type: "submit",
                                                disabled: _vm.isLoading,
                                              },
                                            },
                                            [
                                              _vm.isLoading
                                                ? _c("span", [
                                                    _c("i", {
                                                      staticClass:
                                                        "fa fa-spinner fa-spin",
                                                    }),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t("sendCV")
                                                        ) +
                                                        " "
                                                    ),
                                                  ])
                                                : _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t("sendCV")
                                                        ) +
                                                        " "
                                                    ),
                                                  ]),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm.selectedFile
                                        ? _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                margin: "5px 0 0 20px",
                                                "font-size": "16px",
                                                color: "#fff",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.selectedFile.name)
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c("div", { staticClass: "alert" }),
                                    ]
                                  ),
                                ]),
                              ]),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
      _c("FooterHome"),
      _vm._m(40),
      _c("NavBarLeftHome"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "search-job" }, [
      _c("form", { attrs: { action: "", name: "search-job", method: "" } }, [
        _c("input", {
          attrs: {
            type: "text",
            id: "search",
            "data-url": "#",
            placeholder: "Search position",
          },
        }),
        _c("button", [_c("i", { staticClass: "fa fa-search" })]),
      ]),
      _c("ul", { attrs: { id: "seach-result" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "deadline" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
      _vm._v(" 15-8-2024 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "worktime" }, [
      _c("i", { staticClass: "fa fa-clock" }),
      _vm._v(" 8h00 - 17h00 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-1 pr-0" }, [
      _c("span", { staticClass: "fa fa-angle-right" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "deadline" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
      _vm._v(" 15-8-2024 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "worktime" }, [
      _c("i", { staticClass: "fa fa-clock" }),
      _vm._v(" 8h00 - 17h00 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-1 pr-0" }, [
      _c("span", { staticClass: "fa fa-angle-right" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "deadline" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
      _vm._v(" 15-8-2024 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "worktime" }, [
      _c("i", { staticClass: "fa fa-clock" }),
      _vm._v(" 8h00 - 17h00 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-1 pr-0" }, [
      _c("span", { staticClass: "fa fa-angle-right" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "deadline" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
      _vm._v(" 15-7-2024 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "worktime" }, [
      _c("i", { staticClass: "fa fa-clock" }),
      _vm._v(" 8h00 - 17h00 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-1 pr-0" }, [
      _c("span", { staticClass: "fa fa-angle-right" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "deadline" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
      _vm._v(" 15-8-2024 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "worktime" }, [
      _c("i", { staticClass: "fa fa-clock" }),
      _vm._v(" 8h00 - 17h00 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-1 pr-0" }, [
      _c("span", { staticClass: "fa fa-angle-right" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "deadline" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
      _vm._v(" 15-8-2024 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "worktime" }, [
      _c("i", { staticClass: "fa fa-clock" }),
      _vm._v(" 8h00 - 17h00 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-1 pr-0" }, [
      _c("span", { staticClass: "fa fa-angle-right" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "watch-more",
        staticStyle: { visibility: "hidden" },
        attrs: { id: "watchMore" },
      },
      [
        _c("a", { staticStyle: { color: "black" }, attrs: { href: "#" } }, [
          _vm._v("Show more"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "watch-more",
        staticStyle: { display: "none" },
        attrs: { id: "close" },
      },
      [_c("a", { attrs: { href: "#" } }, [_vm._v("Thu gọn")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "deadline" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
      _vm._v(" 15-8-2024 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "worktime" }, [
      _c("i", { staticClass: "fa fa-clock" }),
      _vm._v(" 8h00 - 17h00 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-1 pr-0" }, [
      _c("span", { staticClass: "fa fa-angle-right" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "deadline" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
      _vm._v(" 15-8-2024 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "worktime" }, [
      _c("i", { staticClass: "fa fa-clock" }),
      _vm._v(" 8h00 - 17h00 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-1 pr-0" }, [
      _c("span", { staticClass: "fa fa-angle-right" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "deadline" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
      _vm._v(" 15-8-2024 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "worktime" }, [
      _c("i", { staticClass: "fa fa-clock" }),
      _vm._v(" 8h00 - 17h00 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-1 pr-0" }, [
      _c("span", { staticClass: "fa fa-angle-right" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "deadline" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
      _vm._v(" 15-8-2024 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "worktime" }, [
      _c("i", { staticClass: "fa fa-clock" }),
      _vm._v(" 8h00 - 17h00 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-1 pr-0" }, [
      _c("span", { staticClass: "fa fa-angle-right" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "deadline" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
      _vm._v(" 15-07-2024 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "worktime" }, [
      _c("i", { staticClass: "fa fa-clock" }),
      _vm._v(" 8h00 - 17h00 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-1 pr-0" }, [
      _c("span", { staticClass: "fa fa-angle-right" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "deadline" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
      _vm._v(" 15-8-2024 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "worktime" }, [
      _c("i", { staticClass: "fa fa-clock" }),
      _vm._v(" 8h00 - 17h00 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-1 pr-0" }, [
      _c("span", { staticClass: "fa fa-angle-right" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "images",
        staticStyle: {
          padding: "10px 10px 20px 10px",
          width: "1167px",
          display: "flex",
          "align-items": "center",
          "justify-content": "center",
        },
      },
      [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-3 p-0" }, [
            _c("div", { staticClass: "d-flex flex-wrap h-100" }, [
              _c("img", {
                staticClass: "mb-2",
                attrs: {
                  src: "https://vimc.co/wp-content/uploads/2024/04/VIMC-Lines_1.4.24.jpg",
                  alt: "Image Item",
                },
              }),
              _c("img", {
                attrs: {
                  src: "https://vimc.co/wp-content/uploads/2024/04/VIMC-Lines_1.4.24-5.jpg",
                  alt: "Image Item",
                },
              }),
            ]),
          ]),
          _c("div", { staticClass: "col-6 pr-2 pl-2" }, [
            _c("img", {
              staticClass: "h-100",
              attrs: {
                src: "https://vimc.co/wp-content/uploads/2024/01/VIMC_Logistics-Hai-Duong.jpg",
                alt: "Image Item",
              },
            }),
          ]),
          _c("div", { staticClass: "col-3 p-0" }, [
            _c("div", { staticClass: "d-flex flex-wrap h-100" }, [
              _c("img", {
                staticClass: "mb-2",
                attrs: {
                  src: "https://vimc.co/wp-content/uploads/2024/04/VIMC-Lines_1.4.24-3.jpg",
                  alt: "Image Item",
                },
              }),
              _c("img", {
                attrs: {
                  src: "https://vimc.co/wp-content/uploads/2023/01/VIMC_tau-1.23.jpg",
                  alt: "Image Item",
                },
              }),
            ]),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { staticClass: "back-to-top", attrs: { href: "/home" } }, [
      _c("i", { staticClass: "fa fa-chevron-up" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }