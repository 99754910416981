import { vslApi, vslApi1 } from '@/api';

export default {
  getContainerTrackingInfo({ params }) {
    return vslApi.get(`/v1/container-tracking`, {
      params: params,
    });
  },
  getContainerNumbers({ params }) {
    return vslApi.get(`/v1/containers/no`, {
      params: params,
    });
  },
  getBookingNumbers(params) {
    return vslApi.get(`/v1/container-tracking/container_no`, {
      params: params,
    });
  },
  getUpdateTable(params) {
    return vslApi.get(`/v1/container-movements`, {
      params: params,
    });
  },
};
