var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    [
      _c("NavBarHome", { attrs: { active: "recruitment" } }),
      _c("div", {
        staticStyle: { height: "170px", "background-color": "#ffffff" },
      }),
      _c(
        "div",
        {
          staticClass: "transx_page_content_wrapper",
          staticStyle: { "background-color": "#ffffff" },
        },
        [
          _c("div", { staticClass: "recruitment" }, [
            _c("div", { staticClass: "container" }, [
              _c("div", { staticClass: "label" }, [
                _c("h3", [_vm._v(_vm._s(_vm.$t("HRC")))]),
                _c("p", [
                  _c("i", { staticClass: "fa fa-calendar" }),
                  _vm._v(" " + _vm._s(_vm.$t("appDeadline")) + ": 15-8-2024 "),
                  _c(
                    "button",
                    {
                      attrs: { id: "button" },
                      on: { click: _vm.scrollToApplySection },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("sendCV")) + " ")]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "common-info" }, [
                _c("h5", [_vm._v(_vm._s(_vm.$t("overview")))]),
                _c("div", { staticClass: "list-info row" }, [
                  _c("div", { staticClass: "item col-6 d-flex" }, [
                    _vm._m(0),
                    _c(
                      "div",
                      {
                        staticClass: "text",
                        staticStyle: {
                          "flex-direction": "column",
                          display: "flex",
                          "align-items": "flex-start",
                        },
                      },
                      [
                        _c("span", { staticStyle: { "font-weight": "700" } }, [
                          _vm._v(
                            _vm._s(
                              this.$i18n.locale === "en"
                                ? "Salary"
                                : "Mức lương"
                            )
                          ),
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              this.$i18n.locale === "en"
                                ? "Agreement"
                                : "Thỏa thuận"
                            )
                          ),
                        ]),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "item col-6 d-flex" }, [
                    _vm._m(1),
                    _c(
                      "div",
                      {
                        staticClass: "text",
                        staticStyle: {
                          "flex-direction": "column",
                          display: "flex",
                          "align-items": "flex-start",
                        },
                      },
                      [
                        _c("span", { staticStyle: { "font-weight": "700" } }, [
                          _vm._v(_vm._s(_vm.$t("workSchedule"))),
                        ]),
                        _c("span", [_vm._v("8h00 - 17h00")]),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "item col-6 d-flex" }, [
                    _vm._m(2),
                    _c(
                      "div",
                      {
                        staticClass: "text",
                        staticStyle: {
                          "flex-direction": "column",
                          display: "flex",
                          "align-items": "flex-start",
                        },
                      },
                      [
                        _c("span", { staticStyle: { "font-weight": "700" } }, [
                          _vm._v(_vm._s(_vm.$t("gender"))),
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              this.$i18n.locale == "en"
                                ? "Male/Female"
                                : "Nam/Nữ"
                            )
                          ),
                        ]),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "item col-6 d-flex" }, [
                    _vm._m(3),
                    _c(
                      "div",
                      {
                        staticClass: "text",
                        staticStyle: {
                          "flex-direction": "column",
                          display: "flex",
                          "align-items": "flex-start",
                        },
                      },
                      [
                        _c("span", { staticStyle: { "font-weight": "700" } }, [
                          _vm._v(
                            _vm._s(
                              this.$i18n.locale == "en"
                                ? "Recruitment Number"
                                : "Số lượng tuyển dụng"
                            )
                          ),
                        ]),
                        _c("span", [_vm._v("1")]),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "item col-6 d-flex" }, [
                    _vm._m(4),
                    _c(
                      "div",
                      {
                        staticClass: "text",
                        staticStyle: {
                          "flex-direction": "column",
                          display: "flex",
                          "align-items": "flex-start",
                        },
                      },
                      [
                        _c("span", { staticStyle: { "font-weight": "700" } }, [
                          _vm._v(
                            _vm._s(
                              this.$i18n.locale === "en" ? "Level" : "Cấp bậc"
                            ) + " "
                          ),
                        ]),
                        _c("span", [_vm._v(_vm._s(_vm.$t("employyee")))]),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "item col-6 d-flex" }, [
                    _vm._m(5),
                    _c(
                      "div",
                      {
                        staticClass: "text",
                        staticStyle: {
                          "flex-direction": "column",
                          display: "flex",
                          "align-items": "flex-start",
                        },
                      },
                      [
                        _c("span", { staticStyle: { "font-weight": "700" } }, [
                          _vm._v(_vm._s(_vm.$t("experience")) + " "),
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              this.$i18n.locale == "en"
                                ? "Have Experience"
                                : "Có kinh Nghiệm"
                            )
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "address" }, [
                _c("h5", { staticStyle: { "font-weight": "700" } }, [
                  _vm._v(_vm._s(_vm.$t("workLocation"))),
                ]),
                _c("ul", [
                  _c("li", [
                    _vm._v(" " + _vm._s(_vm.$t("addressDetail")) + " "),
                  ]),
                  _c("li", [
                    _vm._v(
                      " " +
                        _vm._s(
                          this.$i18n.locale == "en"
                            ? `No. 1 Dao Duy Anh Street, Phuong Mai Ward, Dong Da District, Hanoi`
                            : "Số 01 Đào Duy Anh, Phương Mai, quận Đống Đa, Hà Nội."
                        ) +
                        " "
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "job-desc" }, [
                _c("h2", { staticStyle: { "text-align": "justify" } }, [
                  _c("span", { staticStyle: { "font-size": "16pt" } }, [
                    _c(
                      "span",
                      { staticStyle: { "font-family": "'Times New Roman'" } },
                      [
                        _c("span", { staticStyle: { color: "#000000" } }, [
                          _vm._v(_vm._s(_vm.$t("jobDesc"))),
                        ]),
                      ]
                    ),
                  ]),
                ]),
                _c("ul", [
                  _c("li", { staticStyle: { "list-style-type": "disc" } }, [
                    _c("span", { staticStyle: { "font-size": "12pt" } }, [
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'Times New Roman'" } },
                        [
                          _c("span", { staticStyle: { color: "#000000" } }, [
                            _vm._v(
                              _vm._s(
                                this.$i18n.locale === "en"
                                  ? `Participation in Building and Streamlining Organizational Structure and Management Regulations of the Company:`
                                  : "Tham gia xây dựng, kiện toàn cơ cấu tổ chức và xây dựng các quy định, quy chế quản lý của Công ty:"
                              )
                            ),
                          ]),
                          _c("ul", [
                            _c("li", [
                              _c(
                                "span",
                                { staticStyle: { "font-size": "12pt" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "'Times New Roman'",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#000000" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              this.$i18n.locale === "en"
                                                ? `Participate in researching, analyzing, and proposing organizational structure and human resource development strategies suitable to the company's development orientation in each period.`
                                                : "Tham gia nghiên cứu, phân tích và đề xuất phương án cơ cấu tổ chức, chiến lược phát triển nhân sự của Công ty phù hợp với định hướng phát triển Công ty trong từng thời kỳ."
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "span",
                                { staticStyle: { "font-size": "12pt" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "'Times New Roman'",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#000000" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              this.$i18n.locale === "en"
                                                ? `Participate in researching, analyzing, and proposing organizational structure plans and human resource development strategies in line with the company's development direction at each period.`
                                                : "Tham gia xây dựng và trình ban hành quy chế tổ chức và hoạt động của các đơn vị, điều lệ tổ chức và hoạt động của Công ty, chức năng nhiệm vụ các phòng ban."
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "span",
                                { staticStyle: { "font-size": "12pt" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "'Times New Roman'",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#000000" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              this.$i18n.locale === "en"
                                                ? `Implement and participate in disseminating, guiding, and supervising the application of management improvements, regulations, and policies in the company's departments and units.`
                                                : "Triển khai và tham gia phổ biến, hướng dẫn và giám sát việc áp dụng các cải tiến, quy định, chính sách quản lý tại các phòng ban, đơn vị thuộc Công ty."
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("li", { staticStyle: { "list-style-type": "disc" } }, [
                    _c("span", { staticStyle: { "font-size": "12pt" } }, [
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'Times New Roman'" } },
                        [
                          _c("span", { staticStyle: { color: "#000000" } }, [
                            _vm._v(
                              _vm._s(
                                this.$i18n.locale === "en"
                                  ? `In Charge of Planning, Mobilizing, Appointing, and Reappointing Staff:`
                                  : "Phụ trách công tác quy hoạch, điều động, bổ nhiệm, bổ nhiệm lại cán bộ:"
                              ) + " "
                            ),
                          ]),
                          _c("ul", [
                            _c("li", [
                              _c(
                                "span",
                                { staticStyle: { "font-size": "12pt" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "'Times New Roman'",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#000000" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              this.$i18n.locale === "en"
                                                ? `Participate in researching, advising, and proposing planning and organizational structures for management, operational, and production business units of the company.`
                                                : "Tham gia nghiên cứu, tham mưu và đề xuất phương án quy hoạch, tổ chức bộ máy quản lý điều hành, tổ chức bộ máy sản xuất kinh doanh của Công ty."
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "span",
                                { staticStyle: { "font-size": "12pt" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "'Times New Roman'",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#000000" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              this.$i18n.locale === "en"
                                                ? `Participate in staff allocation for the company and its units/departments, carrying out procedures for mobilizing, appointing, and reappointing staff according to regulations.`
                                                : "Tham gia công tác bố trí cán bộ của Công ty và các đơn vị/bộ phận, thực hiện các thủ tục điều động, bổ nhiệm, bổ nhiệm lại cán bộ theo quy định."
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("li", { staticStyle: { "list-style-type": "disc" } }, [
                    _c("span", { staticStyle: { "font-size": "12pt" } }, [
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'Times New Roman'" } },
                        [
                          _c("span", { staticStyle: { color: "#000000" } }, [
                            _vm._v(
                              _vm._s(
                                this.$i18n.locale === "en"
                                  ? `Implementation of Recruitment and Training for Office Workers:`
                                  : `Triển khai thực hiện công tác tuyển dụng, đào tạo cho lao động tại văn phòng Công ty:`
                              ) + " "
                            ),
                          ]),
                          _c("ul", [
                            _c("li", [
                              _c(
                                "span",
                                { staticStyle: { "font-size": "12pt" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "'Times New Roman'",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#000000" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              this.$i18n.locale === "en"
                                                ? `Gather and determine recruitment and training needs from departments, centers, and units within the company; develop annual recruitment and training plans.`
                                                : "Triển khai tổng hợp và xác định và tổng hợp nhu cầu tuyển dụng, đào tạo của các phòng ban, trung tâm, đơn vị thuộc Công ty; Xây dựng kế hoạch tuyển dụng, đào tạo hàng năm."
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "span",
                                { staticStyle: { "font-size": "12pt" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "'Times New Roman'",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#000000" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              this.$i18n.locale === "en"
                                                ? `Implement recruitment processes in accordance with company procedures and regulations; handle labor reception procedures, sign probationary and labor contracts.`
                                                : "Triển khai thực hiện công tác tuyển dụng lao động theo đúng quy trình, quy định của Công ty; thực hiện các thủ tục tiếp nhận lao động, ký kết hợp đồng thử việc, hợp đồng lao động."
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "span",
                                { staticStyle: { "font-size": "12pt" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "'Times New Roman'",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#000000" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              this.$i18n.locale === "en"
                                                ? `Search for suitable training units and coordinate with training units to organize courses as per the plan and arising needs.`
                                                : "Triển khai tìm kiếm đơn vị đào tạo phù hợp và phối hợp với các đơn vị đào tạo tổ chức các khóa đào tạo theo kế hoạch và theo nhu cầu phát sinh."
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "span",
                                { staticStyle: { "font-size": "12pt" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "'Times New Roman'",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#000000" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              this.$i18n.locale === "en"
                                                ? `Monitor training content implementation according to the plan, supervise training quality according to approved objectives and content.`
                                                : "Theo dõi triển khai các nội dung đào tạo theo kế hoạch, giám sát chất lượng đào tạo theo đúng mục tiêu và nội dung đã được duyệt."
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "span",
                                { staticStyle: { "font-size": "12pt" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "'Times New Roman'",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#000000" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              this.$i18n.locale === "en"
                                                ? `Organize recording, storage, and aggregation of information for reporting, feedback, and evaluation regarding training content, issues needing development/improvement/adjustment, and policies related to training implementation.`
                                                : "Tổ chức ghi nhận, lưu trữ, tổng hợp thông tin để báo cáo, phản hồi, đánh giá về nội dung đào tạo, các vấn đề cần xây dựng/hoàn thiện/điều chỉnh nội dung đào tạo, các chế độ chính sách liên quan đến việc triển khai đào tạo."
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "span",
                                { staticStyle: { "font-size": "12pt" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "'Times New Roman'",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#000000" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              this.$i18n.locale === "en"
                                                ? `Provide related feedback information as a basis for evaluating training performance and establishing annual training plans.`
                                                : "Cung cấp thông tin phản hồi có liên quan để làm cơ sở đánh giá kết quả thực hiện đào tạo và xác lập kế hoạch đào tạo hàng năm."
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "span",
                                { staticStyle: { "font-size": "12pt" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "'Times New Roman'",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#000000" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              this.$i18n.locale === "en"
                                                ? `Compile and report on recruitment and training performance of the company.`
                                                : "Tổ chức tổng hợp, lập báo cáo kết quả thực hiện công tác tuyển dụng, đào tạo của Công ty."
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("li", { staticStyle: { "list-style-type": "disc" } }, [
                    _c("span", { staticStyle: { "font-size": "12pt" } }, [
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'Times New Roman'" } },
                        [
                          _c("span", { staticStyle: { color: "#000000" } }, [
                            _vm._v(
                              _vm._s(
                                this.$i18n.locale === "en"
                                  ? `Implementation of Training for Employees on Occupational Safety and Hygiene:`
                                  : "Triển khai công tác đào tạo, huấn luyện cho người lao động của Công ty về công tác an toàn vệ sinh lao động:"
                              ) + " "
                            ),
                          ]),
                          _c("ul", [
                            _c("li", [
                              _c(
                                "span",
                                { staticStyle: { "font-size": "12pt" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "'Times New Roman'",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#000000" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              this.$i18n.locale === "en"
                                                ? `Develop and update policies and regulations on occupational safety and hygiene (OSH) as per legal requirements and implement monthly, quarterly, and annual OSH plans.`
                                                : "Triển khai xây dựng, cập nhật các chính sách, quy định về an toàn vệ sinh lao động (ATVSLĐ) của Công ty theo quy định của pháp luật và tổ chức thực hiện kế hoạch ATVSLĐ tháng, quý, năm."
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "span",
                                { staticStyle: { "font-size": "12pt" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "'Times New Roman'",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#000000" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              this.$i18n.locale === "en"
                                                ? `Guide, urge, check, and supervise units to comply with OSH regulations; document violations according to regulations.`
                                                : "Triển khai hướng dẫn, đôn đốc, kiểm tra, giám sát các đơn vị thực hiện các quy định về ATVSLĐ; Lập biên bản đối với các trường hợp vi phạm theo đúng quy định."
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "span",
                                { staticStyle: { "font-size": "12pt" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "'Times New Roman'",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#000000" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              this.$i18n.locale === "en"
                                                ? `Direct and participate in investigating, proposing solutions, completing files, and handling procedures when labor accidents occur according to legal regulations; complete files for labor accident benefits.`
                                                : "Chỉ đạo và tham gia điều tra, đề xuất giải quyết, hoàn thiện hồ sơ, thủ tục khi xảy ra tai nạn lao động theo quy định của pháp luật; hoàn thiện các hồ sơ giải quyết chế độ tai nạn lao động."
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "span",
                                { staticStyle: { "font-size": "12pt" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "'Times New Roman'",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#000000" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              this.$i18n.locale === "en"
                                                ? `Forecast, procure, and distribute personal protective equipment for employees.`
                                                : "Triển khai lập dự trù, mua sắm và thực hiện cấp phát trang thiết bị, phương tiện bảo vệ cá nhân cho người lao động."
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("li", { staticStyle: { "list-style-type": "disc" } }, [
                    _c("span", { staticStyle: { "font-size": "12pt" } }, [
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'Times New Roman'" } },
                        [
                          _c("span", { staticStyle: { color: "#000000" } }, [
                            _vm._v(
                              _vm._s(
                                this.$i18n.locale === "en"
                                  ? `Implementation of Emulation, Reward, and Anti-Corruption Practices:`
                                  : "Triển khai thực hiện công tác Thi đua khen thưởng, Thực hành TKCLP, Phòng chống tham nhũng:"
                              ) + " "
                            ),
                          ]),
                          _c("ul", [
                            _c("li", [
                              _c(
                                "span",
                                { staticStyle: { "font-size": "12pt" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "'Times New Roman'",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#000000" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              this.$i18n.locale === "en"
                                                ? `Develop and submit for approval emulation and reward regulations in line with company policies and legal requirements.`
                                                : "Tham gia xây dựng, trình ban hành quy chế thi đua khen thưởng theo đúng chính sách của Công ty và quy định của pháp luật."
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "span",
                                { staticStyle: { "font-size": "12pt" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "'Times New Roman'",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#000000" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              this.$i18n.locale === "en"
                                                ? `Disseminate, guide, and periodically check the implementation of emulation and reward practices to ensure compliance with company and state regulations.`
                                                : "Phổ biến, hướng dẫn và theo dõi, kiểm tra định kỳ các đơn vị thực hiện công tác thi đua khen thưởng đảm bảo theo đúng quy định của Công ty và nhà nước."
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "span",
                                { staticStyle: { "font-size": "12pt" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "'Times New Roman'",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#000000" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              this.$i18n.locale === "en"
                                                ? `Disseminate, guide, and periodically check the implementation of emulation and reward practices to ensure compliance with company and state regulations.`
                                                : "Tham mưu, đề xuất các hình thức khen thưởng của cấp có thẩm quyền cho các tập thể, cá nhân trong công ty."
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "span",
                                { staticStyle: { "font-size": "12pt" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "'Times New Roman'",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#000000" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              this.$i18n.locale === "en"
                                                ? `Compile and review reward nomination files for collectives and individuals, ensuring compliance; complete files for submission to competent authorities for rewards.`
                                                : "Tổng hợp hồ sơ đề nghị khen thưởng của các tập thể, cá nhân được đề nghị khen thưởng. Rà soát và đề nghị tập thể, cá nhân thực hiện đúng theo quy định. Hoàn thiện hồ sơ đề nghị cấp có thẩm quyền khen thưởng."
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "span",
                                { staticStyle: { "font-size": "12pt" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "'Times New Roman'",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#000000" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              this.$i18n.locale === "en"
                                                ? `Compile, monitor, and summarize emulation and reward statistics for collectives and individuals; prepare annual summary reports and set key tasks for the following year.`
                                                : "Thống kê, theo dõi tổng hợp số liệu khen thưởng của tập thể, cá nhân. Lập báo cáo tổng kết công tác thi đua, khen thưởng hàng năm, nhiệm vụ trọng tâm năm tới."
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "span",
                                { staticStyle: { "font-size": "12pt" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "'Times New Roman'",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#000000" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              this.$i18n.locale === "en"
                                                ? `Compile data and information from units, analyze, review, and prepare reports and plans for practicing thrift and combating waste.`
                                                : "Tổng hợp số liệu và thông tin từ các đơn vị, phân tích và rà soát, đối chiếu số liệu và lập báo cáo và kế hoạch thực hành tiết kiệm, chống lãng phí."
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "span",
                                { staticStyle: { "font-size": "12pt" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "'Times New Roman'",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#000000" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              this.$i18n.locale === "en"
                                                ? `Perform annual asset and income declaration tasks.`
                                                : "Thực hiện công tác kê khai tài sản thu nhập hàng năm."
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("li", { staticStyle: { "list-style-type": "disc" } }, [
                    _c("span", { staticStyle: { "font-size": "12pt" } }, [
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'Times New Roman'" } },
                        [
                          _c("span", { staticStyle: { color: "#000000" } }, [
                            _vm._v(
                              _vm._s(
                                this.$i18n.locale === "en"
                                  ? `Supporting Department Head in Managing and Supervising Department Staff:`
                                  : "Hỗ trợ Trưởng phòng quản lý, điều hành công việc của nhân viên trong phòng:"
                              ) + " "
                            ),
                          ]),
                          _c("ul", [
                            _c("li", [
                              _c(
                                "span",
                                { staticStyle: { "font-size": "12pt" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "'Times New Roman'",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#000000" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              this.$i18n.locale === "en"
                                                ? `Determine human resource needs, propose to the Department Head, and participate in recruitment and training.`
                                                : "Xác định nhu cầu nhân sự, đề xuất Trưởng phòng và tham gia thực hiện tuyển dụng, đào tạo."
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "span",
                                { staticStyle: { "font-size": "12pt" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "'Times New Roman'",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#000000" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              this.$i18n.locale === "en"
                                                ? `Assist the Department Head in planning, setting goals, assigning tasks, and guiding staff in task execution.`
                                                : "Hỗ trợ Trưởng phòng lập kế hoạch, xác định mục tiêu và phân công công việc, hướng dẫn nhân viên thực hiện công việc."
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "span",
                                { staticStyle: { "font-size": "12pt" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "'Times New Roman'",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#000000" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              this.$i18n.locale === "en"
                                                ? `Support the Department Head in monitoring, supervising, and evaluating staff performance.`
                                                : "Hỗ trợ Trưởng phòng theo dõi, giám sát quá trình thực hiện và đánh giá kết quả thực hiện công việc của nhân viên. "
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "span",
                                { staticStyle: { "font-size": "12pt" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "'Times New Roman'",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#000000" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              this.$i18n.locale === "en"
                                                ? `Propose rewards, penalties, and motivational measures for staff.`
                                                : "Tham gia đề xuất khen thưởng, phạt và biện pháp động viên, khuyến khích nhân viên."
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("li", { staticStyle: { "list-style-type": "disc" } }, [
                    _c("span", { staticStyle: { "font-size": "12pt" } }, [
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'Times New Roman'" } },
                        [
                          _c("span", { staticStyle: { color: "#000000" } }, [
                            _vm._v(
                              _vm._s(
                                this.$i18n.locale === "en"
                                  ? `Perform Other Responsibilities:`
                                  : "Thực hiện các trách nhiệm công việc khác:"
                              ) + " "
                            ),
                          ]),
                          _c("ul", [
                            _c("li", [
                              _c(
                                "span",
                                { staticStyle: { "font-size": "12pt" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "'Times New Roman'",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#000000" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              this.$i18n.locale === "en"
                                                ? `Participate in developing and improving regulations, processes, and specialized procedures related to the assigned tasks.`
                                                : "Tham gia xây dựng cải tiến các quy chế, quy định, quy trình chuyên môn liên quan đến công tác phụ trách."
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "span",
                                { staticStyle: { "font-size": "12pt" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "'Times New Roman'",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#000000" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              this.$i18n.locale === "en"
                                                ? `Participate in meetings, discussions, and conferences as required.`
                                                : "Tham gia các hội nghị, họp, thảo luận theo đề nghị."
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "span",
                                { staticStyle: { "font-size": "12pt" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "'Times New Roman'",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#000000" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              this.$i18n.locale === "en"
                                                ? `Comply with planning and reporting regulations.`
                                                : "Thực hiện đúng chế độ lập kế hoạch và báo cáo theo quy định."
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "span",
                                { staticStyle: { "font-size": "12pt" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "'Times New Roman'",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#000000" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              this.$i18n.locale === "en"
                                                ? `Comply with planning and reporting regulations.`
                                                : "Thực hiện các công việc khác theo chỉ đạo, yêu cầu của Trưởng phòng."
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                ]),
                _c("h2", { staticStyle: { "text-align": "justify" } }, [
                  _c("span", { staticStyle: { "font-size": "16pt" } }, [
                    _c(
                      "span",
                      { staticStyle: { "font-family": "'Times New Roman'" } },
                      [
                        _c("span", { staticStyle: { color: "#000000" } }, [
                          _vm._v(_vm._s(_vm.$t("requirement"))),
                        ]),
                      ]
                    ),
                  ]),
                ]),
                _c("ul", [
                  _c("li", { staticStyle: { "list-style-type": "disc" } }, [
                    _c("span", { staticStyle: { "font-size": "12pt" } }, [
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'Times New Roman'" } },
                        [
                          _c("span", { staticStyle: { color: "#000000" } }, [
                            _vm._v(
                              _vm._s(
                                this.$i18n.locale === "en"
                                  ? `Education: Bachelor's degree or equivalent or higher in Labor Economics/Human Resource Management and related fields.`
                                  : "Trình độ: Đại học hoặc tương đương trở lên - Chuyên ngành: Kinh tế lao động/Quản trị nhân lực và các chuyên ngành liên quan."
                              )
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("li", { staticStyle: { "list-style-type": "disc" } }, [
                    _c("span", { staticStyle: { "font-size": "12pt" } }, [
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'Times New Roman'" } },
                        [
                          _c("span", { staticStyle: { color: "#000000" } }, [
                            _vm._v(
                              _vm._s(
                                this.$i18n.locale === "en"
                                  ? `Experience: At least 6 years of experience in HR work, including 2 years in management roles.`
                                  : "Kinh nghiệm: Tối thiểu 06 năm kinh nghiệm thực hiện công tác nhân sự, trong đó có 02 năm kinh nghiệm tham gia công tác quản lý."
                              )
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("li", { staticStyle: { "list-style-type": "disc" } }, [
                    _c("span", { staticStyle: { "font-size": "12pt" } }, [
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'Times New Roman'" } },
                        [
                          _c("span", { staticStyle: { color: "#000000" } }, [
                            _vm._v(
                              _vm._s(
                                this.$i18n.locale === "en"
                                  ? `Professional Competence: `
                                  : "Năng lực chuyên môn: "
                              )
                            ),
                          ]),
                          _c("ul", [
                            _c("li", [
                              _c(
                                "span",
                                { staticStyle: { "font-size": "12pt" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "'Times New Roman'",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#000000" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              this.$i18n.locale === "en"
                                                ? `Solid understanding of the knowledge and methods for organizing HR functions within an organization (workforce planning, recruitment, training, labor incentives, evaluation, etc.).`
                                                : "Nắm vững các kiến thức và phương pháp tổ chức các chức năng quản trị nhân sự trong tổ chức (lập kế hoạch nhân sự, tuyển dụng, đào tạo, đãi ngộ lao động, đánh giá….)."
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "span",
                                { staticStyle: { "font-size": "12pt" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "'Times New Roman'",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#000000" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              this.$i18n.locale === "en"
                                                ? `Thorough knowledge of Labor Law, Social Insurance Law, and other relevant legal documents and regulations related to labor management and company policies.`
                                                : "Nắm vững các quy định của Luật lao động, Luật bảo hiểm,… và các văn bản, quy định có liên quan đến quản lý lao động, chế độ chính sách trong doanh nghiệp."
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "span",
                                { staticStyle: { "font-size": "12pt" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "'Times New Roman'",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#000000" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              this.$i18n.locale === "en"
                                                ? `Comprehensive understanding of the company's organizational structure, the functions and tasks of the responsible unit, and related units. Familiarity with the organizational structure of similar entities in the same industry.`
                                                : "Nắm vững về cơ cấu tổ chức Công ty, chức năng nhiệm vụ đơn vị phụ trách và chức năng nhiệm vụ các đơn vị có liên quan. Nắm được cơ cấu tổ chức của các đơn vị tương tự cùng ngành nghề."
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "span",
                                { staticStyle: { "font-size": "12pt" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "'Times New Roman'",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#000000" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              this.$i18n.locale === "en"
                                                ? `Mastery of the processes and regulations related to HR tasks.`
                                                : "Nắm vững hệ thống các quy trình, quy định liên quan đến công tác nhân sự phụ trách."
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "span",
                                { staticStyle: { "font-size": "12pt" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "'Times New Roman'",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#000000" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              this.$i18n.locale === "en"
                                                ? `Knowledge of the company's safety, quality, environmental protection, and maritime labor management systems.`
                                                : "Có hiểu biết về Hệ thống quản lý an toàn, chất lượng, bảo vệ môi trường và lao động hàng hải của Công ty."
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("li", { staticStyle: { "list-style-type": "disc" } }, [
                    _c("span", { staticStyle: { "font-size": "12pt" } }, [
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'Times New Roman'" } },
                        [
                          _c("span", { staticStyle: { color: "#000000" } }, [
                            _vm._v(
                              _vm._s(
                                this.$i18n.locale === "en"
                                  ? `Supplementary Competence:`
                                  : "Năng lực bổ trợ:"
                              )
                            ),
                          ]),
                          _c("ul", [
                            _c("li", [
                              _c(
                                "span",
                                { staticStyle: { "font-size": "12pt" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "'Times New Roman'",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#000000" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              this.$i18n.locale === "en"
                                                ? `Planning and organizational skills, ability to build and promote a culture of cooperation.`
                                                : "Kỹ năng lập kế hoạch và tổ chức thực hiện công việc, xây dựng và thúc đẩy văn hóa hợp tác."
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "span",
                                { staticStyle: { "font-size": "12pt" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "'Times New Roman'",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#000000" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              this.$i18n.locale === "en"
                                                ? `Strong communication, negotiation, presentation, and persuasion skills..`
                                                : "Kỹ năng giao tiếp, đàm phán, thuyết trình, thuyết phục."
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "span",
                                { staticStyle: { "font-size": "12pt" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "'Times New Roman'",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#000000" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              this.$i18n.locale === "en"
                                                ? `Decision-making and problem-solving abilities.`
                                                : "Kỹ năng ra quyết định và giải quyết vấn đề."
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "span",
                                { staticStyle: { "font-size": "12pt" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "'Times New Roman'",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#000000" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              this.$i18n.locale === "en"
                                                ? `Skills in guiding, training, and mentoring employees.`
                                                : "Kỹ năng hướng dẫn, đào tạo, kèm cặp nhân viên."
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "span",
                                { staticStyle: { "font-size": "12pt" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "'Times New Roman'",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#000000" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              this.$i18n.locale === "en"
                                                ? `Analytical skills and risk management capabilities.`
                                                : "Kỹ năng phân tích, quản lý rủi ro."
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "span",
                                { staticStyle: { "font-size": "12pt" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "'Times New Roman'",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#000000" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              this.$i18n.locale === "en"
                                                ? `Relationship building and development skills.`
                                                : "Kỹ năng xây dựng và phát triển quan hệ."
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "span",
                                { staticStyle: { "font-size": "12pt" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "'Times New Roman'",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#000000" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              this.$i18n.locale === "en"
                                                ? `Strategic thinking, the ability to initiate and drive change.`
                                                : "Tư duy chiến lược, khả năng khởi xướng và thúc đẩy những thay đổi."
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                            _c("li", [
                              _c(
                                "span",
                                { staticStyle: { "font-size": "12pt" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "'Times New Roman'",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#000000" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              this.$i18n.locale === "en"
                                                ? `Talent identification, motivation, and utilization skills.`
                                                : "Kỹ năng phát hiện, khơi dậy, động viên và sử dụng nhân tài."
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("li", { staticStyle: { "list-style-type": "disc" } }, [
                    _c("span", { staticStyle: { "font-size": "12pt" } }, [
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'Times New Roman'" } },
                        [
                          _c("span", { staticStyle: { color: "#000000" } }, [
                            _vm._v(
                              _vm._s(
                                this.$i18n.locale === "en"
                                  ? `Proficiency in English (listening, speaking, reading, writing) for conducting transactions and researching documents related to the field.`
                                  : "Có khả năng sử dụng tiếng Anh ở các kỹ năng nghe - nói - đọc - viết để thực hiện các giao dịch, nghiên cứu tài liệu liên quan đến lĩnh vực đảm nhiệm."
                              )
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("li", { staticStyle: { "list-style-type": "disc" } }, [
                    _c("span", { staticStyle: { "font-size": "12pt" } }, [
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'Times New Roman'" } },
                        [
                          _c("span", { staticStyle: { color: "#000000" } }, [
                            _vm._v(
                              _vm._s(
                                this.$i18n.locale === "en"
                                  ? `Proficient in using MS Office software, internal management software (if any), and information searching on the Internet.`
                                  : "Thành thạo sử dụng phần mềm văn phòng MS office, phần mềm quản trị tại nội bộ (nếu có) và tìm kiếm thông tin trên Internet."
                              ) + " "
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                ]),
                _c("h2", { staticStyle: { "text-align": "justify" } }, [
                  _c("span", { staticStyle: { "font-size": "16pt" } }, [
                    _c(
                      "span",
                      { staticStyle: { "font-family": "'Times New Roman'" } },
                      [
                        _c("span", { staticStyle: { color: "#000000" } }, [
                          _vm._v(_vm._s(_vm.$t("benefits"))),
                        ]),
                      ]
                    ),
                  ]),
                ]),
                _c("ul", [
                  _c("li", { staticStyle: { "list-style-type": "disc" } }, [
                    _c("span", { staticStyle: { "font-size": "12pt" } }, [
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'Times New Roman'" } },
                        [
                          _c("span", { staticStyle: { color: "#000000" } }, [
                            _vm._v(_vm._s(_vm.$t("benef1"))),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("li", { staticStyle: { "list-style-type": "disc" } }, [
                    _c("span", { staticStyle: { "font-size": "12pt" } }, [
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'Times New Roman'" } },
                        [
                          _c("span", { staticStyle: { color: "#000000" } }, [
                            _vm._v(_vm._s(_vm.$t("benef2"))),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("li", { staticStyle: { "list-style-type": "disc" } }, [
                    _c("span", { staticStyle: { "font-size": "12pt" } }, [
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'Times New Roman'" } },
                        [
                          _c("span", { staticStyle: { color: "#000000" } }, [
                            _vm._v(_vm._s(_vm.$t("benef3"))),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("li", { staticStyle: { "list-style-type": "disc" } }, [
                    _c("span", { staticStyle: { "font-size": "12pt" } }, [
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'Times New Roman'" } },
                        [
                          _c("span", { staticStyle: { color: "#000000" } }, [
                            _vm._v(_vm._s(_vm.$t("benef4"))),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("li", { staticStyle: { "list-style-type": "disc" } }, [
                    _c("span", { staticStyle: { "font-size": "12pt" } }, [
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'Times New Roman'" } },
                        [
                          _c("span", { staticStyle: { color: "#000000" } }, [
                            _vm._v(_vm._s(_vm.$t("benef5"))),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("li", { staticStyle: { "list-style-type": "disc" } }, [
                    _c("span", { staticStyle: { "font-size": "12pt" } }, [
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'Times New Roman'" } },
                        [
                          _c("span", { staticStyle: { color: "#000000" } }, [
                            _vm._v(_vm._s(_vm.$t("benef6"))),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("li", { staticStyle: { "list-style-type": "disc" } }, [
                    _c("span", { staticStyle: { "font-size": "12pt" } }, [
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'Times New Roman'" } },
                        [
                          _c("span", { staticStyle: { color: "#000000" } }, [
                            _vm._v(_vm._s(_vm.$t("benef7"))),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                ]),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-style": "italic",
                      "text-align": "justify",
                      "font-size": "16px",
                      "font-family": "'Times New Roman'",
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("benefPara")) + " ")]
                ),
                _c("h2", { staticStyle: { "text-align": "justify" } }, [
                  _c("span", { staticStyle: { "font-size": "16pt" } }, [
                    _c(
                      "span",
                      { staticStyle: { "font-family": "'Times New Roman'" } },
                      [
                        _c("span", { staticStyle: { color: "#000000" } }, [
                          _vm._v(_vm._s(_vm.$t("workSchedule"))),
                        ]),
                      ]
                    ),
                  ]),
                ]),
                _c("p", { staticStyle: { "text-align": "justify" } }, [
                  _c("span", { staticStyle: { "font-size": "12pt" } }, [
                    _c(
                      "span",
                      { staticStyle: { "font-family": "'Times New Roman'" } },
                      [
                        _c("span", { staticStyle: { color: "#000000" } }, [
                          _vm._v(_vm._s(_vm.$t("montoFri"))),
                        ]),
                      ]
                    ),
                  ]),
                ]),
                _c("ul", [
                  _c("li", { staticStyle: { "list-style-type": "disc" } }, [
                    _c("span", { staticStyle: { "font-size": "12pt" } }, [
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'Times New Roman'" } },
                        [
                          _c("span", { staticStyle: { color: "#000000" } }, [
                            _vm._v(_vm._s(_vm.$t("morning")) + ": "),
                          ]),
                        ]
                      ),
                    ]),
                    _vm._m(6),
                  ]),
                  _c("li", { staticStyle: { "list-style-type": "disc" } }, [
                    _c("span", { staticStyle: { "font-size": "12pt" } }, [
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'Times New Roman'" } },
                        [
                          _c("span", { staticStyle: { color: "#000000" } }, [
                            _vm._v(
                              _vm._s(_vm.$t("afternoon")) + ": 13h00- 17h00"
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                ]),
                _c("h2", { staticStyle: { "text-align": "justify" } }, [
                  _c("span", { staticStyle: { "font-size": "16pt" } }, [
                    _c(
                      "span",
                      { staticStyle: { "font-family": "'Times New Roman'" } },
                      [
                        _c("span", { staticStyle: { color: "#000000" } }, [
                          _vm._v(_vm._s(_vm.$t("recruitDoc"))),
                        ]),
                      ]
                    ),
                  ]),
                ]),
                _c("ul", [
                  _c("li", { staticStyle: { "list-style-type": "disc" } }, [
                    _c("span", { staticStyle: { "font-size": "12pt" } }, [
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'Times New Roman'" } },
                        [
                          _c("span", { staticStyle: { color: "#000000" } }, [
                            _vm._v(_vm._s(_vm.$t("recruit1"))),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("li", { staticStyle: { "list-style-type": "disc" } }, [
                    _c("span", { staticStyle: { "font-size": "12pt" } }, [
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'Times New Roman'" } },
                        [
                          _c("span", { staticStyle: { color: "#000000" } }, [
                            _vm._v(_vm._s(_vm.$t("recruit2")) + " "),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                ]),
                _c("h2", { staticStyle: { "text-align": "justify" } }, [
                  _c("span", { staticStyle: { "font-size": "16pt" } }, [
                    _c(
                      "span",
                      { staticStyle: { "font-family": "'Times New Roman'" } },
                      [
                        _c("span", { staticStyle: { color: "#000000" } }, [
                          _vm._v(_vm._s(_vm.$t("methodApply"))),
                        ]),
                      ]
                    ),
                  ]),
                ]),
                _c("ul", [
                  _c("li", { staticStyle: { "list-style-type": "disc" } }, [
                    _c("span", { staticStyle: { "font-size": "12pt" } }, [
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'Times New Roman'" } },
                        [
                          _c("span", { staticStyle: { color: "#000000" } }, [
                            _c(
                              "li",
                              { staticStyle: { "list-style-type": "disc" } },
                              [
                                _c(
                                  "span",
                                  { staticStyle: { "font-size": "12pt" } },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "font-family": "'Times New Roman'",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticStyle: { color: "#000000" } },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("method1")) + " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("li", { staticStyle: { "list-style-type": "disc" } }, [
                    _c("span", { staticStyle: { "font-size": "12pt" } }, [
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'Times New Roman'" } },
                        [
                          _c("span", { staticStyle: { color: "#000000" } }, [
                            _c(
                              "li",
                              { staticStyle: { "list-style-type": "disc" } },
                              [
                                _c(
                                  "span",
                                  { staticStyle: { "font-size": "12pt" } },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "font-family": "'Times New Roman'",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticStyle: { color: "#000000" } },
                                          [
                                            _c("div", {
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.$t("method2")
                                                ),
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                ]),
                _c("h2", { staticStyle: { "text-align": "justify" } }, [
                  _c("span", { staticStyle: { "font-size": "16pt" } }, [
                    _c(
                      "span",
                      { staticStyle: { "font-family": "'Times New Roman'" } },
                      [
                        _c("span", { staticStyle: { color: "#000000" } }, [
                          _vm._v(_vm._s(_vm.$t("contactInfo"))),
                        ]),
                      ]
                    ),
                  ]),
                ]),
                _c("ul", [
                  _c("li", { staticStyle: { "list-style-type": "disc" } }, [
                    _c("span", { staticStyle: { "font-size": "12pt" } }, [
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'Times New Roman'" } },
                        [
                          _c("span", { staticStyle: { color: "#000000" } }, [
                            _vm._v(_vm._s(_vm.$t("contactInfo1"))),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("li", { staticStyle: { "list-style-type": "disc" } }, [
                    _c("span", { staticStyle: { "font-size": "12pt" } }, [
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'Times New Roman'" } },
                        [
                          _c("span", { staticStyle: { color: "#000000" } }, [
                            _vm._v(_vm._s(_vm.$t("contactInfo2")) + " "),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._m(7),
                ]),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-style": "italic",
                      "text-align": "justify",
                      "font-size": "16px",
                      "font-family": "'Times New Roman'",
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("contactPara")) + " ")]
                ),
                _c("span", {}),
              ]),
              _c("div", { staticClass: "another-job" }, [
                _c("h3", [_vm._v(_vm._s(_vm.$t("otherJobAvailable")))]),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-12 col-md-6" }, [
                    _c("a", { attrs: { href: "/recruitment/detail/2" } }, [
                      _c("div", { staticClass: "job-item" }, [
                        _c(
                          "p",
                          {
                            staticClass: "name",
                            staticStyle: {
                              color: "#000000",
                              "font-weight": "700",
                              "font-size": "15px",
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("genAccountEmploy")) + " "
                            ),
                          ]
                        ),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-5" }, [
                            _vm._m(8),
                            _c("div", {
                              staticStyle: { "padding-bottom": "5px" },
                            }),
                            _c("p", { staticClass: "location-recruitment" }, [
                              _c("img", {
                                attrs: {
                                  src: require("../../../libhome/img/location.svg"),
                                },
                              }),
                              _vm._v(" " + _vm._s(_vm.$t("location")) + " "),
                            ]),
                            _c("div", {
                              staticStyle: { "padding-bottom": "10px" },
                            }),
                          ]),
                          _c("div", { staticClass: "col-6" }, [
                            _c("p", { staticClass: "salary" }, [
                              _c("img", {
                                attrs: {
                                  src: require("../../../libhome/img/salary.svg"),
                                },
                              }),
                              _vm._v(" " + _vm._s(_vm.$t("agreement")) + " "),
                            ]),
                            _c("div", {
                              staticStyle: { "padding-bottom": "5px" },
                            }),
                            _vm._m(9),
                          ]),
                          _vm._m(10),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "col-12 col-md-6 recruitment-item" },
                    [
                      _c("a", { attrs: { href: "/recruitment/detail/1" } }, [
                        _c("div", { staticClass: "job-item" }, [
                          _c(
                            "p",
                            {
                              staticClass: "name",
                              staticStyle: {
                                color: "#000000",
                                "font-weight": "700",
                                "font-size": "15px",
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("bussEmployee")) + " ")]
                          ),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-5" }, [
                              _vm._m(11),
                              _c("div", {
                                staticStyle: { "margin-bottom": "10px" },
                              }),
                              _c("p", { staticClass: "location-recruitment" }, [
                                _c("img", {
                                  attrs: {
                                    src: require("../../../libhome/img/location.svg"),
                                  },
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      this.$i18n.locale == "en"
                                        ? "Hanoi, Hai Phong, Ho Chi Minh"
                                        : `Hà Nội, Hải Phòng, Hồ Chí Minh`
                                    ) +
                                    " "
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "col-6" }, [
                              _c("p", { staticClass: "salary" }, [
                                _c("img", {
                                  attrs: {
                                    src: require("../../../libhome/img/salary.svg"),
                                  },
                                }),
                                _vm._v(" " + _vm._s(_vm.$t("agreement")) + " "),
                              ]),
                              _c("div", {
                                staticStyle: { "padding-bottom": "5px" },
                              }),
                              _vm._m(12),
                            ]),
                            _vm._m(13),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "col-12 col-md-6 recruitment-item",
                      staticStyle: { display: "block" },
                    },
                    [
                      _c("a", { attrs: { href: "/recruitment/detail/4" } }, [
                        _c("div", { staticClass: "job-item" }, [
                          _c(
                            "p",
                            {
                              staticClass: "name",
                              staticStyle: {
                                color: "#000000",
                                "font-weight": "700",
                                "font-size": "15px",
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("techEngineer")) + " ")]
                          ),
                          _c("span", { staticStyle: { "padding-top": "7px" } }),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-5" }, [
                              _vm._m(14),
                              _c("div", {
                                staticStyle: { "padding-bottom": "5px" },
                              }),
                              _c(
                                "p",
                                {
                                  staticClass: "location-recruitment",
                                  staticStyle: { "padding-top": "5px" },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("../../../libhome/img/location.svg"),
                                    },
                                  }),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("location")) + " "
                                  ),
                                ]
                              ),
                            ]),
                            _c("div", { staticClass: "col-6" }, [
                              _c("p", { staticClass: "salary" }, [
                                _c("img", {
                                  attrs: {
                                    src: require("../../../libhome/img/salary.svg"),
                                  },
                                }),
                                _vm._v(" " + _vm._s(_vm.$t("agreement")) + " "),
                              ]),
                              _c("div", {
                                staticStyle: { "padding-bottom": "5px" },
                              }),
                              _vm._m(15),
                            ]),
                            _vm._m(16),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "col-12 col-md-6 recruitment-item",
                      staticStyle: { display: "block" },
                    },
                    [
                      _c("a", { attrs: { href: "/recruitment/detail/3" } }, [
                        _c("div", { staticClass: "job-item" }, [
                          _c(
                            "p",
                            {
                              staticClass: "name",
                              staticStyle: {
                                color: "#000000",
                                "font-weight": "700",
                                "font-size": "15px",
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("itEmployee")) + " ")]
                          ),
                          _c("span", { staticStyle: { "padding-top": "7px" } }),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-5" }, [
                              _vm._m(17),
                              _c("div", {
                                staticStyle: { "padding-bottom": "5px" },
                              }),
                              _c(
                                "p",
                                {
                                  staticClass: "location-recruitment",
                                  staticStyle: { "padding-top": "5px" },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("../../../libhome/img/location.svg"),
                                    },
                                  }),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("location")) + " "
                                  ),
                                ]
                              ),
                            ]),
                            _c("div", { staticClass: "col-6" }, [
                              _c("p", { staticClass: "salary" }, [
                                _c("img", {
                                  attrs: {
                                    src: require("../../../libhome/img/salary.svg"),
                                  },
                                }),
                                _vm._v(" " + _vm._s(_vm.$t("agreement")) + " "),
                              ]),
                              _c("div", {
                                staticStyle: { "padding-bottom": "5px" },
                              }),
                              _vm._m(18),
                            ]),
                            _vm._m(19),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "col-12 col-md-6 recruitment-item",
                      staticStyle: { display: "block" },
                    },
                    [
                      _c("a", { attrs: { href: "/recruitment/detail/6" } }, [
                        _c("div", { staticClass: "job-item" }, [
                          _c(
                            "p",
                            {
                              staticClass: "name",
                              staticStyle: {
                                color: "#000000",
                                "font-weight": "700",
                                "font-size": "15px",
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("AO-VME")) + " ")]
                          ),
                          _c("span", { staticStyle: { "padding-top": "7px" } }),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-5" }, [
                              _vm._m(20),
                              _c("div", {
                                staticStyle: { "padding-bottom": "5px" },
                              }),
                              _c(
                                "p",
                                {
                                  staticClass: "location-recruitment",
                                  staticStyle: { "padding-top": "5px" },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("../../../libhome/img/location.svg"),
                                    },
                                  }),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("location")) + " "
                                  ),
                                ]
                              ),
                            ]),
                            _c("div", { staticClass: "col-6" }, [
                              _c("p", { staticClass: "salary" }, [
                                _c("img", {
                                  attrs: {
                                    src: require("../../../libhome/img/salary.svg"),
                                  },
                                }),
                                _vm._v(" " + _vm._s(_vm.$t("agreement")) + " "),
                              ]),
                              _c("div", {
                                staticStyle: { "padding-bottom": "5px" },
                              }),
                              _vm._m(21),
                            ]),
                            _vm._m(22),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                ]),
              ]),
              _c(
                "div",
                {
                  staticClass: "row align-items-center justify-content-center",
                },
                [
                  _c("section", { staticClass: "recruitment-list" }, [
                    _c("div", { staticClass: "container" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-md-20" }, [
                          _c("div", { staticClass: "curriculum-vitae" }, [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-12 col-md-6" }, [
                                _c("div", { staticClass: "text" }, [
                                  _c("p", {
                                    ref: "apply",
                                    staticClass: "label",
                                    domProps: {
                                      innerHTML: _vm._s(_vm.$t("recApply")),
                                    },
                                  }),
                                  _c("p", { staticClass: "desc" }, [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("reacContent1")) + " "
                                    ),
                                  ]),
                                  _c("p", { staticClass: "desc" }, [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("reacContent2")) + " "
                                    ),
                                  ]),
                                  _c("p", {
                                    staticClass: "support-line",
                                    domProps: {
                                      innerHTML: _vm._s(_vm.$t("reacContent3")),
                                    },
                                  }),
                                ]),
                              ]),
                              _c("div", { staticClass: "col-12 col-md-6" }, [
                                _c("div", { staticClass: "form" }, [
                                  _c(
                                    "form",
                                    {
                                      ref: "form",
                                      on: {
                                        submit: function ($event) {
                                          $event.preventDefault()
                                          return _vm.sendEmail.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("input", {
                                        attrs: {
                                          type: "hidden",
                                          name: "_token",
                                          value:
                                            "tUJCQfTUaRbmxLqP62LxN27j10jiTMOFtrcgwl11",
                                        },
                                      }),
                                      _c("input", {
                                        attrs: {
                                          type: "password",
                                          name: "password",
                                          value: "",
                                          hidden: "",
                                        },
                                      }),
                                      _c("div", { staticClass: "form-group" }, [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.form.name,
                                              expression: "form.name",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            type: "text",
                                            name: "name",
                                            placeholder:
                                              this.$i18n.locale === "en"
                                                ? "Full Name"
                                                : "Họ và tên",
                                          },
                                          domProps: { value: _vm.form.name },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                _vm.form,
                                                "name",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]),
                                      _c("div", { staticClass: "form-group" }, [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.form.email,
                                              expression: "form.email",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            type: "email",
                                            name: "email",
                                            placeholder: "Email",
                                          },
                                          domProps: { value: _vm.form.email },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                _vm.form,
                                                "email",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]),
                                      _c("div", { staticClass: "form-group" }, [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.form.phone,
                                              expression: "form.phone",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            type: "text",
                                            name: "phone",
                                            placeholder: _vm.$t("phone"),
                                          },
                                          domProps: { value: _vm.form.phone },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                _vm.form,
                                                "phone",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]),
                                      _c("div", { staticClass: "form-group" }, [
                                        _c(
                                          "select",
                                          {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.form.position,
                                                expression: "form.position",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              name: "recruitment_id",
                                              placeholder:
                                                _vm.$t("recPosition"),
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$selectedVal =
                                                  Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function (o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function (o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                _vm.$set(
                                                  _vm.form,
                                                  "position",
                                                  $event.target.multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "option",
                                              { attrs: { value: "" } },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      this.$i18n.locale === "en"
                                                        ? "Apply for the position"
                                                        : "Ứng tuyển vào vị trí này"
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "option",
                                              {
                                                domProps: {
                                                  value: _vm.$t("bussDepart"),
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t("bussDepart")
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "option",
                                              {
                                                domProps: {
                                                  value: _vm.$t("acountDepart"),
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t("acountDepart")
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "option",
                                              {
                                                domProps: {
                                                  value: _vm.$t("itDepart"),
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.$t("itDepart")) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "option",
                                              {
                                                domProps: {
                                                  value: _vm.$t("HRD"),
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.$t("HRD")) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex justify-content-between sp-column",
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "add-cv",
                                              attrs: { for: "upload_cv" },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "fa fa-file",
                                              }),
                                              _vm._v(
                                                _vm._s(_vm.$t("uploadCV"))
                                              ),
                                            ]
                                          ),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: !_vm.isHidden,
                                                expression: "!isHidden",
                                              },
                                            ],
                                            attrs: {
                                              type: "file",
                                              name: "cv",
                                              id: "upload_cv",
                                            },
                                            on: {
                                              change: _vm.handleFileUpload,
                                            },
                                          }),
                                          _c(
                                            "button",
                                            {
                                              staticClass: "send",
                                              attrs: {
                                                type: "submit",
                                                disabled: _vm.isLoading,
                                              },
                                            },
                                            [
                                              _vm.isLoading
                                                ? _c("span", [
                                                    _c("i", {
                                                      staticClass:
                                                        "fa fa-spinner fa-spin",
                                                    }),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t("sendCV")
                                                        ) +
                                                        " "
                                                    ),
                                                  ])
                                                : _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t("sendCV")
                                                        ) +
                                                        " "
                                                    ),
                                                  ]),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm.selectedFile
                                        ? _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                margin: "5px 0 0 20px",
                                                "font-size": "16px",
                                                color: "#fff",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.selectedFile.name)
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c("div", { staticClass: "alert" }),
                                    ]
                                  ),
                                ]),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ]),
          ]),
        ]
      ),
      _c("FooterHome"),
      _vm._m(23),
      _c("NavBarLeftHome"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "image" }, [
      _c("img", {
        attrs: {
          src: "https://phuthailuxury.vn/assets_client/images/1.png?v=1719280770",
          alt: "Item Wallet",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "image" }, [
      _c("img", {
        attrs: {
          src: "https://phuthailuxury.vn/assets_client/images/3.png?v=1719280770",
          alt: "Item Timework",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "image" }, [
      _c("img", {
        attrs: {
          src: "https://phuthailuxury.vn/assets_client/images/5.png?v=1719280770",
          alt: "Item Sex",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "image" }, [
      _c("img", {
        attrs: {
          src: "https://phuthailuxury.vn/assets_client/images/2.png?v=1719280770",
          alt: "Item Number",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "image" }, [
      _c("img", {
        attrs: {
          src: "https://phuthailuxury.vn/assets_client/images/4.png?v=1719280770",
          alt: "Item Level",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "image" }, [
      _c("img", {
        attrs: {
          src: "https://phuthailuxury.vn/assets_client/images/6.png?v=1719280770",
          alt: "Item Level",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticStyle: { "font-size": "12pt" } }, [
      _c("span", { staticStyle: { "font-family": "'Times New Roman'" } }, [
        _c("span", { staticStyle: { color: "#000000" } }, [
          _vm._v("8h00 -12h00"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticStyle: { "list-style-type": "disc" } }, [
      _c("span", { staticStyle: { "font-size": "12pt" } }, [
        _c("span", { staticStyle: { "font-family": "'Times New Roman'" } }, [
          _c("span", { staticStyle: { color: "#000000" } }, [
            _vm._v("Email: hr@vimc-lines.com "),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "deadline" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
      _vm._v(" 15-8-2024 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "worktime" }, [
      _c("i", { staticClass: "fa fa-clock" }),
      _vm._v(" 8h00 - 17h00 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-1 pr-0" }, [
      _c("span", { staticClass: "fa fa-angle-right" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "deadline" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
      _vm._v(" 15-8-2024 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "worktime" }, [
      _c("i", { staticClass: "fa fa-clock" }),
      _vm._v(" 8h00 - 17h00 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-1 pr-0" }, [
      _c("span", { staticClass: "fa fa-angle-right" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "deadline" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
      _vm._v(" 15-7-2024 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "worktime" }, [
      _c("i", { staticClass: "fa fa-clock" }),
      _vm._v(" 8h00 - 17h00 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-1 pr-0" }, [
      _c("span", { staticClass: "fa fa-angle-right" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "deadline" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
      _vm._v(" 15-8-2024 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "worktime" }, [
      _c("i", { staticClass: "fa fa-clock" }),
      _vm._v(" 8h00 - 17h00 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-1 pr-0" }, [
      _c("span", { staticClass: "fa fa-angle-right" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "deadline" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
      _vm._v(" 15-8-2024 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "worktime" }, [
      _c("i", { staticClass: "fa fa-clock" }),
      _vm._v(" 8h00 - 17h00 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-1 pr-0" }, [
      _c("span", { staticClass: "fa fa-angle-right" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { staticClass: "back-to-top", attrs: { href: "/home" } }, [
      _c("i", { staticClass: "fa fa-chevron-up" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }