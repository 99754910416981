<template>
  <div class="">
    <v-card
      class="py-4 pl-7 pr-2"
      rounded="lg"
    >
      <v-card-subtitle class="subheading">
        <span style="color: red">*</span>
        {{ $t('label-tracking-container') }}
        <span
          class="booking-label"
          :style="{ marginLeft: computedMargin }"
        >
          {{ $t('label-tracking-booking') }}
        </span>
      </v-card-subtitle>
      <v-card-text class="search-box">
        <v-form
          @submit="onTrackingContainer"
          onSubmit="return false;"
        >
          <v-row align="center">
            <v-autocomplete
              id="container-autocomplete-field"
              class="mr-4 custom-select-field fixed-width"
              v-model="containerNo"
              :items="filteredContainerOptions"
              :placeholder="$t('label-select-container')"
              :loading="isLoading"
              :readonly="isLoading"
              clearable
              item-text="no"
              item-value="no"
              @change="handleItemSelected"
              @focus="onContainerFocus"
              @click:clear="onClearSearch"
              :filter="customFilter"
              @click:append="handleClickAppend"
            />
            <v-select
              id="booking-select-field"
              class="mr-4 custom-select-field fixed-width"
              v-model="bookingNo"
              :items="bookingOptions"
              :placeholder="$t('label-select-booking')"
              :loading="isLoading"
              :readonly="isLoading"
              clearable
              item-text="no"
              item-value="no"
              @change="onBookingSelect"
              @click:clear="onClearSearch"
            />
            <v-btn
              style="
                text-transform: none;
                font-size: 16px;
                color: #fff !important;
                background-color: #e6734f !important;
              "
              class="px-10 py-6"
              large
              :class="{ 'mt-2': $vuetify.breakpoint.mobile }"
              :width="$vuetify.breakpoint.mobile ? '100%' : undefined"
              :disabled="isLoading"
              @click="onTrackingContainer"
            >
              <v-icon> mdi-magnify </v-icon>
              {{ $t('search') }}
            </v-btn>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <div
      v-if="isLoading"
      class="loading-container"
    >
      <v-progress-circular
        indeterminate
        color="primary"
        size="50"
        class="loading-spinner"
      ></v-progress-circular>
      <div class="loading-text">
        <span>Loading</span>
      </div>
    </div>
    <div
      v-else
      class="tracking-table"
    >
      <v-data-table
        v-if="tableData.length > 0"
        :headers="tableHeaders"
        :items="paginatedItems"
        class="elevation-1 mt-4 v-data-table fixed-column"
        :items-per-page="itemsPerPage"
        :page.sync="page"
        :hide-default-footer="true"
      >
        <template v-slot:item="props">
          <tr
            :class="{
              'even-row': props.index % 2 === 0,
              'odd-row': props.index % 2 !== 0,
            }"
          >
            <td
              v-for="(header, index) in tableHeaders"
              :key="header.value"
              :class="[
                'pl-6 py-4',
                {
                  'sticky-col': index === 0,
                  'even-row': props.index % 2 === 0 && index === 0,
                  'odd-row': props.index % 2 !== 0 && index === 0,
                },
              ]"
            >
              <span v-if="header.value === 'movement_date'">
                {{ formatDateTime(props.item[header.value]) }}
              </span>
              <span v-else>
                {{ props.item[header.value] }}
              </span>
            </td>
          </tr>
        </template>
      </v-data-table>
      <!-- Custom Pagination Component -->
      <div
        v-if="tableData.length > 0"
        class="custom-pagination mt-4"
      >
        <!-- Nút mũi tên điều hướng trái -->
        <v-btn
          v-if="page > 1"
          @click="goToPage(page - 1)"
          icon
        >
          <v-icon class="chevron">mdi-chevron-left</v-icon>
        </v-btn>

        <!-- Nút số trang 1 -->
        <v-btn
          @click="goToPage(1)"
          :disabled="page === 1"
        >
          1
        </v-btn>

        <!-- Các nút số trang giữa -->
        <v-btn
          v-for="pageNum in visiblePages"
          :key="pageNum"
          @click="goToPage(pageNum)"
          :class="{ active: pageNum === page }"
        >
          {{ pageNum }}
        </v-btn>

        <!-- Nút số trang cuối cùng -->
        <v-btn
          @click="goToPage(totalPages)"
          :disabled="page === totalPages"
        >
          {{ totalPages }}
        </v-btn>

        <!-- Nút mũi tên điều hướng phải -->
        <v-btn
          v-if="page < totalPages - 3"
          @click="goToPage(page + 1)"
          icon
        >
          <v-icon class="chevron">mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </div>
    <!-- <v-scroll-y-transition v-if="!$vuetify.breakpoint.mobile">
      <ContainerTrackingSearchResult
        v-if="render && containerMovements.logs.length > 0"
        class="mt-4 mb-16"
        :loading="isLoading"
      />
    </v-scroll-y-transition> -->

    <!-- Các thành phần khác giữ nguyên -->

    <!-- <ContainerTrackingSearchResultV2
      v-if="render && containerMovements.logs.length > 0"
      :onTrackingContainer="onTrackingContainer"
      :trackingNo="trackingNo"
    /> -->

    <v-dialog
      v-if="$vuetify.breakpoint.mobile"
      v-model="dialog.result"
      fullscreen
      transition="dialog-bottom-transition"
      scrollable
    >
      <MobileContainerTrackingSearchResult
        v-if="dialog.result && containerMovements.logs.length > 0"
        @close-dialog="dialog.result = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import ContainerTrackingSearchResult from './ContainerTrackingSearchResult.vue';
import MobileContainerTrackingSearchResult from './MobileContainerTrackingSearchResult.vue';
import ContainerTrackingApi from '@/api/vsl/container-tracking.api';

export default {
  components: {
    ContainerTrackingSearchResult,
    MobileContainerTrackingSearchResult,
  },
  data: () => ({
    isLoading: true,
    containerOptions: [], // Danh sách container gốc
    bookingOptions: [], // Danh sách booking gốc
    filteredContainerOptions: [], // Danh sách container đã lọc
    filteredBookingOptions: [], // Danh sách booking đã lọc
    trackingNo: '',
    dialog: {
      result: false,
    },
    render: false,
    tableHeaders: [
      { text: 'CONTAINER NO', value: 'container_no', sortable: false },
      { text: 'MOVEMENT CODE', value: 'status', sortable: false },
      { text: 'MOVEMENT DATE', value: 'movement_date', sortable: false },
      { text: 'BOOKING NO', value: 'booking_no', sortable: false },
      { text: 'SEAL NO', value: 'seal_no', sortable: false },
      { text: 'VESSEL ID', value: 'vessel_code', sortable: false },
      { text: 'VOYAGE NO', value: 'voyage_code', sortable: false },
      { text: 'POL', value: 'pol_code', sortable: false },
      { text: 'POD', value: 'pod_code', sortable: false },
      { text: 'POFD', value: 'pofd_code', sortable: false },
      { text: 'TERMINAL CODE', value: 'terminal_code', sortable: false },
    ],
    itemsPerPage: 10, // Số lượng item hiển thị trên mỗi trang
    page: 1, // Trang hiện tại
    tableData: [],
  }),
  computed: {
    ...mapState('vsl/containerTracking', ['containerMovements']),
    // Tổng số trang dựa trên số mục mỗi trang và dữ liệu bảng
    totalPages() {
      return Math.ceil(this.tableData.length / this.itemsPerPage);
    },
    // Các mục hiển thị cho trang hiện tại
    paginatedItems() {
      const start = (this.page - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.tableData.slice(start, end);
    },
    // Tạo các trang để hiển thị trong pagination
    visiblePages() {
      const pageRange = 2; // Số trang xung quanh trang hiện tại
      const start = Math.max(2, this.page - pageRange);
      const end = Math.min(this.totalPages - 1, this.page + pageRange);
      const pages = [];

      if (this.totalPages > 5) {
        if (start > 2) pages.push('...'); // Dấu ba chấm nếu cần
        for (let i = start; i <= end; i++) {
          pages.push(i);
        }
        if (end < this.totalPages - 1) pages.push('...'); // Dấu ba chấm nếu cần
      } else {
        for (let i = 1; i <= this.totalPages; i++) {
          pages.push(i);
        }
      }
      return pages;
    },
    computedMargin() {
      const currentLanguage = this.$i18n.locale;

      return currentLanguage === 'en' ? '600px' : '587px';
    },
  },
  mounted() {
    this.trackingNo = this.$route.query.tracking_no || '';
    if (this.trackingNo) {
      this.onTrackingContainer();
    }
    this.scrollToTrackingSearch();
    this.fetchAndUpdateTableData(); // Gọi phương thức khi component được gắn vào DOM
  },
  beforeDestroy() {
    this.RESET();
  },
  methods: {
    ...mapMutations('vsl/containerTracking', ['RESET']),
    ...mapActions('vsl/containerTracking', ['fetchContainerTrackingInfo']),

    async fetchAndUpdateTableData() {
      this.isLoading = true; // Bắt đầu trạng thái tải

      try {
        const queryParams = {
          page: 0,
          size: 20000,
        };
        const res = await ContainerTrackingApi.getUpdateTable(queryParams);
        this.tableData = res.data.data || []; // Đảm bảo dữ liệu không bị lỗi
      } catch (error) {
        console.error('Error fetching table data:', error);
      } finally {
        this.isLoading = false; // Kết thúc trạng thái tải
      }
    },

    async onTrackingContainer() {
      if (
        !this.containerNo ||
        !this.bookingNo ||
        this.containerNo.trim().length === 0 ||
        this.bookingNo.trim().length === 0
      ) {
        console.warn('Both Container No and Booking No must be selected.');
        this.$message.error('No data found');
        return;
      }

      this.RESET(); // Xóa dữ liệu kết quả trước đó
      this.$message.error('No data found');
      // try {
      //   // Gửi yêu cầu đến API
      //   await this.fetchContainerTrackingInfo({
      //     containerNo: this.containerNo,
      //     bookingNo: this.bookingNo,
      //   });

      //   this.render = this.containerMovements.logs.length > 0;

      //   if (this.$vuetify.breakpoint.mobile && this.render) {
      //     this.dialog.result = true; // Hiển thị dialog trên thiết bị di động nếu có kết quả
      //   }
      // } catch (error) {
      //   console.error('Error fetching container tracking info:', error);
      // } finally {
      //   this.isLoading = false;
      // }
    },
    customFilter(item, query) {
      if (!query) return true; // Không lọc khi không có query
      const search = query.toLowerCase();
      const itemText = item.no ? item.no.toLowerCase() : '';
      return itemText.includes(search);
    },
    async onContainerFocus(item) {
      if (item) {
        try {
          const queryParams = {
            container_no: item,
          };
          const res = await ContainerTrackingApi.getContainerNumbers(
            queryParams
          );

          this.containerOptions = res.data.data || [];
          this.filteredContainerOptions = this.containerOptions;
        } catch (error) {
          console.error('Error fetching container data on focus:', error);
        } finally {
          this.isLoading = false;
        }
      }
    },
    viewDetails(item) {
      alert('Viewing details for ' + item.containerNo);
    },
    editItem(item) {
      alert('Editing ' + item.containerNo);
    },
    deleteItem(item) {
      alert('Deleting ' + item.containerNo);
    },
    handlePageChange(newPage) {
      this.page = newPage;
      // Cập nhật dữ liệu hiển thị khi trang thay đổi nếu cần
    },
    goToPage(newPage) {
      this.page = newPage;
    },
    formatDateTime(dateTimeString) {
      if (!dateTimeString) return ''; // Kiểm tra nếu giá trị rỗng

      const optionsTime = {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false, // Sử dụng định dạng 24 giờ
      };

      const optionsDate = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      };

      const time = new Date(dateTimeString).toLocaleTimeString(
        'en-GB',
        optionsTime
      );
      const date = new Date(dateTimeString).toLocaleDateString(
        'en-GB',
        optionsDate
      );

      return `${time} - ${date}`;
    },
    async onClearSearch() {
      this.RESET(); // Xóa dữ liệu kết quả trước đó
      this.trackingNo = ''; // Xóa ô tìm kiếm
      this.render = false; // Đảm bảo rằng kết quả tìm kiếm không hiển thị
    },
    handleClickAppend() {
      // Khi mở lại, giữ nguyên danh sách đã lọc
      this.filteredContainerOptions = (this.containerOptions || []).filter(
        (item) => this.customFilter(item, this.containerNo)
      );
      this.filteredBookingOptions = (this.bookingOptions || []).filter((item) =>
        this.customFilter(item, this.bookingNo)
      );
    },

    // async fetchData() {
    //   this.isLoading = true; // Bắt đầu loading
    //   try {
    //     // Thực hiện yêu cầu lấy dữ liệu
    //     const response = await fetch('your-api-endpoint');
    //     const data = await response.json();
    //     this.tableData = data.items;
    //     this.totalPages = Math.ceil(data.total / this.itemsPerPage);
    //   } catch (error) {
    //     console.error('Error fetching data:', error);
    //   } finally {
    //     this.isLoading = false; // Kết thúc loading
    //   }
    // },

    async handleItemSelected(item) {
      if (item) {
        try {
          // Tạo URL với tham số truy vấn
          const queryParams = {
            container_no: item,
          };
          const res = await ContainerTrackingApi.getBookingNumbers(queryParams);

          if (res && res.data.data) {
            const bookingOptions = res.data.data
              .filter(
                (item) => item.booking_no && item.booking_no.trim() !== ''
              )
              .map((item) => ({
                no: item.booking_no,
              }));

            this.bookingOptions = bookingOptions;
          }
        } catch (error) {
          console.error('Error sending data to API:', error);
        }
      }
    },
    scrollToTrackingSearch() {
      this.$nextTick(() => {
        const searchField = document.getElementById('tracking-search-field');
        if (searchField) {
          const start = window.pageYOffset;
          const end =
            searchField.getBoundingClientRect().top + window.pageYOffset;
          const distance = end - start;
          const duration = 3000; // Thay đổi thời gian cuộn (ms)
          let startTime = null;

          const scroll = (currentTime) => {
            if (startTime === null) startTime = currentTime;
            const timeElapsed = currentTime - startTime;
            const progress = Math.min(timeElapsed / duration, 1);
            const easing =
              progress < 0.5
                ? 4 * progress * progress * progress
                : 1 - Math.pow(-2 * progress + 2, 3) / 2;
            window.scrollTo(0, start + distance * easing);
            if (timeElapsed < duration) {
              requestAnimationFrame(scroll);
            }
          };

          requestAnimationFrame(scroll);
        }
      });
    },
  },
};
</script>

<style scoped>
.subheading {
  font-size: 16px; /* Kích thước chữ phụ đề */
  font-family: 'Montserrat', sans-serif;
  padding: 0 16px 0 6px !important;
}

.custom-text-field {
  margin-top: 0 !important; /* Đảm bảo không có margin-top làm khuất viền */
  padding-top: 0 !important; /* Loại bỏ padding-top nếu cần */

  .v-input__control {
    border-width: 2px !important; /* Tăng độ rộng viền để đảm bảo hiển thị rõ */
  }
}

.fixed-width {
  width: 457px !important;
}

.custom-data-table {
  overflow-x: auto;
  max-width: 120%;
}

.tracking-table {
  margin-top: 40px !important;
}

.v-data-table {
  width: 220%;
  table-layout: auto; /* Cho phép bảng mở rộng và tự động điều chỉnh cột */
}

:deep(.v-data-table > .v-data-table__wrapper > table > thead > tr > th) {
  padding: 0 5px !important;
  margin-right: 20px !important;
  background: #e8724a !important;
  color: #fff !important;
  text-align: center !important;
  border-left: 1px solid #fff !important;
  border-right: 1px solid #fff !important;
  font-size: 14px !important;
}

:deep(.theme--light.v-text-field > .v-input__control > .v-input__slot:before) {
  border: none !important;
}

:deep(.v-text-field) {
  padding-top: 5px !important;
}

:deep(.v-btn) {
  margin-bottom: 3px !important;
}

:deep(.v-input__control) {
  margin-top: 3px !important;
}

:deep(.v-input__slot) {
  border: 1px solid #d2d2d2 !important;
  border-radius: 4px !important;
  padding-left: 10px !important;
  height: 50px !important;
  margin-top: 5px !important;
}

:deep(.v-icon.v-icon) {
  font-size: 16px !important;
  color: #ffffff !important;
  padding-right: 5px !important;
}

:deep(.v-icon.v-icon.chevron) {
  font-size: 16px !important;
  color: #000000 !important;
}

:deep(.theme--light.v-btn.v-btn--disabled .v-icon) {
  color: #ffffff !important;
}

/* Nền cột cố định cho hàng chẵn */
.fixed-column td:first-child.even-row {
  position: sticky;
  left: 0;
  background-color: #f2f2f2; /* Màu nền hàng chẵn */
  z-index: 2; /* Giữ z-index cao để nó nằm trên các hàng khác */
}

/* Nền cột cố định cho hàng lẻ */
.fixed-column td:first-child.odd-row {
  position: sticky;
  left: 0;
  background-color: #ffffff; /* Màu nền hàng lẻ */
  z-index: 2; /* Giữ z-index cao để nó nằm trên các hàng khác */
}

:deep(.fixed-column th:first-child) {
  position: sticky;
  left: 0;
  z-index: 999; /* Z-index cao hơn th để header không bị che */
}

.even-row {
  background-color: #f2f2f2; /* Màu nền cho hàng chẵn */
}

.odd-row {
  background-color: #ffffff; /* Màu nền cho hàng lẻ */
}

.even-row:hover,
.odd-row:hover {
  background-color: #e1f1f9 !important; /* Màu nền cho hàng chẵn */
}

.custom-pagination {
  display: flex;
  justify-content: flex-end; /* Giữ thanh phân trang ở bên trái */
  flex-wrap: nowrap; /* Ngăn các mục tràn xuống dòng tiếp theo */
}

.custom-pagination .v-btn {
  margin: 0 4px;
  background-color: #ffffff;
}

.custom-pagination .v-btn.active {
  font-weight: bold;
  color: #e8724a;
  background-color: #ffffff;
  box-shadow: 0 3px 10px -2px rgba(85, 85, 85, 0.08),
    0 2px 20px 0 rgba(85, 85, 85, 0.06), 0 1px 30px 0 rgba(85, 85, 85, 0.03) !important;
}

.custom-pagination .v-btn[disabled] {
  cursor: not-allowed;
  font-weight: bold;
  color: #e8724a !important;
  background-color: #ffffff !important;
  box-shadow: 0 3px 10px -2px rgba(85, 85, 85, 0.08),
    0 2px 20px 0 rgba(85, 85, 85, 0.06), 0 1px 30px 0 rgba(85, 85, 85, 0.03) !important;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.loading-spinner {
  margin-bottom: 10px;
}

.loading-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.dots-container {
  display: flex;
  justify-content: center;
}

.dot {
  font-size: 24px;
  margin: 0 5px;
  animation: dot-blink 1.5s infinite;
}

@media screen and (max-width: 1367px) {
  .booking-label {
    margin-left: 350px !important;
  }
}

@media screen and (max-width: 1181px) {
  .booking-label {
    position: relative;
    margin-left: 0 !important;
    top: 90px;
    right: 125px;
  }

  :deep(.v-input__slot) {
    width: 905px !important;
  }
}

@media screen and (max-width: 1025px) {
  :deep(.v-input__slot) {
    width: 670px !important;
  }
}

@keyframes dot-blink {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.dot:nth-child(1) {
  animation-delay: 0s;
}

.dot:nth-child(2) {
  animation-delay: 0.5s;
}

.dot:nth-child(3) {
  animation-delay: 1s;
}
</style>
