var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {}, [
    _c("p", { staticClass: "text-h7 mt-4 success--text font-weight-medium" }, [
      _vm._v(" ALWAYS IMPROVING "),
    ]),
    _c(
      "p",
      { staticClass: "text-h3 mt-0", staticStyle: { "font-weight": "600" } },
      [_vm._v(" Services ")]
    ),
    _c(
      "div",
      { staticClass: "mt-16" },
      [
        _c(
          "v-row",
          {
            class: {
              "flex-column": _vm.$vuetify.breakpoint.mobile,
            },
            attrs: { "no-gutters": "" },
          },
          [
            _c(
              "v-col",
              {
                staticClass: "py-3",
                attrs: { cols: _vm.$vuetify.breakpoint.mobile ? 12 : 4 },
              },
              [
                _c(
                  "v-card",
                  {
                    attrs: {
                      rounded: "lg",
                      href: "https://vimc.co/van-tai-bien/",
                      target: "_blank",
                    },
                  },
                  [
                    _c(
                      "v-img",
                      {
                        staticClass: "white--text align-end",
                        attrs: {
                          src: "/assets/img/shipping-vimc.png",
                          gradient: "to bottom, rgba(0,0,0,.1), rgba(0,0,0,.2)",
                          height: "235px",
                        },
                      },
                      [_c("v-card-title", [_vm._v("Shipping")])],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "v-col",
              {
                staticClass: "py-3",
                class: {
                  "px-3": !_vm.$vuetify.breakpoint.mobile,
                },
                attrs: { cols: _vm.$vuetify.breakpoint.mobile ? 12 : 4 },
              },
              [
                _c(
                  "v-card",
                  {
                    attrs: {
                      rounded: "lg",
                      href: "https://vimc.co/khai-thac-cang-bien/",
                      target: "_blank",
                    },
                  },
                  [
                    _c(
                      "v-img",
                      {
                        staticClass: "white--text align-end",
                        attrs: {
                          src: "/assets/img/port-vimc.png",
                          gradient: "to bottom, rgba(0,0,0,.1), rgba(0,0,0,.2)",
                          height: "235px",
                        },
                      },
                      [_c("v-card-title", [_vm._v("Port")])],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "v-col",
              {
                staticClass: "py-3",
                attrs: { cols: _vm.$vuetify.breakpoint.mobile ? 12 : 4 },
              },
              [
                _c(
                  "v-card",
                  {
                    attrs: {
                      rounded: "lg",
                      href: "https://vimc.co/dich-vu-hang-hai/",
                      target: "_blank",
                    },
                  },
                  [
                    _c(
                      "v-img",
                      {
                        staticClass: "white--text align-end",
                        attrs: {
                          src: "/assets/img/logistics-vimc-1.png",
                          gradient: "to bottom, rgba(0,0,0,.1), rgba(0,0,0,.2)",
                          height: "235px",
                        },
                      },
                      [_c("v-card-title", [_vm._v("Logistics")])],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }