<template>
  <main>
    <!-- Nav Bar Start -->
    <NavBarHome active="recruitment" />
    <!-- Banner -->
    <div style="height: 170px; background-color: #ffffff"></div>
    <!-- Banner End -->

    <div
      class="transx_page_content_wrapper"
      style="background-color: #ffffff"
    >
      <!-- About Start -->
      <div class="recruitment">
        <div class="container">
          <div class="label">
            <h3>{{ $t('HRC') }}</h3>
            <p>
              <i class="fa fa-calendar"></i> {{ $t('appDeadline') }}: 15-8-2024
              <button
                id="button"
                @click="scrollToApplySection"
              >
                {{ $t('sendCV') }}
              </button>
            </p>
          </div>
          <div class="common-info">
            <h5>{{ $t('overview') }}</h5>
            <div class="list-info row">
              <div class="item col-6 d-flex">
                <div class="image">
                  <img
                    src="https://phuthailuxury.vn/assets_client/images/1.png?v=1719280770"
                    alt="Item Wallet"
                  />
                </div>
                <div
                  class="text"
                  style="
                    flex-direction: column;
                    display: flex;
                    align-items: flex-start;
                  "
                >
                  <span style="font-weight: 700">{{
                    this.$i18n.locale === 'en' ? 'Salary' : 'Mức lương'
                  }}</span>
                  <span>{{
                    this.$i18n.locale === 'en' ? 'Agreement' : 'Thỏa thuận'
                  }}</span>
                </div>
              </div>
              <div class="item col-6 d-flex">
                <div class="image">
                  <img
                    src="https://phuthailuxury.vn/assets_client/images/3.png?v=1719280770"
                    alt="Item Timework"
                  />
                </div>
                <div
                  class="text"
                  style="
                    flex-direction: column;
                    display: flex;
                    align-items: flex-start;
                  "
                >
                  <span style="font-weight: 700">{{ $t('workSchedule') }}</span>
                  <span>8h00 - 17h00</span>
                </div>
              </div>
              <div class="item col-6 d-flex">
                <div class="image">
                  <img
                    src="https://phuthailuxury.vn/assets_client/images/5.png?v=1719280770"
                    alt="Item Sex"
                  />
                </div>
                <div
                  class="text"
                  style="
                    flex-direction: column;
                    display: flex;
                    align-items: flex-start;
                  "
                >
                  <span style="font-weight: 700">{{ $t('gender') }}</span>
                  <span>{{
                    this.$i18n.locale == 'en' ? 'Male/Female' : 'Nam/Nữ'
                  }}</span>
                </div>
              </div>
              <div class="item col-6 d-flex">
                <div class="image">
                  <img
                    src="https://phuthailuxury.vn/assets_client/images/2.png?v=1719280770"
                    alt="Item Number"
                  />
                </div>
                <div
                  class="text"
                  style="
                    flex-direction: column;
                    display: flex;
                    align-items: flex-start;
                  "
                >
                  <span style="font-weight: 700">{{
                    this.$i18n.locale == 'en'
                      ? 'Recruitment Number'
                      : 'Số lượng tuyển dụng'
                  }}</span>
                  <span>1</span>
                </div>
              </div>
              <div class="item col-6 d-flex">
                <div class="image">
                  <img
                    src="https://phuthailuxury.vn/assets_client/images/4.png?v=1719280770"
                    alt="Item Level"
                  />
                </div>
                <div
                  class="text"
                  style="
                    flex-direction: column;
                    display: flex;
                    align-items: flex-start;
                  "
                >
                  <span style="font-weight: 700"
                    >{{ this.$i18n.locale === 'en' ? 'Level' : 'Cấp bậc' }}
                  </span>
                  <span>{{ $t('employyee') }}</span>
                </div>
              </div>
              <div class="item col-6 d-flex">
                <div class="image">
                  <img
                    src="https://phuthailuxury.vn/assets_client/images/6.png?v=1719280770"
                    alt="Item Level"
                  />
                </div>
                <div
                  class="text"
                  style="
                    flex-direction: column;
                    display: flex;
                    align-items: flex-start;
                  "
                >
                  <span style="font-weight: 700">{{ $t('experience') }} </span>
                  <span>{{
                    this.$i18n.locale == 'en'
                      ? 'Have Experience'
                      : 'Có kinh Nghiệm'
                  }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="address">
            <h5 style="font-weight: 700">{{ $t('workLocation') }}</h5>
            <ul>
              <li>
                {{ $t('addressDetail') }}
              </li>
              <li>
                {{
                  this.$i18n.locale == 'en'
                    ? `No. 1 Dao Duy Anh Street, Phuong Mai Ward, Dong Da District, Hanoi`
                    : 'Số 01 Đào Duy Anh, Phương Mai, quận Đống Đa, Hà Nội.'
                }}
              </li>
            </ul>
          </div>

          <div class="job-desc">
            <h2 style="text-align: justify">
              <span style="font-size: 16pt"
                ><span style="font-family: 'Times New Roman'"
                  ><span style="color: #000000">{{ $t('jobDesc') }}</span></span
                ></span
              >
            </h2>

            <ul>
              <li style="list-style-type: disc">
                <span style="font-size: 12pt"
                  ><span style="font-family: 'Times New Roman'"
                    ><span style="color: #000000">{{
                      this.$i18n.locale === 'en'
                        ? `Participation in Building and Streamlining Organizational Structure and Management Regulations of the Company:`
                        : 'Tham gia xây dựng, kiện toàn cơ cấu tổ chức và xây dựng các quy định, quy chế quản lý của Công ty:'
                    }}</span>

                    <ul>
                      <li>
                        <span style="font-size: 12pt"
                          ><span style="font-family: 'Times New Roman'"
                            ><span style="color: #000000">{{
                              this.$i18n.locale === 'en'
                                ? `Participate in researching, analyzing, and proposing organizational structure and human resource development strategies suitable to the company's development orientation in each period.`
                                : 'Tham gia nghiên cứu, phân tích và đề xuất phương án cơ cấu tổ chức, chiến lược phát triển nhân sự của Công ty phù hợp với định hướng phát triển Công ty trong từng thời kỳ.'
                            }}</span></span
                          ></span
                        >
                      </li>
                      <li>
                        <span style="font-size: 12pt"
                          ><span style="font-family: 'Times New Roman'"
                            ><span style="color: #000000">{{
                              this.$i18n.locale === 'en'
                                ? `Participate in researching, analyzing, and proposing organizational structure plans and human resource development strategies in line with the company's development direction at each period.`
                                : 'Tham gia xây dựng và trình ban hành quy chế tổ chức và hoạt động của các đơn vị, điều lệ tổ chức và hoạt động của Công ty, chức năng nhiệm vụ các phòng ban.'
                            }}</span></span
                          ></span
                        >
                      </li>
                      <li>
                        <span style="font-size: 12pt"
                          ><span style="font-family: 'Times New Roman'"
                            ><span style="color: #000000">{{
                              this.$i18n.locale === 'en'
                                ? `Implement and participate in disseminating, guiding, and supervising the application of management improvements, regulations, and policies in the company's departments and units.`
                                : 'Triển khai và tham gia phổ biến, hướng dẫn và giám sát việc áp dụng các cải tiến, quy định, chính sách quản lý tại các phòng ban, đơn vị thuộc Công ty.'
                            }}</span></span
                          ></span
                        >
                      </li>
                    </ul>
                  </span>
                </span>
              </li>
              <li style="list-style-type: disc">
                <span style="font-size: 12pt"
                  ><span style="font-family: 'Times New Roman'"
                    ><span style="color: #000000"
                      >{{
                        this.$i18n.locale === 'en'
                          ? `In Charge of Planning, Mobilizing, Appointing, and Reappointing Staff:`
                          : 'Phụ trách công tác quy hoạch, điều động, bổ nhiệm, bổ nhiệm lại cán bộ:'
                      }}
                    </span>
                    <ul>
                      <li>
                        <span style="font-size: 12pt"
                          ><span style="font-family: 'Times New Roman'"
                            ><span style="color: #000000">{{
                              this.$i18n.locale === 'en'
                                ? `Participate in researching, advising, and proposing planning and organizational structures for management, operational, and production business units of the company.`
                                : 'Tham gia nghiên cứu, tham mưu và đề xuất phương án quy hoạch, tổ chức bộ máy quản lý điều hành, tổ chức bộ máy sản xuất kinh doanh của Công ty.'
                            }}</span></span
                          ></span
                        >
                      </li>
                      <li>
                        <span style="font-size: 12pt"
                          ><span style="font-family: 'Times New Roman'"
                            ><span style="color: #000000">{{
                              this.$i18n.locale === 'en'
                                ? `Participate in staff allocation for the company and its units/departments, carrying out procedures for mobilizing, appointing, and reappointing staff according to regulations.`
                                : 'Tham gia công tác bố trí cán bộ của Công ty và các đơn vị/bộ phận, thực hiện các thủ tục điều động, bổ nhiệm, bổ nhiệm lại cán bộ theo quy định.'
                            }}</span></span
                          ></span
                        >
                      </li>
                    </ul>
                  </span></span
                >
              </li>
              <li style="list-style-type: disc">
                <span style="font-size: 12pt"
                  ><span style="font-family: 'Times New Roman'"
                    ><span style="color: #000000"
                      >{{
                        this.$i18n.locale === 'en'
                          ? `Implementation of Recruitment and Training for Office Workers:`
                          : `Triển khai thực hiện công tác tuyển dụng, đào tạo cho lao động tại văn phòng Công ty:`
                      }}
                    </span>
                    <ul>
                      <li>
                        <span style="font-size: 12pt"
                          ><span style="font-family: 'Times New Roman'"
                            ><span style="color: #000000">{{
                              this.$i18n.locale === 'en'
                                ? `Gather and determine recruitment and training needs from departments, centers, and units within the company; develop annual recruitment and training plans.`
                                : 'Triển khai tổng hợp và xác định và tổng hợp nhu cầu tuyển dụng, đào tạo của các phòng ban, trung tâm, đơn vị thuộc Công ty; Xây dựng kế hoạch tuyển dụng, đào tạo hàng năm.'
                            }}</span></span
                          ></span
                        >
                      </li>
                      <li>
                        <span style="font-size: 12pt"
                          ><span style="font-family: 'Times New Roman'"
                            ><span style="color: #000000">{{
                              this.$i18n.locale === 'en'
                                ? `Implement recruitment processes in accordance with company procedures and regulations; handle labor reception procedures, sign probationary and labor contracts.`
                                : 'Triển khai thực hiện công tác tuyển dụng lao động theo đúng quy trình, quy định của Công ty; thực hiện các thủ tục tiếp nhận lao động, ký kết hợp đồng thử việc, hợp đồng lao động.'
                            }}</span></span
                          ></span
                        >
                      </li>
                      <li>
                        <span style="font-size: 12pt"
                          ><span style="font-family: 'Times New Roman'"
                            ><span style="color: #000000">{{
                              this.$i18n.locale === 'en'
                                ? `Search for suitable training units and coordinate with training units to organize courses as per the plan and arising needs.`
                                : 'Triển khai tìm kiếm đơn vị đào tạo phù hợp và phối hợp với các đơn vị đào tạo tổ chức các khóa đào tạo theo kế hoạch và theo nhu cầu phát sinh.'
                            }}</span></span
                          ></span
                        >
                      </li>
                      <li>
                        <span style="font-size: 12pt"
                          ><span style="font-family: 'Times New Roman'"
                            ><span style="color: #000000">{{
                              this.$i18n.locale === 'en'
                                ? `Monitor training content implementation according to the plan, supervise training quality according to approved objectives and content.`
                                : 'Theo dõi triển khai các nội dung đào tạo theo kế hoạch, giám sát chất lượng đào tạo theo đúng mục tiêu và nội dung đã được duyệt.'
                            }}</span></span
                          ></span
                        >
                      </li>
                      <li>
                        <span style="font-size: 12pt"
                          ><span style="font-family: 'Times New Roman'"
                            ><span style="color: #000000">{{
                              this.$i18n.locale === 'en'
                                ? `Organize recording, storage, and aggregation of information for reporting, feedback, and evaluation regarding training content, issues needing development/improvement/adjustment, and policies related to training implementation.`
                                : 'Tổ chức ghi nhận, lưu trữ, tổng hợp thông tin để báo cáo, phản hồi, đánh giá về nội dung đào tạo, các vấn đề cần xây dựng/hoàn thiện/điều chỉnh nội dung đào tạo, các chế độ chính sách liên quan đến việc triển khai đào tạo.'
                            }}</span></span
                          ></span
                        >
                      </li>
                      <li>
                        <span style="font-size: 12pt"
                          ><span style="font-family: 'Times New Roman'"
                            ><span style="color: #000000">{{
                              this.$i18n.locale === 'en'
                                ? `Provide related feedback information as a basis for evaluating training performance and establishing annual training plans.`
                                : 'Cung cấp thông tin phản hồi có liên quan để làm cơ sở đánh giá kết quả thực hiện đào tạo và xác lập kế hoạch đào tạo hàng năm.'
                            }}</span></span
                          ></span
                        >
                      </li>
                      <li>
                        <span style="font-size: 12pt"
                          ><span style="font-family: 'Times New Roman'"
                            ><span style="color: #000000">{{
                              this.$i18n.locale === 'en'
                                ? `Compile and report on recruitment and training performance of the company.`
                                : 'Tổ chức tổng hợp, lập báo cáo kết quả thực hiện công tác tuyển dụng, đào tạo của Công ty.'
                            }}</span></span
                          ></span
                        >
                      </li>
                    </ul>
                  </span></span
                >
              </li>
              <li style="list-style-type: disc">
                <span style="font-size: 12pt"
                  ><span style="font-family: 'Times New Roman'"
                    ><span style="color: #000000"
                      >{{
                        this.$i18n.locale === 'en'
                          ? `Implementation of Training for Employees on Occupational Safety and Hygiene:`
                          : 'Triển khai công tác đào tạo, huấn luyện cho người lao động của Công ty về công tác an toàn vệ sinh lao động:'
                      }}
                    </span>
                    <ul>
                      <li>
                        <span style="font-size: 12pt"
                          ><span style="font-family: 'Times New Roman'"
                            ><span style="color: #000000">{{
                              this.$i18n.locale === 'en'
                                ? `Develop and update policies and regulations on occupational safety and hygiene (OSH) as per legal requirements and implement monthly, quarterly, and annual OSH plans.`
                                : 'Triển khai xây dựng, cập nhật các chính sách, quy định về an toàn vệ sinh lao động (ATVSLĐ) của Công ty theo quy định của pháp luật và tổ chức thực hiện kế hoạch ATVSLĐ tháng, quý, năm.'
                            }}</span></span
                          ></span
                        >
                      </li>
                      <li>
                        <span style="font-size: 12pt"
                          ><span style="font-family: 'Times New Roman'"
                            ><span style="color: #000000">{{
                              this.$i18n.locale === 'en'
                                ? `Guide, urge, check, and supervise units to comply with OSH regulations; document violations according to regulations.`
                                : 'Triển khai hướng dẫn, đôn đốc, kiểm tra, giám sát các đơn vị thực hiện các quy định về ATVSLĐ; Lập biên bản đối với các trường hợp vi phạm theo đúng quy định.'
                            }}</span></span
                          ></span
                        >
                      </li>
                      <li>
                        <span style="font-size: 12pt"
                          ><span style="font-family: 'Times New Roman'"
                            ><span style="color: #000000">{{
                              this.$i18n.locale === 'en'
                                ? `Direct and participate in investigating, proposing solutions, completing files, and handling procedures when labor accidents occur according to legal regulations; complete files for labor accident benefits.`
                                : 'Chỉ đạo và tham gia điều tra, đề xuất giải quyết, hoàn thiện hồ sơ, thủ tục khi xảy ra tai nạn lao động theo quy định của pháp luật; hoàn thiện các hồ sơ giải quyết chế độ tai nạn lao động.'
                            }}</span></span
                          ></span
                        >
                      </li>
                      <li>
                        <span style="font-size: 12pt"
                          ><span style="font-family: 'Times New Roman'"
                            ><span style="color: #000000">{{
                              this.$i18n.locale === 'en'
                                ? `Forecast, procure, and distribute personal protective equipment for employees.`
                                : 'Triển khai lập dự trù, mua sắm và thực hiện cấp phát trang thiết bị, phương tiện bảo vệ cá nhân cho người lao động.'
                            }}</span></span
                          ></span
                        >
                      </li>
                    </ul></span
                  ></span
                >
              </li>
              <li style="list-style-type: disc">
                <span style="font-size: 12pt"
                  ><span style="font-family: 'Times New Roman'"
                    ><span style="color: #000000"
                      >{{
                        this.$i18n.locale === 'en'
                          ? `Implementation of Emulation, Reward, and Anti-Corruption Practices:`
                          : 'Triển khai thực hiện công tác Thi đua khen thưởng, Thực hành TKCLP, Phòng chống tham nhũng:'
                      }}
                    </span>
                    <ul>
                      <li>
                        <span style="font-size: 12pt"
                          ><span style="font-family: 'Times New Roman'"
                            ><span style="color: #000000">{{
                              this.$i18n.locale === 'en'
                                ? `Develop and submit for approval emulation and reward regulations in line with company policies and legal requirements.`
                                : 'Tham gia xây dựng, trình ban hành quy chế thi đua khen thưởng theo đúng chính sách của Công ty và quy định của pháp luật.'
                            }}</span></span
                          ></span
                        >
                      </li>
                      <li>
                        <span style="font-size: 12pt"
                          ><span style="font-family: 'Times New Roman'"
                            ><span style="color: #000000">{{
                              this.$i18n.locale === 'en'
                                ? `Disseminate, guide, and periodically check the implementation of emulation and reward practices to ensure compliance with company and state regulations.`
                                : 'Phổ biến, hướng dẫn và theo dõi, kiểm tra định kỳ các đơn vị thực hiện công tác thi đua khen thưởng đảm bảo theo đúng quy định của Công ty và nhà nước.'
                            }}</span></span
                          ></span
                        >
                      </li>
                      <li>
                        <span style="font-size: 12pt"
                          ><span style="font-family: 'Times New Roman'"
                            ><span style="color: #000000">{{
                              this.$i18n.locale === 'en'
                                ? `Disseminate, guide, and periodically check the implementation of emulation and reward practices to ensure compliance with company and state regulations.`
                                : 'Tham mưu, đề xuất các hình thức khen thưởng của cấp có thẩm quyền cho các tập thể, cá nhân trong công ty.'
                            }}</span></span
                          ></span
                        >
                      </li>
                      <li>
                        <span style="font-size: 12pt"
                          ><span style="font-family: 'Times New Roman'"
                            ><span style="color: #000000">{{
                              this.$i18n.locale === 'en'
                                ? `Compile and review reward nomination files for collectives and individuals, ensuring compliance; complete files for submission to competent authorities for rewards.`
                                : 'Tổng hợp hồ sơ đề nghị khen thưởng của các tập thể, cá nhân được đề nghị khen thưởng. Rà soát và đề nghị tập thể, cá nhân thực hiện đúng theo quy định. Hoàn thiện hồ sơ đề nghị cấp có thẩm quyền khen thưởng.'
                            }}</span></span
                          ></span
                        >
                      </li>
                      <li>
                        <span style="font-size: 12pt"
                          ><span style="font-family: 'Times New Roman'"
                            ><span style="color: #000000">{{
                              this.$i18n.locale === 'en'
                                ? `Compile, monitor, and summarize emulation and reward statistics for collectives and individuals; prepare annual summary reports and set key tasks for the following year.`
                                : 'Thống kê, theo dõi tổng hợp số liệu khen thưởng của tập thể, cá nhân. Lập báo cáo tổng kết công tác thi đua, khen thưởng hàng năm, nhiệm vụ trọng tâm năm tới.'
                            }}</span></span
                          ></span
                        >
                      </li>
                      <li>
                        <span style="font-size: 12pt"
                          ><span style="font-family: 'Times New Roman'"
                            ><span style="color: #000000">{{
                              this.$i18n.locale === 'en'
                                ? `Compile data and information from units, analyze, review, and prepare reports and plans for practicing thrift and combating waste.`
                                : 'Tổng hợp số liệu và thông tin từ các đơn vị, phân tích và rà soát, đối chiếu số liệu và lập báo cáo và kế hoạch thực hành tiết kiệm, chống lãng phí.'
                            }}</span></span
                          ></span
                        >
                      </li>
                      <li>
                        <span style="font-size: 12pt"
                          ><span style="font-family: 'Times New Roman'"
                            ><span style="color: #000000">{{
                              this.$i18n.locale === 'en'
                                ? `Perform annual asset and income declaration tasks.`
                                : 'Thực hiện công tác kê khai tài sản thu nhập hàng năm.'
                            }}</span></span
                          ></span
                        >
                      </li>
                    </ul></span
                  ></span
                >
              </li>
              <li style="list-style-type: disc">
                <span style="font-size: 12pt"
                  ><span style="font-family: 'Times New Roman'"
                    ><span style="color: #000000"
                      >{{
                        this.$i18n.locale === 'en'
                          ? `Supporting Department Head in Managing and Supervising Department Staff:`
                          : 'Hỗ trợ Trưởng phòng quản lý, điều hành công việc của nhân viên trong phòng:'
                      }}
                    </span>
                    <ul>
                      <li>
                        <span style="font-size: 12pt"
                          ><span style="font-family: 'Times New Roman'"
                            ><span style="color: #000000">{{
                              this.$i18n.locale === 'en'
                                ? `Determine human resource needs, propose to the Department Head, and participate in recruitment and training.`
                                : 'Xác định nhu cầu nhân sự, đề xuất Trưởng phòng và tham gia thực hiện tuyển dụng, đào tạo.'
                            }}</span></span
                          ></span
                        >
                      </li>
                      <li>
                        <span style="font-size: 12pt"
                          ><span style="font-family: 'Times New Roman'"
                            ><span style="color: #000000">{{
                              this.$i18n.locale === 'en'
                                ? `Assist the Department Head in planning, setting goals, assigning tasks, and guiding staff in task execution.`
                                : 'Hỗ trợ Trưởng phòng lập kế hoạch, xác định mục tiêu và phân công công việc, hướng dẫn nhân viên thực hiện công việc.'
                            }}</span></span
                          ></span
                        >
                      </li>
                      <li>
                        <span style="font-size: 12pt"
                          ><span style="font-family: 'Times New Roman'"
                            ><span style="color: #000000">{{
                              this.$i18n.locale === 'en'
                                ? `Support the Department Head in monitoring, supervising, and evaluating staff performance.`
                                : 'Hỗ trợ Trưởng phòng theo dõi, giám sát quá trình thực hiện và đánh giá kết quả thực hiện công việc của nhân viên. '
                            }}</span></span
                          ></span
                        >
                      </li>
                      <li>
                        <span style="font-size: 12pt"
                          ><span style="font-family: 'Times New Roman'"
                            ><span style="color: #000000">{{
                              this.$i18n.locale === 'en'
                                ? `Propose rewards, penalties, and motivational measures for staff.`
                                : 'Tham gia đề xuất khen thưởng, phạt và biện pháp động viên, khuyến khích nhân viên.'
                            }}</span></span
                          ></span
                        >
                      </li>
                    </ul></span
                  ></span
                >
              </li>
              <li style="list-style-type: disc">
                <span style="font-size: 12pt"
                  ><span style="font-family: 'Times New Roman'"
                    ><span style="color: #000000"
                      >{{
                        this.$i18n.locale === 'en'
                          ? `Perform Other Responsibilities:`
                          : 'Thực hiện các trách nhiệm công việc khác:'
                      }}
                    </span>
                    <ul>
                      <li>
                        <span style="font-size: 12pt"
                          ><span style="font-family: 'Times New Roman'"
                            ><span style="color: #000000">{{
                              this.$i18n.locale === 'en'
                                ? `Participate in developing and improving regulations, processes, and specialized procedures related to the assigned tasks.`
                                : 'Tham gia xây dựng cải tiến các quy chế, quy định, quy trình chuyên môn liên quan đến công tác phụ trách.'
                            }}</span></span
                          ></span
                        >
                      </li>
                      <li>
                        <span style="font-size: 12pt"
                          ><span style="font-family: 'Times New Roman'"
                            ><span style="color: #000000">{{
                              this.$i18n.locale === 'en'
                                ? `Participate in meetings, discussions, and conferences as required.`
                                : 'Tham gia các hội nghị, họp, thảo luận theo đề nghị.'
                            }}</span></span
                          ></span
                        >
                      </li>
                      <li>
                        <span style="font-size: 12pt"
                          ><span style="font-family: 'Times New Roman'"
                            ><span style="color: #000000">{{
                              this.$i18n.locale === 'en'
                                ? `Comply with planning and reporting regulations.`
                                : 'Thực hiện đúng chế độ lập kế hoạch và báo cáo theo quy định.'
                            }}</span></span
                          ></span
                        >
                      </li>
                      <li>
                        <span style="font-size: 12pt"
                          ><span style="font-family: 'Times New Roman'"
                            ><span style="color: #000000">{{
                              this.$i18n.locale === 'en'
                                ? `Comply with planning and reporting regulations.`
                                : 'Thực hiện các công việc khác theo chỉ đạo, yêu cầu của Trưởng phòng.'
                            }}</span></span
                          ></span
                        >
                      </li>
                    </ul></span
                  ></span
                >
              </li>
            </ul>

            <h2 style="text-align: justify">
              <span style="font-size: 16pt"
                ><span style="font-family: 'Times New Roman'"
                  ><span style="color: #000000">{{
                    $t('requirement')
                  }}</span></span
                ></span
              >
            </h2>

            <ul>
              <li style="list-style-type: disc">
                <span style="font-size: 12pt"
                  ><span style="font-family: 'Times New Roman'"
                    ><span style="color: #000000">{{
                      this.$i18n.locale === 'en'
                        ? `Education: Bachelor's degree or equivalent or higher in Labor Economics/Human Resource Management and related fields.`
                        : 'Trình độ: Đại học hoặc tương đương trở lên - Chuyên ngành: Kinh tế lao động/Quản trị nhân lực và các chuyên ngành liên quan.'
                    }}</span></span
                  ></span
                >
              </li>
              <li style="list-style-type: disc">
                <span style="font-size: 12pt"
                  ><span style="font-family: 'Times New Roman'"
                    ><span style="color: #000000">{{
                      this.$i18n.locale === 'en'
                        ? `Experience: At least 6 years of experience in HR work, including 2 years in management roles.`
                        : 'Kinh nghiệm: Tối thiểu 06 năm kinh nghiệm thực hiện công tác nhân sự, trong đó có 02 năm kinh nghiệm tham gia công tác quản lý.'
                    }}</span></span
                  ></span
                >
              </li>
              <li style="list-style-type: disc">
                <span style="font-size: 12pt"
                  ><span style="font-family: 'Times New Roman'"
                    ><span style="color: #000000">{{
                      this.$i18n.locale === 'en'
                        ? `Professional Competence: `
                        : 'Năng lực chuyên môn: '
                    }}</span>
                    <ul>
                      <li>
                        <span style="font-size: 12pt"
                          ><span style="font-family: 'Times New Roman'"
                            ><span style="color: #000000">{{
                              this.$i18n.locale === 'en'
                                ? `Solid understanding of the knowledge and methods for organizing HR functions within an organization (workforce planning, recruitment, training, labor incentives, evaluation, etc.).`
                                : 'Nắm vững các kiến thức và phương pháp tổ chức các chức năng quản trị nhân sự trong tổ chức (lập kế hoạch nhân sự, tuyển dụng, đào tạo, đãi ngộ lao động, đánh giá….).'
                            }}</span></span
                          ></span
                        >
                      </li>
                      <li>
                        <span style="font-size: 12pt"
                          ><span style="font-family: 'Times New Roman'"
                            ><span style="color: #000000">{{
                              this.$i18n.locale === 'en'
                                ? `Thorough knowledge of Labor Law, Social Insurance Law, and other relevant legal documents and regulations related to labor management and company policies.`
                                : 'Nắm vững các quy định của Luật lao động, Luật bảo hiểm,… và các văn bản, quy định có liên quan đến quản lý lao động, chế độ chính sách trong doanh nghiệp.'
                            }}</span></span
                          ></span
                        >
                      </li>
                      <li>
                        <span style="font-size: 12pt"
                          ><span style="font-family: 'Times New Roman'"
                            ><span style="color: #000000">{{
                              this.$i18n.locale === 'en'
                                ? `Comprehensive understanding of the company's organizational structure, the functions and tasks of the responsible unit, and related units. Familiarity with the organizational structure of similar entities in the same industry.`
                                : 'Nắm vững về cơ cấu tổ chức Công ty, chức năng nhiệm vụ đơn vị phụ trách và chức năng nhiệm vụ các đơn vị có liên quan. Nắm được cơ cấu tổ chức của các đơn vị tương tự cùng ngành nghề.'
                            }}</span></span
                          ></span
                        >
                      </li>
                      <li>
                        <span style="font-size: 12pt"
                          ><span style="font-family: 'Times New Roman'"
                            ><span style="color: #000000">{{
                              this.$i18n.locale === 'en'
                                ? `Mastery of the processes and regulations related to HR tasks.`
                                : 'Nắm vững hệ thống các quy trình, quy định liên quan đến công tác nhân sự phụ trách.'
                            }}</span></span
                          ></span
                        >
                      </li>
                      <li>
                        <span style="font-size: 12pt"
                          ><span style="font-family: 'Times New Roman'"
                            ><span style="color: #000000">{{
                              this.$i18n.locale === 'en'
                                ? `Knowledge of the company's safety, quality, environmental protection, and maritime labor management systems.`
                                : 'Có hiểu biết về Hệ thống quản lý an toàn, chất lượng, bảo vệ môi trường và lao động hàng hải của Công ty.'
                            }}</span></span
                          ></span
                        >
                      </li>
                    </ul>
                  </span></span
                >
              </li>
              <li style="list-style-type: disc">
                <span style="font-size: 12pt"
                  ><span style="font-family: 'Times New Roman'"
                    ><span style="color: #000000">{{
                      this.$i18n.locale === 'en'
                        ? `Supplementary Competence:`
                        : 'Năng lực bổ trợ:'
                    }}</span>
                    <ul>
                      <li>
                        <span style="font-size: 12pt"
                          ><span style="font-family: 'Times New Roman'"
                            ><span style="color: #000000">{{
                              this.$i18n.locale === 'en'
                                ? `Planning and organizational skills, ability to build and promote a culture of cooperation.`
                                : 'Kỹ năng lập kế hoạch và tổ chức thực hiện công việc, xây dựng và thúc đẩy văn hóa hợp tác.'
                            }}</span></span
                          ></span
                        >
                      </li>
                      <li>
                        <span style="font-size: 12pt"
                          ><span style="font-family: 'Times New Roman'"
                            ><span style="color: #000000">{{
                              this.$i18n.locale === 'en'
                                ? `Strong communication, negotiation, presentation, and persuasion skills..`
                                : 'Kỹ năng giao tiếp, đàm phán, thuyết trình, thuyết phục.'
                            }}</span></span
                          ></span
                        >
                      </li>
                      <li>
                        <span style="font-size: 12pt"
                          ><span style="font-family: 'Times New Roman'"
                            ><span style="color: #000000">{{
                              this.$i18n.locale === 'en'
                                ? `Decision-making and problem-solving abilities.`
                                : 'Kỹ năng ra quyết định và giải quyết vấn đề.'
                            }}</span></span
                          ></span
                        >
                      </li>
                      <li>
                        <span style="font-size: 12pt"
                          ><span style="font-family: 'Times New Roman'"
                            ><span style="color: #000000">{{
                              this.$i18n.locale === 'en'
                                ? `Skills in guiding, training, and mentoring employees.`
                                : 'Kỹ năng hướng dẫn, đào tạo, kèm cặp nhân viên.'
                            }}</span></span
                          ></span
                        >
                      </li>
                      <li>
                        <span style="font-size: 12pt"
                          ><span style="font-family: 'Times New Roman'"
                            ><span style="color: #000000">{{
                              this.$i18n.locale === 'en'
                                ? `Analytical skills and risk management capabilities.`
                                : 'Kỹ năng phân tích, quản lý rủi ro.'
                            }}</span></span
                          ></span
                        >
                      </li>
                      <li>
                        <span style="font-size: 12pt"
                          ><span style="font-family: 'Times New Roman'"
                            ><span style="color: #000000">{{
                              this.$i18n.locale === 'en'
                                ? `Relationship building and development skills.`
                                : 'Kỹ năng xây dựng và phát triển quan hệ.'
                            }}</span></span
                          ></span
                        >
                      </li>
                      <li>
                        <span style="font-size: 12pt"
                          ><span style="font-family: 'Times New Roman'"
                            ><span style="color: #000000">{{
                              this.$i18n.locale === 'en'
                                ? `Strategic thinking, the ability to initiate and drive change.`
                                : 'Tư duy chiến lược, khả năng khởi xướng và thúc đẩy những thay đổi.'
                            }}</span></span
                          ></span
                        >
                      </li>
                      <li>
                        <span style="font-size: 12pt"
                          ><span style="font-family: 'Times New Roman'"
                            ><span style="color: #000000">{{
                              this.$i18n.locale === 'en'
                                ? `Talent identification, motivation, and utilization skills.`
                                : 'Kỹ năng phát hiện, khơi dậy, động viên và sử dụng nhân tài.'
                            }}</span></span
                          ></span
                        >
                      </li>
                    </ul>
                  </span></span
                >
              </li>
              <li style="list-style-type: disc">
                <span style="font-size: 12pt"
                  ><span style="font-family: 'Times New Roman'"
                    ><span style="color: #000000">{{
                      this.$i18n.locale === 'en'
                        ? `Proficiency in English (listening, speaking, reading, writing) for conducting transactions and researching documents related to the field.`
                        : 'Có khả năng sử dụng tiếng Anh ở các kỹ năng nghe - nói - đọc - viết để thực hiện các giao dịch, nghiên cứu tài liệu liên quan đến lĩnh vực đảm nhiệm.'
                    }}</span></span
                  ></span
                >
              </li>
              <li style="list-style-type: disc">
                <span style="font-size: 12pt"
                  ><span style="font-family: 'Times New Roman'"
                    ><span style="color: #000000"
                      >{{
                        this.$i18n.locale === 'en'
                          ? `Proficient in using MS Office software, internal management software (if any), and information searching on the Internet.`
                          : 'Thành thạo sử dụng phần mềm văn phòng MS office, phần mềm quản trị tại nội bộ (nếu có) và tìm kiếm thông tin trên Internet.'
                      }}
                    </span></span
                  ></span
                >
              </li>
            </ul>

            <h2 style="text-align: justify">
              <span style="font-size: 16pt"
                ><span style="font-family: 'Times New Roman'"
                  ><span style="color: #000000">{{
                    $t('benefits')
                  }}</span></span
                ></span
              >
            </h2>

            <ul>
              <li style="list-style-type: disc">
                <span style="font-size: 12pt"
                  ><span style="font-family: 'Times New Roman'"
                    ><span style="color: #000000">{{ $t('benef1') }}</span>
                  </span></span
                >
              </li>
              <li style="list-style-type: disc">
                <span style="font-size: 12pt"
                  ><span style="font-family: 'Times New Roman'"
                    ><span style="color: #000000">{{
                      $t('benef2')
                    }}</span></span
                  ></span
                >
              </li>
              <li style="list-style-type: disc">
                <span style="font-size: 12pt"
                  ><span style="font-family: 'Times New Roman'"
                    ><span style="color: #000000">{{
                      $t('benef3')
                    }}</span></span
                  ></span
                >
              </li>
              <li style="list-style-type: disc">
                <span style="font-size: 12pt"
                  ><span style="font-family: 'Times New Roman'"
                    ><span style="color: #000000">{{
                      $t('benef4')
                    }}</span></span
                  ></span
                >
              </li>
              <li style="list-style-type: disc">
                <span style="font-size: 12pt"
                  ><span style="font-family: 'Times New Roman'"
                    ><span style="color: #000000">{{
                      $t('benef5')
                    }}</span></span
                  ></span
                >
              </li>
              <li style="list-style-type: disc">
                <span style="font-size: 12pt"
                  ><span style="font-family: 'Times New Roman'"
                    ><span style="color: #000000">{{
                      $t('benef6')
                    }}</span></span
                  ></span
                >
              </li>
              <li style="list-style-type: disc">
                <span style="font-size: 12pt"
                  ><span style="font-family: 'Times New Roman'"
                    ><span style="color: #000000">{{
                      $t('benef7')
                    }}</span></span
                  ></span
                >
              </li>
            </ul>
            <p
              style="
                font-style: italic;
                text-align: justify;
                font-size: 16px;
                font-family: 'Times New Roman';
              "
            >
              {{ $t('benefPara') }}
            </p>

            <h2 style="text-align: justify">
              <span style="font-size: 16pt"
                ><span style="font-family: 'Times New Roman'"
                  ><span style="color: #000000">{{
                    $t('workSchedule')
                  }}</span></span
                ></span
              >
            </h2>

            <p style="text-align: justify">
              <span style="font-size: 12pt"
                ><span style="font-family: 'Times New Roman'"
                  ><span style="color: #000000">{{
                    $t('montoFri')
                  }}</span></span
                ></span
              >
            </p>

            <ul>
              <li style="list-style-type: disc">
                <span style="font-size: 12pt"
                  ><span style="font-family: 'Times New Roman'"
                    ><span style="color: #000000"
                      >{{ $t('morning') }}:
                    </span></span
                  ></span
                ><span style="font-size: 12pt"
                  ><span style="font-family: 'Times New Roman'"
                    ><span style="color: #000000">8h00 -12h00</span></span
                  ></span
                >
              </li>
              <li style="list-style-type: disc">
                <span style="font-size: 12pt"
                  ><span style="font-family: 'Times New Roman'"
                    ><span style="color: #000000"
                      >{{ $t('afternoon') }}: 13h00- 17h00</span
                    ></span
                  ></span
                >
              </li>
            </ul>

            <h2 style="text-align: justify">
              <span style="font-size: 16pt"
                ><span style="font-family: 'Times New Roman'"
                  ><span style="color: #000000">{{
                    $t('recruitDoc')
                  }}</span></span
                ></span
              >
            </h2>
            <ul>
              <li style="list-style-type: disc">
                <span style="font-size: 12pt"
                  ><span style="font-family: 'Times New Roman'"
                    ><span style="color: #000000">{{
                      $t('recruit1')
                    }}</span></span
                  ></span
                >
              </li>
              <li style="list-style-type: disc">
                <span style="font-size: 12pt"
                  ><span style="font-family: 'Times New Roman'"
                    ><span style="color: #000000"
                      >{{ $t('recruit2') }}
                    </span></span
                  ></span
                >
              </li>
            </ul>

            <h2 style="text-align: justify">
              <span style="font-size: 16pt"
                ><span style="font-family: 'Times New Roman'"
                  ><span style="color: #000000">{{
                    $t('methodApply')
                  }}</span></span
                ></span
              >
            </h2>
            <ul>
              <li style="list-style-type: disc">
                <span style="font-size: 12pt"
                  ><span style="font-family: 'Times New Roman'"
                    ><span style="color: #000000">
                      <li style="list-style-type: disc">
                        <span style="font-size: 12pt"
                          ><span style="font-family: 'Times New Roman'"
                            ><span style="color: #000000"
                              >{{ $t('method1') }}
                            </span></span
                          ></span
                        >
                      </li>
                    </span></span
                  ></span
                >
              </li>
              <li style="list-style-type: disc">
                <span style="font-size: 12pt"
                  ><span style="font-family: 'Times New Roman'"
                    ><span style="color: #000000">
                      <li style="list-style-type: disc">
                        <span style="font-size: 12pt"
                          ><span style="font-family: 'Times New Roman'"
                            ><span style="color: #000000">
                              <div v-html="$t('method2')"></div> </span></span
                        ></span>
                      </li> </span></span
                ></span>
              </li>
            </ul>

            <h2 style="text-align: justify">
              <span style="font-size: 16pt"
                ><span style="font-family: 'Times New Roman'"
                  ><span style="color: #000000">{{
                    $t('contactInfo')
                  }}</span></span
                ></span
              >
            </h2>
            <ul>
              <li style="list-style-type: disc">
                <span style="font-size: 12pt"
                  ><span style="font-family: 'Times New Roman'"
                    ><span style="color: #000000">{{
                      $t('contactInfo1')
                    }}</span></span
                  ></span
                >
              </li>
              <li style="list-style-type: disc">
                <span style="font-size: 12pt"
                  ><span style="font-family: 'Times New Roman'"
                    ><span style="color: #000000"
                      >{{ $t('contactInfo2') }}
                    </span></span
                  ></span
                >
              </li>
              <li style="list-style-type: disc">
                <span style="font-size: 12pt"
                  ><span style="font-family: 'Times New Roman'"
                    ><span style="color: #000000"
                      >Email: hr@vimc-lines.com
                    </span></span
                  ></span
                >
              </li>
            </ul>
            <p
              style="
                font-style: italic;
                text-align: justify;
                font-size: 16px;
                font-family: 'Times New Roman';
              "
            >
              {{ $t('contactPara') }}
            </p>
            <span style=""></span>
          </div>

          <div class="another-job">
            <h3>{{ $t('otherJobAvailable') }}</h3>
            <div class="row">
              <div class="col-12 col-md-6">
                <a href="/recruitment/detail/2">
                  <div class="job-item">
                    <p
                      class="name"
                      style="color: #000000; font-weight: 700; font-size: 15px"
                    >
                      {{ $t('genAccountEmploy') }}
                    </p>
                    <div class="row">
                      <div class="col-5">
                        <p class="deadline">
                          <i class="fa fa-calendar"></i>
                          15-8-2024
                        </p>
                        <div style="padding-bottom: 5px"></div>
                        <p class="location-recruitment">
                          <img src="../../../libhome/img/location.svg" />
                          {{ $t('location') }}
                        </p>
                        <div style="padding-bottom: 10px"></div>
                      </div>
                      <div class="col-6">
                        <p class="salary">
                          <img src="../../../libhome/img/salary.svg" />
                          {{ $t('agreement') }}
                        </p>
                        <div style="padding-bottom: 5px"></div>
                        <p class="worktime">
                          <i class="fa fa-clock"></i> 8h00 - 17h00
                        </p>
                      </div>
                      <div class="col-1 pr-0">
                        <span class="fa fa-angle-right"></span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div class="col-12 col-md-6 recruitment-item">
                <a href="/recruitment/detail/1">
                  <div class="job-item">
                    <p
                      class="name"
                      style="color: #000000; font-weight: 700; font-size: 15px"
                    >
                      {{ $t('bussEmployee') }}
                    </p>
                    <div class="row">
                      <div class="col-5">
                        <p class="deadline">
                          <i class="fa fa-calendar"></i>
                          15-8-2024
                        </p>
                        <div style="margin-bottom: 10px"></div>
                        <p class="location-recruitment">
                          <img src="../../../libhome/img/location.svg" />
                          {{
                            this.$i18n.locale == 'en'
                              ? 'Hanoi, Hai Phong, Ho Chi Minh'
                              : `Hà Nội, Hải Phòng,
                                             Hồ Chí Minh`
                          }}
                        </p>
                      </div>
                      <div class="col-6">
                        <p class="salary">
                          <img src="../../../libhome/img/salary.svg" />
                          {{ $t('agreement') }}
                        </p>
                        <div style="padding-bottom: 5px"></div>
                        <p class="worktime">
                          <i class="fa fa-clock"></i> 8h00 - 17h00
                        </p>
                      </div>
                      <div class="col-1 pr-0">
                        <span class="fa fa-angle-right"></span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div
                class="col-12 col-md-6 recruitment-item"
                style="display: block"
              >
                <a href="/recruitment/detail/4">
                  <div class="job-item">
                    <p
                      class="name"
                      style="color: #000000; font-weight: 700; font-size: 15px"
                    >
                      {{ $t('techEngineer') }}
                    </p>
                    <span style="padding-top: 7px"></span>
                    <div class="row">
                      <div class="col-5">
                        <p class="deadline">
                          <i class="fa fa-calendar"></i>
                          15-7-2024
                        </p>
                        <div style="padding-bottom: 5px"></div>
                        <p
                          class="location-recruitment"
                          style="padding-top: 5px"
                        >
                          <img src="../../../libhome/img/location.svg" />
                          {{ $t('location') }}
                        </p>
                      </div>
                      <div class="col-6">
                        <p class="salary">
                          <img src="../../../libhome/img/salary.svg" />
                          {{ $t('agreement') }}
                        </p>
                        <div style="padding-bottom: 5px"></div>
                        <p class="worktime">
                          <i class="fa fa-clock"></i> 8h00 - 17h00
                        </p>
                      </div>
                      <div class="col-1 pr-0">
                        <span class="fa fa-angle-right"></span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div
                class="col-12 col-md-6 recruitment-item"
                style="display: block"
              >
                <a href="/recruitment/detail/3">
                  <div class="job-item">
                    <p
                      class="name"
                      style="color: #000000; font-weight: 700; font-size: 15px"
                    >
                      {{ $t('itEmployee') }}
                    </p>
                    <span style="padding-top: 7px"></span>
                    <div class="row">
                      <div class="col-5">
                        <p class="deadline">
                          <i class="fa fa-calendar"></i>
                          15-8-2024
                        </p>
                        <div style="padding-bottom: 5px"></div>
                        <p
                          class="location-recruitment"
                          style="padding-top: 5px"
                        >
                          <img src="../../../libhome/img/location.svg" />
                          {{ $t('location') }}
                        </p>
                      </div>
                      <div class="col-6">
                        <p class="salary">
                          <img src="../../../libhome/img/salary.svg" />
                          {{ $t('agreement') }}
                        </p>
                        <div style="padding-bottom: 5px"></div>
                        <p class="worktime">
                          <i class="fa fa-clock"></i> 8h00 - 17h00
                        </p>
                      </div>
                      <div class="col-1 pr-0">
                        <span class="fa fa-angle-right"></span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div
                class="col-12 col-md-6 recruitment-item"
                style="display: block"
              >
                <a href="/recruitment/detail/6">
                  <div class="job-item">
                    <p
                      class="name"
                      style="color: #000000; font-weight: 700; font-size: 15px"
                    >
                      {{ $t('AO-VME') }}
                    </p>
                    <span style="padding-top: 7px"></span>
                    <div class="row">
                      <div class="col-5">
                        <p class="deadline">
                          <i class="fa fa-calendar"></i>
                          15-8-2024
                        </p>
                        <div style="padding-bottom: 5px"></div>
                        <p
                          class="location-recruitment"
                          style="padding-top: 5px"
                        >
                          <img src="../../../libhome/img/location.svg" />
                          {{ $t('location') }}
                        </p>
                      </div>
                      <div class="col-6">
                        <p class="salary">
                          <img src="../../../libhome/img/salary.svg" />
                          {{ $t('agreement') }}
                        </p>
                        <div style="padding-bottom: 5px"></div>
                        <p class="worktime">
                          <i class="fa fa-clock"></i> 8h00 - 17h00
                        </p>
                      </div>
                      <div class="col-1 pr-0">
                        <span class="fa fa-angle-right"></span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div class="row align-items-center justify-content-center">
            <section class="recruitment-list">
              <div class="container">
                <div class="row">
                  <div class="col-md-20">
                    <div class="curriculum-vitae">
                      <div class="row">
                        <div class="col-12 col-md-6">
                          <div class="text">
                            <p
                              class="label"
                              ref="apply"
                              v-html="$t('recApply')"
                            ></p>
                            <p class="desc">
                              {{ $t('reacContent1') }}
                            </p>
                            <p class="desc">
                              {{ $t('reacContent2') }}
                            </p>
                            <p
                              class="support-line"
                              v-html="$t('reacContent3')"
                            ></p>
                          </div>
                        </div>
                        <div class="col-12 col-md-6">
                          <div class="form">
                            <form
                              @submit.prevent="sendEmail"
                              ref="form"
                            >
                              <input
                                type="hidden"
                                name="_token"
                                value="tUJCQfTUaRbmxLqP62LxN27j10jiTMOFtrcgwl11"
                              />
                              <input
                                type="password"
                                name="password"
                                value=""
                                hidden
                              />
                              <div class="form-group">
                                <input
                                  type="text"
                                  class="form-control"
                                  name="name"
                                  :placeholder="
                                    this.$i18n.locale === 'en'
                                      ? 'Full Name'
                                      : 'Họ và tên'
                                  "
                                  v-model="form.name"
                                />
                              </div>
                              <div class="form-group">
                                <input
                                  type="email"
                                  class="form-control"
                                  name="email"
                                  placeholder="Email"
                                  v-model="form.email"
                                />
                              </div>
                              <div class="form-group">
                                <input
                                  type="text"
                                  class="form-control"
                                  name="phone"
                                  :placeholder="$t('phone')"
                                  v-model="form.phone"
                                />
                              </div>
                              <div class="form-group">
                                <select
                                  class="form-control"
                                  name="recruitment_id"
                                  :placeholder="$t('recPosition')"
                                  v-model="form.position"
                                >
                                  <option value="">
                                    {{
                                      this.$i18n.locale === 'en'
                                        ? 'Apply for the position'
                                        : 'Ứng tuyển vào vị trí này'
                                    }}
                                  </option>
                                  <option :value="$t('bussDepart')">
                                    {{ $t('bussDepart') }}
                                  </option>
                                  <option :value="$t('acountDepart')">
                                    {{ $t('acountDepart') }}
                                  </option>
                                  <option :value="$t('itDepart')">
                                    {{ $t('itDepart') }}
                                  </option>
                                  <option :value="$t('HRD')">
                                    {{ $t('HRD') }}
                                  </option>
                                </select>
                              </div>
                              <div
                                class="d-flex justify-content-between sp-column"
                              >
                                <label
                                  for="upload_cv"
                                  class="add-cv"
                                  ><i class="fa fa-file"></i
                                  >{{ $t('uploadCV') }}</label
                                >
                                <input
                                  type="file"
                                  name="cv"
                                  id="upload_cv"
                                  @change="handleFileUpload"
                                  v-show="!isHidden"
                                />

                                <button
                                  type="submit"
                                  class="send"
                                  :disabled="isLoading"
                                >
                                  <span v-if="isLoading">
                                    <i class="fa fa-spinner fa-spin"></i>
                                    {{ $t('sendCV') }}
                                    <!-- Icon loading -->
                                  </span>
                                  <span v-else>
                                    {{ $t('sendCV') }}
                                  </span>
                                </button>
                              </div>
                              <span
                                v-if="selectedFile"
                                style="
                                  display: flex;
                                  margin: 5px 0 0 20px;
                                  font-size: 16px;
                                  color: #fff;
                                "
                                >{{ selectedFile.name }}</span
                              >
                              <div class="alert"></div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <!-- About End -->
    </div>

    <FooterHome />

    <!-- Back to top button -->
    <a
      href="/home"
      class="back-to-top"
      ><i class="fa fa-chevron-up"></i
    ></a>

    <NavBarLeftHome />
  </main>
</template>
<script>
import NavBarHome from '@/views/Home/PageChild/NavBarHome.vue';
import FooterHome from '@/views/Home/PageChild/FooterHome.vue';
import NavBarLeftHome from '@/views/Home/PageChild/NavBarLeftHome.vue';

export default {
  components: { NavBarLeftHome, FooterHome, NavBarHome },
  data() {
    return {
      form: {
        name: '',
        email: '',
        phone: '',
        position: '',
      },
      attachment: null,
      isHidden: true,
      selectedFile: null,
      isLoading: false,
      fieldErrors: {
        name: false,
        email: false,
        phone: false,
        position: false,
        attachment: false,
      },
      errorMessages: {
        name: {
          en: 'Please enter your full name',
          vn: 'Vui lòng nhập họ tên của bạn',
        },
        email: {
          en: 'Please enter your email',
          vn: 'Vui lòng nhập địa chỉ email của bạn',
        },
        phone: {
          en: 'Please enter your phone number',
          vn: 'Vui lòng nhập số điện thoại của bạn',
        },
        position: {
          en: 'Please select the position',
          vn: 'Vui lòng chọn vị trí ứng tuyển',
        },
        attachment: {
          en: 'Please upload your CV',
          vn: 'Vui lòng tải lên CV của bạn',
        },
      },
    };
  },
  methods: {
    scrollToApplySection() {
      this.$nextTick(() => {
        var applySection = this.$refs.apply;
        if (applySection) {
          applySection.scrollIntoView({ behavior: 'smooth' });
        }
      });
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      this.selectedFile = file;
      this.attachment = event.target.files[0];
    },
    async sendEmail() {
      this.isLoading = true;

      // Validate form fields
      if (!this.form.name) {
        this.$toast.error(
          this.$i18n.locale === 'en'
            ? 'Please enter your full name'
            : 'Vui lòng nhập họ và tên'
        );
        this.isLoading = false;
        return;
      }

      if (!this.form.email) {
        this.$toast.error(
          this.$i18n.locale === 'en'
            ? 'Please enter your email'
            : 'Vui lòng nhập email'
        );
        this.isLoading = false;
        return;
      }

      if (!this.form.phone) {
        this.$toast.error(
          this.$i18n.locale === 'en'
            ? 'Please enter your phone number'
            : 'Vui lòng nhập số điện thoại'
        );
        this.isLoading = false;
        return;
      }

      if (!this.form.position) {
        this.$toast.error(
          this.$i18n.locale === 'en'
            ? 'Please select the position'
            : 'Vui lòng nhập vị trí'
        );
        this.isLoading = false;
        return;
      }

      if (!this.selectedFile) {
        this.$toast.error(
          this.$i18n.locale === 'en'
            ? 'Please upload your CV'
            : 'Vui lòng nhập CV'
        );
        this.isLoading = false;
        return;
      }

      const formData = new FormData();
      formData.append('name', this.form.name);
      formData.append('from', this.form.email);
      formData.append('position', this.form.position);
      formData.append('phone', this.form.phone);
      formData.append('cv', this.attachment);

      try {
        const response = await fetch(
          'https://sendmail-tl91.onrender.com/send-email1',
          {
            method: 'POST',
            body: formData,
          }
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        this.$toast.success(
          `${
            this.$i18n.locale === 'en'
              ? 'Email sent successfully'
              : 'Email đã được gửi'
          }`
        );
        // this.$refs.form.reset();
        this.form.name = '';
        this.form.email = '';
        this.form.phone = '';
        this.form.position = '';
        this.selectedFile = null;
      } catch (error) {
        this.$toast.error(
          `${
            this.$i18n.locale === 'en'
              ? 'Error when sending email'
              : 'Có lỗi khi gửi email'
          }`
        );
      } finally {
        this.isLoading = false;
      }
    },
  },
};

('use strict');

window.addEventListener('scroll', function () {
  const navbar = document.getElementById('navbarCr');
  if (navbar) {
    if (window.scrollY > 0) {
      navbar.style.marginTop = '0px';
    } else {
      navbar.style.marginTop = '10px';
    }
  }
});

document.addEventListener('DOMContentLoaded', function () {
  const openBtn = document.querySelector('.nav-column-open-btn');
  const closeBtn = document.querySelector('.nav-column-close-btn');
  const overlay = document.querySelector('.overlay');
  const navbarColumn = document.querySelector('.navbar-column');
  if (openBtn) {
    openBtn.addEventListener('click', function () {
      navbarColumn.classList.add('active');
      overlay.classList.add('active');
    });

    closeBtn.addEventListener('click', function () {
      navbarColumn?.classList.remove('active');
      overlay?.classList.remove('active');
    });

    overlay.addEventListener('click', function () {
      navbarColumn.classList.remove('active');
      overlay.classList.remove('active');
    });
  }
});
</script>
<style>
/*
Removes white gap between slides
*/
.carousel {
  position: relative;
  width: 100%;
  min-height: 400px;
  background: #ffffff;
  margin-bottom: 45px;
}

.carousel-item .img-fluid {
  width: 100%;
  height: 850px;
}

.row {
  /* display: flex; */
  /* -ms-flex-wrap: wrap; */
  flex-wrap: wrap;
  width: 100%;
  border: none;
}

.common-info {
  width: 100%;
  max-width: 945px;
  margin: 0 auto;
  background-color: #e8734e;
  border-radius: 10px;
  padding: 40px 90px;
  color: #ffffff;
}
.label h3 {
  font-size: 24px;
  color: #e8734e;
  text-transform: uppercase;
  font-weight: 700;
}

.label p button {
  padding: 15px 60px;
  margin-left: 10px;
  background-color: #fde585;
  text-transform: uppercase;
  border-radius: 30px;
  border: none;
  outline: none;
  color: #e8734e;
  font-size: 16px;
  font-weight: 600;
}

.common-info h5 {
  font-size: 20px;
  font-weight: 700;
  color: #fff;
}
.common-info .list-info .item {
  margin: 15px 0;
}

.common-info .list-info .item .image {
  background-color: #ffffff;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 10px;
}

.common-info .list-info .item .image img {
  width: 35px;
  height: auto;
  object-fit: contain;
}

.common-info .list-info .item .text {
  display: flex;
  align-items: center;
  justify-content: center;
}

.address {
  width: 100%;
  max-width: 945px;
  margin: 15px auto;
  background-color: #fde585;
  border-radius: 10px;
  padding: 40px 90px;
  color: #000;
}

.address h5 {
  color: #000;
}

.job-desc {
  width: 100%;
  max-width: 945px;
  margin: 0 auto;
  background-color: #fef4f0;
  border-radius: 10px;
  padding: 15px 90px;
  color: #000000;
}
.another-job {
  margin-top: 80px;
}

.another-job h3 {
  text-align: center;
  font-size: 24px;
  color: #e8734e;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 20px;
}

.another-job .job-item {
  padding: 15px;
  border-radius: 5px;
  background-color: #fef4f0;
  transition: all ease 0.3s;
  height: 160px;
}
.another-job .job-item .deadline {
  color: #000;
  font-size: 14px;
}

.another-job .job-item .deadline i {
  color: #e8734e;
  font-size: 14px;
}

.another-job .job-item:hover .deadline i,
.another-job .job-item:hover .worktime i {
  color: #fff;
  font-size: 14px;
}

.another-job .job-item:hover .location-recruitment img,
.another-job .job-item:hover .salary img {
  filter: brightness(0);
  color: #000000;
}

.another-job .job-item .deadline p {
  color: #000;
  font-size: 14px;
}

.another-job .job-item .location {
  color: #000;
  font-size: 14px;
}
.another-job .job-item .salary {
  color: #000;
  font-size: 14px;
}

.another-job .job-item .worktime {
  color: #000;
  font-size: 14px;
}

.another-job .job-item .worktime i {
  color: #e8734e;
  font-size: 14px;
}

.another-job .job-item .worktime p {
  color: #000;
  font-size: 14px;
}

.recruitment-item {
  display: block;
  /* margin-bottom: 20px; */
}

.recruitment-item a {
  text-decoration: none;
  color: inherit;
}

.job-item {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  color: #fef4f0;
  transition: background-color 0.3s, color 0.3s;
}

.job-item:hover {
  background-color: #e8734e;
  color: black;
}

.job-item:hover .fa-calendar {
  color: #000;
  filter: invert(100%);
}

.job-item:hover .fa-clock {
  color: #000;
  filter: invert(100%);
}

.job-item p {
  margin: 0;
  padding: 2px;
  color: #000000;
}

.name {
  font-size: 18px;
  font-weight: bold;
}

.deadline,
.location-recruitment,
.salary,
.worktime {
  margin-top: 10px;
  font-size: 14px;
}

.location-recruitment img,
.salary img {
  width: 16px;
  height: 16px;
  vertical-align: middle;
  margin-right: 5px;
}

.fa-calendar,
.fa-clock {
  margin-right: 5px;
}

.curriculum-vitae {
  /* margin-top: 80px; */
  border-radius: 10px;
  background-color: #e8734a;
  padding: 45px;
  width: 1167px;
}
</style>