<template>
  <div class="">
    <ShippingLeadsListItem />
    <ShippingLeadsListItem />
    <ShippingLeadsListItem />
    <ShippingLeadsListItem />
    <ShippingLeadsListItem />
    <ShippingLeadsListItem />
    <ShippingLeadsListItem />
  </div>
</template>

<script>
import ShippingLeadsListItem from './ShippingLeadsListItem.vue';
export default {
  components: { ShippingLeadsListItem },
};
</script>

<style></style>
