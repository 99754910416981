import { vslApi } from '@/api';

export default Object.freeze({
  searchContainerReleaseOrder({ searchNo }) {
    return vslApi.get(
      `/v1/container-release-orders/search?search_no=${searchNo}`,
      {
        headers: { common: { delayed: true } },
      }
    );
  },
});
