var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    _vm._g(
      _vm._b(
        {
          staticClass:
            "d-flex flex-column justify-center align-center text-center font-italic text--secondary pb-12",
          attrs: {
            color: _vm.background,
            elevation: "0",
            "min-height": _vm.minHeight,
          },
        },
        "v-card",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c("v-img", {
        attrs: {
          src: "/assets/icons/empty-state-1.png",
          "max-height": "250",
          contain: "",
        },
      }),
      _c("div", [_vm._v(_vm._s(_vm.text))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }