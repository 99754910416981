var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    [
      _c("NavBarHome", { attrs: { active: "blog" } }),
      _c(
        "div",
        {
          staticClass: "carousel slide carousel-fade",
          attrs: {
            id: "carousel-2",
            "data-ride": "carousel",
            "data-interval": "6000",
          },
        },
        [
          _c(
            "div",
            { staticClass: "carousel-inner", attrs: { role: "listbox" } },
            [
              _c("div", { staticClass: "carousel-item active" }, [
                _c("img", {
                  staticClass: "d-block img-fluid",
                  attrs: {
                    src: require("../../../libhome/img/vimc diamond.png"),
                    alt: "responsive image",
                  },
                }),
                _c("div", { staticClass: "carousel-caption" }, [
                  _c("div", [
                    _c("div", { staticClass: "transx_content_slide_wrapper" }, [
                      _c("div", { staticClass: "transx_content_container" }, [
                        _c("div", { staticClass: "transx_content_wrapper_1" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "transx_up_heading_overlay transx_overlay_align_center",
                            },
                            [_vm._v(" VIMC LINES ")]
                          ),
                          _c(
                            "h2",
                            { staticClass: "transx_content_slider_title" },
                            [_vm._v(" " + _vm._s(_vm.$t("blogs")) + " ")]
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
        ]
      ),
      _c("div", { staticClass: "transx_page_content_wrapper" }, [
        _c("div", { staticClass: "blog" }, [
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "row align-items-center" }, [
              _c(
                "div",
                {
                  staticClass:
                    "post-36 page type-page status-publish has-post-thumbnail hentry",
                  attrs: { id: "post-36" },
                },
                [
                  _c("div", { staticClass: "transx_page_content_container" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "transx_page_content_wrapper transx_page_title_show",
                      },
                      [
                        _c("div", { staticClass: "container" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "row transx_sidebar_right transx_bg_color_alt",
                            },
                            [
                              _c("div", { staticClass: "col-lg-8 col-xl-8" }, [
                                _c(
                                  "div",
                                  { staticClass: "transx_content_wrapper" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "elementor elementor-36",
                                        attrs: {
                                          "data-elementor-type": "wp-page",
                                          "data-elementor-id": "36",
                                          "data-elementor-settings": "[]",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "elementor-section-wrap",
                                          },
                                          [
                                            _c(
                                              "section",
                                              {
                                                staticClass:
                                                  "elementor-section-blog elementor-top-section elementor-element elementor-element-3561e36f elementor-section-boxed elementor-section-height-default elementor-section-height-default",
                                                attrs: {
                                                  "data-id": "3561e36f",
                                                  "data-element_type":
                                                    "section",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "elementor-container elementor-column-gap-default",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-54b91072",
                                                        attrs: {
                                                          "data-id": "54b91072",
                                                          "data-element_type":
                                                            "column",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "elementor-widget-wrap elementor-element-populated",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "elementor-element elementor-element-43bce01d elementor-widget elementor-widget-transx_blog_listing",
                                                                attrs: {
                                                                  "data-id":
                                                                    "43bce01d",
                                                                  "data-element_type":
                                                                    "widget",
                                                                  "data-widget_type":
                                                                    "transx_blog_listing.default",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "elementor-widget-container",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "transx_blog_listing_widget",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "transx_blog_listing_wrapper transx_view_type_1",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "transx_blog_listing_item",
                                                                              },
                                                                              [
                                                                                _vm._m(
                                                                                  0
                                                                                ),
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "transx_blog_listing_content_wrapper",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "transx_blog_listing_meta",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "span",
                                                                                          {
                                                                                            staticClass:
                                                                                              "transx_blog_listing_date",
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                _vm.$t(
                                                                                                  "day3"
                                                                                                )
                                                                                              )
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _c(
                                                                                          "span",
                                                                                          {
                                                                                            staticClass:
                                                                                              "transx_separator",
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "/"
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _c(
                                                                                          "span",
                                                                                          {
                                                                                            staticClass:
                                                                                              "transx_blog_listing_author",
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                this
                                                                                                  .$i18n
                                                                                                  .locale ===
                                                                                                  "en"
                                                                                                  ? "by"
                                                                                                  : "bởi"
                                                                                              ) +
                                                                                                " Vimc Lines"
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "h3",
                                                                                      {
                                                                                        staticClass:
                                                                                          "transx_blog_listing_title",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "a",
                                                                                          {
                                                                                            attrs:
                                                                                              {
                                                                                                href: "",
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                _vm.$t(
                                                                                                  "blogtTitle3"
                                                                                                )
                                                                                              )
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "p",
                                                                                      {
                                                                                        staticStyle:
                                                                                          {
                                                                                            "text-align":
                                                                                              "justify",
                                                                                            margin:
                                                                                              "5px 0",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              this
                                                                                                .$i18n
                                                                                                .locale ===
                                                                                                "en"
                                                                                                ? `On June 12th, General Director Nguyen Canh Tinh and the delegation from Vietnam Maritime Corporation (VIMC) conducted a working session to inspect the progress of construction for Container Terminals 3 and 4 at the Hai Phong International Gateway Port in Lach Huyen.`
                                                                                                : "Ngày 12/6, Tổng giám đốc Nguyễn Cảnh Tĩnh cùng đoàn công tác Tổng công ty Hàng hải Việt Nam (VIMC) đã có buổi làm việc, kiểm tra tiến độ thi công Dự án đầu tư xây dựng hai bến container số 3,4 Cảng cửa ngõ quốc tế Hải Phòng tại Lạch Huyện."
                                                                                            ) +
                                                                                            " "
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "div",
                                                                                      [
                                                                                        _c(
                                                                                          "img",
                                                                                          {
                                                                                            attrs:
                                                                                              {
                                                                                                src: "https://vimc.co/wp-content/uploads/2024/06/CHP_12.6.24.jpg",
                                                                                                alt: "",
                                                                                                width:
                                                                                                  "650",
                                                                                              },
                                                                                          }
                                                                                        ),
                                                                                        _c(
                                                                                          "span",
                                                                                          {
                                                                                            staticStyle:
                                                                                              {
                                                                                                display:
                                                                                                  "flex",
                                                                                                "align-items":
                                                                                                  "center",
                                                                                                "justify-content":
                                                                                                  "center",
                                                                                                "font-size":
                                                                                                  "12px",
                                                                                                "font-style":
                                                                                                  "italic",
                                                                                                "margin-top":
                                                                                                  "5px",
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                this
                                                                                                  .$i18n
                                                                                                  .locale ===
                                                                                                  "en"
                                                                                                  ? `General Director Nguyen Canh Tinh and the VIMC delegation inspected the construction site of the project.`
                                                                                                  : "Tổng giám đốc Nguyễn Cảnh Tĩnh và đoàn công tác VIMC kiểm tra công trường thi công Dự án"
                                                                                              ) +
                                                                                                " "
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "p",
                                                                                      {
                                                                                        staticStyle:
                                                                                          {
                                                                                            "text-align":
                                                                                              "justify",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              this
                                                                                                .$i18n
                                                                                                .locale ===
                                                                                                "en"
                                                                                                ? `Upon the delegation's arrival at Hai Phong Port, they were received by Chairman of the Board Pham Hong Minh, Board Member and General Director Nguyen Tuong Anh, Deputy General Director Ha Vu Hao, along with representatives from various departments.`
                                                                                                : "Về phía Cảng Hải Phòng, đón và tiếp đoàn có Chủ tịch Hội đồng quản trị Phạm Hồng Minh; Thành viên HĐQT, Tổng Giám đốc Nguyễn Tường Anh; Phó Tổng giám đốc Hà Vũ Hào; cùng đại diện lãnh đạo các phòng nghiệp vụ."
                                                                                            ) +
                                                                                            " "
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "p",
                                                                                      {
                                                                                        staticStyle:
                                                                                          {
                                                                                            "text-align":
                                                                                              "justify",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              this
                                                                                                .$i18n
                                                                                                .locale ===
                                                                                                "en"
                                                                                                ? ` During the working session, Hai Phong Port's leadership reported that as of the end of May, approximately 80% of the total construction volume had been completed, ensuring progress aligned with the overall project schedule. Construction activities are being executed according to plan, with specific milestones such as the completion of two port bridges achieved three months ahead of schedule.`
                                                                                                : "Báo cáo tại buổi làm việc, lãnh đạo Cảng Hải Phòng cho biết tính đến hết tháng 5, lũy kế giá trị thi công đã đạt khoảng 80% tổng khối lượng thi công, đảm bảo tiến độ thực hiện các công việc theo tiến độ tổng thể chung của Dự án. Các hạng mục thi công đang được đơn vị triển khai đảm bảo kế hoạch đề ra. Trong đó hạng mục xây dựng hai cầu cảng đã hoàn thành vượt tiến độ 3 tháng."
                                                                                            ) +
                                                                                            " "
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "div",
                                                                                      [
                                                                                        _c(
                                                                                          "img",
                                                                                          {
                                                                                            attrs:
                                                                                              {
                                                                                                src: "https://haiphongport.com.vn/FileUpload/Images/z5532393920881_92ef145564592fb6a583904b7cadf792.jpg",
                                                                                                alt: "",
                                                                                                width:
                                                                                                  "650",
                                                                                              },
                                                                                          }
                                                                                        ),
                                                                                        _c(
                                                                                          "span",
                                                                                          {
                                                                                            staticStyle:
                                                                                              {
                                                                                                display:
                                                                                                  "flex",
                                                                                                "align-items":
                                                                                                  "center",
                                                                                                "justify-content":
                                                                                                  "center",
                                                                                                "font-size":
                                                                                                  "12px",
                                                                                                "font-style":
                                                                                                  "italic",
                                                                                                "margin-top":
                                                                                                  "5px",
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                this
                                                                                                  .$i18n
                                                                                                  .locale ===
                                                                                                  "en"
                                                                                                  ? `The overall view shows that both port bridges have been completed.`
                                                                                                  : "Toàn cảnh hai cầu cảng đã hoàn thiện"
                                                                                              ) +
                                                                                                " "
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "p",
                                                                                      {
                                                                                        staticStyle:
                                                                                          {
                                                                                            "text-align":
                                                                                              "justify",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              this
                                                                                                .$i18n
                                                                                                .locale ===
                                                                                                "en"
                                                                                                ? `Currently, the contractor is mobilizing maximum resources, including manpower and equipment, to ensure synchronized construction activities and achieve high-quality results. The goal is to complete and hand over the project as per the committed schedule.`
                                                                                                : "Hiện tại, nhà thầu đang huy động tối đa nguồn lực bao gồm nhân lực và thiết bị để đảm bảo các hạng mục thi công đồng bộ và đạt chất lượng cao. Mục tiêu là hoàn thành và bàn giao công trình theo đúng cam kết."
                                                                                            ) +
                                                                                            " "
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "div",
                                                                                      [
                                                                                        _c(
                                                                                          "img",
                                                                                          {
                                                                                            attrs:
                                                                                              {
                                                                                                src: "https://haiphongport.com.vn/FileUpload/Images/img_8641_3.JPG",
                                                                                                alt: "",
                                                                                                width:
                                                                                                  "650",
                                                                                              },
                                                                                          }
                                                                                        ),
                                                                                        _c(
                                                                                          "span",
                                                                                          {
                                                                                            staticStyle:
                                                                                              {
                                                                                                display:
                                                                                                  "flex",
                                                                                                "align-items":
                                                                                                  "center",
                                                                                                "justify-content":
                                                                                                  "center",
                                                                                                "font-size":
                                                                                                  "12px",
                                                                                                "font-style":
                                                                                                  "italic",
                                                                                                "margin-top":
                                                                                                  "5px",
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                this
                                                                                                  .$i18n
                                                                                                  .locale ===
                                                                                                  "en"
                                                                                                  ? `General Director of VIMC, Nguyen Canh Tinh, delivered directives during the working session.`
                                                                                                  : "Tổng giám đốc VIMC, Nguyễn Cảnh Tĩnh phát biểu chỉ đạo tại buổi làm việc"
                                                                                              ) +
                                                                                                " "
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "p",
                                                                                      {
                                                                                        staticStyle:
                                                                                          {
                                                                                            "text-align":
                                                                                              "justify",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              this
                                                                                                .$i18n
                                                                                                .locale ===
                                                                                                "en"
                                                                                                ? ` After hearing the reports, General Director Nguyen Canh Tinh of VIMC highly appreciated the efforts of Hai Phong Port and the construction contractor in executing the project, closely adhering to the established schedule. Emphasizing that the project is entering its final stages, General Director Tinh urged Hai Phong Port and the contractor to collaborate closely and promptly resolve any obstacles and challenges during construction. He stressed the need to focus on manpower, resources, and equipment, improve construction methods, accelerate the project's progress, and ensure its timely completion according to schedule.`
                                                                                                : "Sau khi nghe báo cáo, Tổng Giám đốc VIMC, Nguyễn Cảnh Tĩnh đánh giá cao những nỗ lực của Cảng Hải Phòng cùng Nhà thầu thi công trong công tác triển khai Dự án, luôn bám sát tiến độ đề ra. Nhấn mạnh, Dự án đang dần đi vào giai đoạn hoàn thiện, Tổng giám đốc VIMC yêu cầu Cảng Hải Phòng và nhà thầu phối hợp chặt chẽ, kịp thời tháo gỡ các vướng mắc, khó khăn trong quá trình thi công; tập trung nhân lực, phương tiện, thiết bị, cải tiến biện pháp thi công, đẩy nhanh tiến độ dự án, đảm bảo Dự án về đích đúng tiến độ."
                                                                                            ) +
                                                                                            " "
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "p",
                                                                                      {
                                                                                        staticStyle:
                                                                                          {
                                                                                            "text-align":
                                                                                              "justify",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              this
                                                                                                .$i18n
                                                                                                .locale ===
                                                                                                "en"
                                                                                                ? `The Container Terminals 3 and 4 project at Hai Phong International Gateway Port in Lach Huyen is a critical and strategically significant project for Hai Phong Port specifically and the ecosystem of Vietnam Maritime Corporation in general. With the ambition to "Advance into the vast sea" shared by generations of leaders and employees, Hai Phong Port is determined to exert full effort, striving to commence Phase 1 operations of Terminals 3 and 4 in Q1 2025 and complete the entire project by the end of 2025.`
                                                                                                : "Dự án bến container số 3, 4 Cảng cửa ngõ quốc tế Hải phòng tại Lạch Huyện là dự án trọng điểm, quan trọng, mang tính chiến lược đối với Cảng Hải Phòng nói riêng và hệ sinh thái của Tổng công ty Hàng hải Việt Nam nói chung. Mang khát vọng “Tiến ra biển lớn” của các thế hệ lãnh đạo và cán bộ công nhân viên, Cảng Hải Phòng quyết tâm dốc toàn lực, phấn đấu đưa Dự án bến số 3,4 vào khai thác giai đoạn 1 trong Quý I/2025 và hoàn thành toàn bộ dự án trong năm 2025."
                                                                                            ) +
                                                                                            " "
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "p",
                                                                                      {
                                                                                        staticStyle:
                                                                                          {
                                                                                            "text-align":
                                                                                              "left",
                                                                                            "font-weight":
                                                                                              "bold",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              this
                                                                                                .$i18n
                                                                                                .locale ===
                                                                                                "en"
                                                                                                ? `Hai Phong Port`
                                                                                                : "Cảng Hải Phòng"
                                                                                            ) +
                                                                                            " "
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "hr"
                                                                                    ),
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticStyle:
                                                                                          {
                                                                                            display:
                                                                                              "flex",
                                                                                            "align-items":
                                                                                              "center",
                                                                                            "justify-content":
                                                                                              "space-between",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "div",
                                                                                          [
                                                                                            _c(
                                                                                              "span",
                                                                                              {
                                                                                                staticStyle:
                                                                                                  {
                                                                                                    "margin-right":
                                                                                                      "5px",
                                                                                                    "font-weight":
                                                                                                      "600",
                                                                                                  },
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "#" +
                                                                                                    _vm._s(
                                                                                                      _vm.$t(
                                                                                                        "terminal"
                                                                                                      )
                                                                                                    )
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _vm._m(
                                                                                          1
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "transx_blog_listing_item",
                                                                              }
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._m(
                                                                          2
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c("div", {
                                  staticClass: "transx_content_paging_wrapper",
                                }),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "transx_sidebar custom-padding col-md-8 offset-md-2 col-lg-4 offset-lg-0 col-xl-4",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "widget widget_search",
                                      attrs: { id: "search-2" },
                                    },
                                    [
                                      _c(
                                        "form",
                                        {
                                          staticClass: "transx_search_form",
                                          attrs: {
                                            name: "search_form",
                                            id: "search-395",
                                          },
                                        },
                                        [
                                          _vm._m(3),
                                          _c("input", {
                                            staticClass: "form__field",
                                            staticStyle: { display: "none" },
                                            attrs: {
                                              type: "text",
                                              name: "s",
                                              value: "",
                                              placeholder:
                                                this.$i18n.locale === "en"
                                                  ? "Search by title"
                                                  : "Tìm kiếm theo tiêu đề",
                                              title: "Search the site...",
                                            },
                                          }),
                                          _c("div", { staticClass: "clear" }),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "widget widget_categories",
                                      attrs: { id: "categories-2" },
                                    },
                                    [
                                      _c(
                                        "h6",
                                        {
                                          staticClass: "widget_title",
                                          staticStyle: {
                                            "font-weight": "bold",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("category")) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c("hr"),
                                      _c("ul", [
                                        _c(
                                          "li",
                                          {
                                            staticClass: "cat-item cat-item-28",
                                            attrs: { "data-count": "(1)" },
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "/blogs/detail/0",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("companionCom"))
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "li",
                                          {
                                            staticClass: "cat-item cat-item-28",
                                            attrs: { "data-count": "(1)" },
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "/blogs/detail/1",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("establishmentCom")
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "li",
                                          {
                                            staticClass: "cat-item cat-item-27",
                                            attrs: { "data-count": "(1)" },
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "/blogs/detail/2",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("seafarer"))
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "li",
                                          {
                                            staticClass: "cat-item cat-item-30",
                                            attrs: { "data-count": "(1)" },
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "/blogs/detail/3",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("containerTerminal")
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "li",
                                          {
                                            staticClass: "cat-item cat-item-30",
                                            attrs: { "data-count": "(1)" },
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "/blogs/detail/4",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("annoucement"))
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "li",
                                          {
                                            staticClass: "cat-item cat-item-30",
                                            attrs: { "data-count": "(1)" },
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "/blogs/detail/5",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("signCom"))
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "widget widget_categories",
                                      attrs: { id: "categories-2" },
                                    },
                                    [
                                      _c(
                                        "h6",
                                        {
                                          staticClass: "widget_title",
                                          staticStyle: {
                                            "font-weight": "bold",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("archive")) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c("hr"),
                                      _c("ul", [
                                        _c(
                                          "li",
                                          {
                                            staticClass: "cat-item cat-item-28",
                                            attrs: { "data-count": "(1)" },
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "/blogs/detail/1",
                                                },
                                              },
                                              [_vm._v(_vm._s(_vm.$t("day1")))]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "li",
                                          {
                                            staticClass: "cat-item cat-item-30",
                                            attrs: { "data-count": "(1)" },
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "/blogs/detail/3",
                                                },
                                              },
                                              [_vm._v(_vm._s(_vm.$t("day3")))]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "li",
                                          {
                                            staticClass: "cat-item cat-item-27",
                                            attrs: { "data-count": "(1)" },
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "/blogs/detail/2",
                                                },
                                              },
                                              [_vm._v(_vm._s(_vm.$t("day2")))]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "li",
                                          {
                                            staticClass: "cat-item cat-item-27",
                                            attrs: { "data-count": "(1)" },
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "/blogs/detail/4",
                                                },
                                              },
                                              [_vm._v(_vm._s(_vm.$t("day4")))]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "li",
                                          {
                                            staticClass: "cat-item cat-item-28",
                                            attrs: { "data-count": "(1)" },
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "/blogs/detail/0",
                                                },
                                              },
                                              [_vm._v(_vm._s(_vm.$t("day0")))]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "li",
                                          {
                                            staticClass: "cat-item cat-item-27",
                                            attrs: { "data-count": "(1)" },
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "/blogs/detail/4",
                                                },
                                              },
                                              [_vm._v(_vm._s(_vm.$t("day5")))]
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "mb-10",
                                      attrs: { id: "custom_html-2" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "textwidget custom-html-widget",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "transx_contact-trigger transx_contact-trigger--style-2",
                                            },
                                            [
                                              _c("img", {
                                                staticClass:
                                                  "transx_contact-trigger__img",
                                                attrs: {
                                                  src: "https://demo.artureanec.com/themes/transx-new/wp-content/uploads/2024/01/contact_background.png",
                                                  alt: "img",
                                                },
                                              }),
                                              _c(
                                                "h4",
                                                {
                                                  staticClass:
                                                    "transx_contact-trigger__title",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.$t("helper")) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c("p", {
                                                staticClass:
                                                  "transx_contact-trigger__text",
                                              }),
                                              _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "transx_button_contactus",
                                                  attrs: { href: "/contact" },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        display: "flex",
                                                        "align-items": "center",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("contactUs")
                                                        ) + "  "
                                                      ),
                                                      _c(
                                                        "a",
                                                        {
                                                          attrs: {
                                                            href: "/",
                                                            target: "_blank",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "svg",
                                                            {
                                                              staticClass:
                                                                "icon",
                                                            },
                                                            [
                                                              _c(
                                                                "svg",
                                                                {
                                                                  staticClass:
                                                                    "icon arrow-icon",
                                                                  attrs: {
                                                                    xmlns:
                                                                      "http://www.w3.org/2000/svg",
                                                                    viewBox:
                                                                      "0 0 24 24",
                                                                    id: "next-arrow",
                                                                  },
                                                                },
                                                                [
                                                                  _c("path", {
                                                                    attrs: {
                                                                      d: "M22.707,12.707a1,1,0,0,0,0-1.414l-5-5a1,1,0,0,0-1.414,1.414L19.586,11H2a1,1,0,0,0,0,2H19.586l-3.293,3.293a1,1,0,0,0,1.414,1.414Z",
                                                                      fill: "#fff",
                                                                    },
                                                                  }),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "widget widget_tag_cloud",
                                      attrs: { id: "tag_cloud-2" },
                                    },
                                    [
                                      _c(
                                        "h6",
                                        { staticClass: "widget_title" },
                                        [_vm._v(_vm._s(_vm.$t("tag")))]
                                      ),
                                      _c("hr"),
                                      _c("div", { staticClass: "tagcloud" }, [
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "tag-cloud-link tag-link-31 tag-link-position-1",
                                            attrs: {
                                              href: "/blogs/detail/",
                                              "aria-label":
                                                "Business (4 items)",
                                            },
                                          },
                                          [_vm._v(_vm._s(_vm.$t("companion")))]
                                        ),
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "tag-cloud-link tag-link-31 tag-link-position-1",
                                            attrs: {
                                              href: "/blogs/detail/1",
                                              "aria-label":
                                                "Business (4 items)",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("establishment"))
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "tag-cloud-link tag-link-32 tag-link-position-2",
                                            attrs: {
                                              href: "/blogs/detail/2",
                                              "aria-label": "Cargo (4 items)",
                                            },
                                          },
                                          [_vm._v(_vm._s(_vm.$t("seafarer")))]
                                        ),
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "tag-cloud-link tag-link-34 tag-link-position-3",
                                            attrs: {
                                              href: "/blogs/detail/3",
                                              "aria-label":
                                                "Environment (3 items)",
                                            },
                                          },
                                          [_vm._v(_vm._s(_vm.$t("terminal")))]
                                        ),
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "tag-cloud-link tag-link-34 tag-link-position-3",
                                            attrs: {
                                              href: "/blogs/detail/4",
                                              "aria-label":
                                                "Environment (3 items)",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("annoucement"))
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "tag-cloud-link tag-link-34 tag-link-position-3",
                                            attrs: {
                                              href: "/blogs/detail/5",
                                              "aria-label":
                                                "Environment (3 items)",
                                            },
                                          },
                                          [_vm._v(_vm._s(_vm.$t("signCom")))]
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
      _c("FooterHome"),
      _vm._m(4),
      _c("NavBarLeftHome"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "transx_blog_listing_image_container" }, [
      _c(
        "span",
        {
          staticStyle: {
            position: "absolute",
            bottom: "0",
            padding: "8px 15px",
            "background-color": "#e8734e",
            "font-weight": "700",
            "font-size": "14px",
            color: "aliceblue",
            "border-radius": "2px",
            "margin-left": "16px",
          },
        },
        [_vm._v("Terminal")]
      ),
      _c("img", {
        staticClass: "transx_img--bg",
        attrs: {
          src: "https://vimc.co/wp-content/uploads/2024/06/CHP_12.6.24.jpg",
          alt: "Featured Image",
        },
      }),
      _c("div", { staticClass: "transx_post_cat_cont" }, [
        _c("div", { staticClass: "transx_post_cat_wrapper" }, [
          _c("a", { staticClass: "link link_text", attrs: { href: "" } }),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "social-buttons" }, [
      _c(
        "a",
        {
          staticClass: "btn",
          attrs: { href: "mailto:vimclines@vimc-lines.com" },
        },
        [
          _c("img", {
            attrs: {
              src: require("../../../libhome/img/gmail1.svg"),
              alt: "",
              width: "20px",
            },
          }),
        ]
      ),
      _c(
        "a",
        {
          staticClass: "btn",
          attrs: { href: "https://wa.me/84335299516", target: "_blank" },
        },
        [
          _c("img", {
            attrs: {
              src: require("../../../libhome/img/whatsapp1.svg"),
              alt: "",
              width: "20px",
            },
          }),
        ]
      ),
      _c(
        "a",
        { staticClass: "btn", attrs: { href: "skype:suoinho_mk?chat" } },
        [
          _c("img", {
            attrs: {
              src: require("../../../libhome/img/skype1.svg"),
              alt: "",
              width: "20px",
            },
          }),
        ]
      ),
      _c(
        "a",
        { staticClass: "btn", attrs: { href: "https://zalo.me/0974040006" } },
        [
          _c("img", {
            attrs: {
              src: require("../../../libhome/img/zalo1.svg"),
              alt: "",
              width: "20px",
            },
          }),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "transx_pagination" }, [
      _c("nav", {
        staticClass: "navigation pagination",
        attrs: { role: "navigation", "aria-label": "Posts" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "span",
      { staticClass: "transx_icon_search", staticStyle: { display: "none" } },
      [
        _c("img", {
          attrs: { src: require("../../../assets/icons/icons8-search.svg") },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { staticClass: "back-to-top", attrs: { href: "/home" } }, [
      _c("i", { staticClass: "fa fa-chevron-up" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }