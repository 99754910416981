var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { rounded: "lg", elevation: "0" } },
    [
      _c(
        "v-timeline",
        { attrs: { "align-top": "", dense: "" } },
        _vm._l(_vm.logs, function (log, index) {
          return _c(
            "v-timeline-item",
            {
              key: log.step,
              attrs: { small: "", color: "none" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "icon",
                    fn: function () {
                      return [
                        _c("StatusIndicator", {
                          attrs: {
                            status: index === 0 ? "active" : "",
                            pulse: index === 0,
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "opposite",
                    fn: function () {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$filters.formatDatetime(log.movementDate)
                            )
                          ),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                true
              ),
            },
            [
              _c("div", {}, [
                log.status
                  ? _c(
                      "div",
                      {
                        staticClass: "text--primary text-uppercase",
                        staticStyle: { "font-weight": "600" },
                      },
                      [_vm._v(" " + _vm._s(log.status.replace("_", " ")) + " ")]
                    )
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass: "text--secondary text-uppercase",
                    staticStyle: { "font-size": "12px" },
                  },
                  [
                    _vm._v(
                      " " + _vm._s(log.depotName || log.terminalName) + " "
                    ),
                    log.depotPortName || log.terminalPortName
                      ? _c("span", [
                          _vm._v(
                            "(" +
                              _vm._s(
                                log.depotPortName || log.terminalPortName
                              ) +
                              ")"
                          ),
                        ])
                      : _vm._e(),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "text--secondary caption",
                    staticStyle: {
                      "font-size": "10px",
                      "line-height": "1.5rem",
                    },
                  },
                  [
                    _vm._v(
                      " At " +
                        _vm._s(_vm.$filters.formatDatetime(log.movementDate)) +
                        " "
                    ),
                  ]
                ),
              ]),
            ]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }