// VPL
import OrderStore from './order.store';
import PortStore from './port.store';
import CustomerServiceStore from './customer-service.store';
import UploadStore from './upload.store';

export default {
  namespaced: true,
  modules: {
    order: OrderStore,
    port: PortStore,
    customerService: CustomerServiceStore,
    upload: UploadStore,
  },
};
