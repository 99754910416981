import containerReleaseOrderApi from '@/api/vsl/container-release-order.api';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Asia/Ho_Chi_Minh');

const initialState = () => ({
  containerReleaseOrderList: [],
});

const state = initialState();
const getters = {};
const mutations = {
  RESET(state, payload) {
    const initStates = initialState();
    if (!payload) {
      Object.keys(initStates).map((key) => {
        state[key] = initStates[key];
      });
    } else {
      const statesToReset = payload;
      statesToReset.map((key) => {
        state[key] = initStates[key];
      });
    }
  },
  SET_CONTAINER_RELEASE_ORDER_LIST(state, payload) {
    state.containerReleaseOrderList = payload;
  },
};
const actions = {
  async searchContainerReleaseOrder({ commit }, payload) {
    const { searchNo } = payload;
    const res = await containerReleaseOrderApi.searchContainerReleaseOrder({
      searchNo,
    });

    const mapped = res.data.data.map((item) => {
      const mappedItem = {
        id: item.id,
        shipperName: item.shipper?.name,
        expiryDate: item.expiry_date && dayjs.tz(item.expiry_date).toDate(),
        releaseNo: item.release_no,
        releaseDate: item.release_date && dayjs.tz(item.release_date).toDate(),
        pickupDate: item.pickup_date && dayjs.tz(item.pickup_date).toDate(),
        depotTerminalCode: item.depot_terminal_code,
        depotTerminalName: item.depot_terminal_name,
        bookingNo: item.booking_no,
        remark: item.remark,
        status: item.status,
      };
      return mappedItem;
    });
    commit('SET_CONTAINER_RELEASE_ORDER_LIST', mapped);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
