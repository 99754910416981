<template>
  <main>
    <!-- Nav Bar Start -->
    <NavBarHome active="recruitment" />
    <!-- Banner -->
    <div style="height: 170px; background-color: #ffffff"></div>
    <!-- Banner End -->

    <div
      class="transx_page_content_wrapper"
      style="background-color: #ffffff"
    >
      <!-- About Start -->
      <div class="recruitment">
        <div class="container">
          <div class="label">
            <h3>{{ $t('AO-VME') }}</h3>
            <p>
              <i class="fa fa-calendar"></i> {{ $t('appDeadline') }}: 15-8-2024
              <button
                id="button"
                @click="scrollToApplySection"
              >
                {{ $t('sendCV') }}
              </button>
            </p>
          </div>
          <div class="common-info">
            <h5>{{ $t('overview') }}</h5>
            <div class="list-info row">
              <div class="item col-6 d-flex">
                <div class="image">
                  <img
                    src="https://phuthailuxury.vn/assets_client/images/1.png?v=1719280770"
                    alt="Item Wallet"
                  />
                </div>
                <div
                  class="text"
                  style="
                    flex-direction: column;
                    display: flex;
                    align-items: flex-start;
                  "
                >
                  <span style="font-weight: 700">{{
                    this.$i18n.locale === 'en' ? 'Salary' : 'Mức lương'
                  }}</span>
                  <span>{{
                    this.$i18n.locale === 'en' ? 'Agreement' : 'Thỏa thuận'
                  }}</span>
                </div>
              </div>
              <div class="item col-6 d-flex">
                <div class="image">
                  <img
                    src="https://phuthailuxury.vn/assets_client/images/3.png?v=1719280770"
                    alt="Item Timework"
                  />
                </div>
                <div
                  class="text"
                  style="
                    flex-direction: column;
                    display: flex;
                    align-items: flex-start;
                  "
                >
                  <span style="font-weight: 700">{{ $t('workSchedule') }}</span>
                  <span>8h00 - 17h00</span>
                </div>
              </div>
              <div class="item col-6 d-flex">
                <div class="image">
                  <img
                    src="https://phuthailuxury.vn/assets_client/images/5.png?v=1719280770"
                    alt="Item Sex"
                  />
                </div>
                <div
                  class="text"
                  style="
                    flex-direction: column;
                    display: flex;
                    align-items: flex-start;
                  "
                >
                  <span style="font-weight: 700">{{ $t('gender') }}</span>
                  <span>{{
                    this.$i18n.locale == 'en' ? 'Male/Female' : 'Nam/Nữ'
                  }}</span>
                </div>
              </div>
              <div class="item col-6 d-flex">
                <div class="image">
                  <img
                    src="https://phuthailuxury.vn/assets_client/images/2.png?v=1719280770"
                    alt="Item Number"
                  />
                </div>
                <div
                  class="text"
                  style="
                    flex-direction: column;
                    display: flex;
                    align-items: flex-start;
                  "
                >
                  <span style="font-weight: 700">{{
                    this.$i18n.locale == 'en'
                      ? 'Recruitment Number'
                      : 'Số lượng tuyển dụng'
                  }}</span>
                  <span>1</span>
                </div>
              </div>
              <div class="item col-6 d-flex">
                <div class="image">
                  <img
                    src="https://phuthailuxury.vn/assets_client/images/4.png?v=1719280770"
                    alt="Item Level"
                  />
                </div>
                <div
                  class="text"
                  style="
                    flex-direction: column;
                    display: flex;
                    align-items: flex-start;
                  "
                >
                  <span style="font-weight: 700"
                    >{{ this.$i18n.locale === 'en' ? 'Level' : 'Cấp bậc' }}
                  </span>
                  <span>{{ $t('employyee') }}</span>
                </div>
              </div>
              <div class="item col-6 d-flex">
                <div class="image">
                  <img
                    src="https://phuthailuxury.vn/assets_client/images/6.png?v=1719280770"
                    alt="Item Level"
                  />
                </div>
                <div
                  class="text"
                  style="
                    flex-direction: column;
                    display: flex;
                    align-items: flex-start;
                  "
                >
                  <span style="font-weight: 700">{{ $t('experience') }} </span>
                  <span>{{
                    this.$i18n.locale == 'en'
                      ? 'Have Experience'
                      : 'Có kinh Nghiệm'
                  }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="address">
            <h5 style="font-weight: 700">{{ $t('workLocation') }}</h5>
            <ul>
              <li>
                {{ $t('addressDetail') }}
              </li>
              <li>
                {{
                  this.$i18n.locale == 'en'
                    ? `No. 1 Dao Duy Anh Street, Phuong Mai Ward, Dong Da District, Hanoi`
                    : 'Số 01 Đào Duy Anh, Phương Mai, quận Đống Đa, Hà Nội.'
                }}
              </li>
            </ul>
          </div>

          <div class="job-desc">
            <h2 style="text-align: justify">
              <span style="font-size: 16pt"
                ><span style="font-family: 'Times New Roman'"
                  ><span style="color: #000000">{{ $t('jobDesc') }}</span></span
                ></span
              >
            </h2>

            <ul>
              <li style="list-style-type: disc">
                <span style="font-size: 12pt"
                  ><span style="font-family: 'Times New Roman'"
                    ><span style="color: #000000">{{
                      this.$i18n.locale === 'en'
                        ? `Monitor, update, and handle information and documents related to arising economic transactions concerning ship management cost accounting, accounts payable, bank accounting, payment accounting, payroll accounting, social insurance, and personal income tax.`
                        : 'Theo dõi, cập nhật và xử lý thông tin, chứng từ liên quan đến các nghiệp vụ kinh tế phát sinh thuộc phần hành kế toán chi phí quản lý tàu và công nợ phải trả, kế toán ngân hàng, kế toán thanh toán, kế toán tiền lương, BHXH, thuế TNCN.'
                    }}</span></span
                  ></span
                >
              </li>
              <li style="list-style-type: disc">
                <span style="font-size: 12pt"
                  ><span style="font-family: 'Times New Roman'"
                    ><span style="color: #000000"
                      >{{
                        this.$i18n.locale === 'en'
                          ? `Record arising economic transactions, compile, and prepare reports for the responsible accounting sections.`
                          : 'Hạch toán các nghiệp vụ kinh tế phát sinh và tổng hợp, lập báo cáo phần hành kế toán phụ trách.'
                      }}
                    </span></span
                  ></span
                >
              </li>
              <li style="list-style-type: disc">
                <span style="font-size: 12pt"
                  ><span style="font-family: 'Times New Roman'"
                    ><span style="color: #000000"
                      >{{
                        this.$i18n.locale === 'en'
                          ? `Perform other job responsibilities as assigned.`
                          : `Thực hiện các trách nhiệm công việc khác.`
                      }}
                    </span></span
                  ></span
                >
              </li>
            </ul>

            <h2 style="text-align: justify">
              <span style="font-size: 16pt"
                ><span style="font-family: 'Times New Roman'"
                  ><span style="color: #000000">{{
                    $t('requirement')
                  }}</span></span
                ></span
              >
            </h2>

            <ul>
              <li style="list-style-type: disc">
                <span style="font-size: 12pt"
                  ><span style="font-family: 'Times New Roman'"
                    ><span style="color: #000000">{{
                      this.$i18n.locale === 'en'
                        ? `Education: Bachelor's degree or higher in Economics/Finance & Accounting/Auditing.`
                        : 'Trình độ: Đại học trở lên các chuyên ngành Kinh tế/Tài chính kế toán/ Kiểm toán.'
                    }}</span></span
                  ></span
                >
              </li>
              <li style="list-style-type: disc">
                <span style="font-size: 12pt"
                  ><span style="font-family: 'Times New Roman'"
                    ><span style="color: #000000">{{
                      this.$i18n.locale === 'en'
                        ? `Experience: 5 years of experience in accounting within a business environment.`
                        : 'Kinh nghiệm: 05 năm kinh nghiệm thực hiện công tác kế toán tại doanh nghiệp.'
                    }}</span></span
                  ></span
                >
              </li>
              <li style="list-style-type: disc">
                <span style="font-size: 12pt"
                  ><span style="font-family: 'Times New Roman'"
                    ><span style="color: #000000">{{
                      this.$i18n.locale === 'en'
                        ? `Professional Competence: `
                        : 'Năng lực chuyên môn: '
                    }}</span>
                    <ul>
                      <li>
                        <span style="font-size: 12pt"
                          ><span style="font-family: 'Times New Roman'"
                            ><span style="color: #000000">{{
                              this.$i18n.locale === 'en'
                                ? `Proficient in specialized knowledge, processes, technical requirements, and principles of accounting practices.`
                                : 'Nắm được các kiến thức chuyên môn, quy trình, yêu cầu kỹ thuật, nguyên tắc thực hiện nghiệp vụ kế toán.'
                            }}</span></span
                          ></span
                        >
                      </li>
                      <li>
                        <span style="font-size: 12pt"
                          ><span style="font-family: 'Times New Roman'"
                            ><span style="color: #000000">{{
                              this.$i18n.locale === 'en'
                                ? `Understand the accounting principles and standards applied within the enterprise.`
                                : 'Nắm được các nguyên tắc, chuẩn mực kế toán áp dụng tại doanh nghiệp.'
                            }}</span></span
                          ></span
                        >
                      </li>
                      <li>
                        <span style="font-size: 12pt"
                          ><span style="font-family: 'Times New Roman'"
                            ><span style="color: #000000">{{
                              this.$i18n.locale === 'en'
                                ? `Knowledgeable about laws and government regulations on taxes and financial management, accounting practices.`
                                : 'Nắm được luật và các văn bản hướng dẫn của nhà nước về thuế và quản lý tài chính, hạch toán kế toán.'
                            }}</span></span
                          ></span
                        >
                      </li>
                      <li>
                        <span style="font-size: 12pt"
                          ><span style="font-family: 'Times New Roman'"
                            ><span style="color: #000000">{{
                              this.$i18n.locale === 'en'
                                ? `Familiar with the processes and regulations related to responsible accounting tasks.`
                                : 'Nắm vững hệ thống các quy trình, quy định liên quan đến công tác kế toán phụ trách.'
                            }}</span></span
                          ></span
                        >
                      </li>
                      <li>
                        <span style="font-size: 12pt"
                          ><span style="font-family: 'Times New Roman'"
                            ><span style="color: #000000">{{
                              this.$i18n.locale === 'en'
                                ? `Understand the company's organizational structure, its operational areas, the functions and tasks of the department, and an overview of the functions and tasks of related units.`
                                : 'Nắm được về cơ cấu tổ chức toàn Công ty, lĩnh vực hoạt động của Công ty, chức năng nhiệm vụ của Phòng và khái quát chức năng nhiệm vụ các đơn vị có liên quan đến công việc phụ trách.'
                            }}</span></span
                          ></span
                        >
                      </li>
                    </ul>
                  </span></span
                >
              </li>
              <li style="list-style-type: disc">
                <span style="font-size: 12pt"
                  ><span style="font-family: 'Times New Roman'"
                    ><span style="color: #000000">{{
                      this.$i18n.locale === 'en'
                        ? `Supplementary Competence:`
                        : 'Năng lực bổ trợ:'
                    }}</span>
                    <ul>
                      <li>
                        <span style="font-size: 12pt"
                          ><span style="font-family: 'Times New Roman'"
                            ><span style="color: #000000">{{
                              this.$i18n.locale === 'en'
                                ? `Planning and organizational skills for work execution.`
                                : 'Kỹ năng lập kế hoạch và tổ chức thực hiện công việc.'
                            }}</span></span
                          ></span
                        >
                      </li>
                      <li>
                        <span style="font-size: 12pt"
                          ><span style="font-family: 'Times New Roman'"
                            ><span style="color: #000000">{{
                              this.$i18n.locale === 'en'
                                ? `Communication and persuasion skills.`
                                : 'Kỹ năng giao tiếp, thuyết phục.'
                            }}</span></span
                          ></span
                        >
                      </li>
                      <li>
                        <span style="font-size: 12pt"
                          ><span style="font-family: 'Times New Roman'"
                            ><span style="color: #000000">{{
                              this.$i18n.locale === 'en'
                                ? `Ability to work independently and in teams.`
                                : 'Kỹ năng làm việc độc lập và làm việc nhóm.'
                            }}</span></span
                          ></span
                        >
                      </li>
                      <li>
                        <span style="font-size: 12pt"
                          ><span style="font-family: 'Times New Roman'"
                            ><span style="color: #000000">{{
                              this.$i18n.locale === 'en'
                                ? `Risk management skills.`
                                : 'Kỹ năng quản lý rủi ro.'
                            }}</span></span
                          ></span
                        >
                      </li>
                      <li>
                        <span style="font-size: 12pt"
                          ><span style="font-family: 'Times New Roman'"
                            ><span style="color: #000000">{{
                              this.$i18n.locale === 'en'
                                ? `Report compilation and analysis skills.`
                                : 'Kỹ năng tổng hợp và phân tích báo cáo.'
                            }}</span></span
                          ></span
                        >
                      </li>
                    </ul></span
                  ></span
                >
              </li>
              <li style="list-style-type: disc">
                <span style="font-size: 12pt"
                  ><span style="font-family: 'Times New Roman'"
                    ><span style="color: #000000">{{
                      this.$i18n.locale === 'en'
                        ? `Proficiency in English (listening, speaking, reading, writing) to perform specialized tasks.`
                        : 'Có khả năng sử dụng tiếng Anh ở các kỹ năng nghe - nói - đọc - viết để thực hiện các công việc chuyên môn.'
                    }}</span></span
                  ></span
                >
              </li>
              <li style="list-style-type: disc">
                <span style="font-size: 12pt"
                  ><span style="font-family: 'Times New Roman'"
                    ><span style="color: #000000"
                      >{{
                        this.$i18n.locale === 'en'
                          ? `Proficient in using MS Office, accounting software, internal management software (if any), and information searching on the Internet.`
                          : 'Thành thạo sử dụng phần mềm văn phòng MS office, phần mềm kế toán, phần mềm quản trị tại nội bộ (nếu có) và tìm kiếm thông tin trên Internet.'
                      }}
                    </span></span
                  ></span
                >
              </li>
            </ul>

            <h2 style="text-align: justify">
              <span style="font-size: 16pt"
                ><span style="font-family: 'Times New Roman'"
                  ><span style="color: #000000">{{
                    $t('benefits')
                  }}</span></span
                ></span
              >
            </h2>

            <ul>
              <li style="list-style-type: disc">
                <span style="font-size: 12pt"
                  ><span style="font-family: 'Times New Roman'"
                    ><span style="color: #000000">{{ $t('benef1') }}</span>
                  </span></span
                >
              </li>
              <li style="list-style-type: disc">
                <span style="font-size: 12pt"
                  ><span style="font-family: 'Times New Roman'"
                    ><span style="color: #000000">{{
                      $t('benef2')
                    }}</span></span
                  ></span
                >
              </li>
              <li style="list-style-type: disc">
                <span style="font-size: 12pt"
                  ><span style="font-family: 'Times New Roman'"
                    ><span style="color: #000000">{{
                      $t('benef3')
                    }}</span></span
                  ></span
                >
              </li>
              <li style="list-style-type: disc">
                <span style="font-size: 12pt"
                  ><span style="font-family: 'Times New Roman'"
                    ><span style="color: #000000">{{
                      $t('benef4')
                    }}</span></span
                  ></span
                >
              </li>
              <li style="list-style-type: disc">
                <span style="font-size: 12pt"
                  ><span style="font-family: 'Times New Roman'"
                    ><span style="color: #000000">{{
                      $t('benef5')
                    }}</span></span
                  ></span
                >
              </li>
              <li style="list-style-type: disc">
                <span style="font-size: 12pt"
                  ><span style="font-family: 'Times New Roman'"
                    ><span style="color: #000000">{{
                      $t('benef6')
                    }}</span></span
                  ></span
                >
              </li>
              <li style="list-style-type: disc">
                <span style="font-size: 12pt"
                  ><span style="font-family: 'Times New Roman'"
                    ><span style="color: #000000">{{
                      $t('benef7')
                    }}</span></span
                  ></span
                >
              </li>
            </ul>
            <p
              style="
                font-style: italic;
                text-align: justify;
                font-size: 16px;
                font-family: 'Times New Roman';
              "
            >
              {{ $t('benefPara') }}
            </p>

            <h2 style="text-align: justify">
              <span style="font-size: 16pt"
                ><span style="font-family: 'Times New Roman'"
                  ><span style="color: #000000">{{
                    $t('workSchedule')
                  }}</span></span
                ></span
              >
            </h2>

            <p style="text-align: justify">
              <span style="font-size: 12pt"
                ><span style="font-family: 'Times New Roman'"
                  ><span style="color: #000000">{{
                    $t('montoFri')
                  }}</span></span
                ></span
              >
            </p>

            <ul>
              <li style="list-style-type: disc">
                <span style="font-size: 12pt"
                  ><span style="font-family: 'Times New Roman'"
                    ><span style="color: #000000"
                      >{{ $t('morning') }}:
                    </span></span
                  ></span
                ><span style="font-size: 12pt"
                  ><span style="font-family: 'Times New Roman'"
                    ><span style="color: #000000">8h00 -12h00</span></span
                  ></span
                >
              </li>
              <li style="list-style-type: disc">
                <span style="font-size: 12pt"
                  ><span style="font-family: 'Times New Roman'"
                    ><span style="color: #000000"
                      >{{ $t('afternoon') }}: 13h00- 17h00</span
                    ></span
                  ></span
                >
              </li>
            </ul>

            <h2 style="text-align: justify">
              <span style="font-size: 16pt"
                ><span style="font-family: 'Times New Roman'"
                  ><span style="color: #000000">{{
                    $t('recruitDoc')
                  }}</span></span
                ></span
              >
            </h2>
            <ul>
              <li style="list-style-type: disc">
                <span style="font-size: 12pt"
                  ><span style="font-family: 'Times New Roman'"
                    ><span style="color: #000000">{{
                      $t('recruit1')
                    }}</span></span
                  ></span
                >
              </li>
              <li style="list-style-type: disc">
                <span style="font-size: 12pt"
                  ><span style="font-family: 'Times New Roman'"
                    ><span style="color: #000000"
                      >{{ $t('recruit2') }}
                    </span></span
                  ></span
                >
              </li>
            </ul>

            <h2 style="text-align: justify">
              <span style="font-size: 16pt"
                ><span style="font-family: 'Times New Roman'"
                  ><span style="color: #000000">{{
                    $t('methodApply')
                  }}</span></span
                ></span
              >
            </h2>
            <ul>
              <li style="list-style-type: disc">
                <span style="font-size: 12pt"
                  ><span style="font-family: 'Times New Roman'"
                    ><span style="color: #000000">
                      <li style="list-style-type: disc">
                        <span style="font-size: 12pt"
                          ><span style="font-family: 'Times New Roman'"
                            ><span style="color: #000000"
                              >{{ $t('method1') }}
                            </span></span
                          ></span
                        >
                      </li>
                    </span></span
                  ></span
                >
              </li>
              <li style="list-style-type: disc">
                <span style="font-size: 12pt"
                  ><span style="font-family: 'Times New Roman'"
                    ><span style="color: #000000">
                      <li style="list-style-type: disc">
                        <span style="font-size: 12pt"
                          ><span style="font-family: 'Times New Roman'"
                            ><span style="color: #000000">
                              <div v-html="$t('method2')"></div> </span></span
                        ></span>
                      </li> </span></span
                ></span>
              </li>
            </ul>

            <h2 style="text-align: justify">
              <span style="font-size: 16pt"
                ><span style="font-family: 'Times New Roman'"
                  ><span style="color: #000000">{{
                    $t('contactInfo')
                  }}</span></span
                ></span
              >
            </h2>
            <ul>
              <li style="list-style-type: disc">
                <span style="font-size: 12pt"
                  ><span style="font-family: 'Times New Roman'"
                    ><span style="color: #000000">{{
                      $t('contactInfo1')
                    }}</span></span
                  ></span
                >
              </li>
              <li style="list-style-type: disc">
                <span style="font-size: 12pt"
                  ><span style="font-family: 'Times New Roman'"
                    ><span style="color: #000000"
                      >{{ $t('contactInfo2') }}
                    </span></span
                  ></span
                >
              </li>
              <li style="list-style-type: disc">
                <span style="font-size: 12pt"
                  ><span style="font-family: 'Times New Roman'"
                    ><span style="color: #000000"
                      >Email: hr@vimc-lines.com
                    </span></span
                  ></span
                >
              </li>
            </ul>
            <p
              style="
                font-style: italic;
                text-align: justify;
                font-size: 16px;
                font-family: 'Times New Roman';
              "
            >
              {{ $t('contactPara') }}
            </p>
            <span style=""></span>
          </div>

          <div class="another-job">
            <h3>{{ $t('otherJobAvailable') }}</h3>
            <div class="row">
              <div class="col-12 col-md-6">
                <a href="/recruitment/detail/2">
                  <div class="job-item">
                    <p
                      class="name"
                      style="color: #000000; font-weight: 700; font-size: 15px"
                    >
                      {{ $t('genAccountEmploy') }}
                    </p>
                    <div class="row">
                      <div class="col-5">
                        <p class="deadline">
                          <i class="fa fa-calendar"></i>
                          15-8-2024
                        </p>
                        <div style="padding-bottom: 5px"></div>
                        <p class="location-recruitment">
                          <img src="../../../libhome/img/location.svg" />
                          {{ $t('location') }}
                        </p>
                        <div style="padding-bottom: 10px"></div>
                      </div>
                      <div class="col-6">
                        <p class="salary">
                          <img src="../../../libhome/img/salary.svg" />
                          {{ $t('agreement') }}
                        </p>
                        <div style="padding-bottom: 5px"></div>
                        <p class="worktime">
                          <i class="fa fa-clock"></i> 8h00 - 17h00
                        </p>
                      </div>
                      <div class="col-1 pr-0">
                        <span class="fa fa-angle-right"></span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div class="col-12 col-md-6 recruitment-item">
                <a href="/recruitment/detail/1">
                  <div class="job-item">
                    <p
                      class="name"
                      style="color: #000000; font-weight: 700; font-size: 15px"
                    >
                      {{ $t('bussEmployee') }}
                    </p>
                    <div class="row">
                      <div class="col-5">
                        <p class="deadline">
                          <i class="fa fa-calendar"></i>
                          15-8-2024
                        </p>
                        <div style="margin-bottom: 10px"></div>
                        <p class="location-recruitment">
                          <img src="../../../libhome/img/location.svg" />
                          {{
                            this.$i18n.locale == 'en'
                              ? 'Hanoi, Hai Phong, Ho Chi Minh'
                              : `Hà Nội, Hải Phòng, Hồ Chí Minh`
                          }}
                        </p>
                      </div>
                      <div class="col-6">
                        <p class="salary">
                          <img src="../../../libhome/img/salary.svg" />
                          {{ $t('agreement') }}
                        </p>
                        <div style="padding-bottom: 5px"></div>
                        <p class="worktime">
                          <i class="fa fa-clock"></i> 8h00 - 17h00
                        </p>
                      </div>
                      <div class="col-1 pr-0">
                        <span class="fa fa-angle-right"></span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div
                class="col-12 col-md-6 recruitment-item"
                style="display: block"
              >
                <a href="/recruitment/detail/3">
                  <div class="job-item">
                    <p
                      class="name"
                      style="color: #000000; font-weight: 700; font-size: 15px"
                    >
                      {{ $t('itEmployee') }}
                    </p>
                    <span style="padding-top: 7px"></span>
                    <div class="row">
                      <div class="col-5">
                        <p class="deadline">
                          <i class="fa fa-calendar"></i>
                          15-8-2024
                        </p>
                        <div style="padding-bottom: 5px"></div>
                        <p
                          class="location-recruitment"
                          style="padding-top: 5px"
                        >
                          <img src="../../../libhome/img/location.svg" />
                          {{ $t('location') }}
                        </p>
                      </div>
                      <div class="col-6">
                        <p class="salary">
                          <img src="../../../libhome/img/salary.svg" />
                          {{ $t('agreement') }}
                        </p>
                        <div style="padding-bottom: 5px"></div>
                        <p class="worktime">
                          <i class="fa fa-clock"></i> 8h00 - 17h00
                        </p>
                      </div>
                      <div class="col-1 pr-0">
                        <span class="fa fa-angle-right"></span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div
                class="col-12 col-md-6 recruitment-item"
                style="display: block"
              >
                <a href="/recruitment/detail/4">
                  <div class="job-item">
                    <p
                      class="name"
                      style="color: #000000; font-weight: 700; font-size: 15px"
                    >
                      {{ $t('techEngineer') }}
                    </p>
                    <span style="padding-top: 7px"></span>
                    <div class="row">
                      <div class="col-5">
                        <p class="deadline">
                          <i class="fa fa-calendar"></i>
                          15-7-2024
                        </p>
                        <div style="padding-bottom: 5px"></div>
                        <p
                          class="location-recruitment"
                          style="padding-top: 5px"
                        >
                          <img src="../../../libhome/img/location.svg" />
                          {{ $t('location') }}
                        </p>
                      </div>
                      <div class="col-6">
                        <p class="salary">
                          <img src="../../../libhome/img/salary.svg" />
                          {{ $t('agreement') }}
                        </p>
                        <div style="padding-bottom: 5px"></div>
                        <p class="worktime">
                          <i class="fa fa-clock"></i> 8h00 - 17h00
                        </p>
                      </div>
                      <div class="col-1 pr-0">
                        <span class="fa fa-angle-right"></span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div
                class="col-12 col-md-6 recruitment-item"
                style="display: block"
              >
                <a href="/recruitment/detail/5">
                  <div class="job-item">
                    <p
                      class="name"
                      style="color: #000000; font-weight: 700; font-size: 15px"
                    >
                      {{ $t('HRC') }}
                    </p>
                    <span style="padding-top: 7px"></span>
                    <div class="row">
                      <div class="col-5">
                        <p class="deadline">
                          <i class="fa fa-calendar"></i>
                          15-8-2024
                        </p>
                        <div style="padding-bottom: 5px"></div>
                        <p
                          class="location-recruitment"
                          style="padding-top: 5px"
                        >
                          <img src="../../../libhome/img/location.svg" />
                          {{ $t('location') }}
                        </p>
                      </div>
                      <div class="col-6">
                        <p class="salary">
                          <img src="../../../libhome/img/salary.svg" />
                          {{ $t('agreement') }}
                        </p>
                        <div style="padding-bottom: 5px"></div>
                        <p class="worktime">
                          <i class="fa fa-clock"></i> 8h00 - 17h00
                        </p>
                      </div>
                      <div class="col-1 pr-0">
                        <span class="fa fa-angle-right"></span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div class="row align-items-center justify-content-center">
            <section class="recruitment-list">
              <div class="container">
                <div class="row">
                  <div class="col-md-20">
                    <div class="curriculum-vitae">
                      <div class="row">
                        <div class="col-12 col-md-6">
                          <div class="text">
                            <p
                              class="label"
                              ref="apply"
                              v-html="$t('recApply')"
                            ></p>
                            <p class="desc">
                              {{ $t('reacContent1') }}
                            </p>
                            <p class="desc">
                              {{ $t('reacContent2') }}
                            </p>
                            <p
                              class="support-line"
                              v-html="$t('reacContent3')"
                            ></p>
                          </div>
                        </div>
                        <div class="col-12 col-md-6">
                          <div class="form">
                            <form
                              @submit.prevent="sendEmail"
                              ref="form"
                            >
                              <input
                                type="hidden"
                                name="_token"
                                value="tUJCQfTUaRbmxLqP62LxN27j10jiTMOFtrcgwl11"
                              />
                              <input
                                type="password"
                                name="password"
                                value=""
                                hidden
                              />
                              <div class="form-group">
                                <input
                                  type="text"
                                  class="form-control"
                                  name="name"
                                  :placeholder="
                                    this.$i18n.locale === 'en'
                                      ? 'Full Name'
                                      : 'Họ và tên'
                                  "
                                  v-model="form.name"
                                />
                              </div>
                              <div class="form-group">
                                <input
                                  type="email"
                                  class="form-control"
                                  name="email"
                                  placeholder="Email"
                                  v-model="form.email"
                                />
                              </div>
                              <div class="form-group">
                                <input
                                  type="text"
                                  class="form-control"
                                  name="phone"
                                  :placeholder="$t('phone')"
                                  v-model="form.phone"
                                />
                              </div>
                              <div class="form-group">
                                <select
                                  class="form-control"
                                  name="recruitment_id"
                                  :placeholder="$t('recPosition')"
                                  v-model="form.position"
                                >
                                  <option value="">
                                    {{
                                      this.$i18n.locale === 'en'
                                        ? 'Apply for the position'
                                        : 'Ứng tuyển vào vị trí này'
                                    }}
                                  </option>
                                  <option :value="$t('bussDepart')">
                                    {{ $t('bussDepart') }}
                                  </option>
                                  <option :value="$t('acountDepart')">
                                    {{ $t('acountDepart') }}
                                  </option>
                                  <option :value="$t('itDepart')">
                                    {{ $t('itDepart') }}
                                  </option>
                                  <option :value="$t('HRD')">
                                    {{ $t('HRD') }}
                                  </option>
                                </select>
                              </div>
                              <div
                                class="d-flex justify-content-between sp-column"
                              >
                                <label
                                  for="upload_cv"
                                  class="add-cv"
                                  ><i class="fa fa-file"></i
                                  >{{ $t('uploadCV') }}</label
                                >
                                <input
                                  type="file"
                                  name="cv"
                                  id="upload_cv"
                                  @change="handleFileUpload"
                                  v-show="!isHidden"
                                />

                                <button
                                  type="submit"
                                  class="send"
                                  :disabled="isLoading"
                                >
                                  <span v-if="isLoading">
                                    <i class="fa fa-spinner fa-spin"></i>
                                    {{ $t('sendCV') }}
                                    <!-- Icon loading -->
                                  </span>
                                  <span v-else>
                                    {{ $t('sendCV') }}
                                  </span>
                                </button>
                              </div>
                              <span
                                v-if="selectedFile"
                                style="
                                  display: flex;
                                  margin: 5px 0 0 20px;
                                  font-size: 16px;
                                  color: #fff;
                                "
                                >{{ selectedFile.name }}</span
                              >
                              <div class="alert"></div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <!-- About End -->
    </div>

    <FooterHome />

    <!-- Back to top button -->
    <a
      href="/home"
      class="back-to-top"
      ><i class="fa fa-chevron-up"></i
    ></a>

    <NavBarLeftHome />
  </main>
</template>
<script>
import NavBarHome from '@/views/Home/PageChild/NavBarHome.vue';
import FooterHome from '@/views/Home/PageChild/FooterHome.vue';
import NavBarLeftHome from '@/views/Home/PageChild/NavBarLeftHome.vue';

export default {
  components: { NavBarLeftHome, FooterHome, NavBarHome },
  data() {
    return {
      form: {
        name: '',
        email: '',
        phone: '',
        position: '',
      },
      attachment: null,
      isHidden: true,
      selectedFile: null,
      isLoading: false,
      fieldErrors: {
        name: false,
        email: false,
        phone: false,
        position: false,
        attachment: false,
      },
      errorMessages: {
        name: {
          en: 'Please enter your full name',
          vn: 'Vui lòng nhập họ tên của bạn',
        },
        email: {
          en: 'Please enter your email',
          vn: 'Vui lòng nhập địa chỉ email của bạn',
        },
        phone: {
          en: 'Please enter your phone number',
          vn: 'Vui lòng nhập số điện thoại của bạn',
        },
        position: {
          en: 'Please select the position',
          vn: 'Vui lòng chọn vị trí ứng tuyển',
        },
        attachment: {
          en: 'Please upload your CV',
          vn: 'Vui lòng tải lên CV của bạn',
        },
      },
    };
  },
  methods: {
    scrollToApplySection() {
      this.$nextTick(() => {
        var applySection = this.$refs.apply;
        if (applySection) {
          applySection.scrollIntoView({ behavior: 'smooth' });
        }
      });
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      this.selectedFile = file;
      this.attachment = event.target.files[0];
    },
    async sendEmail() {
      this.isLoading = true;

      // Validate form fields
      if (!this.form.name) {
        this.$toast.error(
          this.$i18n.locale === 'en'
            ? 'Please enter your full name'
            : 'Vui lòng nhập họ và tên'
        );
        this.isLoading = false;
        return;
      }

      if (!this.form.email) {
        this.$toast.error(
          this.$i18n.locale === 'en'
            ? 'Please enter your email'
            : 'Vui lòng nhập email'
        );
        this.isLoading = false;
        return;
      }

      if (!this.form.phone) {
        this.$toast.error(
          this.$i18n.locale === 'en'
            ? 'Please enter your phone number'
            : 'Vui lòng nhập số điện thoại'
        );
        this.isLoading = false;
        return;
      }

      if (!this.form.position) {
        this.$toast.error(
          this.$i18n.locale === 'en'
            ? 'Please select the position'
            : 'Vui lòng nhập vị trí'
        );
        this.isLoading = false;
        return;
      }

      if (!this.selectedFile) {
        this.$toast.error(
          this.$i18n.locale === 'en'
            ? 'Please upload your CV'
            : 'Vui lòng nhập CV'
        );
        this.isLoading = false;
        return;
      }

      const formData = new FormData();
      formData.append('name', this.form.name);
      formData.append('from', this.form.email);
      formData.append('position', this.form.position);
      formData.append('phone', this.form.phone);
      formData.append('cv', this.attachment);

      try {
        const response = await fetch(
          'https://sendmail-tl91.onrender.com/send-email1',
          {
            method: 'POST',
            body: formData,
          }
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        this.$toast.success(
          `${
            this.$i18n.locale === 'en'
              ? 'Email sent successfully'
              : 'Email đã được gửi'
          }`
        );
        // this.$refs.form.reset();
        this.form.name = '';
        this.form.email = '';
        this.form.phone = '';
        this.form.position = '';
        this.selectedFile = null;
      } catch (error) {
        this.$toast.error(
          `${
            this.$i18n.locale === 'en'
              ? 'Error when sending email'
              : 'Có lỗi khi gửi email'
          }`
        );
      } finally {
        this.isLoading = false;
      }
    },
  },
};

('use strict');

window.addEventListener('scroll', function () {
  const navbar = document.getElementById('navbarCr');
  if (navbar) {
    if (window.scrollY > 0) {
      navbar.style.marginTop = '0px';
    } else {
      navbar.style.marginTop = '10px';
    }
  }
});

document.addEventListener('DOMContentLoaded', function () {
  const openBtn = document.querySelector('.nav-column-open-btn');
  const closeBtn = document.querySelector('.nav-column-close-btn');
  const overlay = document.querySelector('.overlay');
  const navbarColumn = document.querySelector('.navbar-column');
  if (openBtn) {
    openBtn.addEventListener('click', function () {
      navbarColumn.classList.add('active');
      overlay.classList.add('active');
    });

    closeBtn.addEventListener('click', function () {
      navbarColumn?.classList.remove('active');
      overlay?.classList.remove('active');
    });

    overlay.addEventListener('click', function () {
      navbarColumn.classList.remove('active');
      overlay.classList.remove('active');
    });
  }
});
</script>
<style>
/*
Removes white gap between slides
*/
.carousel {
  position: relative;
  width: 100%;
  min-height: 400px;
  background: #ffffff;
  margin-bottom: 45px;
}

.carousel-item .img-fluid {
  width: 100%;
  height: 850px;
}

.row {
  /* display: flex; */
  /* -ms-flex-wrap: wrap; */
  flex-wrap: wrap;
  width: 100%;
  border: none;
}

.common-info {
  width: 100%;
  max-width: 945px;
  margin: 0 auto;
  background-color: #e8734e;
  border-radius: 10px;
  padding: 40px 90px;
  color: #ffffff;
}
.label h3 {
  font-size: 24px;
  color: #e8734e;
  text-transform: uppercase;
  font-weight: 700;
}

.label p button {
  padding: 15px 60px;
  margin-left: 10px;
  background-color: #fde585;
  text-transform: uppercase;
  border-radius: 30px;
  border: none;
  outline: none;
  color: #e8734e;
  font-size: 16px;
  font-weight: 600;
}

.common-info h5 {
  font-size: 20px;
  font-weight: 700;
  color: #fff;
}
.common-info .list-info .item {
  margin: 15px 0;
}

.common-info .list-info .item .image {
  background-color: #ffffff;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 10px;
}

.common-info .list-info .item .image img {
  width: 35px;
  height: auto;
  object-fit: contain;
}

.common-info .list-info .item .text {
  display: flex;
  align-items: center;
  justify-content: center;
}

.address {
  width: 100%;
  max-width: 945px;
  margin: 15px auto;
  background-color: #fde585;
  border-radius: 10px;
  padding: 40px 90px;
  color: #000;
}

.address h5 {
  color: #000;
}

.job-desc {
  width: 100%;
  max-width: 945px;
  margin: 0 auto;
  background-color: #fef4f0;
  border-radius: 10px;
  padding: 15px 90px;
  color: #000000;
}
.another-job {
  margin-top: 80px;
}

.another-job h3 {
  text-align: center;
  font-size: 24px;
  color: #e8734e;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 20px;
}

.another-job .job-item {
  padding: 15px;
  border-radius: 5px;
  background-color: #fef4f0;
  transition: all ease 0.3s;
  height: 160px;
}
.another-job .job-item .deadline {
  color: #000;
  font-size: 14px;
}

.another-job .job-item .deadline i {
  color: #e8734e;
  font-size: 14px;
}

.another-job .job-item:hover .deadline i,
.another-job .job-item:hover .worktime i {
  color: #fff;
  font-size: 14px;
}

.another-job .job-item:hover .location-recruitment img,
.another-job .job-item:hover .salary img {
  filter: brightness(0);
  color: #000000;
}

.another-job .job-item .deadline p {
  color: #000;
  font-size: 14px;
}

.another-job .job-item .location {
  color: #000;
  font-size: 14px;
}
.another-job .job-item .salary {
  color: #000;
  font-size: 14px;
}

.another-job .job-item .worktime {
  color: #000;
  font-size: 14px;
}

.another-job .job-item .worktime i {
  color: #e8734e;
  font-size: 14px;
}

.another-job .job-item .worktime p {
  color: #000;
  font-size: 14px;
}

.recruitment-item {
  display: block;
  /* margin-bottom: 20px; */
}

.recruitment-item a {
  text-decoration: none;
  color: inherit;
}

.job-item {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  color: #fef4f0;
  transition: background-color 0.3s, color 0.3s;
}

.job-item:hover {
  background-color: #e8734e;
  color: black;
}

.job-item:hover .fa-calendar {
  color: #000;
  filter: invert(100%);
}

.job-item:hover .fa-clock {
  color: #000;
  filter: invert(100%);
}

.job-item p {
  margin: 0;
  padding: 2px;
  color: #000000;
}

.name {
  font-size: 18px;
  font-weight: bold;
}

.deadline,
.location-recruitment,
.salary,
.worktime {
  margin-top: 10px;
  font-size: 14px;
}

.location-recruitment img,
.salary img {
  width: 16px;
  height: 16px;
  vertical-align: middle;
  margin-right: 5px;
}

.fa-calendar,
.fa-clock {
  margin-right: 5px;
}

.curriculum-vitae {
  /* margin-top: 80px; */
  border-radius: 10px;
  background-color: #e8734a;
  padding: 45px;
  width: 1167px;
}
</style>