var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    [
      _c("NavBarHome", { attrs: { active: "blog" } }),
      _c(
        "div",
        {
          staticClass: "carousel slide carousel-fade",
          attrs: {
            id: "carousel-2",
            "data-ride": "carousel",
            "data-interval": "6000",
          },
        },
        [
          _c(
            "div",
            { staticClass: "carousel-inner", attrs: { role: "listbox" } },
            [
              _c("div", { staticClass: "carousel-item active" }, [
                _c("img", {
                  staticClass: "d-block img-fluid",
                  attrs: {
                    src: require("../../../libhome/img/vimc diamond.png"),
                    alt: "responsive image",
                  },
                }),
                _c("div", { staticClass: "carousel-caption" }, [
                  _c("div", [
                    _c("div", { staticClass: "transx_content_slide_wrapper" }, [
                      _c("div", { staticClass: "transx_content_container" }, [
                        _c("div", { staticClass: "transx_content_wrapper_1" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "transx_up_heading_overlay transx_overlay_align_center",
                            },
                            [_vm._v(" VIMC LINES ")]
                          ),
                          _c(
                            "h2",
                            { staticClass: "transx_content_slider_title" },
                            [_vm._v(" " + _vm._s(_vm.$t("blogs")) + " ")]
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
        ]
      ),
      _c("div", { staticClass: "transx_page_content_wrapper" }, [
        _c("div", { staticClass: "blog" }, [
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "row align-items-center" }, [
              _c(
                "div",
                {
                  staticClass:
                    "post-36 page type-page status-publish has-post-thumbnail hentry",
                  attrs: { id: "post-36" },
                },
                [
                  _c("div", { staticClass: "transx_page_content_container" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "transx_page_content_wrapper transx_page_title_show",
                      },
                      [
                        _c("div", { staticClass: "container" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "row transx_sidebar_right transx_bg_color_alt",
                            },
                            [
                              _c("div", { staticClass: "col-lg-8 col-xl-8" }, [
                                _c(
                                  "div",
                                  { staticClass: "transx_content_wrapper" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "elementor elementor-36",
                                        attrs: {
                                          "data-elementor-type": "wp-page",
                                          "data-elementor-id": "36",
                                          "data-elementor-settings": "[]",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "elementor-section-wrap",
                                          },
                                          [
                                            _c(
                                              "section",
                                              {
                                                staticClass:
                                                  "elementor-section-blog elementor-top-section elementor-element elementor-element-3561e36f elementor-section-boxed elementor-section-height-default elementor-section-height-default",
                                                attrs: {
                                                  "data-id": "3561e36f",
                                                  "data-element_type":
                                                    "section",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "elementor-container elementor-column-gap-default",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-54b91072",
                                                        attrs: {
                                                          "data-id": "54b91072",
                                                          "data-element_type":
                                                            "column",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "elementor-widget-wrap elementor-element-populated",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "elementor-element elementor-element-43bce01d elementor-widget elementor-widget-transx_blog_listing",
                                                                attrs: {
                                                                  "data-id":
                                                                    "43bce01d",
                                                                  "data-element_type":
                                                                    "widget",
                                                                  "data-widget_type":
                                                                    "transx_blog_listing.default",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "elementor-widget-container",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "transx_blog_listing_widget",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "transx_blog_listing_wrapper transx_view_type_1",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "transx_blog_listing_item",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "transx_blog_listing_image_container",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "span",
                                                                                      {
                                                                                        staticStyle:
                                                                                          {
                                                                                            position:
                                                                                              "absolute",
                                                                                            bottom:
                                                                                              "0",
                                                                                            padding:
                                                                                              "8px 15px",
                                                                                            "background-color":
                                                                                              "#e8734e",
                                                                                            "font-weight":
                                                                                              "700",
                                                                                            "font-size":
                                                                                              "14px",
                                                                                            color:
                                                                                              "aliceblue",
                                                                                            "border-radius":
                                                                                              "2px",
                                                                                            "margin-left":
                                                                                              "16px",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            _vm.$t(
                                                                                              "sign"
                                                                                            )
                                                                                          )
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "img",
                                                                                      {
                                                                                        staticClass:
                                                                                          "transx_img--bg",
                                                                                        attrs:
                                                                                          {
                                                                                            src: require("../../../libhome/img/Blog5.4.png"),
                                                                                            alt: "Featured Image",
                                                                                          },
                                                                                      }
                                                                                    ),
                                                                                    _vm._m(
                                                                                      0
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "transx_blog_listing_content_wrapper",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "transx_blog_listing_meta",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "span",
                                                                                          {
                                                                                            staticClass:
                                                                                              "transx_blog_listing_date",
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                _vm.$t(
                                                                                                  "day5"
                                                                                                )
                                                                                              )
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _c(
                                                                                          "span",
                                                                                          {
                                                                                            staticClass:
                                                                                              "transx_separator",
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "/"
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _c(
                                                                                          "span",
                                                                                          {
                                                                                            staticClass:
                                                                                              "transx_blog_listing_author",
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                this
                                                                                                  .$i18n
                                                                                                  .locale ===
                                                                                                  "en"
                                                                                                  ? "by"
                                                                                                  : "bởi"
                                                                                              ) +
                                                                                                " Vimc Lines"
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "h3",
                                                                                      {
                                                                                        staticClass:
                                                                                          "transx_blog_listing_title",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "a",
                                                                                          {
                                                                                            attrs:
                                                                                              {
                                                                                                href: "",
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                _vm.$t(
                                                                                                  "blogtTitle5"
                                                                                                )
                                                                                              )
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "p",
                                                                                      {
                                                                                        staticStyle:
                                                                                          {
                                                                                            "text-align":
                                                                                              "justify",
                                                                                            margin:
                                                                                              "5px 0",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              this
                                                                                                .$i18n
                                                                                                .locale ===
                                                                                                "en"
                                                                                                ? ` On the morning of August 6th, at the headquarters of Vietnam Maritime Corporation, the signing ceremony of the MOU between LX Pantos and VIMC Container Transport Joint Stock Company (VIMC LINES) took place. The event was attended by the leadership and executive boards of both companies, who engaged in discussions and marked a significant milestone in their long-term cooperation, aiming towards a strong and enduring future in the maritime and logistics sectors.`
                                                                                                : "Sáng ngày 06/8, tại Tổng công ty Hàng hải Việt Nam đã diễn ra lễ ký kết MOU giữa LX Pantos và Công ty cổ phần Vận tải Container VIMC (VIMC LINES). Tham dự buổi lễ có sự hiện của Lãnh đạo, Ban điều hành 2 doanh nghiệp, cùng trao đổi và đánh dấu cột mốc quan trọng cùng hợp tác lâu dài giữa hai bên, hướng tới một tương lai vững mạnh, bền chặt của ngành hàng hải, logistic."
                                                                                            ) +
                                                                                            " "
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _vm._m(
                                                                                      1
                                                                                    ),
                                                                                    _c(
                                                                                      "p",
                                                                                      {
                                                                                        staticStyle:
                                                                                          {
                                                                                            "text-align":
                                                                                              "justify",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              this
                                                                                                .$i18n
                                                                                                .locale ===
                                                                                                "en"
                                                                                                ? ` `
                                                                                                : " "
                                                                                            ) +
                                                                                            " "
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _vm._m(
                                                                                      2
                                                                                    ),
                                                                                    _c(
                                                                                      "p",
                                                                                      {
                                                                                        staticStyle:
                                                                                          {
                                                                                            "text-align":
                                                                                              "justify",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              this
                                                                                                .$i18n
                                                                                                .locale ===
                                                                                                "en"
                                                                                                ? ` `
                                                                                                : " "
                                                                                            ) +
                                                                                            " "
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "p",
                                                                                      {
                                                                                        staticStyle:
                                                                                          {
                                                                                            "text-align":
                                                                                              "justify",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              this
                                                                                                .$i18n
                                                                                                .locale ===
                                                                                                "en"
                                                                                                ? `Attending the signing ceremony on behalf of VIMC LINES were Mr. Nguyen Ngoc Anh – Chairman of the Board of Directors; Mr. Le Duy Duong – Member of the Board of Directors; Ms. Do Thi Ngoc Trang – General Director; Mr. Dao Hong Chuong – General Director of Dinh Vu Port and Mr. Vu Viet Thang – Deputy General Director overseeing Sales and Marketing.`
                                                                                                : "Tham dự lễ ký kết, về phía VIMC LINES có sự tham dự của Ông Nguyễn Ngọc Ánh – Chủ tịch HĐQT; Ông Lê Duy Dương – Thành viên HĐQT; Bà Đỗ Thị Ngọc Trang – Tổng giám đốc; Ông Đào Hồng Chương – Tổng giám đốc Cảng Đình Vũ; Ông Vũ Việt Thắng – Phó Tổng giám đốc quản lý Sales và Marketing."
                                                                                            ) +
                                                                                            " "
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _vm._m(
                                                                                      3
                                                                                    ),
                                                                                    _c(
                                                                                      "p",
                                                                                      {
                                                                                        staticStyle:
                                                                                          {
                                                                                            "text-align":
                                                                                              "justify",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              this
                                                                                                .$i18n
                                                                                                .locale ===
                                                                                                "en"
                                                                                                ? ` `
                                                                                                : " "
                                                                                            ) +
                                                                                            " "
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "p",
                                                                                      {
                                                                                        staticStyle:
                                                                                          {
                                                                                            "text-align":
                                                                                              "justify",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              this
                                                                                                .$i18n
                                                                                                .locale ===
                                                                                                "en"
                                                                                                ? ` On behalf of LX Pantos, we were honored to welcome Mr. Ro Seung Huyn – W&D Senior Vice President; Mr. Lee Jong Chan – Asia Regional Director; Mr. Lee Joung Houn – General Director; Mr. Lee Myung Chul – Manager Team Leader and Mr. Duy Anh Pham – W&D Team Leader.`
                                                                                                : "Về phía LX Pantos, trân trọng chào đón Mr. Ro Seung Huyn – W&D Senior Vice President; Mr. Lee Jong Chan – Asia Regional Director; Mr. Lee Joung Houn – General director; Mr. Lee Myung Chul – Manager team leader, Mr Duy Anh Pham – W&D Team leader."
                                                                                            ) +
                                                                                            " "
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _vm._m(
                                                                                      4
                                                                                    ),
                                                                                    _c(
                                                                                      "p",
                                                                                      {
                                                                                        staticStyle:
                                                                                          {
                                                                                            "text-align":
                                                                                              "justify",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              this
                                                                                                .$i18n
                                                                                                .locale ===
                                                                                                "en"
                                                                                                ? ` `
                                                                                                : " "
                                                                                            ) +
                                                                                            " "
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "p",
                                                                                      {
                                                                                        staticStyle:
                                                                                          {
                                                                                            "text-align":
                                                                                              "justify",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              this
                                                                                                .$i18n
                                                                                                .locale ===
                                                                                                "en"
                                                                                                ? ` As discussed between the two parties, VIMC LINES seeks the partnership of LX Pantos not only in freight transportation from warehouse to warehouse within the domestic Vietnamese market but also in strengthening the nationwide network of ports and storage facilities. Additionally, the collaboration aims to optimize costs, delivering an efficient and seamless supply chain service to customers.`
                                                                                                : "Theo sự trao đổi giữa hai bên, VIMC LINES mong muốn có sự đồng hành của LX Pantos không chỉ về mặt vận tải hàng hóa tfrw kho tới kho trong thị trường nội địa Việt Nam mà còn củng cố thêm về hệ thống Cảng, kho bãi trên cả nước. Ngoài ra, góp phần làm tối ưu hóa chi phí để đưa đến khách một dịch vụ chuỗi liền mạch hiệu quả."
                                                                                            ) +
                                                                                            " "
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _vm._m(
                                                                                      5
                                                                                    ),
                                                                                    _c(
                                                                                      "p",
                                                                                      {
                                                                                        staticStyle:
                                                                                          {
                                                                                            "text-align":
                                                                                              "justify",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              this
                                                                                                .$i18n
                                                                                                .locale ===
                                                                                                "en"
                                                                                                ? ` `
                                                                                                : " "
                                                                                            ) +
                                                                                            " "
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "p",
                                                                                      {
                                                                                        staticStyle:
                                                                                          {
                                                                                            "text-align":
                                                                                              "justify",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              this
                                                                                                .$i18n
                                                                                                .locale ===
                                                                                                "en"
                                                                                                ? ` The collaboration combines the nationwide network of ports and yards across Vietnam with LX Pantos expertise in building and operating warehouse systems, along with their advanced information technology systems.`
                                                                                                : "Sự kết hợp giữa hệ thống Cảng, bãi trải dọc Việt Nam với kinh nghiệm xây dựng và vận hành hệ thống kho cùng hệ thống công nghệ thông tin hiện đại của LX Pantos. "
                                                                                            ) +
                                                                                            " "
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _vm._m(
                                                                                      6
                                                                                    ),
                                                                                    _c(
                                                                                      "p",
                                                                                      {
                                                                                        staticStyle:
                                                                                          {
                                                                                            "text-align":
                                                                                              "justify",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              this
                                                                                                .$i18n
                                                                                                .locale ===
                                                                                                "en"
                                                                                                ? ` `
                                                                                                : " "
                                                                                            ) +
                                                                                            " "
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "p",
                                                                                      {
                                                                                        staticStyle:
                                                                                          {
                                                                                            "text-align":
                                                                                              "justify",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              this
                                                                                                .$i18n
                                                                                                .locale ===
                                                                                                "en"
                                                                                                ? ` The signing ceremony concluded with a commemorative photo session and the exchange of gifts between the two parties, marking a milestone in their collaborative development and reaffirming their commitment to a strong and prosperous future.`
                                                                                                : "Kết thúc buổi lễ ký kết là phần chụp ảnh lưu niệm và trao quà giữa hai bên, đánh dấu cột mốc hợp tác cùng phát triển, cùng thống nhất hướng tới tương lai phát triển vững mạnh. "
                                                                                            ) +
                                                                                            " "
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "hr"
                                                                                    ),
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticStyle:
                                                                                          {
                                                                                            display:
                                                                                              "flex",
                                                                                            "align-items":
                                                                                              "center",
                                                                                            "justify-content":
                                                                                              "space-between",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "div",
                                                                                          [
                                                                                            _c(
                                                                                              "span",
                                                                                              {
                                                                                                staticStyle:
                                                                                                  {
                                                                                                    "margin-right":
                                                                                                      "5px",
                                                                                                    "font-weight":
                                                                                                      "600",
                                                                                                  },
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "#" +
                                                                                                    _vm._s(
                                                                                                      _vm.$t(
                                                                                                        "sign"
                                                                                                      )
                                                                                                    )
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _vm._m(
                                                                                          7
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "transx_blog_listing_item",
                                                                              }
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._m(
                                                                          8
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c("div", {
                                  staticClass: "transx_content_paging_wrapper",
                                }),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "transx_sidebar custom-padding col-md-8 offset-md-2 col-lg-4 offset-lg-0 col-xl-4",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "widget widget_search",
                                      attrs: { id: "search-2" },
                                    },
                                    [
                                      _c(
                                        "form",
                                        {
                                          staticClass: "transx_search_form",
                                          attrs: {
                                            name: "search_form",
                                            id: "search-395",
                                          },
                                        },
                                        [
                                          _vm._m(9),
                                          _c("input", {
                                            staticClass: "form__field",
                                            staticStyle: { display: "none" },
                                            attrs: {
                                              type: "text",
                                              name: "s",
                                              value: "",
                                              placeholder:
                                                this.$i18n.locale === "en"
                                                  ? "Search by title"
                                                  : "Tìm kiếm theo tiêu đề",
                                              title: "Search the site...",
                                            },
                                          }),
                                          _c("div", { staticClass: "clear" }),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "widget widget_categories",
                                      attrs: { id: "categories-2" },
                                    },
                                    [
                                      _c(
                                        "h6",
                                        {
                                          staticClass: "widget_title",
                                          staticStyle: {
                                            "font-weight": "bold",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("category")) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c("hr"),
                                      _c("ul", [
                                        _c(
                                          "li",
                                          {
                                            staticClass: "cat-item cat-item-28",
                                            attrs: { "data-count": "(1)" },
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "/blogs/detail/0",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("companionCom"))
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "li",
                                          {
                                            staticClass: "cat-item cat-item-28",
                                            attrs: { "data-count": "(1)" },
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "/blogs/detail/1",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("establishmentCom")
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "li",
                                          {
                                            staticClass: "cat-item cat-item-27",
                                            attrs: { "data-count": "(1)" },
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "/blogs/detail/2",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("seafarer"))
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "li",
                                          {
                                            staticClass: "cat-item cat-item-30",
                                            attrs: { "data-count": "(1)" },
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "/blogs/detail/3",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("containerTerminal")
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "li",
                                          {
                                            staticClass: "cat-item cat-item-30",
                                            attrs: { "data-count": "(1)" },
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "/blogs/detail/4",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("annoucement"))
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "li",
                                          {
                                            staticClass: "cat-item cat-item-30",
                                            attrs: { "data-count": "(1)" },
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "/blogs/detail/5",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("signCom"))
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "widget widget_categories",
                                      attrs: { id: "categories-2" },
                                    },
                                    [
                                      _c(
                                        "h6",
                                        {
                                          staticClass: "widget_title",
                                          staticStyle: {
                                            "font-weight": "bold",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("archive")) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c("hr"),
                                      _c("ul", [
                                        _c(
                                          "li",
                                          {
                                            staticClass: "cat-item cat-item-28",
                                            attrs: { "data-count": "(1)" },
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "/blogs/detail/1",
                                                },
                                              },
                                              [_vm._v(_vm._s(_vm.$t("day1")))]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "li",
                                          {
                                            staticClass: "cat-item cat-item-30",
                                            attrs: { "data-count": "(1)" },
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "/blogs/detail/3",
                                                },
                                              },
                                              [_vm._v(_vm._s(_vm.$t("day3")))]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "li",
                                          {
                                            staticClass: "cat-item cat-item-27",
                                            attrs: { "data-count": "(1)" },
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "/blogs/detail/2",
                                                },
                                              },
                                              [_vm._v(_vm._s(_vm.$t("day2")))]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "li",
                                          {
                                            staticClass: "cat-item cat-item-27",
                                            attrs: { "data-count": "(1)" },
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "/blogs/detail/4",
                                                },
                                              },
                                              [_vm._v(_vm._s(_vm.$t("day4")))]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "li",
                                          {
                                            staticClass: "cat-item cat-item-28",
                                            attrs: { "data-count": "(1)" },
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "/blogs/detail/0",
                                                },
                                              },
                                              [_vm._v(_vm._s(_vm.$t("day0")))]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "li",
                                          {
                                            staticClass: "cat-item cat-item-27",
                                            attrs: { "data-count": "(1)" },
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "/blogs/detail/4",
                                                },
                                              },
                                              [_vm._v(_vm._s(_vm.$t("day5")))]
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "mb-10",
                                      attrs: { id: "custom_html-2" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "textwidget custom-html-widget",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "transx_contact-trigger transx_contact-trigger--style-2",
                                            },
                                            [
                                              _c("img", {
                                                staticClass:
                                                  "transx_contact-trigger__img",
                                                attrs: {
                                                  src: "https://demo.artureanec.com/themes/transx-new/wp-content/uploads/2024/01/contact_background.png",
                                                  alt: "img",
                                                },
                                              }),
                                              _c(
                                                "h4",
                                                {
                                                  staticClass:
                                                    "transx_contact-trigger__title",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.$t("helper")) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c("p", {
                                                staticClass:
                                                  "transx_contact-trigger__text",
                                              }),
                                              _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "transx_button_contactus",
                                                  attrs: { href: "/contact" },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        display: "flex",
                                                        "align-items": "center",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("contactUs")
                                                        ) + "  "
                                                      ),
                                                      _c(
                                                        "a",
                                                        {
                                                          attrs: {
                                                            href: "/",
                                                            target: "_blank",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "svg",
                                                            {
                                                              staticClass:
                                                                "icon",
                                                            },
                                                            [
                                                              _c(
                                                                "svg",
                                                                {
                                                                  staticClass:
                                                                    "icon arrow-icon",
                                                                  attrs: {
                                                                    xmlns:
                                                                      "http://www.w3.org/2000/svg",
                                                                    viewBox:
                                                                      "0 0 24 24",
                                                                    id: "next-arrow",
                                                                  },
                                                                },
                                                                [
                                                                  _c("path", {
                                                                    attrs: {
                                                                      d: "M22.707,12.707a1,1,0,0,0,0-1.414l-5-5a1,1,0,0,0-1.414,1.414L19.586,11H2a1,1,0,0,0,0,2H19.586l-3.293,3.293a1,1,0,0,0,1.414,1.414Z",
                                                                      fill: "#fff",
                                                                    },
                                                                  }),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "widget widget_tag_cloud",
                                      attrs: { id: "tag_cloud-2" },
                                    },
                                    [
                                      _c(
                                        "h6",
                                        { staticClass: "widget_title" },
                                        [_vm._v(_vm._s(_vm.$t("tag")))]
                                      ),
                                      _c("hr"),
                                      _c("div", { staticClass: "tagcloud" }, [
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "tag-cloud-link tag-link-31 tag-link-position-1",
                                            attrs: {
                                              href: "/blogs/detail/",
                                              "aria-label":
                                                "Business (4 items)",
                                            },
                                          },
                                          [_vm._v(_vm._s(_vm.$t("companion")))]
                                        ),
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "tag-cloud-link tag-link-31 tag-link-position-1",
                                            attrs: {
                                              href: "/blogs/detail/1",
                                              "aria-label":
                                                "Business (4 items)",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("establishment"))
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "tag-cloud-link tag-link-32 tag-link-position-2",
                                            attrs: {
                                              href: "/blogs/detail/2",
                                              "aria-label": "Cargo (4 items)",
                                            },
                                          },
                                          [_vm._v(_vm._s(_vm.$t("seafarer")))]
                                        ),
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "tag-cloud-link tag-link-34 tag-link-position-3",
                                            attrs: {
                                              href: "/blogs/detail/3",
                                              "aria-label":
                                                "Environment (3 items)",
                                            },
                                          },
                                          [_vm._v(_vm._s(_vm.$t("terminal")))]
                                        ),
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "tag-cloud-link tag-link-34 tag-link-position-3",
                                            attrs: {
                                              href: "/blogs/detail/4",
                                              "aria-label":
                                                "Environment (3 items)",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("annoucement"))
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "tag-cloud-link tag-link-34 tag-link-position-3",
                                            attrs: {
                                              href: "/blogs/detail/5",
                                              "aria-label":
                                                "Environment (3 items)",
                                            },
                                          },
                                          [_vm._v(_vm._s(_vm.$t("sign")))]
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
      _c("FooterHome"),
      _vm._m(10),
      _c("NavBarLeftHome"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "transx_post_cat_cont" }, [
      _c("div", { staticClass: "transx_post_cat_wrapper" }, [
        _c("a", { staticClass: "link link_text", attrs: { href: "" } }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("img", {
        attrs: {
          src: require("../../../libhome/img/Blog5.png"),
          alt: "",
          width: "650",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("img", {
        attrs: {
          src: require("../../../libhome/img/Blog5.1.png"),
          alt: "",
          width: "650",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("img", {
        attrs: {
          src: require("../../../libhome/img/Blog5.2.png"),
          alt: "",
          width: "650",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("img", {
        attrs: {
          src: require("../../../libhome/img/Blog5.3.png"),
          alt: "",
          width: "650",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("img", {
        attrs: {
          src: require("../../../libhome/img/Blog5.6.png"),
          alt: "",
          width: "650",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("img", {
        attrs: {
          src: require("../../../libhome/img/Blog5.5.png"),
          alt: "",
          width: "650",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "social-buttons" }, [
      _c(
        "a",
        {
          staticClass: "btn",
          attrs: { href: "mailto:vimclines@vimc-lines.com" },
        },
        [
          _c("img", {
            attrs: {
              src: require("../../../libhome/img/gmail1.svg"),
              alt: "",
              width: "20px",
            },
          }),
        ]
      ),
      _c(
        "a",
        {
          staticClass: "btn",
          attrs: { href: "https://wa.me/84335299516", target: "_blank" },
        },
        [
          _c("img", {
            attrs: {
              src: require("../../../libhome/img/whatsapp1.svg"),
              alt: "",
              width: "20px",
            },
          }),
        ]
      ),
      _c(
        "a",
        { staticClass: "btn", attrs: { href: "skype:suoinho_mk?chat" } },
        [
          _c("img", {
            attrs: {
              src: require("../../../libhome/img/skype1.svg"),
              alt: "",
              width: "20px",
            },
          }),
        ]
      ),
      _c(
        "a",
        { staticClass: "btn", attrs: { href: "https://zalo.me/0974040006" } },
        [
          _c("img", {
            attrs: {
              src: require("../../../libhome/img/zalo1.svg"),
              alt: "",
              width: "20px",
            },
          }),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "transx_pagination" }, [
      _c("nav", {
        staticClass: "navigation pagination",
        attrs: { role: "navigation", "aria-label": "Posts" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "span",
      { staticClass: "transx_icon_search", staticStyle: { display: "none" } },
      [
        _c("img", {
          attrs: { src: require("../../../assets/icons/icons8-search.svg") },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { staticClass: "back-to-top", attrs: { href: "/home" } }, [
      _c("i", { staticClass: "fa fa-chevron-up" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }