<template>
  <div class="transx_blog_listing_item">
    <div
      :id="id"
      class="transx_blog_listing_image_container"
    >
      <img
        class="transx_img--bg"
        :src="`${imageSrc}`"
        alt="Featured Image"
      />
      <div class="transx_post_cat_cont">
        <div
          class="transx_post_cat_wrapper"
          style="position: relative"
        >
          <span
            style="
              position: absolute;
              bottom: 0;
              padding: 5px 20px;
              background-color: #e8734e;
              font-weight: 700;
              font-size: 14px;
              color: aliceblue;
              border-radius: 2px;
              margin-left: 16px;
            "
          >
            {{ $t(category) }}
          </span>
          <a
            href=""
            class="link link_text"
          ></a>
        </div>
      </div>
    </div>
    <div class="transx_blog_listing_content_wrapper">
      <div class="transx_blog_listing_meta">
        <span class="transx_blog_listing_date">{{ $t(date) }}</span>
        <span class="transx_separator">/</span>
        <span class="transx_blog_listing_author">
          {{ this.$i18n.locale === 'en' ? 'by' : 'bởi' }} Vimc Lines
        </span>
      </div>
      <h3 class="transx_blog_listing_title">
        <a :href="detailLink">{{ $t(title) }}</a>
      </h3>
      <p
        class="transx_blog_listing_excerpt"
        style="text-align: justify"
      >
        {{ $t(content) }}
      </p>
      <a
        class="transx_button_explore"
        :href="detailLink"
        style="border: 2px solid #e8734e"
      >
        <span class="explore-more">
          {{ $t('exploremore') }}&nbsp;&nbsp;
          <a
            href="/"
            target="_blank"
          >
            <svg class="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                id="next-arrow"
                class="icon arrow-icon"
              >
                <path
                  d="M22.707,12.707a1,1,0,0,0,0-1.414l-5-5a1,1,0,0,0-1.414,1.414L19.586,11H2a1,1,0,0,0,0,2H19.586l-3.293,3.293a1,1,0,0,0,1.414,1.414Z"
                  fill="#e8734e"
                ></path>
              </svg>
            </svg>
          </a>
        </span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: String,
    imageSrc: String,
    category: String,
    date: String,
    title: String,
    content: String,
    detailLink: String,
  },
};
</script>

<style scoped>
/* Add any additional styling for BlogListingItem here */
</style>
