var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _c(
        "v-card",
        { staticClass: "py-4 pl-7 pr-2", attrs: { rounded: "lg" } },
        [
          _c("v-card-subtitle", { staticClass: "subheading" }, [
            _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")]),
            _vm._v(" " + _vm._s(_vm.$t("label-tracking-container")) + " "),
            _c(
              "span",
              {
                staticClass: "booking-label",
                style: { marginLeft: _vm.computedMargin },
              },
              [_vm._v(" " + _vm._s(_vm.$t("label-tracking-booking")) + " ")]
            ),
          ]),
          _c(
            "v-card-text",
            { staticClass: "search-box" },
            [
              _c(
                "v-form",
                {
                  attrs: { onSubmit: "return false;" },
                  on: { submit: _vm.onTrackingContainer },
                },
                [
                  _c(
                    "v-row",
                    { attrs: { align: "center" } },
                    [
                      _c("v-autocomplete", {
                        staticClass: "mr-4 custom-select-field fixed-width",
                        attrs: {
                          id: "container-autocomplete-field",
                          items: _vm.filteredContainerOptions,
                          placeholder: _vm.$t("label-select-container"),
                          loading: _vm.isLoading,
                          readonly: _vm.isLoading,
                          clearable: "",
                          "item-text": "no",
                          "item-value": "no",
                          filter: _vm.customFilter,
                        },
                        on: {
                          change: _vm.handleItemSelected,
                          focus: _vm.onContainerFocus,
                          "click:clear": _vm.onClearSearch,
                          "click:append": _vm.handleClickAppend,
                        },
                        model: {
                          value: _vm.containerNo,
                          callback: function ($$v) {
                            _vm.containerNo = $$v
                          },
                          expression: "containerNo",
                        },
                      }),
                      _c("v-select", {
                        staticClass: "mr-4 custom-select-field fixed-width",
                        attrs: {
                          id: "booking-select-field",
                          items: _vm.bookingOptions,
                          placeholder: _vm.$t("label-select-booking"),
                          loading: _vm.isLoading,
                          readonly: _vm.isLoading,
                          clearable: "",
                          "item-text": "no",
                          "item-value": "no",
                        },
                        on: {
                          change: _vm.onBookingSelect,
                          "click:clear": _vm.onClearSearch,
                        },
                        model: {
                          value: _vm.bookingNo,
                          callback: function ($$v) {
                            _vm.bookingNo = $$v
                          },
                          expression: "bookingNo",
                        },
                      }),
                      _c(
                        "v-btn",
                        {
                          staticClass: "px-10 py-6",
                          class: { "mt-2": _vm.$vuetify.breakpoint.mobile },
                          staticStyle: {
                            "text-transform": "none",
                            "font-size": "16px",
                            color: "#fff !important",
                            "background-color": "#e6734f !important",
                          },
                          attrs: {
                            large: "",
                            width: _vm.$vuetify.breakpoint.mobile
                              ? "100%"
                              : undefined,
                            disabled: _vm.isLoading,
                          },
                          on: { click: _vm.onTrackingContainer },
                        },
                        [
                          _c("v-icon", [_vm._v(" mdi-magnify ")]),
                          _vm._v(" " + _vm._s(_vm.$t("search")) + " "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.isLoading
        ? _c(
            "div",
            { staticClass: "loading-container" },
            [
              _c("v-progress-circular", {
                staticClass: "loading-spinner",
                attrs: { indeterminate: "", color: "primary", size: "50" },
              }),
              _vm._m(0),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "tracking-table" },
            [
              _vm.tableData.length > 0
                ? _c("v-data-table", {
                    staticClass: "elevation-1 mt-4 v-data-table fixed-column",
                    attrs: {
                      headers: _vm.tableHeaders,
                      items: _vm.paginatedItems,
                      "items-per-page": _vm.itemsPerPage,
                      page: _vm.page,
                      "hide-default-footer": true,
                    },
                    on: {
                      "update:page": function ($event) {
                        _vm.page = $event
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "item",
                          fn: function (props) {
                            return [
                              _c(
                                "tr",
                                {
                                  class: {
                                    "even-row": props.index % 2 === 0,
                                    "odd-row": props.index % 2 !== 0,
                                  },
                                },
                                _vm._l(
                                  _vm.tableHeaders,
                                  function (header, index) {
                                    return _c(
                                      "td",
                                      {
                                        key: header.value,
                                        class: [
                                          "pl-6 py-4",
                                          {
                                            "sticky-col": index === 0,
                                            "even-row":
                                              props.index % 2 === 0 &&
                                              index === 0,
                                            "odd-row":
                                              props.index % 2 !== 0 &&
                                              index === 0,
                                          },
                                        ],
                                      },
                                      [
                                        header.value === "movement_date"
                                          ? _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.formatDateTime(
                                                      props.item[header.value]
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ])
                                          : _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    props.item[header.value]
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2303786452
                    ),
                  })
                : _vm._e(),
              _vm.tableData.length > 0
                ? _c(
                    "div",
                    { staticClass: "custom-pagination mt-4" },
                    [
                      _vm.page > 1
                        ? _c(
                            "v-btn",
                            {
                              attrs: { icon: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.goToPage(_vm.page - 1)
                                },
                              },
                            },
                            [
                              _c("v-icon", { staticClass: "chevron" }, [
                                _vm._v("mdi-chevron-left"),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-btn",
                        {
                          attrs: { disabled: _vm.page === 1 },
                          on: {
                            click: function ($event) {
                              return _vm.goToPage(1)
                            },
                          },
                        },
                        [_vm._v(" 1 ")]
                      ),
                      _vm._l(_vm.visiblePages, function (pageNum) {
                        return _c(
                          "v-btn",
                          {
                            key: pageNum,
                            class: { active: pageNum === _vm.page },
                            on: {
                              click: function ($event) {
                                return _vm.goToPage(pageNum)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(pageNum) + " ")]
                        )
                      }),
                      _c(
                        "v-btn",
                        {
                          attrs: { disabled: _vm.page === _vm.totalPages },
                          on: {
                            click: function ($event) {
                              return _vm.goToPage(_vm.totalPages)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.totalPages) + " ")]
                      ),
                      _vm.page < _vm.totalPages - 3
                        ? _c(
                            "v-btn",
                            {
                              attrs: { icon: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.goToPage(_vm.page + 1)
                                },
                              },
                            },
                            [
                              _c("v-icon", { staticClass: "chevron" }, [
                                _vm._v("mdi-chevron-right"),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          ),
      _vm.$vuetify.breakpoint.mobile
        ? _c(
            "v-dialog",
            {
              attrs: {
                fullscreen: "",
                transition: "dialog-bottom-transition",
                scrollable: "",
              },
              model: {
                value: _vm.dialog.result,
                callback: function ($$v) {
                  _vm.$set(_vm.dialog, "result", $$v)
                },
                expression: "dialog.result",
              },
            },
            [
              _vm.dialog.result && _vm.containerMovements.logs.length > 0
                ? _c("MobileContainerTrackingSearchResult", {
                    on: {
                      "close-dialog": function ($event) {
                        _vm.dialog.result = false
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "loading-text" }, [
      _c("span", [_vm._v("Loading")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }