var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        { attrs: { rounded: "lg", elevation: "0" } },
        [
          _c(
            "v-timeline",
            { attrs: { "align-top": "", dense: "" } },
            _vm._l(_vm.logs, function (log, index) {
              return _c(
                "v-timeline-item",
                {
                  key: log.step,
                  attrs: { small: "", color: "none" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "icon",
                        fn: function () {
                          return [
                            _c("StatusIndicator", {
                              attrs: {
                                status: index === 0 ? "active" : "",
                                pulse: index === 0,
                              },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                },
                [
                  _c(
                    "v-row",
                    { staticClass: "pt-1" },
                    [
                      _c("v-col", { attrs: { cols: "3" } }, [
                        _c("div", [_c("strong", [_vm._v("DATETIME")])]),
                        _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$filters.formatDatetime(log.movementDate)
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("v-col", { attrs: { cols: "2" } }, [
                        _c("strong", [_vm._v("STATUS")]),
                        log.status
                          ? _c("div", [
                              _vm._v(
                                " " + _vm._s(log.status.replace("_", " ")) + " "
                              ),
                            ])
                          : _vm._e(),
                      ]),
                      _c("v-col", { attrs: { cols: "7" } }, [
                        _c("strong", [_vm._v("LOCATION")]),
                        _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(log.depotName || log.terminalName) +
                              " "
                          ),
                          log.depotPortName || log.terminalPortName
                            ? _c("span", [
                                _vm._v(
                                  "(" +
                                    _vm._s(
                                      log.depotPortName || log.terminalPortName
                                    ) +
                                    ")"
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }