<template>
  <div class="mb-16">
    <v-row no-gutters>
      <v-col cols="12">
        <VesselScheduleSearchFilterV3
          class="mb-2"
          :class="{
            'mb-2': $vuetify.breakpoint.mobile,
          }"
        />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col
        :cols="$vuetify.breakpoint.mobile ? 12 : 12"
        :order="$vuetify.breakpoint.mobile ? 2 : 1"
      >
        <VesselScheduleSearchResultList />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import VesselScheduleSearchFilterV3 from './VesselScheduleSearchFilterV3.vue';
import VesselScheduleSearchResultList from './VesselScheduleSearchResultList.vue';
export default {
  props: {
    requestOpenInNewTab: {
      type: Boolean,
      default: false,
    },
  },
  provide() {
    return {
      isRequestOpenInNewTab: this.requestOpenInNewTab,
      getIsSearched: () => this.isSearched,
      setIsSearched: (value) => {
        this.isSearched = value;
      },
    };
  },
  components: {
    VesselScheduleSearchFilterV3,
    VesselScheduleSearchResultList,
  },
  data: () => ({
    isSearched: false,
  }),
  methods: {},
};
</script>

<style lang="scss" scoped>
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 16px;
  z-index: 4;
}
</style>
