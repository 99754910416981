import CustomerServiceApi from '@/api/vpl/customer-service.api';

const initialState = () => ({
  customerService: {
    id: null,
    name: '',
  },
  customerServiceList: [],
});

const state = initialState();
const getters = {};
const mutations = {
  RESET(state, payload) {
    const initStates = initialState();
    if (!payload) {
      Object.keys(initStates).map((key) => {
        state[key] = initStates[key];
      });
    } else {
      const statesToReset = payload;
      statesToReset.map((key) => {
        state[key] = initStates[key];
      });
    }
  },
  SET_CUSTOMER_SERVICE_LIST(state, payload) {
    state.customerServiceList = payload;
  },
};
const actions = {
  async fetchCustomerServiceList({ commit }, payload) {
    const res = await CustomerServiceApi.fetchCustomerServiceList();
    const mapped = res.data.data.map(({ id, name }) => ({ id, name }));
    commit('SET_CUSTOMER_SERVICE_LIST', mapped);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
