var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex align-center justify-center mr-2" },
    [
      _vm.lang === "vi"
        ? _c(
            "v-btn",
            {
              staticClass: "px-2 py-4",
              attrs: {
                width: "160px",
                depressed: "",
                color: "white",
                small: "",
              },
              on: {
                click: function ($event) {
                  return _vm.onChangeLanguage("en")
                },
              },
            },
            [
              _c("v-img", {
                attrs: {
                  height: "24",
                  width: "50",
                  contain: "",
                  src: require("@/assets/country/vietnam.png"),
                },
              }),
              _vm._v(" Tiếng Việt "),
              _c("v-icon", { attrs: { right: "" } }, [
                _vm._v(" mdi-arrow-down-drop "),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm.lang === "en"
        ? _c(
            "v-btn",
            {
              staticClass: "px-2 py-4",
              attrs: {
                width: "160px",
                depressed: "",
                small: "",
                color: "white",
              },
              on: {
                click: function ($event) {
                  return _vm.onChangeLanguage("vi")
                },
              },
            },
            [
              _c("v-img", {
                attrs: {
                  height: "24",
                  width: "50",
                  contain: "",
                  src: require("@/assets/country/united-kingdom.png"),
                },
              }),
              _vm._v(" English "),
              _c("v-icon", { attrs: { right: "" } }, [
                _vm._v(" mdi-arrow-down-drop "),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }