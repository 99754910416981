<template>
  <v-row
    no-gutters
    align="center"
    class="font-weight-medium"
    style="font-size: 12px"
  >
    <v-col
      cols="2"
      class="d-flex justify-start"
    >
      <span class="info--text">8 Hours</span>
    </v-col>

    <v-col
      cols="10"
      class="d-flex align-center justify-center"
    >
      <v-hover v-slot="{ hover }">
        <v-card
          class="px-3 py-2"
          width="100%"
          :color="hover ? 'secondary' : 'background'"
          :elevation="hover ? 1 : 0"
          :ripple="true"
          style="cursor: pointer"
        >
          <v-row no-gutters>
            <v-col
              cols="5"
              class="d-flex align-center justify-start"
            >
              <flag iso="us" />
              <span class="pl-3"> Long Beach </span>
            </v-col>
            <v-col
              cols="2"
              class="d-flex align-center justify-center"
            >
              <v-icon> mdi-chevron-right </v-icon>
            </v-col>
            <v-col
              cols="5"
              class="d-flex align-center justify-end"
            >
              <div>
                <span class="pr-3">Istanbul</span>
                <flag iso="ca" />
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-hover>
    </v-col>
  </v-row>
</template>

<script>
export default {};
</script>

<style scoped>
.flag-icon {
  width: 20px !important;
  line-height: 16px !important;
  border-radius: 2px !important;
}

.v-card {
  transition: all 0.2s ease-in-out;
}
</style>
