<template>
  <div class="">
    <p class="text-h7 mt-4 success--text font-weight-medium">WORKFLOW</p>
    <v-row
      no-gutters
      align="center"
    >
      <div>
        <p
          class="text-h3 my-0"
          style="font-weight: 600"
        >
          Active shipping leads
        </p>
      </div>
      <v-spacer />
      <v-btn
        :class="{
          'mt-6': $vuetify.breakpoint.mobile,
        }"
        :width="$vuetify.breakpoint.mobile ? '100%' : '250px'"
        color="info"
        large
      >
        Request a quote
      </v-btn>
    </v-row>
    <div class="mt-16">
      <v-row
        no-gutters
        :class="{
          'flex-column': $vuetify.breakpoint.mobile,
        }"
      >
        <v-col
          :cols="$vuetify.breakpoint.mobile ? 12 : 4"
          :class="{
            'pr-3': !$vuetify.breakpoint.mobile,
          }"
        >
          <p
            class="text-h6 d-flex align-center"
            style="font-weight: 600"
          >
            <v-icon left>mdi-shape-square-rounded-plus</v-icon>
            <span>Less container load</span>
          </p>
          <ShippingLeadsList />
          <p
            class="mt-2 text--disabled font-weight-medium"
            style="font-size: 12px"
          >
            Submit a form for cargo volumes from one cubic meter up to 20m³. LCL
            shipment is the most economical way to ship small cargo...
          </p>
        </v-col>
        <v-col
          :cols="$vuetify.breakpoint.mobile ? 12 : 4"
          :class="{
            'px-3 border-list': !$vuetify.breakpoint.mobile,
          }"
        >
          <p
            class="text-h6 d-flex align-center"
            style="font-weight: 600"
          >
            <v-icon left>mdi-shape-square-rounded-plus</v-icon>
            <span>Full container load</span>
          </p>
          <ShippingLeadsList />
          <p
            class="mt-2 text--disabled font-weight-medium"
            style="font-size: 12px"
          >
            Transportation in ocean containers (FCL). A whole container is
            utlised for one consignee. Submit a rate request form for a type of
            container
          </p>
        </v-col>
        <v-col
          :cols="$vuetify.breakpoint.mobile ? 12 : 4"
          :class="{
            'pl-3': !$vuetify.breakpoint.mobile,
          }"
        >
          <p
            class="text-h6 d-flex align-center"
            style="font-weight: 600"
          >
            <v-icon left>mdi-shape-square-rounded-plus</v-icon>
            <span>In Bulk</span>
          </p>
          <ShippingLeadsList />
          <p
            class="mt-2 text--disabled font-weight-medium"
            style="font-size: 12px"
          >
            Break bulk cargo, bulk cargoes, project cargo and heavy lift,
            military equipment or almost any other oversized or overweight
            cargo.
          </p>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import ShippingLeadsList from './ShippingLeadsList.vue';
export default {
  components: { ShippingLeadsList },
};
</script>

<style scoped>
.border-list {
  border-left: 2px solid rgb(236 236 236);
  border-right: 2px solid rgb(236 236 236);
}
</style>
