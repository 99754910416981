var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "carousel slide carousel-fade",
        attrs: {
          id: "carousel-2",
          "data-ride": "carousel",
          "data-interval": "6000",
        },
      },
      [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "carousel-inner", attrs: { role: "listbox" } },
          [
            _c("div", { staticClass: "carousel-item active" }, [
              _c("img", {
                staticClass: "d-block img-fluid",
                attrs: {
                  src: require("../../../libhome/img/anhnen1.jpg"),
                  alt: "responsive image",
                },
              }),
              _c(
                "div",
                {
                  staticClass:
                    "carousel-caption justify-content-center align-items-center",
                },
                [
                  _c("div", { staticClass: "transx_content_slide_wrapper" }, [
                    _c("div", { staticClass: "transx_content_container" }, [
                      _c("div", { staticClass: "transx_content_wrapper_1" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "transx_up_heading_overlay transx_overlay_align_center",
                          },
                          [_vm._v(" VIMC LINES ")]
                        ),
                        _c(
                          "h2",
                          { staticClass: "transx_content_slider_title" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  this.$i18n.locale === "en"
                                    ? "Home"
                                    : "Trang chủ"
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._m(1),
                ]
              ),
            ]),
            _c("div", { staticClass: "carousel-item" }, [
              _c("img", {
                staticClass: "d-block img-fluid",
                attrs: {
                  src: require("../../../libhome/img/anhnenhome.jpg"),
                  alt: "responsive image",
                },
              }),
              _c(
                "div",
                {
                  staticClass:
                    "carousel-caption justify-content-center align-items-center",
                },
                [
                  _c("div", { staticClass: "transx_content_slide_wrapper" }, [
                    _c("div", { staticClass: "transx_content_container" }, [
                      _c("div", { staticClass: "transx_content_wrapper_1" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "transx_up_heading_overlay transx_overlay_align_center",
                          },
                          [_vm._v(" VIMC LINES ")]
                        ),
                        _c(
                          "h2",
                          { staticClass: "transx_content_slider_title" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  this.$i18n.locale === "en"
                                    ? "Home"
                                    : "Trang chủ"
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._m(2),
                ]
              ),
            ]),
            _c("div", { staticClass: "carousel-item" }, [
              _c("img", {
                staticClass: "d-block img-fluid",
                attrs: {
                  src: require("../../../libhome/img/tòa nhà ocean park - trụ sở tổng công ty hàng hải việt nam(vimc).jpg"),
                  alt: "responsive image",
                },
              }),
              _c(
                "div",
                {
                  staticClass:
                    "carousel-caption justify-content-center align-items-center",
                },
                [
                  _c("div", { staticClass: "transx_content_slide_wrapper" }, [
                    _c("div", { staticClass: "transx_content_container" }, [
                      _c("div", { staticClass: "transx_content_wrapper_1" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "transx_up_heading_overlay transx_overlay_align_center",
                          },
                          [_vm._v(" VIMC LINES ")]
                        ),
                        _c(
                          "h2",
                          { staticClass: "transx_content_slider_title" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  this.$i18n.locale === "en"
                                    ? "Home"
                                    : "Trang chủ"
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._m(3),
                ]
              ),
            ]),
          ]
        ),
      ]
    ),
    _c("div", { staticClass: "container" }, [
      _c(
        "section",
        {
          staticClass:
            "elementor-section elementor-top-section elementor-element elementor-element-4a6ec8d5 elementor-section-boxed elementor-section-height-default elementor-section-height-default",
          attrs: { "data-id": "4a6ec8d5", "data-element_type": "section" },
        },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                staticClass:
                  "col-4 elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-19c4fdd2",
                attrs: { "data-id": "19c4fdd2", "data-element_type": "column" },
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "elementor-widget-wrap elementor-element-populated",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "elementor-element elementor-element-4e86b3b8 elementor-widget elementor-widget-transx_heading",
                        attrs: {
                          "data-id": "4e86b3b8",
                          "data-element_type": "widget",
                          "data-widget_type": "transx_heading.default",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "elementor-widget-container" },
                          [
                            _c(
                              "div",
                              { staticClass: "transx_heading_widget-home" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "transx_up_heading",
                                    staticStyle: {
                                      padding: "0 0 10px 0",
                                      margin: "0",
                                    },
                                    attrs: { id: "services" },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.$t("services"))),
                                    ]),
                                  ]
                                ),
                                _c("h2", { staticClass: "transx_heading" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("transportationServices")) +
                                      " "
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
            _c(
              "div",
              {
                staticClass:
                  "col-8 elementor-column elementor-col-66 elementor-top-column elementor-element elementor-element-11762ac5",
                attrs: { "data-id": "11762ac5", "data-element_type": "column" },
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "elementor-widget-wrap elementor-element-populated",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "elementor-element elementor-element-bc7fb4b elementor-widget elementor-widget-text-editor",
                        attrs: {
                          "data-id": "bc7fb4b",
                          "data-element_type": "widget",
                          "data-widget_type": "text-editor.default",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "elementor-widget-container",
                            staticStyle: { padding: "0 110px" },
                          },
                          [
                            _c("p", [
                              _c("strong", [
                                _vm._v(_vm._s(_vm.$t("homeServicesTitlePara"))),
                              ]),
                            ]),
                            _c("p", [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("homeServicesContentPara")) +
                                  " "
                              ),
                            ]),
                          ]
                        ),
                      ]
                    ),
                    _c("div", {
                      staticClass:
                        "elementor-element elementor-element-8b3e402 elementor-widget elementor-widget-transx_button",
                      attrs: {
                        "data-id": "8b3e402",
                        "data-element_type": "widget",
                        "data-widget_type": "transx_button.default",
                      },
                    }),
                  ]
                ),
              ]
            ),
          ]),
        ]
      ),
      _c(
        "section",
        {
          staticClass:
            "elementor-section elementor-top-section elementor-element elementor-element-4dad42f8 elementor-section-boxed elementor-section-height-default elementor-section-height-default",
          attrs: { "data-id": "4dad42f8", "data-element_type": "section" },
        },
        [
          _c(
            "div",
            {
              staticClass: "elementor-container elementor-column-gap-extended",
            },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-lg-4 col-md-6" }, [
                  _c("div", { staticClass: "service-item" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-79384b5b",
                        attrs: {
                          "data-id": "79384b5b",
                          "data-element_type": "column",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "elementor-widget-wrap elementor-element-populated",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "elementor-element elementor-element-4eaaa3c elementor-widget elementor-widget-transx_promo_box",
                                attrs: {
                                  "data-id": "4eaaa3c",
                                  "data-element_type": "widget",
                                  "data-widget_type":
                                    "transx_promo_box.default",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "elementor-widget-container" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "transx_promo_box_widget",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "transx_promo_box_item transx_view_type_1",
                                          },
                                          [
                                            _vm._m(4),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "transx_promo_box_content",
                                              },
                                              [
                                                _c(
                                                  "h6",
                                                  {
                                                    staticClass:
                                                      "transx_promo_box_title",
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "transx_promo_box_count",
                                                      },
                                                      [_vm._v("01")]
                                                    ),
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "title-service-count",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t("lines")
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "transx_promo_box_description",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "logisticsService"
                                                          )
                                                        ) +
                                                        "   "
                                                    ),
                                                    _c(
                                                      "a",
                                                      {
                                                        attrs: {
                                                          href: "/",
                                                          target: "_blank",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "svg",
                                                          {
                                                            staticClass: "icon",
                                                          },
                                                          [
                                                            _c(
                                                              "svg",
                                                              {
                                                                attrs: {
                                                                  xmlns:
                                                                    "http://www.w3.org/2000/svg",
                                                                  viewBox:
                                                                    "0 0 24 24",
                                                                  id: "next-arrow",
                                                                },
                                                              },
                                                              [
                                                                _c("path", {
                                                                  attrs: {
                                                                    d: "M22.707,12.707a1,1,0,0,0,0-1.414l-5-5a1,1,0,0,0-1.414,1.414L19.586,11H2a1,1,0,0,0,0,2H19.586l-3.293,3.293a1,1,0,0,0,1.414,1.414Z",
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "col-lg-4 col-md-6" }, [
                  _c("div", { staticClass: "service-item" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-121bc60c",
                        attrs: {
                          "data-id": "121bc60c",
                          "data-element_type": "column",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "elementor-widget-wrap elementor-element-populated",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "elementor-element elementor-element-671c5cbb elementor-widget elementor-widget-transx_promo_box",
                                attrs: {
                                  "data-id": "671c5cbb",
                                  "data-element_type": "widget",
                                  "data-widget_type":
                                    "transx_promo_box.default",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "elementor-widget-container" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "transx_promo_box_widget",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "transx_promo_box_item transx_view_type_1",
                                          },
                                          [
                                            _vm._m(5),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "transx_promo_box_content",
                                              },
                                              [
                                                _c(
                                                  "h6",
                                                  {
                                                    staticClass:
                                                      "transx_promo_box_title",
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "transx_promo_box_count",
                                                      },
                                                      [_vm._v("02")]
                                                    ),
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "title-service-count",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              this.$i18n
                                                                .locale == "en"
                                                                ? "Terminal"
                                                                : "Terminal"
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "transx_promo_box_description",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "cargoHandlingTerminal"
                                                          )
                                                        ) +
                                                        "   "
                                                    ),
                                                    _c(
                                                      "a",
                                                      {
                                                        attrs: {
                                                          href: "https://www.vimcdinhvu.com.vn/",
                                                          target: "_blank",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "svg",
                                                          {
                                                            staticClass: "icon",
                                                          },
                                                          [
                                                            _c(
                                                              "svg",
                                                              {
                                                                attrs: {
                                                                  xmlns:
                                                                    "http://www.w3.org/2000/svg",
                                                                  viewBox:
                                                                    "0 0 24 24",
                                                                  id: "next-arrow",
                                                                },
                                                              },
                                                              [
                                                                _c("path", {
                                                                  attrs: {
                                                                    d: "M22.707,12.707a1,1,0,0,0,0-1.414l-5-5a1,1,0,0,0-1.414,1.414L19.586,11H2a1,1,0,0,0,0,2H19.586l-3.293,3.293a1,1,0,0,0,1.414,1.414Z",
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "col-lg-4 col-md-6" }, [
                  _c("div", { staticClass: "service-item" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-4f3022a",
                        attrs: {
                          "data-id": "4f3022a",
                          "data-element_type": "column",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "elementor-widget-wrap elementor-element-populated",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "elementor-element elementor-element-26fea086 elementor-widget elementor-widget-transx_promo_box",
                                attrs: {
                                  "data-id": "26fea086",
                                  "data-element_type": "widget",
                                  "data-widget_type":
                                    "transx_promo_box.default",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "elementor-widget-container" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "transx_promo_box_widget",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "transx_promo_box_item transx_view_type_1",
                                          },
                                          [
                                            _vm._m(6),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "transx_promo_box_content",
                                              },
                                              [
                                                _c(
                                                  "h6",
                                                  {
                                                    staticClass:
                                                      "transx_promo_box_title",
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "transx_promo_box_count",
                                                      },
                                                      [_vm._v("03")]
                                                    ),
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "title-service-count",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t("depot")
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "transx_promo_box_description",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t("warehouse")
                                                        ) +
                                                        "   "
                                                    ),
                                                    _c(
                                                      "a",
                                                      {
                                                        attrs: {
                                                          href: "https://vimadeco.com.vn/",
                                                          target: "_blank",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "svg",
                                                          {
                                                            staticClass: "icon",
                                                          },
                                                          [
                                                            _c(
                                                              "svg",
                                                              {
                                                                attrs: {
                                                                  xmlns:
                                                                    "http://www.w3.org/2000/svg",
                                                                  viewBox:
                                                                    "0 0 24 24",
                                                                  id: "next-arrow",
                                                                },
                                                              },
                                                              [
                                                                _c("path", {
                                                                  attrs: {
                                                                    d: "M22.707,12.707a1,1,0,0,0,0-1.414l-5-5a1,1,0,0,0-1.414,1.414L19.586,11H2a1,1,0,0,0,0,2H19.586l-3.293,3.293a1,1,0,0,0,1.414,1.414Z",
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
        ]
      ),
    ]),
    _c("div", { staticClass: "about-new" }, [
      _c("div", { staticClass: "container-nav" }, [
        _c(
          "div",
          {
            staticClass:
              "row about-content-new transx_sidebar_none transx_bg_color_default",
          },
          [
            _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
              _c("div", { staticClass: "transx_content_wrapper" }, [
                _c(
                  "div",
                  {
                    staticClass: "elementor elementor-42",
                    attrs: {
                      "data-elementor-type": "wp-page",
                      "data-elementor-id": "42",
                      "data-elementor-settings": "[]",
                    },
                  },
                  [
                    _c("div", { staticClass: "elementor-section-wrap" }, [
                      _c(
                        "section",
                        {
                          staticClass:
                            "elementor-section elementor-top-section elementor-element elementor-element-223bd736 elementor-hidden-tablet elementor-hidden-phone elementor-section-boxed elementor-section-height-default elementor-section-height-default",
                          attrs: {
                            "data-id": "223bd736",
                            "data-element_type": "section",
                          },
                        },
                        [
                          _c(
                            "v-row",
                            { staticClass: "d-sm-column" },
                            [
                              _c("v-col", {
                                staticClass:
                                  "col-12 col-md-5 elementor-column-img-about elementor-col-33 elementor-top-column elementor-element elementor-element-7b9387f9",
                                attrs: {
                                  cols: _vm.$vuetify.breakpoint.mobile ? 12 : 5,
                                  "data-id": "7b9387f9",
                                  "data-element_type": "column",
                                },
                              }),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "col-12 col-md-7 elementor-column-content-about elementor-column elementor-col-66 elementor-top-column elementor-element elementor-element-5075a951",
                                  staticStyle: {
                                    padding: "15px 105px 15px 35px",
                                  },
                                  attrs: {
                                    cols: _vm.$vuetify.breakpoint.mobile
                                      ? 12
                                      : 7,
                                    "data-id": "5075a951",
                                    "data-element_type": "column",
                                  },
                                },
                                [
                                  _c("div", {
                                    staticClass: "about-new-background-overlay",
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "elementor-widget-wrap elementor-element-populated",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "elementor-element elementor-element-72dc12f8 elementor-widget elementor-widget-transx_heading",
                                          attrs: {
                                            "data-id": "72dc12f8",
                                            "data-element_type": "widget",
                                            "data-widget_type":
                                              "transx_heading.default",
                                          },
                                        },
                                        [
                                          _c("div", { staticClass: "mt-12" }, [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "about-new-section-header text-left",
                                                staticStyle: { left: "0" },
                                                attrs: { id: "about-us" },
                                              },
                                              [
                                                _c("p", [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("aboutUs"))
                                                  ),
                                                ]),
                                                _c("h2", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "aboutUsTitlePara"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "about-content-text-new",
                                              },
                                              [
                                                _c(
                                                  "p",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "justify",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "aboutUsContentPara"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c("ul", [
                                                  _c("li", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t("aboutNameVn")
                                                        ) +
                                                        " "
                                                    ),
                                                  ]),
                                                  _c("li", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t("aboutNameEn")
                                                        ) +
                                                        " "
                                                    ),
                                                  ]),
                                                  _c("li", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t("aboutNameInt")
                                                        ) +
                                                        " "
                                                    ),
                                                  ]),
                                                  _c("li", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t("aboutAddress")
                                                        ) +
                                                        " "
                                                    ),
                                                  ]),
                                                ]),
                                              ]
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("section", {
                        staticClass:
                          "elementor-top-section elementor-element elementor-element-3903e669 elementor-hidden-desktop elementor-section-boxed elementor-section-height-default elementor-section-height-default",
                        attrs: {
                          "data-id": "3903e669",
                          "data-element_type": "section",
                        },
                      }),
                    ]),
                  ]
                ),
              ]),
              _c("div", { staticClass: "transx_content_paging_wrapper" }),
            ]),
          ]
        ),
      ]),
      _c("div", { staticClass: "content__card" }, [
        _c("strong", { staticClass: "content__card--ques" }, [
          _vm._v(_vm._s(_vm.$t("helper"))),
        ]),
        _c("div", { staticClass: "card-contact" }, [
          _c("div", { staticClass: "card-contact--link" }, [
            _c("a", { attrs: { href: "/contact" } }, [
              _c("span", [_vm._v(_vm._s(_vm.$t("contactUs")))]),
              _c("div", { staticClass: "icon" }, [
                _c(
                  "svg",
                  {
                    staticClass: "arrow",
                    attrs: {
                      viewBox: "0 0 150 78.6",
                      xmlns: "http://www.w3.org/2000/svg",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        d: "M0 31.5h150v12.7H0V31.5zM112.8-.1l30.9 31.5-8.8 9L104 8.9l8.8-9zm18.1 51l-18.4 18.8 8.9 9 18.4-18.8-8.9-9z",
                      },
                    }),
                  ]
                ),
              ]),
            ]),
          ]),
          _c("img", {
            attrs: {
              src: require("../../../libhome/img/email_img.png"),
              alt: "",
            },
          }),
        ]),
      ]),
    ]),
    _c("div", [
      _c("div", { staticClass: "main_quote" }, [
        _c("div", { staticClass: "main_quote__content" }, [
          _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "content__details" }, [
              _c(
                "p",
                {
                  staticStyle: {
                    display: "inline-block",
                    "text-align": "center",
                    "margin-bottom": "10px",
                    "padding-bottom": "5px",
                    position: "relative",
                    "font-size": "16px",
                    "font-weight": "700",
                    "letter-spacing": "4px",
                    "text-transform": "uppercase",
                    color: "#fff",
                    "padding-top": "15px",
                    width: "105px",
                    "border-bottom": "2px solid #fff",
                    "font-family": "Montserrat, sans-serif",
                  },
                  attrs: { id: "quote" },
                },
                [_vm._v(" " + _vm._s(_vm.$t("quote")) + " ")]
              ),
              _c("div", { attrs: { clquoteass: "content__details--title" } }, [
                _c(
                  "h2",
                  {
                    staticStyle: {
                      color: "rgba(0, 0, 0, 0.85)",
                      "font-size": "45px",
                      "font-weight": "700",
                      "font-family": "Montserrat, sans-serif",
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("quoteHomeTitleContaier")) + " ")]
                ),
              ]),
              _c(
                "p",
                {
                  staticClass: "content__details--des",
                  staticStyle: {
                    color: "#000",
                    "font-size": "16px",
                    "font-weight": "500",
                    "text-align": "justify",
                    height: "180px",
                    "font-family": "Montserrat, sans-serif",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("quoteHomePara")) + " ")]
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "service" }, [
      _c(
        "div",
        {
          staticClass: "section-header text-center",
          attrs: { id: "all-services" },
        },
        [
          _c("p", [_vm._v(_vm._s(_vm.$t("allServicesCaptital")))]),
          _c("h2", [_vm._v(_vm._s(_vm.$t("trustService")))]),
        ]
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "align-items": "center",
            "justify-content": "center",
            padding: "0 110px",
          },
        },
        [
          _c("div", { staticStyle: { display: "flex", gap: "50px" } }, [
            _c("div", { staticStyle: { width: "100%", height: "auto" } }, [
              _c("div", { staticClass: "service-item" }, [
                _c("i", { staticClass: "flaticon-car-wash-1" }),
                _c("h3", [_vm._v(_vm._s(_vm.$t("fastDelivery")))]),
                _c("p", { staticStyle: { "text-align": "justify" } }, [
                  _vm._v(" " + _vm._s(_vm.$t("fastDeliPara")) + " "),
                ]),
              ]),
            ]),
            _c("div", { staticStyle: { width: "100%", height: "auto" } }, [
              _c("div", { staticClass: "service-item" }, [
                _c("i", { staticClass: "flaticon-car-wash" }),
                _c("h3", [_vm._v(_vm._s(_vm.$t("highReliability")))]),
                _c("p", { staticStyle: { "text-align": "justify" } }, [
                  _vm._v(" " + _vm._s(_vm.$t("highReliPara")) + " "),
                ]),
              ]),
            ]),
            _c("div", { staticStyle: { width: "100%", height: "auto" } }, [
              _c("div", { staticClass: "service-item" }, [
                _c("i", { staticClass: "flaticon-vacuum-cleaner" }),
                _c("h3", [_vm._v(_vm._s(_vm.$t("greatOffer")))]),
                _c("p", { staticStyle: { "text-align": "justify" } }, [
                  _vm._v(" " + _vm._s(_vm.$t("greatOfferPara")) + " "),
                ]),
              ]),
            ]),
            _c("div", { staticStyle: { width: "100%", height: "auto" } }, [
              _c("div", { staticClass: "service-item" }, [
                _c("i", { staticClass: "flaticon-seat" }),
                _c("h3", [_vm._v(_vm._s(_vm.$t("support24")))]),
                _c("p", { staticStyle: { "text-align": "justify" } }, [
                  _vm._v(" " + _vm._s(_vm.$t("suportPara")) + " "),
                ]),
              ]),
            ]),
            _c("div", { staticStyle: { width: "100%", height: "auto" } }, [
              _c("div", { staticClass: "service-item" }, [
                _c("i", { staticClass: "flaticon-car-service" }),
                _c("h3", [_vm._v(_vm._s(_vm.$t("bestService")))]),
                _c("p", { staticStyle: { "text-align": "justify" } }, [
                  _vm._v(" " + _vm._s(_vm.$t("bestServicePara")) + " "),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
    ]),
    _c(
      "div",
      {
        staticStyle: {
          "text-align": "center",
          width: "100vw",
          padding: "15px 200px",
          "background-color": "#f0f0f0",
        },
      },
      [
        _c(
          "div",
          { staticClass: "elementor-widget-wrap elementor-element-populated" },
          [
            _c(
              "div",
              {
                staticClass:
                  "elementor-element elementor-element-3e10245d elementor-widget elementor-widget-transx_heading",
                attrs: {
                  "data-id": "3e10245d",
                  "data-element_type": "widget",
                  "data-widget_type": "transx_heading.default",
                },
              },
              [
                _c("div", { staticClass: "elementor-widget-container" }, [
                  _c("div", { staticClass: "transx_heading_widget" }, [
                    _c(
                      "div",
                      {
                        staticClass: "transx_up_heading",
                        attrs: { id: "achivement" },
                      },
                      [_c("p", [_vm._v(_vm._s(_vm.$t("achivement")))])]
                    ),
                    _c("h2", { staticClass: "transx_heading" }, [
                      _vm._v(_vm._s(_vm.$t("highWorkAchievements"))),
                    ]),
                  ]),
                ]),
              ]
            ),
            _c(
              "section",
              {
                staticClass:
                  "elementor-section elementor-inner-section elementor-element elementor-element-18a82c3a elementor-hidden-phone elementor-section-boxed elementor-section-height-default elementor-section-height-default",
                attrs: {
                  "data-id": "18a82c3a",
                  "data-element_type": "section",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "elementor-container elementor-column-gap-no",
                  },
                  [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { staticClass: "col-12" },
                          [
                            _c(
                              "v-row",
                              [
                                _c("v-col", { staticClass: "col-4 col-left" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-21e7f9a4",
                                      attrs: {
                                        "data-id": "21e7f9a4",
                                        "data-element_type": "column",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "elementor-widget-wrap elementor-element-populated",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "elementor-element elementor-element-53b415f5 transx_counter_align_left elementor-widget elementor-widget-counter",
                                              attrs: {
                                                "data-id": "53b415f5",
                                                "data-element_type": "widget",
                                                "data-widget_type":
                                                  "counter.default",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "elementor-widget-container",
                                                  staticStyle: {
                                                    display: "flex",
                                                    "align-items": "center",
                                                    "justify-content": "center",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "elementor-counter",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "elementor-counter-number-wrapper",
                                                        },
                                                        [
                                                          _c("span", {
                                                            staticClass:
                                                              "elementor-counter-number-prefix",
                                                          }),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "elementor-counter-number",
                                                              staticStyle: {
                                                                color:
                                                                  "#e8734e",
                                                              },
                                                              attrs: {
                                                                "data-duration":
                                                                  "2000",
                                                                "data-to-value":
                                                                  "1.2",
                                                                "data-from-value":
                                                                  "0",
                                                                "data-delimiter":
                                                                  ",",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    "font-size":
                                                                      "60px",
                                                                    display:
                                                                      "flex",
                                                                    "align-items":
                                                                      "end",
                                                                    gap: "15px",
                                                                    "padding-bottom":
                                                                      "10px",
                                                                    "white-space":
                                                                      "nowrap",
                                                                    overflow:
                                                                      "hidden",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          "font-size":
                                                                            "60px",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "1.015"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "billion"
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "elementor-counter-title",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "charterCap"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                                _c(
                                  "v-col",
                                  { staticClass: "col-4 col-center" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-6725ae06",
                                        attrs: {
                                          "data-id": "6725ae06",
                                          "data-element_type": "column",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "elementor-widget-wrap elementor-element-populated",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "elementor-element elementor-element-41151222 transx_counter_align_left transx_figure_corner_no elementor-widget elementor-widget-counter",
                                                attrs: {
                                                  "data-id": "41151222",
                                                  "data-element_type": "widget",
                                                  "data-widget_type":
                                                    "counter.default",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "elementor-widget-container",
                                                    staticStyle: {
                                                      display: "flex",
                                                      "align-items": "center",
                                                      "justify-content":
                                                        "center",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "elementor-counter",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "elementor-counter-number-wrapper",
                                                          },
                                                          [
                                                            _c("span", {
                                                              staticClass:
                                                                "elementor-counter-number-prefix",
                                                            }),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "elementor-counter-number",
                                                                staticStyle: {
                                                                  color:
                                                                    "#e8734e",
                                                                },
                                                                attrs: {
                                                                  "data-duration":
                                                                    "2000",
                                                                  "data-to-value":
                                                                    "1.2",
                                                                  "data-from-value":
                                                                    "0",
                                                                  "data-delimiter":
                                                                    ",",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        "font-size":
                                                                          "60px",
                                                                        display:
                                                                          "flex",
                                                                        "align-items":
                                                                          "end",
                                                                        gap: "50px",
                                                                        "white-space":
                                                                          "nowrap",
                                                                        overflow:
                                                                          "hidden",
                                                                        position:
                                                                          "relative",
                                                                        "padding-bottom":
                                                                          "10px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            "font-size":
                                                                              "60px",
                                                                            position:
                                                                              "relative",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " 30 "
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticStyle:
                                                                              {
                                                                                "font-size":
                                                                                  "60px",
                                                                                position:
                                                                                  "absolute",
                                                                                transform:
                                                                                  "translateY(-20%)",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " + "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "partner"
                                                                          )
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "elementor-counter-title",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "DomesAndInternational"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "v-col",
                                  { staticClass: "col-4 col-right" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-3bce258a",
                                        attrs: {
                                          "data-id": "3bce258a",
                                          "data-element_type": "column",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "elementor-widget-wrap elementor-element-populated",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "elementor-element elementor-element-71249b1c transx_counter_align_left transx_figure_corner_no elementor-widget elementor-widget-counter",
                                                attrs: {
                                                  "data-id": "71249b1c",
                                                  "data-element_type": "widget",
                                                  "data-widget_type":
                                                    "counter.default",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "elementor-widget-container",
                                                    staticStyle: {
                                                      display: "flex",
                                                      "align-items": "center",
                                                      "justify-content":
                                                        "center",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "elementor-counter",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "elementor-counter-number-wrapper",
                                                          },
                                                          [
                                                            _c("span", {
                                                              staticClass:
                                                                "elementor-counter-number-prefix",
                                                            }),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "elementor-counter-number",
                                                                staticStyle: {
                                                                  color:
                                                                    "#e8734e",
                                                                },
                                                                attrs: {
                                                                  "data-duration":
                                                                    "2000",
                                                                  "data-to-value":
                                                                    "1.2",
                                                                  "data-from-value":
                                                                    "0",
                                                                  "data-delimiter":
                                                                    ",",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        "font-size":
                                                                          "60px",
                                                                        display:
                                                                          "flex",
                                                                        "align-items":
                                                                          "end",
                                                                        gap: "50px",
                                                                        "white-space":
                                                                          "nowrap",
                                                                        overflow:
                                                                          "hidden",
                                                                        position:
                                                                          "relative",
                                                                        "padding-bottom":
                                                                          "10px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            "font-size":
                                                                              "60px",
                                                                            position:
                                                                              "relative",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " 300 "
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticStyle:
                                                                              {
                                                                                "font-size":
                                                                                  "60px",
                                                                                position:
                                                                                  "absolute",
                                                                                transform:
                                                                                  "translateY(-20%)",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " + "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "customers"
                                                                          )
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "elementor-counter-title",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "DomesAndInternational"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]
        ),
      ]
    ),
    _c(
      "div",
      {
        staticClass: "timeline-1",
        staticStyle: { "background-color": "#ffffff" },
      },
      [
        _c("div", { staticClass: "container container-time" }, [
          _c("div", { staticClass: "transx_timeline_heading_container" }, [
            _c(
              "div",
              { staticClass: "transx_up_heading", attrs: { id: "history" } },
              [_c("p", [_vm._v(_vm._s(_vm.$t("history")))])]
            ),
            _c("h2", { staticClass: "transx_heading_history" }, [
              _vm._v(_vm._s(_vm.$t("historyTitle"))),
            ]),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "container1" },
          [
            _c(
              "swiper",
              { ref: "mySwiper", attrs: { options: _vm.swiperOptions } },
              [
                _vm._l(_vm.loopedItems, function (item, index) {
                  return _c("swiper-slide", { key: index }, [
                    _c("div", { staticClass: "timeline-wrapper" }, [
                      _c("div", { staticClass: "middle-line" }),
                      _c("div", { staticClass: "box box-bottom" }, [
                        _c("div", { staticClass: "box-content-top2" }, [
                          _c("p", { staticClass: "transx_timeline_date" }, [
                            _vm._v(" " + _vm._s(item.year) + " "),
                            _c(
                              "span",
                              { staticStyle: { "font-size": "22px" } },
                              [_vm._v("year")]
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "date" }),
                        _c("div", { staticClass: "box-content" }, [
                          _c("p", [_vm._v(_vm._s(item.content))]),
                        ]),
                      ]),
                    ]),
                  ])
                }),
                _c("div", {
                  staticClass: "swiper-pagination",
                  attrs: { slot: "pagination" },
                  slot: "pagination",
                }),
              ],
              2
            ),
            _c("div", { staticClass: "swiper-button-prev" }),
            _c("div", { staticClass: "swiper-button-next" }),
          ],
          1
        ),
      ]
    ),
    _c("div", { staticClass: "team" }, [
      _c("div", { staticClass: "container container-team" }, [
        _c(
          "div",
          {
            staticClass: "section-header section-header-team text-center",
            attrs: { id: "our-team" },
          },
          [
            _c("p", [_vm._v(_vm._s(_vm.$t("meetOurTeam")))]),
            _c("h2", [_vm._v(_vm._s(_vm.$t("ourEngineer")))]),
          ]
        ),
        _c(
          "div",
          { staticStyle: { display: "flex", "justify-content": "center" } },
          [
            _c("div", {}, [
              _c("div", { staticClass: "team-item" }, [
                _vm._m(7),
                _c("div", { staticClass: "team-text" }, [
                  _c("h2", { style: _vm.computedFontSize }, [
                    _vm._v(_vm._s(_vm.$t("CEO"))),
                  ]),
                  _c("p", [_vm._v(_vm._s(_vm.$t("engineer")))]),
                  _vm._m(8),
                ]),
              ]),
            ]),
          ]
        ),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              staticClass: "col-lg-3 col-md-6",
              staticStyle: { "margin-left": "-22px", "margin-right": "60px" },
            },
            [
              _c("div", { staticClass: "team-item" }, [
                _vm._m(9),
                _c("div", { staticClass: "team-text" }, [
                  _c("h2", { style: _vm.computedFontSize }, [
                    _vm._v(_vm._s(_vm.$t("CEO"))),
                  ]),
                  _c("p", [_vm._v(_vm._s(_vm.$t("engineer")))]),
                  _vm._m(10),
                ]),
              ]),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "col-lg-3 col-md-6",
              staticStyle: { "margin-left": "-35px", "margin-right": "56px" },
            },
            [
              _c("div", { staticClass: "team-item" }, [
                _vm._m(11),
                _c("div", { staticClass: "team-text" }, [
                  _c("h2", { style: _vm.computedFontSize }, [
                    _vm._v(_vm._s(_vm.$t("CFO"))),
                  ]),
                  _c("p", [_vm._v(_vm._s(_vm.$t("engineer")))]),
                  _vm._m(12),
                ]),
              ]),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "col-lg-3 col-md-6",
              staticStyle: { "margin-left": "-35px", "margin-right": "20px" },
            },
            [
              _c("div", { staticClass: "team-item" }, [
                _vm._m(13),
                _c("div", { staticClass: "team-text" }, [
                  _c("h2", { style: _vm.computedFontSize }, [
                    _vm._v(" " + _vm._s(_vm.$t("CHRO")) + " "),
                  ]),
                  _c("p", [_vm._v(_vm._s(_vm.$t("worker")))]),
                  _vm._m(14),
                ]),
              ]),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "col-lg-3 col-md-6",
              staticStyle: { "margin-left": "5px", "margin-right": "-123px" },
            },
            [
              _c("div", { staticClass: "team-item" }, [
                _vm._m(15),
                _c("div", { staticClass: "team-text" }, [
                  _c("h2", { style: _vm.computedFontSize }, [
                    _vm._v(_vm._s(_vm.$t("CTO"))),
                  ]),
                  _c("p", [_vm._v(_vm._s(_vm.$t("worker")))]),
                  _vm._m(16),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "ol",
      { staticClass: "carousel-indicators", attrs: { id: "home" } },
      [
        _c("li", {
          staticClass: "active",
          attrs: { "data-target": "#carousel-2", "data-slide-to": "0" },
        }),
        _c("li", {
          attrs: { "data-target": "#carousel-2", "data-slide-to": "1" },
        }),
        _c("li", {
          attrs: { "data-target": "#carousel-2", "data-slide-to": "2" },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("p")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("p")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("p")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "transx_promo_box_image" }, [
      _c("img", {
        staticClass: "orange-icon",
        staticStyle: {
          width: "200px",
          height: "168px",
          "object-fit": "contain",
          "margin-left": "50px",
        },
        attrs: {
          src: require("../../../libhome/img/icon_new_2.png"),
          alt: "Icon",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "transx_promo_box_image" }, [
      _c("img", {
        staticClass: "orange-icon",
        staticStyle: {
          width: "200px",
          height: "168px",
          "object-fit": "contain",
          "margin-left": "50px",
        },
        attrs: {
          src: require("../../../libhome/img/serviceimg3.svg"),
          alt: "Icon",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "transx_promo_box_image" }, [
      _c("img", {
        staticClass: "orange-icon",
        staticStyle: {
          width: "200px",
          height: "168px",
          "object-fit": "contain",
          "margin-left": "50px",
        },
        attrs: {
          src: require("../../../libhome/img/terminal1.png"),
          alt: "Icon",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "team-img" }, [
      _c("img", {
        attrs: {
          src: require("../../../libhome/img/team-1.jpg"),
          alt: "Team Image",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "team-social" }, [
      _c(
        "a",
        {
          staticClass: "btn",
          attrs: { href: "mailto:vimclines@vimc-lines.com" },
        },
        [
          _c("img", {
            attrs: {
              src: require("../../../libhome/img/gmail.svg"),
              alt: "",
              width: "20px",
            },
          }),
        ]
      ),
      _c(
        "a",
        {
          staticClass: "btn",
          attrs: { href: "https://wa.me/84335299516", target: "_blank" },
        },
        [
          _c("img", {
            attrs: {
              src: require("../../../libhome/img/whatsapp.svg"),
              alt: "",
              width: "20px",
            },
          }),
        ]
      ),
      _c(
        "a",
        {
          staticClass: "btn",
          attrs: { href: "skype:suoinho_mk?chat", target: "_blank" },
        },
        [
          _c("img", {
            attrs: {
              src: require("../../../libhome/img/skype.svg"),
              alt: "",
              width: "20px",
            },
          }),
        ]
      ),
      _c(
        "a",
        {
          staticClass: "btn",
          attrs: { href: "https://zalo.me/0974040006", target: "_blank" },
        },
        [
          _c("img", {
            attrs: {
              src: require("../../../libhome/img/zalo.svg"),
              alt: "",
              width: "20px",
            },
          }),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "team-img" }, [
      _c("img", {
        attrs: {
          src: require("../../../libhome/img/team-1.jpg"),
          alt: "Team Image",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "team-social" }, [
      _c(
        "a",
        {
          staticClass: "btn",
          attrs: { href: "mailto:vimclines@vimc-lines.com" },
        },
        [
          _c("img", {
            attrs: {
              src: require("../../../libhome/img/gmail.svg"),
              alt: "",
              width: "20px",
            },
          }),
        ]
      ),
      _c(
        "a",
        {
          staticClass: "btn",
          attrs: { href: "https://wa.me/84335299516", target: "_blank" },
        },
        [
          _c("img", {
            attrs: {
              src: require("../../../libhome/img/whatsapp.svg"),
              alt: "",
              width: "20px",
            },
          }),
        ]
      ),
      _c(
        "a",
        {
          staticClass: "btn",
          attrs: { href: "skype:suoinho_mk?chat", target: "_blank" },
        },
        [
          _c("img", {
            attrs: {
              src: require("../../../libhome/img/skype.svg"),
              alt: "",
              width: "20px",
            },
          }),
        ]
      ),
      _c(
        "a",
        {
          staticClass: "btn",
          attrs: { href: "https://zalo.me/0974040006", target: "_blank" },
        },
        [
          _c("img", {
            attrs: {
              src: require("../../../libhome/img/zalo.svg"),
              alt: "",
              width: "20px",
            },
          }),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "team-img" }, [
      _c("img", {
        attrs: {
          src: require("../../../libhome/img/team-2.jpg"),
          alt: "Team Image",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "team-social" }, [
      _c(
        "a",
        {
          staticClass: "btn",
          attrs: { href: "mailto:vimclines@vimc-lines.com" },
        },
        [
          _c("img", {
            attrs: {
              src: require("../../../libhome/img/gmail.svg"),
              alt: "",
              width: "20px",
            },
          }),
        ]
      ),
      _c(
        "a",
        {
          staticClass: "btn",
          attrs: { href: "https://wa.me/84335299516", target: "_blank" },
        },
        [
          _c("img", {
            attrs: {
              src: require("../../../libhome/img/whatsapp.svg"),
              alt: "",
              width: "20px",
            },
          }),
        ]
      ),
      _c(
        "a",
        {
          staticClass: "btn",
          attrs: { href: "skype:suoinho_mk?chat", target: "_blank" },
        },
        [
          _c("img", {
            attrs: {
              src: require("../../../libhome/img/skype.svg"),
              alt: "",
              width: "20px",
            },
          }),
        ]
      ),
      _c(
        "a",
        {
          staticClass: "btn",
          attrs: { href: "https://zalo.me/0974040006", target: "_blank" },
        },
        [
          _c("img", {
            attrs: {
              src: require("../../../libhome/img/zalo.svg"),
              alt: "",
              width: "20px",
            },
          }),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "team-img" }, [
      _c("img", {
        attrs: {
          src: require("../../../libhome/img/team-3.jpg"),
          alt: "Team Image",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "team-social" }, [
      _c(
        "a",
        {
          staticClass: "btn",
          attrs: { href: "mailto:vimclines@vimc-lines.com" },
        },
        [
          _c("img", {
            attrs: {
              src: require("../../../libhome/img/gmail.svg"),
              alt: "",
              width: "20px",
            },
          }),
        ]
      ),
      _c(
        "a",
        {
          staticClass: "btn",
          attrs: { href: "https://wa.me/84335299516", target: "_blank" },
        },
        [
          _c("img", {
            attrs: {
              src: require("../../../libhome/img/whatsapp.svg"),
              alt: "",
              width: "20px",
            },
          }),
        ]
      ),
      _c(
        "a",
        {
          staticClass: "btn",
          attrs: { href: "skype:suoinho_mk?chat", target: "_blank" },
        },
        [
          _c("img", {
            attrs: {
              src: require("../../../libhome/img/skype.svg"),
              alt: "",
              width: "20px",
            },
          }),
        ]
      ),
      _c(
        "a",
        {
          staticClass: "btn",
          attrs: { href: "https://zalo.me/0974040006", target: "_blank" },
        },
        [
          _c("img", {
            attrs: {
              src: require("../../../libhome/img/zalo.svg"),
              alt: "",
              width: "20px",
            },
          }),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "team-img" }, [
      _c("img", {
        attrs: {
          src: require("../../../libhome/img/team-4.jpg"),
          alt: "Team Image",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "team-social" }, [
      _c(
        "a",
        {
          staticClass: "btn",
          attrs: { href: "mailto:vimclines@vimc-lines.com" },
        },
        [
          _c("img", {
            attrs: {
              src: require("../../../libhome/img/gmail.svg"),
              alt: "",
              width: "20px",
            },
          }),
        ]
      ),
      _c(
        "a",
        {
          staticClass: "btn",
          attrs: { href: "https://wa.me/84335299516", target: "_blank" },
        },
        [
          _c("img", {
            attrs: {
              src: require("../../../libhome/img/whatsapp.svg"),
              alt: "",
              width: "20px",
            },
          }),
        ]
      ),
      _c(
        "a",
        {
          staticClass: "btn",
          attrs: { href: "skype:suoinho_mk?chat", target: "_blank" },
        },
        [
          _c("img", {
            attrs: {
              src: require("../../../libhome/img/skype.svg"),
              alt: "",
              width: "20px",
            },
          }),
        ]
      ),
      _c(
        "a",
        {
          staticClass: "btn",
          attrs: { href: "https://zalo.me/0974040006", target: "_blank" },
        },
        [
          _c("img", {
            attrs: {
              src: require("../../../libhome/img/zalo.svg"),
              alt: "",
              width: "20px",
            },
          }),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }