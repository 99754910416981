<template>
  <v-card>
    <v-card-text style="font-size: 18px">
      <v-row
        no-gutters
        align="center"
      >
        <v-col
          cols="7"
          class="text-truncate"
        >
          <v-btn
            rounded
            color="secondary lighten-1"
            class="primary--text"
            elevation="0"
          >
            {{ container }}
          </v-btn>
          <flag
            :iso="country"
            class="mx-4"
          />

          <span class="font-weight-medium text--primary"
            >{{ location }}, {{ country.toUpperCase() }}</span
          >
        </v-col>
        <v-col cols="4">
          <span class="mx-4">from</span>
          <span class="font-weight-bold text--primary"
            >{{ currency }} {{ price }}</span
          >
        </v-col>
        <v-col cols="1">
          <v-row
            no-gutters
            justify="center"
          >
            <v-icon> mdi-chevron-right </v-icon>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ['container', 'price', 'currency', 'country', 'location'],
};
</script>

<style scoped>
.flag-icon {
  width: 26px !important;
  line-height: 20px !important;
}
</style>
