var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-snackbar",
    {
      attrs: {
        timeout: _vm.snackbar.timeout,
        color: _vm.snackbar.color,
        transition: "scroll-y-reverse-transition",
        top: "",
        rounded: "lg",
      },
      model: {
        value: _vm.snackbarStatus,
        callback: function ($$v) {
          _vm.snackbarStatus = $$v
        },
        expression: "snackbarStatus",
      },
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "d-flex align-center", attrs: { cols: "11" } },
            [
              _c("span", {
                domProps: { innerHTML: _vm._s(_vm.snackbar.message) },
              }),
            ]
          ),
          _c(
            "v-col",
            {
              staticClass: "d-flex justify-end align-center",
              attrs: { cols: "1" },
            },
            [
              _c(
                "v-btn",
                {
                  attrs: { icon: "", "x-small": "" },
                  on: {
                    click: function ($event) {
                      _vm.snackbarStatus = false
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { small: "" } }, [
                    _vm._v(" mdi-close "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }