var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c(
        "v-card-text",
        [
          _c(
            "v-form",
            {
              attrs: { onSubmit: "return false;" },
              on: {
                submit: function ($event) {
                  return _vm.onSignup()
                },
              },
            },
            [
              _c(
                "v-alert",
                { staticClass: "mb-8", attrs: { outlined: "", type: "error" } },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("text-change-password-security")) + " "
                  ),
                ]
              ),
              _c("p", { staticClass: "overline" }, [
                _vm._v(_vm._s(_vm.$t("change-password"))),
              ]),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pr-2",
                      attrs: { cols: _vm.$vuetify.breakpoint.mobile ? 12 : 4 },
                    },
                    [
                      _c("div", { staticClass: "caption" }, [
                        _vm._v(_vm._s(_vm.$t("old-password"))),
                      ]),
                      _c("v-text-field", {
                        attrs: {
                          label: _vm.$t("label-old-password"),
                          "prepend-inner-icon": "mdi-lock",
                          type: "password",
                          outlined: "",
                          solo: "",
                          dense: "",
                          autocomplete: "",
                          "error-messages": _vm.oldPasswordErrors,
                          counter: 32,
                        },
                        on: {
                          input: function ($event) {
                            return _vm.$v.oldPassword.$touch()
                          },
                          blur: function ($event) {
                            return _vm.$v.oldPassword.$touch()
                          },
                        },
                        model: {
                          value: _vm.$v.oldPassword.$model,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.$v.oldPassword,
                              "$model",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "$v.oldPassword.$model",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "px-2",
                      attrs: { cols: _vm.$vuetify.breakpoint.mobile ? 12 : 4 },
                    },
                    [
                      _c("div", { staticClass: "caption" }, [
                        _vm._v(_vm._s(_vm.$t("new-password"))),
                      ]),
                      _c("v-text-field", {
                        attrs: {
                          label: _vm.$t("label-new-password"),
                          "prepend-inner-icon": "mdi-lock",
                          type: "password",
                          outlined: "",
                          solo: "",
                          dense: "",
                          autocomplete: "new-password",
                          "error-messages": _vm.newPasswordErrors,
                          counter: 32,
                        },
                        on: {
                          input: function ($event) {
                            return _vm.$v.newPassword.$touch()
                          },
                          blur: function ($event) {
                            return _vm.$v.newPassword.$touch()
                          },
                        },
                        model: {
                          value: _vm.$v.newPassword.$model,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.$v.newPassword,
                              "$model",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "$v.newPassword.$model",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pl-2",
                      attrs: { cols: _vm.$vuetify.breakpoint.mobile ? 12 : 4 },
                    },
                    [
                      _c("div", { staticClass: "caption" }, [
                        _vm._v(_vm._s(_vm.$t("confirm-new-password"))),
                      ]),
                      _c("v-text-field", {
                        attrs: {
                          label: _vm.$t("label-confirm-new-password"),
                          "prepend-inner-icon": "mdi-lock",
                          type: "password",
                          outlined: "",
                          solo: "",
                          dense: "",
                          autocomplete: "new-password",
                          "error-messages": _vm.confirmNewPasswordErrors,
                          counter: 32,
                        },
                        on: {
                          input: function ($event) {
                            return _vm.$v.confirmNewPassword.$touch()
                          },
                          blur: function ($event) {
                            return _vm.$v.confirmNewPassword.$touch()
                          },
                        },
                        model: {
                          value: _vm.$v.confirmNewPassword.$model,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.$v.confirmNewPassword,
                              "$model",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "$v.confirmNewPassword.$model",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "mt-4", attrs: { "no-gutters": "" } },
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "px-16",
                      attrs: {
                        color: "primary",
                        loading: _vm.isLoading,
                        disabled:
                          _vm.isLoading ||
                          _vm.$v.$invalid ||
                          _vm.isChangedPassword,
                      },
                      on: { click: _vm.onChangePassword },
                    },
                    [_vm._v(_vm._s(_vm.$t("button-update")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }